import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import ButtonComp from "../../../component/Button";
import { Box, Typography, Grid } from "@mui/material";
import CancelPolicyModal from "./CancelPolicyModal";
import { getSx } from "./style";
import NavBar from "../home";
import { convertTo12HourFormat, getCurrencyFormat } from "../../../common/constant";
import { setAddOnCategoryIds, setPageNum, setSelectedCategoryIds } from "../../../store/slices/bookserviceinfo";
import { Container } from "@mui/system";

export const EventInfoData = ({ bookingData, info, setOpenBookServiceModal, amount }) => {
  const classes = getSx();
  const addOnCategories = bookingData?.services?.slice(1,).flatMap(service => service.categories);
  const addOnServices = info?.services?.slice(1,)?.flatMap(service => service.categories);
  const dispatch = useDispatch();
  function convertToHours(timeStr) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }

    return new Date(1970, 0, 1, hours, minutes);
  }



  const getTotalAmount = (type, peramount, count, daysArr, noofDays) => {
    if (type == "Per Day") {
      let val = (peramount * noofDays).toFixed(2)
      return getCurrencyFormat(val);
    }
    else if (type == "Per Person") {
      let val = (peramount * count * daysArr?.length).toFixed(2);
      return getCurrencyFormat(val)
    }
    else if (type == "Hourly") {
      let hours = getNumberOfHours(daysArr);
      // daysArr?.map((item) => {
      //   let startTime = convertToHours(item.startTime);
      //   let endTime = convertToHours(item.endTime);
      //   const diffInMilliseconds = endTime - startTime;
      //   const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      //   hours = hours + diffInHours;
      // })
      let val = (hours * peramount).toFixed(2)
      return getCurrencyFormat(val)
    }
    else {
      let val = peramount.toFixed(2)
      return getCurrencyFormat(val);
    }
  }
  const getNumberOfHours = (daysArr) => {
    let hours = 0;
    daysArr?.map((item) => {
      let startTime = convertToHours(item.startTime);
      let endTime = convertToHours(item.endTime);

      // If end time is less than start time, it means the end time is on the next day
      if (endTime < startTime) {
        endTime.setDate(endTime.getDate() + 1); // Add one day to end time
      }

      const diffInMilliseconds = endTime - startTime;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      hours += diffInHours;
    });
    return hours;
  };
  // const getNumberOfHours=(daysArr)=>{
  //   let hours=0;
  //     daysArr?.map((item)=>{
  //       let startTime=convertToHours(item.startTime);
  //       let endTime=convertToHours(item.endTime);
  //       const diffInMilliseconds = endTime - startTime;
  //       const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  //             hours=hours+diffInHours;
  //     })
  //     return hours

  // }
  useEffect(() => {
    let catIds = bookingData.services[0].categories.map((item) => item._id);
    dispatch(setSelectedCategoryIds(catIds));
    let addOnCats = bookingData?.services?.slice(1,).flatMap(service => service.categories);
    let addOnCatIds = addOnCats.map((item) => item?._id);
    dispatch(setAddOnCategoryIds(addOnCatIds))
  }, [bookingData])
  return (
    <Box sx={{ padding: { xs: "15px 0", sm: "15px 0", md: '15px 0', lg: '15px 0', xl: '30px 0' } }}>
      <Typography sx={{
        color: "black",
        fontWeight: "bold",
        margin: {
          xs: "10px 0 5px",
          sm: "10px 0 5px",
          lg: "10px 0 5px",
          xl: "15px 0 5px",
          md: "10px 0 5px"
        },
        fontFamily: "Montserrat, sans-serif",
        fontSize: {
          xs: '14px',
          sm: '15px',
          md: '15px',
          lg: '18px',
          xl: '18px'
        }
      }} variant="h5">
        Service Category
      </Typography>

      <Grid
        container
        style={{ width: "99%", margin: "0px", display: "flex" }}
        sx={classes.catinfo}
      >
        {bookingData?.services[0]?.categories?.map((item) => {
          return (
            <Grid item xs={12} md={5.6} sx={classes.catinfo1} key={item}>
              <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Service Category</Box>
                <Box sx={classes.catright}>{item?.categoryId?.categoryName}</Box>
              </Box>
              {(item?.field1.value == "Per Day" || item?.field1.value == "Per Person") && <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Selected Dates</Box>
                <Box sx={classes.catright}>
                  <Box sx={{ display: "flex" }}>
                    {item?.field6?.value.map((dateitem, index) => {
                      return (
                        <Box style={{ marginLeft: "5px" }} key={dateitem}>{dayjs(dateitem?.date)?.format("MMM DD")}{item?.field6?.value?.length - 1 != index && ","}</Box>
                      )

                    })}
                  </Box>
                </Box>
              </Box>}
              {(item?.field1.value == "Hourly" || item?.field1.value == "Fixed" || item?.field1.value == "Fixed Cost") &&
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Selected Dates</Box>
                  <Box sx={classes.catright}>
                    <Box sx={{ display: "block" }}>
                      {item?.field6?.value?.map((dateitem, index) => {
                        return (
                          <Box key={dateitem}>{`${dayjs(dateitem?.date).format("MMM DD")} From: ${convertTo12HourFormat(dateitem?.startTime)} - ${convertTo12HourFormat(dateitem?.endTime)}`}</Box>
                        )

                      })}
                    </Box>
                  </Box>
                </Box>}
              {item?.field1.value == "Hourly" && <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Total Number of Hours</Box>
                <Box sx={classes.catright}>{getNumberOfHours(item.field6.value)}</Box>
              </Box>}
              {item?.field1.value == "Per Day" && <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>{item?.field7.label}</Box>
                <Box sx={classes.catright}>{item.field7?.value}</Box>
              </Box>}{item?.field3?.label && item?.field3?.value &&
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>{item?.field3?.label}</Box>
                  <Box sx={classes.catright}>{item?.field3?.value}</Box>
                </Box>}
              <hr
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  height: "1px",
                  border: "none",
                }}
              />
              <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>{`Service fee (${item?.field1?.value})`}</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(item?.field2?.value)}</Box>
              </Box>
              <hr
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  height: "1px",
                  border: "none",
                }}
              />
              <Box sx={classes.catmain}>
                <Typography variant="body" fontWeight="bold" sx={{ fontFamily: 'Montserrat,sans-serif' }}>
                  Total Amount
                </Typography>
                <Typography variant="body" fontWeight="bold" sx={{ fontFamily: 'Montserrat,sans-serif' }}>
                  {getTotalAmount(item.field1.value, item.field2.value, item.field3.value, item.field6.value, item?.field7?.value)}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      {info.services[0]?.categories?.length > bookingData?.services[0]?.categories?.length && <Box sx={{ margin: "0px 10px", marginBottom: "1rem", width: { xs: "95%", md: "46%" } }}><ButtonComp
        label="Add More Service Category" type="whitebtn" width="100%" borderRadius="12px" onClick={() => { setOpenBookServiceModal(true); dispatch(setPageNum(1)) }} /></Box>}

      {info.services.length > 1 && <Box>
        <Typography variant="h5" fontWeight="bold" margin="10px 0" sx={{
          fontFamily: 'Montserrat,sans-serif', fontSize: {
            xs: '16px', sm: '18px', md: '18px', lg: '20px', xl: '20px', color: '#000000', lineHeight: '26px'
          }
        }}>
          Add-On Services
        </Typography>
      </Box>}
      <Grid
        container
        style={{ width: "99%", margin: "0px", display: "flex" }}
        sx={classes.catinfo}
      >
        {addOnCategories.map((item) => {
          return (
            <Grid item xs={12} md={5.6} sx={classes.catinfo1} key={item}>
              <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Service Category</Box>
                <Box sx={classes.catright}>{item?.categoryId?.categoryName}</Box>
              </Box>
              {(item?.field1?.value == "Per Person" || item?.field1?.value == "Per Day") &&
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Selected Dates</Box>
                  <Box sx={classes.catright}>
                    <Box sx={{ display: "flex" }}>
                      {item?.field6?.value.map((dateitem, index) => {
                        return (
                          <Box style={{ marginLeft: "5px" }} key={dateitem}>{dayjs(dateitem?.date)?.format("MMM DD")}{item?.field6?.value?.length - 1 != index && ","}</Box>
                        )

                      })}
                    </Box>
                  </Box>
                </Box>}
              {(item?.field1.value == "Hourly" || item?.field1.value == "Fixed" || item?.field1.value == "Fixed Cost") &&
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Selected Dates</Box>
                  <Box sx={classes.catright}>
                    <Box sx={{ display: "block" }}>
                      {item?.field6?.value?.map((dateitem, index) => {
                        return (
                          <Box key={dateitem}>{`${dayjs(dateitem?.date).format("MMM DD")} From: ${convertTo12HourFormat(dateitem?.startTime)} - ${convertTo12HourFormat(dateitem?.endTime)}`}</Box>
                        )

                      })}
                    </Box>
                  </Box>
                </Box>}
              {item?.field1.value == "Hourly" && <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Total Number of Hours</Box>
                <Box sx={classes.catright}>{getNumberOfHours(item.field6.value)}</Box>
              </Box>}
              {item?.field3?.value && item?.field3?.label &&
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>{item?.field3.label}</Box>
                  <Box sx={classes.catright}>{item.field3?.value}</Box>
                </Box>}
              {item?.field1.value == "Per Day" && <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>{item?.field7.label}</Box>
                <Box sx={classes.catright}>{item.field7?.value}</Box>
              </Box>}
              <hr
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  height: "1px",
                  border: "none",
                }}
              />
              <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>{`Service fee (${item.field1.value})`}</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(item.field2.value)}</Box>
              </Box>
              <hr
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  height: "1px",
                  border: "none",
                }}
              />
              <Box sx={classes.catmain}>
                <Typography variant="body" fontWeight="bold" sx={{ fontFamily: 'Montserrat,sans-serif' }}>
                  Total Amount
                </Typography>
                <Typography variant="body" sx={{ fontFamily: 'Montserrat,sans-serif' }} fontWeight="bold">
                  {getTotalAmount(item?.field1?.value, item?.field2?.value, item?.field3?.value, item?.field6?.value, item?.field7?.value)}
                </Typography>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      {addOnServices?.length > addOnCategories?.length && <Box sx={{ margin: "0px 10px ", width: { xs: "95%", md: "46%" }, marginBottom: "3rem" }}><ButtonComp label="Add More Add-On Service Category"
        type="whitebtn" borderRadius="12px" width="100%" onClick={() => { setOpenBookServiceModal(true); dispatch(setPageNum(3)) }} /></Box>}
      <CancelPolicyModal />
    </Box>
  );
};
const EventInfo = () => {
  return (
    <NavBar>
      <Container sx={{
        padding: {
          xs: '0 15px',
          sm: '0 30px',
          md: '0 30px',
          lg: '0 30px',
          xl: 0
        }
      }} maxWidth="xl">
        <EventInfoData />
      </Container>
    </NavBar>
  );
};

export default EventInfo;
