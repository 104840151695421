import React ,{useEffect}from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import { getSx } from "./style";
import englishlang from "../../assets/images/svg/englishlang.svg";
import event2tlogo from "../../assets/images/svg/event2tlogo.svg";
import spanishlang from "../../assets/images/svg/spanishlang.svg";

import {storeLanguage} from '../../utils/auth-token-utils/cookies';
import { setProvider } from "../../store/slices/common";

import { routePaths } from "../../Routes/route-paths";

import "react-lazy-load-image-component/src/effects/blur.css";

const LanguageSelect = () => {
  const classes = getSx();
  const navigate = useNavigate();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(setProvider(3))
  },[])

  return (
    <Box sx={classes.maincontainer}>
      <Box sx={classes.container}>
        <Box sx={classes.event2timgcont}>
          <img alt="" style={{ width: "100%", height: "100%" }} src={event2tlogo} />
        </Box>
        <Box sx={{...classes.heading,fontSize:{xs:"21px",md:"25px",fontFamily:"Montserrat, sans-serif"}}}>Choose Your Language</Box>
        <Box sx={classes.content}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididun
        </Box>
        <Box sx={classes.englishlang}>
          <LazyLoadImage
            className={`model wow fadeInUp`}
            data-wow-delay=".3s"
            src={englishlang}
            effect="blur"
            style={{
              animationName: "fadeInUp",
              animationDelay: "0.3s",
              width: "100%",
              height: "100%",
            }}
            onClick={() => {
              navigate(routePaths.PROVIDER_SELECT);
              storeLanguage("en");
            }}
          />
        </Box>
        <Box sx={classes.englishlang}>
          <LazyLoadImage
            className={`model wow fadeInUp`}
            data-wow-delay=".3s"
            src={spanishlang}
            effect="blur"
            style={{
              animationName: "fadeInUp",
              animationDelay: "0.3s",
              width: "100%",
              height: "100%",
              opacity:"0.2",
              cursor:"not-allowed"
            }}
          
          />
        
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageSelect;
