import React from "react";
import { Box } from "@mui/system";
import { getSx } from "./style";
import righticon from "../../assets/images/svg/rightarrowicon.svg";
import ButtonComp from "../Button";

import { getRole } from "../../utils/auth-token-utils/cookies";

const PrivacyPolicy = ({ handlePolicy, handleClick, setTerms, setPolicy,fromMenu }) => {
  const classes = getSx();
  const role = getRole();
  const handleClick1 = () => {
    handlePolicy();
  };
  return (
    <Box sx={classes.maincontainer}>
      <Box sx={!location.pathname.includes("privacypolicy")? classes.container:classes.container1}>
      {!location.pathname.includes("privacypolicy") &&<><Box
          sx={{
            left: 0,
            cursor: "pointer",
            position:'absolute'
          }}
        >
          <img
            onClick={() => {
              if(fromMenu)
                handleClick1();
              else
              {
              if (role == "1" || role == 1) {
                setPolicy(false);
                setTerms(true);
              } else {
                handleClick1();
              }
            }
            }}
            src={righticon}
          />
        </Box>
        <Box sx={classes.heading}>
          Privacy & <Box sx={classes.spanheading}>Policy</Box>
        </Box>
        <Box
          sx={{
            ...classes.heading,
            ...classes.smallheading,
            fontSize: { xs: "12px", md: "16px" },fontFamily:"Montserrat, sans-serif"
          }}
        >
          Last updated:<Box sx={classes.spansmallheading}> July 1, 2024</Box>
        </Box>
        </>}
        <Box sx={!location.pathname.includes("privacypolicy")?classes.content:classes.content1}>
          <Box sx={classes.question}>1. Introduction</Box>
          <Box sx={classes.answer}>
            Eventutti ("we," "us," or "our") is committed to protecting the
            privacy of our users ("you," "your"). This Data Privacy Policy
            outlines how we collect, use, disclose, and safeguard your
            information when you use our Eventutti application. By using
            Eventutti, you agree to the collection and use of information in
            accordance with this policy.
          </Box>
          <Box sx={classes.question}>2.Information We Collect</Box>
          <Box sx={classes.answer}>
            We collect information from you when you use our application,
            including:
            <ul listType="dotted" className="dotted-list">
              <li>
                {" "}
               <span style={{color:"black",fontWeight:"bold"}}> Personal Information</span>: Name, email address, phone number, and
                other contact details.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Event Information
                </span>: Details about the events you create,
                including dates, locations, and types of services required.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Payment Information
                </span>
                : Credit card details, billing address, and
                transaction history.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Usage Data
                </span>
                : Information about how you use our application,
                including the services you interact with and the preferences you
                set.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Technical Data
                </span>
                : IP address, browser type, device information,
                and other technical details.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Business License Information
                </span>
                : For Eventutti Partners, we collect
                business license information to verify and authenticate your
                business.
              </li>
            </ul>
          </Box>
          <Box sx={classes.question}>3. How We Use Your Information</Box>
          <Box sx={classes.answer}>
            We use your information to:
            <ul listType="dotted" className="dotted-list">
              <li>Provide and maintain our services.</li>
              <li>Process transactions and manage payments.</li>
              <li>Verify and authenticate Eventutti Partners' businesses.</li>
              <li>Communicate with you about your account and events.</li>
              <li>
                Personalize your experience and recommend relevant services.
              </li>
              <li>Improve our application and develop new features.</li>
              <li>Ensure the security and integrity of our application.</li>
              <li>Comply with legal obligations.</li>
            </ul>
          </Box>
          <Box sx={classes.question}>4. Sharing Your Information</Box>
          <Box sx={classes.answer}>
            We do not sell your personal information. We may share your
            information with:
            <ul listType="dotted" className="dotted-list">
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Service Providers 
                </span>
                : Third-party vendors who help us provide our
                services, such as payment processors and email service
                providers.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Event Partners 
                </span>
                : Vendors and service providers you choose to
                engage with through our application.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}>
                Legal Authorities 
                </span>
                : When required by law or to protect our
                rights and property.
              </li>
            </ul>
          </Box>
          <Box sx={classes.question}>5. Data Security</Box>
          <Box sx={classes.answer}>
            We implement industry-standard security measures to protect your
            information from unauthorized access, use, or disclosure. These
            measures include encryption, secure servers, and regular security
            assessments.
          </Box>
          <Box sx={classes.question}>6. Data Retention</Box>
          <Box sx={classes.answer}>
            We retain your personal information only as long as necessary to
            fulfill the purposes outlined in this policy, unless a longer
            retention period is required or permitted by law.
          </Box>
          <Box sx={classes.question}>7. Your Rights</Box>
          <Box sx={classes.answer}>
            <ul listType="dotted" className="dotted-list">
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Access 
                </span>
                : You can request access to the personal information we
                hold about you.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Correction
                </span>
                 : You can request corrections to your personal
                information if it is inaccurate or incomplete.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Deletion
                </span>
                 : You can request the deletion of your personal
                information, subject to legal constraints.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}>
                Restriction
                </span> : You can request restrictions on the processing of
                your personal information.
              </li>
              <li>
              <span style={{color:"black",fontWeight:"bold"}}> 
                Portability
                </span>
                 : You can request a copy of your personal
                information in a structured, commonly used, and machine-readable
                format.
              </li>
            </ul>
          </Box>
          <Box sx={classes.question}>8. Children's Privacy</Box>
          <Box sx={classes.answer}>
            Our application is not intended for use by children under the age of
            13. We do not knowingly collect personal information from children
            under 13. If we become aware that we have collected personal
            information from a child under 13, we will delete it promptly.
          </Box>
          <Box sx={classes.question}>9. Changes to This Policy</Box>
          <Box sx={classes.answer}>
            We may update this Data Privacy Policy from time to time. We will
            notify you of any changes by posting the new policy on our
            application and updating the effective date. Your continued use of
            our application after any changes constitutes your acceptance of the
            new policy.
          </Box>
          <Box sx={classes.question}>10. Contact Us</Box>
          <Box sx={classes.answer}>
            If you have any questions or concerns about this Data Privacy
            Policy, please contact us at:
            <br />
            <br />
           <span style={{color:"black",fontWeight:"bold"}}> Eventutti Privacy Team</span>
            <br />
            <span style={{color:"black",fontWeight:"bold"}}>  Email </span>: <a  href="mailto: Info@eventutti.io">info@eventutti.io</a>
            <br />
            <span style={{color:"black",fontWeight:"bold"}}>    Address: 5000 Centregreen Way, Suite 500, Cary, North Carolina
            27513, United States</span>
            <br />
          </Box>
        </Box>
        {role == "1" && !fromMenu && (
          <Box sx={classes.button}>
            <ButtonComp className="height-50"
                height={'60px'}
              width="400px"
              type="active"
              label="I agree"
              onClick={handleClick}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
