import React, { useState, useEffect } from "react";

import ButtonComp from "../../../../component/Button";
import CustomTextField from "../../../../component/Textfield";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { getSx } from "../style";

import { getCategoryListApi } from "../../../../api/addService";

const AddAddOnSubCategory = ({
  addonsubCategory,
  setaddonSubCategory,
  setPage,
  addOnCat,
  isEdit,
  isAddOn,
  handleDelete,
}) => {
  const classes = getSx();
  const [loading, setLoading] = useState(false);
  const [filteredList,setFilteredList]=useState([]);
  const [subCategories,setSubCategories]=useState([]);

  const handleClick = () => {
      setPage((prev)=>prev+1)
  };
  const callApi = async () => {
    setLoading(true);
    const resp = await getCategoryListApi(addOnCat?._id);
    setSubCategories(resp?.data?.data);
    setFilteredList(resp?.data?.data);
    setLoading(false);
  };
  const handleFilter=(val)=>{
   const resp=subCategories?.filter((item)=>{
  if(item.categoryName.toLowerCase().includes(val.toLowerCase()))
  return item;
   })
   setFilteredList(resp)
  }
  const handleSetSubCategory = (val) => {
    let isInCat = addonsubCategory?.filter((item) => item?._id == val?._id);
    let filterData = addonsubCategory?.filter((item) => item?._id != val?._id);

    if(isInCat?.length>0)
    setaddonSubCategory([...filterData]) ;
  else
  setaddonSubCategory([...filterData,val]) ;

      };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {loading ? (
        <Box sx={{ ...classes.main, height: "60vh" }}>
          <CircularProgress color="#fff" />
        </Box>
      ) : (
        <Box sx={classes.main}>
          <Box sx={classes.search}>
            <CustomTextField
              backgroundColor="rgba(246, 245, 245, 1)"
              placeholder={  isAddOn?"Search Add-On Service Category":"Search Service Category"}
              type="text"
              name="category"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
          </Box>
          <Typography variant="body" fontWeight="bold">
          {  isAddOn?"Select Add-On Service Category":"Select Service Category"}
          </Typography>
          <Box sx={{ minHeight: {
              xs: "auto",
              sm:'auto',
              md:'auto',
              lg:'auto',
              xl: "auto"
            },
            maxHeight: {
              xs: "calc(100vh - 400px)",
              sm:'calc(100vh - 400px)',
              md:'calc(100vh - 600px)',
              lg:'calc(100vh - 345px)',
              xl: "calc(100vh - 450px)"
            }, overflow: "auto" }}>
            <Box sx={classes.catbox}>
              {filteredList?.map((item) => {
                return (
                  <Box style={{cursor:'pointer'}}
                    sx={
                        addonsubCategory?.some(subItem => subItem._id === item._id)
                    
                        ? { ...classes.singleItem, ...classes.singleItemsel }
                        : classes.singleItem
                    }
                    key={item}
                    onClick={() => {
                      handleSetSubCategory(item);
                    }}
                  >
                    {item.categoryName}
                  </Box>
                );
              })}
            </Box>
          </Box>
          {isEdit?<Box sx={{display:"flex",justifyContent:"space-between",width:"100%",padding:"0px 15% "}}>
            <ButtonComp
            label="Delete"
            width="44%"
            onClick={handleDelete}
            type="whitebtn"
          />
           <ButtonComp
            label="Next"
            width="52%"
            onClick={handleClick}
            disabled={addonsubCategory?.length ? false : true}
            type={addonsubCategory?.length ? "active" : "disabled"}
          />

          </Box>:
          <ButtonComp
            label="Next"
            width="100%"
            onClick={handleClick}
            disabled={addonsubCategory?.length ? false : true}
            type={addonsubCategory?.length ? "active" : "disabled"}
          />}
        </Box>
      )}
    </>
  );
};

export default AddAddOnSubCategory;
