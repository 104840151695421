import React, { useState,useEffect } from "react";

import ButtonComp from "../../../component/Button";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { getSx } from "./style";
import camicon from "../../../assets/images/svg/camicon.svg";
import editicon from "../../../assets/images/svg/editicon.svg";
import plusicon from "../../../assets/images/svg/plusicon.svg";

import { getRole } from "../../../utils/auth-token-utils/cookies";
import { uploadImageApi } from "../../../api/Signup";

const UploadProfile = ({ setValue, setPage,handleData,data,loader,saveProfile }) => {
  const [image, setImage] = useState(false);
  const [loading,setLoading]=useState(false);
  const role=getRole();
  const classes = getSx();
  useEffect(()=>{
    if(data?.profileImage)
    setImage(data?.profileImage)
  if(role=="1")
  setValue(45)
  },[])
  const Circle = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  }));
  const handleFileChange = async (e) => {
    setLoading(true)
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === "success") {
          setImage({url:response?.data?.fileUrl,key:response?.data?.key});
          if(role=="0")
         setValue(100);
        } 
      }
    }
     setLoading(false);
  };

  return (
    <Box>
      <Typography sx={classes.heading}>Upload your profile photo</Typography>
      {/* <Box sx={classes.imageinfo}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industrys standard
      </Box> */}
        <Box sx={classes.createFormMain}>
      <Box sx={classes.profileimg}>
        {loading ? (
            <Box sx={classes.profileImgbox}>
          <CircularProgress  color="rgba(23, 186, 174, 1)" />
          </Box>
        ) : (
          <Box sx={classes.profileImgbox}>
            <Circle sx={classes.profimgdoted}>
            <Circle sx={classes.profimgback}>
          <Circle sx={classes.profimg}>
            <img
              src={image  ? image.url : camicon}
              style={{ width: "100%", height: "100%", borderRadius: "50%",objectFit:"cover" }}
            />
          </Circle>
          </Circle>
          </Circle>
          <Box sx={classes.plusicon}>
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="image/*"
          />
          <label htmlFor="file-upload">
            <Box>
              <img alt="" src={image ? editicon : plusicon} style={{cursor:"pointer"}} className="edit-plus-img" />
            </Box>
          </label>
        </Box>
        </Box>
        )}
      </Box>
      <Box sx={{...classes.profileimg}}>
        <ButtonComp
            className="responsive-btn-class"
          width="80%"
          loading={loader}
          label="Continue"
          disabled={!(image&&!loading)}
          type={image&&!loading ? "active" : "disabled"}
          style={{marginTop:0, width: "100%",height:'60px', fontWeight:600,fontFamily:"Montserrat, sans-serif" }}
          onClick={() => {
            handleData({ profileImage: image,countryName:"",rfcNumber:"" });
            if(role=="1")
            setPage(prev=>prev+1)
          else
            saveProfile({profileImage:image});
          }}
        />
      </Box>
        </Box>
    </Box>
  );
};

export default UploadProfile;
