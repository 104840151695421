import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import notransaction from "../../../assets/images/notransaction.png";
import { transactionHistoryApi } from "../../../api/profile";
import { getCurrencyFormat } from "../../../common/constant";

const TransactionHistory = () => {
  const [transArr, setTransArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("role");
  const callApi = async () => {
    setLoading(true);
    const resp = await transactionHistoryApi();
    setTransArr(resp.data.data);
    setLoading(false);
  };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h5"
            sx={{
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
                lg: "24px",
                xl: "28px",
              },
              lineHeight: {
                xs: "26px",
                sm: "28px",
                md: "32px",
                lg: "36px",
                xl: "40px",
              },
              fontWeight: 700,
              marginBottom: "30px",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            Transaction History
          </Typography>
          {transArr?.length == 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                flexDirection: "column",
              }}
            >
              <img alt="" src={notransaction} style={{ width: "185px", height: "185px" }} />
              <Typography
                sx={{ fontFamily: "Montserrat, sans-serif" }}
                variant="h5"
                fontWeight="bold"
                margin="10px 0px"
              >
                No Transaction Yet!
              </Typography>
              <Typography
                sx={{ fontFamily: "Montserrat, sans-serif" }}
                variant="h6"
                color="rgba(0, 0, 0, 0.5)"
                margin="10px 0px"
                textAlign="center"
              >
                {role == "0"
                  ? "Your Transactions Will Show Here Once You’ve Made Your First Payment"
                  : "Your Transactions Will Show Here Once You’ve Receive Your First Payment"}
              </Typography>
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              style={{ padding: "10px", width: "100%", overflowX: "auto" }}
              className="scrollableinfo"
            >
              <Table>
                <TableBody>
                  <TableRow
                    style={{
                      borderRadius: "5px",
                      marginBottom: "10px",
                      overflow: "hidden",
                      padding: "5px",
                      backgroundColor: "rgba(246, 245, 245, 1)",
                      display: "flex",
                      fontWeight: "bold",
                      justifyContent: "space-between",
                      borderBottom: "none",
                      color: "black",
                    }}
                  >
                    <TableCell
                      sx={{
                        width: "154px",
                        borderBottom: "none",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        borderBottom: "none",
                        width: "224px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Booking Id
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "112px",
                        borderBottom: "none",
                        fontWeight: "bold",
                        fontSize: "17px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Payment
                    </TableCell>

                    {role == "0" && (
                      <TableCell
                        sx={{
                          width: "185px",
                          borderBottom: "none",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Service Amount
                      </TableCell>
                    )}

                    {role == "0" && (
                      <TableCell
                        sx={{
                          width: "185px",
                          borderBottom: "none",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Discount Amount
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        width: "157px",
                        borderBottom: "none",
                        fontWeight: "bold",
                        fontSize: "16px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      EventTutti Fee
                    </TableCell>

                    {role == "0" && (
                      <TableCell
                        sx={{
                          width: "185px",
                          borderBottom: "none",
                          fontWeight: "bold",
                          fontSize: "16px",
                          fontFamily: "Montserrat, sans-serif",
                          textAlign: "center",
                        }}
                      >
                        Tax(Amount)
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        width: "150px",
                        borderBottom: "none",
                        fontWeight: "bold",
                        fontSize: "16px",
                        fontFamily: "Montserrat, sans-serif",
                        textAlign: "center",
                      }}
                    >
                      Total Amount
                    </TableCell>
                  </TableRow>
                  <Box
                    sx={{ height: { sm: "50vh", lg: "50vh", xl: "56vh" }, overflow: "auto" }}
                    className="scrollableinfo"
                  >
                    {transArr?.map((item) => {
                      return (
                        <TableRow
                          key={item}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: "5px",
                            marginBottom: "10px",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            padding: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "154px",
                              borderBottom: "none",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "15px",
                            }}
                          >
                            {role == "1" ? (
                              <>
                                <img
                                  src={item?.userId?.profileImage?.url}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "30%",
                                    marginRight: "4px",
                                    objectFit: "cover",
                                  }}
                                />
                                {item?.userId.firstName} {item?.userId.lastName}
                              </>
                            ) : (
                              <>{item?.parentServiceId?.title}</>
                            )}
                          </TableCell>
                          <TableCell sx={{ fontSize: "15px", borderBottom: "none", width: "224px" }}>
                            {item?.bookingId}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "112px",
                              borderBottom: "none",
                              fontSize: "15px",
                            }}
                          >
                            Credit Card
                          </TableCell>
                          {role == "0" && (
                            <TableCell
                              sx={{
                                width: "185px",
                                borderBottom: "none",
                                fontSize: "15px",
                                textAlign: "center",
                              }}
                            >
                              {getCurrencyFormat(item?.totalAmount)}
                            </TableCell>
                          )}
                          {role == "0" && (
                            <TableCell
                              sx={{
                                width: "185px",
                                borderBottom: "none",
                                fontSize: "15px",
                                textAlign: "center",
                              }}
                            >
                              {getCurrencyFormat(item?.discountAmount)}
                            </TableCell>
                          )}
                          <TableCell
                            sx={{
                              width: "157px",
                              borderBottom: "none",
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            {role == "1"
                              ? getCurrencyFormat(item?.totalEventuttiFee)
                              : getCurrencyFormat(item?.eoEventTuttiFee)}
                          </TableCell>

                          {role == "0" && (
                            <TableCell
                              sx={{
                                width: "185px",
                                borderBottom: "none",
                                fontSize: "15px",
                                textAlign: "center",
                              }}
                            >
                              {getCurrencyFormat(item?.eoTax)}
                            </TableCell>
                          )}
                          <TableCell
                            sx={{
                              width: "150px",
                              borderBottom: "none",
                              fontSize: "15px",
                              textAlign: "center",
                            }}
                          >
                            {role == "1"
                              ? getCurrencyFormat(item?.serviceProviderAmount)
                              : getCurrencyFormat(item?.eoTotalPaidAmount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Box>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
};

export default TransactionHistory;
