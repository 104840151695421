import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { modalVariant } from "../../../component/Modal/Modalutils";
import { Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AddOnServices from "./add-on-services";
import AddonServiceList from "./add-on-services/AddonServiceList";
import ServiceImageDesc from "./add-on-services/ServiceImageDesc";
import { AddressImage } from "./AddressImage";
import AddService from "./AddService";
import AddServiceCategory from "./AddServiceCategory";
import AddServiceDesc from "./AddServiceDesc";
import AddSubInfo from "./AddSubInfo";
import Aminities from "./Aminities";
import AddAddOnSubCategory from "./edit-add-on-service/AddAddOnSubCategory";
import AddAddOnSubInfo from "./edit-add-on-service/AddAddOnSubInfo";
import AddOnAddService from "./edit-add-on-service/AddOnAddService";
import ImagesAndDesc from "./edit-add-on-service/ImagesAndDesc";
import InstaBook from "./InstaBook";
import Policy from "./Policy";
import rightarrow from "../../../assets/images/rightarrow.svg";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import { addAddOnServiceApi, deleteAddOnServiceApi, updateEditAddOnService, addServiceApi } from "../../../api/addService";
import ServiceTimings from "./ServiceTimings";
import { showModal } from "../../../store/slices/common";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "680px",
    borderRadius: "20px",

  },
  "& .MuiPaper-root": { maxWidth: "800px" },
  "& .MuiDialogActions-root": {},
}));
const AddServiceModal = ({
  addServiceModal,
  setAddServiceModal,
  page,
  setPage,
  isAddOn,
  setIsAddOn,
  servId,
  setServId,
  setServiceSelected,
  serviceSelected,
  callApi,
}) => {
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isDayAvail, setIsDayAvail] = useState(Array(7).fill(false));
  const [loading, setLoading] = useState(false);
  const [addService, setAddService] = useState({
    categories: [],
    cancellationPolicy: "",
    amenities: [],
    title: "",
    maximumGuestCapacity: "",
    serviceImages: [],
    isInstaBook: true,
    description: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    location: {
      lat: "",
      lng: "",
    },
    serviceTiming: {
      monday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      tuesday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      wednesday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      thursday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      friday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      saturday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
      sunday: {
        isAvailable: false,
        startTime: null,
        endTime: null,
        isOverNight: false,
      },
    },
  });

  const [services, setServices] = useState([]);

  const dispatch = useDispatch();
  const handleCategory = (e) => {
    setCategory(e);
    if (page == 13) {
      setSubCategory([]);
      setAddService({ ...addService, addOnServiceImages: [], description: "" });
    }
  };
  const callAddOnApi = async () => {
    setLoading(true);
    let val = {
      description: addService.description,
      addOnServiceImages: addService.serviceImages,
      categories: addService.categories,
      serviceId: category?._id,
    };
    let val1 = [...serviceSelected, category?._id];
    setServiceSelected(val1);
    const resp = await addAddOnServiceApi(servId, val);
    if (resp?.status == 200) {
      setAddServiceModal(false);
      callApi();
      dispatch(
        showModal({
          modalType: modalVariant.ADDON_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            setPage(12);
            setAddServiceModal(true);
            setIsAddOn(true);
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    }
    setLoading(false);
  };

  const handleRest = () => {
    setPage(1);
    setAddServiceModal(false);
    setServId();
    setIsAddOn(false);
    setServiceSelected([]);
  };
  const convertToHourFormat = (time) => {
    if (time) {
      let utcTime = dayjs.utc(time).local();
      let formattedTime = utcTime.format("hh:mm A");
      if (formattedTime.startsWith("0"))
        formattedTime = formattedTime.substring(1,)
      return formattedTime;
    }
  }
  const callAddServiceApi = async () => {
    setLoading(true);
    const modifiedServiceTiming = {};
    Object.keys(addService.serviceTiming).forEach((day) => {
      const item = addService.serviceTiming[day];
      modifiedServiceTiming[day] = {
        isAvailable: item.isAvailable,
        isOverNight: item.isOverNight,
        startTime: item.startTime
          ? convertToHourFormat(item.startTime)
          : "",
        endTime: item.endTime ? convertToHourFormat(item.endTime) : "",
      };
    });
    const resp = await addServiceApi({
      ...addService,
      services: [
        { serviceId: category?._id, categories: addService.categories },
      ],
      serviceTiming: modifiedServiceTiming,
    });
    let val1 = [...serviceSelected, category?._id];
    setServiceSelected(val1);
    if (resp?.status == 200) {
      setAddServiceModal(false);
      setPage(10);
      setServId(resp?.data?.data?._id);
      callApi();
      dispatch(
        showModal({
          modalType: modalVariant.ADDSERVICE_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            setAddServiceModal(true);
            setIsAddOn(true);
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    }
    setLoading(false);
  };
  const callDeleteAddonApi = async () => {
    setLoading(true);
    const resp = await deleteAddOnServiceApi(
      servId,
      addService?._id,
    );
    setAddServiceModal(false);

    if (resp?.status == 200) {
      setLoading(false)
      setPage(12);
      setAddServiceModal(true);
      dispatch(showModal(null));

    }
  }
  const hadleAddOnDelete = async () => {
    dispatch(
      showModal({
        modalType: modalVariant.DELETESERVICE_POPUP,
        resolveCallback: () => {
          callDeleteAddonApi();
        },
        rejectCallback: () => {
          dispatch(showModal(null));
        },
      })
    );

  }
  const callUpdateAddOnApi = async () => {
    setLoading(true);
    let cat = subCategory?.map((item) => {
      return { ...item, categoryId: item.categoryId._id };
    });
    let val = {
      description: addService.description,
      addOnServiceImages: addService.addOnServiceImages,
      categories: cat,
      serviceId: category,
      isAdOnService: true
    };
    const resp = await updateEditAddOnService(
      servId,
      addService?._id,
      val
    );
    if (resp?.status == 200) {
      setAddServiceModal(false)
      dispatch(
        showModal({
          modalType: modalVariant.ADDONUPDATE_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            setPage(12);
            setAddServiceModal(true);

          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    }
    setLoading(false);
  };

  const callFunction = (page) => {
    switch (page) {
      case 1:
        return (
          <AddService
            setCategory={setCategory}
            category={category}
            setAddServiceModal={setAddServiceModal}
            setPage={setPage}
            handleCategory={handleCategory}
            setServices={setServices}
            services={services}
            setSubCategory={setSubCategory}
            isAddOn={isAddOn}
            serviceSelected={serviceSelected}
          />
        );
      case 2:
        return (
          <AddServiceCategory
            category={category}
            setSubCategory={setSubCategory}
            subCategory={subCategory}
            setAddServiceModal={setAddServiceModal}
            setPage={setPage}
            setSubCategories={setSubCategories}
            subCategories={subCategories}
            addService={addService}
            setAddService={setAddService}
            isAddOn={isAddOn}
          />
        );
      case 3:
        return (
          <AddSubInfo
            setPage={setPage}
            subCategory={subCategory}
            setAddService={setAddService}
            addService={addService}
            setSubCategory={setSubCategory}
            isAddOn={isAddOn}
          />
        );
      case 4:
        return (
          <AddServiceDesc
            setPage={setPage}
            setAddService={setAddService}
            addService={addService}
          />
        );
      case 5:
        return (
          <ServiceTimings
            setPage={setPage}
            setAddService={setAddService}
            addService={addService}
            setIsDayAvail={setIsDayAvail}
            isDayAvail={isDayAvail}
          />
        );
      case 6:
        return (
          <AddressImage
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
            isAddOn={isAddOn}
            callAddOnApi={callAddOnApi}
            loader={loading}
          />
        );
      case 7:
        return category && category.name === "Venues" ? (
          <Aminities
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
          />
        ) : (
          <Policy
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
          />
        );
      case 8:
        return category && category.name === "Venues" ? (
          <Policy
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
          />
        ) : (
          <InstaBook
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
            callAddServiceApi={callAddServiceApi}
            loading={loading}
          />
        );
      case 9:
        return (
          <InstaBook
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
            callAddServiceApi={callAddServiceApi}
            loading={loading}
          />
        );
      case 10:
        return <AddOnServices setPage={setPage} handleRest={handleRest} />;
      case 11:
        return <ServiceImageDesc setPage={setPage} />;
      case 12:
        return (
          <AddonServiceList
            servId={servId}
            setPage={setPage}
            setAddServiceModal={setAddServiceModal}
            handleRest={handleRest}
            setAddService={setAddService}
            setCategory={setCategory}
            setSubCategory={setSubCategory}
          />
        );
      case 13:
        return (
          <AddOnAddService
            addOnCat={category}
            handleAddOnCategory={handleCategory}
            setPage={setPage}
            serviceSelected={serviceSelected}
            setServiceSelected={setServiceSelected}
          />
        )
      case 14:
        return (
          <AddAddOnSubCategory
            addonsubCategory={subCategory}
            setaddonSubCategory={setSubCategory}
            setPage={setPage}
            addOnCat={category}
            isEdit={false}
            handleDelete={hadleAddOnDelete}
          />
        );
      case 15:
        return (
          <AddAddOnSubInfo
            setPage={setPage}
            addonsubCategory={subCategory}
            setaddonSubCategory={setSubCategory}
            isEdit={true}
            isAddOn={true}
          />
        )

      case 16:
        return (
          <ImagesAndDesc
            setPage={setPage}
            addService={addService}
            setAddService={setAddService}
            isEdit={true}
            isAddOn={true}
            callUpdateAddOnApi={callUpdateAddOnApi}
            loader={loading}
          />

        )

    }
  };

  return (
    <CustomDialog
      open={addServiceModal}
      keepMounted
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "580px",
          padding: { xs: "20px 15px", sm: '30px', md: "30px", lg: '30px', xl: '30px' },
          maxHeight: "90%",
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      }}
    >
      <DialogContent sx={{ padding: { xs: 0, md: 0, lg: 0 }, overflow: "auto", height: "100%" }}>
        <img className="bookcrossicon" alt={""}
          src={crossicon}
          style={{
            position: "absolute",
            right: "2rem",
            top: "2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            handleRest();
          }}
        />
        {
          <img className="bookrightcrossicon" alt=""
            src={rightarrow}
            style={{
              position: "absolute",
              left: "2rem",
              top: "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              if (page == 1 && isAddOn) setPage(12);
              else if (page == 1) setAddServiceModal(false);
              else if (page == 6 && isAddOn) setPage(3);
              else if (page == 12 || page == 10) {
                handleRest();
              }
              // else if (page == 13)
              //   setPage(10);
              else setPage((prev) => prev - 1);
            }}
          />
        }
        <Typography
          sx={{
            fontSize: { xs: "16px", sm: '18px', md: "18px", lg: '20px', xl: '24px' },
            fontFamily: "Montserrat, sans-serif",
            marginBottom: '15px'
          }}
          variant="h5" fontWeight="bold" textAlign="center">
          {isAddOn ? (page == 13 || page == 14 || page == 15 || page == 16) ? "Edit Add-On Services" : "Add-On Services" : "Add a service"}
        </Typography>
        {callFunction(page)}
      </DialogContent>
      <DialogActions disableSpacing={false}></DialogActions>
    </CustomDialog>
  );
};

export default AddServiceModal;
