import React from 'react'

const Explore = ({val}) => {
  return !val ? (
    <svg
       className='profile-icon'
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1824_75730)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.83594 11.6665C2.83594 5.86751 7.53695 1.1665 13.3359 1.1665C19.1349 1.1665 23.8359 5.86751 23.8359 11.6665C23.8359 17.4655 19.1349 22.1665 13.3359 22.1665C7.53695 22.1665 2.83594 17.4655 2.83594 11.6665ZM12.1209 4.6014C12.1965 5.0787 11.8709 5.52691 11.3936 5.60251C9.27194 5.93854 7.60798 7.60251 7.27194 9.72414C7.19634 10.2014 6.74813 10.5271 6.27083 10.4515C5.79354 10.3759 5.46789 9.92768 5.54349 9.45038C5.99812 6.57994 8.24937 4.32869 11.1198 3.87405C11.5971 3.79846 12.0453 4.1241 12.1209 4.6014Z"
          fill="rgba(26, 26, 26, 0.25)"
        />
        <path
          d="M22.0506 20.3811C22.3923 20.0394 22.9463 20.0394 23.288 20.3811L27.9545 25.0477C28.2962 25.3894 28.2962 25.9435 27.9545 26.2852C27.6128 26.6269 27.0588 26.6269 26.7171 26.2852L22.0505 21.6186C21.7088 21.2768 21.7088 20.7228 22.0506 20.3811Z"
          fill="rgba(26, 26, 26, 0.25)"
          // fill="#1A1A1A"
          opactiy="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_1824_75730">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
     className='profile-icon'
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1811_68157)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.83594 11.6665C2.83594 5.86751 7.53695 1.1665 13.3359 1.1665C19.1349 1.1665 23.8359 5.86751 23.8359 11.6665C23.8359 17.4655 19.1349 22.1665 13.3359 22.1665C7.53695 22.1665 2.83594 17.4655 2.83594 11.6665ZM12.1209 4.6014C12.1965 5.0787 11.8709 5.52691 11.3936 5.60251C9.27194 5.93854 7.60798 7.60251 7.27194 9.72414C7.19634 10.2014 6.74813 10.5271 6.27083 10.4515C5.79354 10.3759 5.46789 9.92768 5.54349 9.45038C5.99812 6.57994 8.24937 4.32869 11.1198 3.87405C11.5971 3.79846 12.0453 4.1241 12.1209 4.6014Z"
          fill="url(#paint0_linear_1811_68157)"
        />
        <path
          d="M22.0506 20.3811C22.3923 20.0394 22.9463 20.0394 23.288 20.3811L27.9545 25.0477C28.2962 25.3894 28.2962 25.9435 27.9545 26.2852C27.6128 26.6269 27.0588 26.6269 26.7171 26.2852L22.0505 21.6186C21.7088 21.2768 21.7088 20.7228 22.0506 20.3811Z"
          fill="url(#paint1_linear_1811_68157)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1811_68157"
          x1="2.83594"
          y1="13.854"
          x2="28.2108"
          y2="13.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1811_68157"
          x1="2.83594"
          y1="13.854"
          x2="28.2108"
          y2="13.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
        <clipPath id="clip0_1811_68157">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Explore