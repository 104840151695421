import React from 'react'

const DashboardIcon = ({val}) => {
  return val ? (
    <svg 

    className='profile-icon'
    style={{height: '27px', width: '27px'}}
    viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.664062 4.16797C0.664062 3.08077 0.664062 2.53717 0.841677 2.10837C1.0785 1.53664 1.53274 1.0824 2.10447 0.845583C2.53327 0.667969 3.07687 0.667969 4.16406 0.667969C5.25126 0.667969 5.79486 0.667969 6.22366 0.845583C6.79539 1.0824 7.24963 1.53664 7.48645 2.10837C7.66406 2.53717 7.66406 3.08077 7.66406 4.16797C7.66406 5.25517 7.66406 5.79876 7.48645 6.22756C7.24963 6.7993 6.79539 7.25354 6.22366 7.49036C5.79486 7.66797 5.25126 7.66797 4.16406 7.66797C3.07687 7.66797 2.53327 7.66797 2.10447 7.49036C1.53274 7.25354 1.0785 6.7993 0.841677 6.22756C0.664062 5.79876 0.664062 5.25517 0.664062 4.16797Z" fill="url(#paint0_linear_8539_43495)" />
    <path d="M12.3307 4.16797C12.3307 3.08077 12.3307 2.53717 12.5083 2.10837C12.7452 1.53664 13.1994 1.0824 13.7711 0.845583C14.1999 0.667969 14.7435 0.667969 15.8307 0.667969C16.9179 0.667969 17.4615 0.667969 17.8903 0.845583C18.4621 1.0824 18.9163 1.53664 19.1531 2.10837C19.3307 2.53717 19.3307 3.08077 19.3307 4.16797C19.3307 5.25517 19.3307 5.79876 19.1531 6.22756C18.9163 6.7993 18.4621 7.25354 17.8903 7.49036C17.4615 7.66797 16.9179 7.66797 15.8307 7.66797C14.7435 7.66797 14.1999 7.66797 13.7711 7.49036C13.1994 7.25354 12.7452 6.7993 12.5083 6.22756C12.3307 5.79876 12.3307 5.25517 12.3307 4.16797Z" fill="url(#paint1_linear_8539_43495)" />
    <path d="M0.664062 15.8346C0.664062 14.7474 0.664062 14.2038 0.841677 13.775C1.0785 13.2033 1.53274 12.7491 2.10447 12.5123C2.53327 12.3346 3.07687 12.3346 4.16406 12.3346C5.25126 12.3346 5.79486 12.3346 6.22366 12.5123C6.79539 12.7491 7.24963 13.2033 7.48645 13.775C7.66406 14.2038 7.66406 14.7474 7.66406 15.8346C7.66406 16.9218 7.66406 17.4654 7.48645 17.8942C7.24963 18.466 6.79539 18.9202 6.22366 19.157C5.79486 19.3346 5.25126 19.3346 4.16406 19.3346C3.07687 19.3346 2.53327 19.3346 2.10447 19.157C1.53274 18.9202 1.0785 18.466 0.841677 17.8942C0.664062 17.4654 0.664062 16.9218 0.664062 15.8346Z" fill="url(#paint2_linear_8539_43495)" />
    <path d="M12.3307 15.8346C12.3307 14.7474 12.3307 14.2038 12.5083 13.775C12.7452 13.2033 13.1994 12.7491 13.7711 12.5123C14.1999 12.3346 14.7435 12.3346 15.8307 12.3346C16.9179 12.3346 17.4615 12.3346 17.8903 12.5123C18.4621 12.7491 18.9163 13.2033 19.1531 13.775C19.3307 14.2038 19.3307 14.7474 19.3307 15.8346C19.3307 16.9218 19.3307 17.4654 19.1531 17.8942C18.9163 18.466 18.4621 18.9202 17.8903 19.157C17.4615 19.3346 16.9179 19.3346 15.8307 19.3346C14.7435 19.3346 14.1999 19.3346 13.7711 19.157C13.1994 18.9202 12.7452 18.466 12.5083 17.8942C12.3307 17.4654 12.3307 16.9218 12.3307 15.8346Z" fill="url(#paint3_linear_8539_43495)" />
    <defs>
      <linearGradient id="paint0_linear_8539_43495" x1="0.664063" y1="10.0013" x2="19.3307" y2="10.0013" gradientUnits="userSpaceOnUse">
        <stop stop-color="#17BAAE" />
        <stop offset="1" stop-color="#29EAA5" />
      </linearGradient>
      <linearGradient id="paint1_linear_8539_43495" x1="0.664063" y1="10.0013" x2="19.3307" y2="10.0013" gradientUnits="userSpaceOnUse">
        <stop stop-color="#17BAAE" />
        <stop offset="1" stop-color="#29EAA5" />
      </linearGradient>
      <linearGradient id="paint2_linear_8539_43495" x1="0.664063" y1="10.0013" x2="19.3307" y2="10.0013" gradientUnits="userSpaceOnUse">
        <stop stop-color="#17BAAE" />
        <stop offset="1" stop-color="#29EAA5" />
      </linearGradient>
      <linearGradient id="paint3_linear_8539_43495" x1="0.664063" y1="10.0013" x2="19.3307" y2="10.0013" gradientUnits="userSpaceOnUse">
        <stop stop-color="#17BAAE" />
        <stop offset="1" stop-color="#29EAA5" />
      </linearGradient>
    </defs>
  </svg>
  ) : (
    <svg
     className='profile-icon'
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.164062 4.16797C0.164062 3.08077 0.164062 2.53717 0.341677 2.10837C0.578496 1.53664 1.03274 1.0824 1.60447 0.845583C2.03327 0.667969 2.57687 0.667969 3.66406 0.667969C4.75126 0.667969 5.29486 0.667969 5.72366 0.845583C6.29539 1.0824 6.74963 1.53664 6.98645 2.10837C7.16406 2.53717 7.16406 3.08077 7.16406 4.16797C7.16406 5.25517 7.16406 5.79876 6.98645 6.22756C6.74963 6.7993 6.29539 7.25354 5.72366 7.49036C5.29486 7.66797 4.75126 7.66797 3.66406 7.66797C2.57687 7.66797 2.03327 7.66797 1.60447 7.49036C1.03274 7.25354 0.578496 6.7993 0.341677 6.22756C0.164062 5.79876 0.164062 5.25517 0.164062 4.16797Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
      <path
        d="M11.8307 4.16797C11.8307 3.08077 11.8307 2.53717 12.0083 2.10837C12.2452 1.53664 12.6994 1.0824 13.2711 0.845583C13.6999 0.667969 14.2435 0.667969 15.3307 0.667969C16.4179 0.667969 16.9615 0.667969 17.3903 0.845583C17.9621 1.0824 18.4163 1.53664 18.6531 2.10837C18.8307 2.53717 18.8307 3.08077 18.8307 4.16797C18.8307 5.25517 18.8307 5.79876 18.6531 6.22756C18.4163 6.7993 17.9621 7.25354 17.3903 7.49036C16.9615 7.66797 16.4179 7.66797 15.3307 7.66797C14.2435 7.66797 13.6999 7.66797 13.2711 7.49036C12.6994 7.25354 12.2452 6.7993 12.0083 6.22756C11.8307 5.79876 11.8307 5.25517 11.8307 4.16797Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
      <path
        d="M0.164062 15.8346C0.164062 14.7474 0.164062 14.2038 0.341677 13.775C0.578496 13.2033 1.03274 12.7491 1.60447 12.5123C2.03327 12.3346 2.57687 12.3346 3.66406 12.3346C4.75126 12.3346 5.29486 12.3346 5.72366 12.5123C6.29539 12.7491 6.74963 13.2033 6.98645 13.775C7.16406 14.2038 7.16406 14.7474 7.16406 15.8346C7.16406 16.9218 7.16406 17.4654 6.98645 17.8942C6.74963 18.466 6.29539 18.9202 5.72366 19.157C5.29486 19.3346 4.75126 19.3346 3.66406 19.3346C2.57687 19.3346 2.03327 19.3346 1.60447 19.157C1.03274 18.9202 0.578496 18.466 0.341677 17.8942C0.164062 17.4654 0.164062 16.9218 0.164062 15.8346Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
      <path
        d="M11.8307 15.8346C11.8307 14.7474 11.8307 14.2038 12.0083 13.775C12.2452 13.2033 12.6994 12.7491 13.2711 12.5123C13.6999 12.3346 14.2435 12.3346 15.3307 12.3346C16.4179 12.3346 16.9615 12.3346 17.3903 12.5123C17.9621 12.7491 18.4163 13.2033 18.6531 13.775C18.8307 14.2038 18.8307 14.7474 18.8307 15.8346C18.8307 16.9218 18.8307 17.4654 18.6531 17.8942C18.4163 18.466 17.9621 18.9202 17.3903 19.157C16.9615 19.3346 16.4179 19.3346 15.3307 19.3346C14.2435 19.3346 13.6999 19.3346 13.2711 19.157C12.6994 18.9202 12.2452 18.466 12.0083 17.8942C11.8307 17.4654 11.8307 16.9218 11.8307 15.8346Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
    </svg>
  );
}

export default DashboardIcon