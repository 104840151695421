import React, { useState } from "react";
import PropTypes from "prop-types";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { FormHelperText, InputAdornment,Box } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import {  styled } from "@mui/system";
import dropdown from '../../assets/images/dropdown.png';
import couponcodeimg from '../../assets/images/couponcodeimg.png';

const BootstrapInput = styled(InputBase)(({ backgroundColor,dolarFor }) => ({
  width: "100%",

  "&.Mui-error .MuiInputBase-input": {
    border: "1px solid",
    borderColor: "red",
  },
  "& .MuiInputBase-input": {
    borderRadius: "18px",
    border: "1px solid #D9D9D9",
    height: "42px",
    width: "100%",
    fontSize: 16,
    fontWeight: 600,
    color: "#000",
    fontFamily:"Montserrat, sans-serif",
    padding:"8px",
    paddingRight:"2.2rem",
    paddingLeft:dolarFor=="number"?"1.5rem":"20px",
    backgroundColor: `${backgroundColor}`,
    '&:placeholder':{
      fontWeight: 500,
    }
  },
}));

function CustomTextField({
  name,
  variant,
  type,
  onChange,
  dolarFor,
  value,
  placeholder,
  errorMessage,
  maxLength,
  className,
  onMouseLeave,
  textAlign,
  onWheel,
   backgroundColor,
   allowdot,
   onKeyPress,
   isNum,
  disabled = false,
  position = "absolute",
  marginTop = "4rem",
  readOnly,
  onKeyDown,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(true);
  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <BootstrapInput
        variant={variant}
        id={name}
        onMouseLeave={onMouseLeave}
        type={showPassword ? type : "text"}
        style={{fontFamily:"Montserrat",textAlign:textAlign}}
        inputProps={{
          maxLength,
          readOnly,
          onWheel,
          onKeyDown:onKeyDown?onKeyDown: (evt) => allowdot && ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()||(type === "number") && ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault() ,
          onKeyPress:onKeyPress,
          type:isNum?"number":showPassword ? type : "text",
          inputmode: isNum ? 'numeric' : showPassword ? type : 'text',
        }}  
        onChange={onChange}
        value={value !== undefined?value:""}
        placeholder={placeholder}
        disabled={disabled}
        backgroundColor={backgroundColor}
        className={className}
        error={Boolean(errorMessage)}
        dolarFor={dolarFor}
        autoComplete="off"
        {...props}
        startAdornment={
          dolarFor=="number"&&<InputAdornment><span style={{ cursor: "pointer",
          color: value?"black":"rgba(167, 167, 167, 1)",
          position: "absolute",
          left: "15px"}}>$</span></InputAdornment>
        }
        endAdornment={
          type === "password" ? (
            <InputAdornment>
              {showPassword ? (
                <VisibilityOffOutlinedIcon
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    color: "#B4B4B4",
                    position: "absolute",
                    right: "15px",
                    fontFamily:"Montserrat, sans-serif"
                  }}
                  onClick={togglePasswordVisiblity}
                />
              ) : (
                <VisibilityOutlinedIcon
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    color: "#B4B4B4",
                    position: "absolute",
                    right: "15px",
                    fontFamily:"Montserrat, sans-serif"
                  }}
                  onClick={togglePasswordVisiblity}
                />
              )}
            </InputAdornment>
          ) : type=="dropdown"?<Box   sx={{
            cursor: "pointer",
            color: "#B4B4B4",
            position: "absolute",
            right: "15px",
          }}><img src={dropdown}/></Box>:type=="couponcode"?<img src={couponcodeimg} style={{position:"absolute",right:"1rem",top:"1rem"}}/>:null
        }
      />
      {errorMessage && (
        <FormHelperText
          sx={{ color: "error.main", fontSize: "11px", fontWeight: 400,fontFamily:"Montserrat, sans-serif" }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
}

export default CustomTextField;

CustomTextField.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
