import React, { useState, useEffect } from "react";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import radioicongreen from "../../../assets/images/radioicongreen.png";
import tickcircleicon from "../../../assets/images/tickcircleicon.png";
import blackcrossicon from ".././../../assets/images/blackcrossicon.png";

const AddSubInfo = ({
  setPage,
  subCategory,
  setAddService,
  setSubCategory,
  addService,
  isAddOn,
  isEdit
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(addService?.categories?.length).fill(false)
  );
  const [isEnableBtn, setIsEnableBtn] = useState(false);
  const classes = getSx();
  const isAllTrue = (array) => {
    return array.every((element) => {
      return !!element;
    });
  };
  const handleEnableFunc = () => {
    let isEnable = Array(subCategory?.length).fill(false);
    if (subCategory?.length < 1) setIsEnableBtn(false);
    else {
      for (let i = 0; i < subCategory?.length; i++) {
        if (subCategory[i]?.isAdditionalInfoRequired) {
          if (
            addService?.categories[i]?.field1?.label &&
            addService?.categories[i]?.field1?.value &&
            addService?.categories[i]?.field2?.label &&
            addService?.categories[i]?.field2?.value &&
            addService?.categories[i]?.field3?.label &&
            addService?.categories[i]?.field3?.value && addService?.categories[i]?.field3?.value > 0
          )
            isEnable[i] = true;
        } else if (
          addService?.categories[i]?.field1?.label &&
          addService?.categories[i]?.field1?.value &&
          addService?.categories[i]?.field2?.label &&
          addService?.categories[i]?.field2?.value
        )
          isEnable[i] = true;
      }
      let val = isAllTrue(isEnable);
      setIsEnableBtn(val);
    }
  };
  const convertField2ToNumber = (data) => {
    let categoriesCopy = data.map(item => ({
      ...item,
      field2: {
        ...item.field2,
        value: parseFloat(item.field2.value)
      }
    }));
    setAddService({
      ...addService,
      categories: categoriesCopy,
      title: "",
      description: "",
      maximumGuestCapacity: "",
      serviceImages: []
    });

  };

  const handleDelSubCategory = (val, index) => {
    let filteredSubCat = subCategory.filter((item) => item?._id != val);
    setSubCategory(filteredSubCat);
    let filteredServiceCat = addService.categories.filter(
      (item, i) => i != index
    );
    let val1 = { ...addService, categories: filteredServiceCat };
    setAddService(val1);
  };
  const feeType = ["Per Day", "Hourly", "Per Person"];
  const handleClick = () => {
    if (isEdit)
      setPage(1)
    else {
      if (isAddOn) setPage(6);
      else
        setPage(4);
      const categoriesCopy = [...addService.categories];
      convertField2ToNumber(categoriesCopy)

    }
  };
  const handleDropDown = (index) => {
    const updatedDropdownOpen = [...dropdownOpen];
    updatedDropdownOpen[index] = !updatedDropdownOpen[index];
    setDropdownOpen(updatedDropdownOpen);
  };
  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };
  const handleChangeInfo = (index, field, val, label) => {
    const categoriesCopy = [...addService.categories];
    let categoryToUpdate = categoriesCopy[index];
    if (!categoryToUpdate) {
      categoryToUpdate = {};
      categoriesCopy[index] = categoryToUpdate;
    }
    if (field === "field1") {
      categoryToUpdate.categoryId = subCategory[index]?._id;
      categoryToUpdate.field1 = { label: label, value: val };
    } else if (field === "field2") {
      categoryToUpdate.field2 = { label: label, value: val };
    } else if (field === "field3") {
      categoryToUpdate.field3 = { label: label, value: val };
    }
    setAddService({
      ...addService,
      categories: categoriesCopy,
    });
  };

  useEffect(() => {
    handleEnableFunc();
  }, [addService?.categories]);
  return (
    <Box>
      <Box
        sx={{
          minHeight: {
            xs: "auto",
            sm: 'auto',
            md: 'auto',
            lg: 'auto',
            xl: "auto"
          },
          maxHeight: {
            xs: "calc(100vh - 400px)",
            sm: 'calc(100vh - 400px)',
            md: 'calc(100vh - 600px)',
            lg: 'calc(100vh - 345px)',
            xl: "calc(100vh - 450px)"
          }, overflow: "auto"
        }}
      >
        {subCategory?.map((item, index) => {
          return (
            <Box sx={classes.subcatinfo} key={item}>
              <Box sx={classes.singlebox}>
                <Box sx={classes.headinfo}>
                  <Box className="flexbox">
                    <Typography variant="h6" fontWeight="bold" sx={{
                      fontSize: { xs: "13px", md: "16px" }, fontFamily: "Montserrat, sans-serif", display: 'inline-block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      overflow: 'auto'
                    }}>
                      {item.categoryName}
                    </Typography>
                    <img alt=""
                      src={blackcrossicon}
                      style={{ width: "30px", height: "30px" }}
                      onClick={() => {
                        handleDelSubCategory(item?._id, index);
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    margin: '12px 0',
                    fontSize: '16px',
                  }}
                >
                  Service Fee Type
                </Typography>
                <Box
                  onClick={() => {
                    handleDropDown(index);
                  }}
                >
                  <CustomTextField
                    variant="outlined"
                    placeholder="Select Service Fee Type"
                    type="dropdown"
                    backgroundColor="rgba(246, 245, 245, 1);"
                    readOnly={true}
                    value={addService?.categories[index]?.field1?.value}
                    className="dropdownselection"
                  />
                </Box>

                {dropdownOpen[index] && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      height: "165px",
                      width: "93%",
                      zIndex: "23",
                      padding: "15px",
                      border: "1px solid rgba(63, 73, 88, 0.1)",
                      borderRadius: "15px",
                      marginTop: "5px",
                    }}
                  >
                    {feeType.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "5px",
                            fontWeight: "500",
                          }}
                          key={item}
                          onClick={() => {
                            handleChangeInfo(
                              index,
                              "field1",
                              item,
                              "serviceFeeType"
                            );
                            handleDropDown(index);
                          }}
                        >
                          <Box>{item}</Box>
                          <Box>
                            <img alt=""
                              src={
                                addService?.categories[index]?.field1?.value ==
                                  item
                                  ? tickcircleicon
                                  : radioicongreen
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <Typography variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily: "Montserrat, sans-serif",
                    margin: '12px 0',
                    fontSize: '16px',
                  }}>
                  Service Fee
                </Typography>
                <CustomTextField
                  variant="outlined"
                  placeholder="Enter Service Fee"
                  backgroundColor="rgba(246, 245, 245, 1);"
                  type="number"
                  isNum={true}
                  dolarFor="number"
                  maxLength={15}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val.match(/^[\d.].*$/) && val.length > 0 && !val.match(/^00/))
                      handleChangeInfo(
                        index,
                        "field2",
                        e.target.value,
                        "serviceFee"
                      );
                    else
                      handleChangeInfo(
                        index,
                        "field2",
                        "",
                        "serviceFee"
                      );

                  }}
                  onKeyPress={(e) => {
                    let value = e.target.value;
                    if (value.includes('.')) {
                      const parts = value.split('.');
                      if (parts[1].length > 1) {
                        e.preventDefault()
                      }
                    }

                  }
                  }
                  name="servicefee"
                  value={addService?.categories[index]?.field2?.value}
                  onWheel={handleWheel}
                />
                {item?.isAdditionalInfoRequired && (
                  <>
                    {" "}
                    <Typography variant="body1"
                      fontWeight="bold"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        margin: '12px 0',
                        fontSize: '16px',
                      }}>
                      {item?.additionalFieldLabel}
                    </Typography>
                    <CustomTextField
                      variant="outlined"
                      placeholder={`${item?.additionalFieldLabel}`}
                      backgroundColor="rgba(246, 245, 245, 1);"
                      type="number"
                      allowdot={true}
                      maxLength={5}
                      name="fee"
                      isNum={true}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val.length > 5) return;

                        if (val.match(/^[\d.].*$/) && !val.match(/^00/))
                          handleChangeInfo(
                            index,
                            "field3",
                            e.target.value,
                            item?.additionalFieldLabel
                          );
                        else
                          handleChangeInfo(
                            index,
                            "field3",
                            "",
                            item?.additionalFieldLabel)
                      }}
                      value={addService?.categories[index]?.field3?.value}
                      onWheel={handleWheel}
                    />
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ marginTop: "15px" }}>
        <ButtonComp
          label={isEdit ? "Update" : "Next"}
          type={isEnableBtn ? "active" : "disabled"}
          disabled={!isEnableBtn}
          onClick={handleClick}
          style={{ height: '60px', fontFamily: "Montserrat, sans-serif", fontSize: '16px', width: '100%', fontWeight: 600 }}
        />
      </Box>
    </Box>
  );
};

export default AddSubInfo;
