import React from 'react'

const NotificationIcon = ({val}) => {
  return val ? (
    <svg
      className='profile-icon'
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9122 7.86142C2.42084 3.93053 5.48589 0.458496 9.44737 0.458496H11.4341C15.3998 0.458496 18.4681 3.9342 17.9762 7.86925L17.9008 8.47234C17.755 9.63914 18.1792 10.8049 19.0408 11.6051L19.092 11.6526C20.9742 13.4006 21.3394 16.2434 19.9603 18.4104C19.059 19.8267 17.5477 20.7276 15.8903 20.8586L15.6672 21.241C14.4856 23.2666 12.6446 24.3752 10.7114 24.3752C8.77815 24.3752 6.93714 23.2666 5.75555 21.241L5.53589 20.8645C3.5627 20.7417 1.79513 19.5664 0.924627 17.7718L0.804051 17.5232C-0.183708 15.4869 0.278403 13.044 1.9417 11.5091C2.74437 10.7684 3.14026 9.68585 3.00478 8.60207L2.9122 7.86142ZM7.60422 20.8752C8.49012 22.0858 9.63669 22.6252 10.7114 22.6252C11.786 22.6252 12.9326 22.0858 13.8185 20.8752H7.60422Z"
        fill="url(#paint0_linear_1811_68193)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1811_68193"
          x1="0.289063"
          y1="12.4168"
          x2="20.7927"
          y2="12.4168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      className='profile-icon'
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.9122 7.86142C2.42084 3.93053 5.48589 0.458496 9.44737 0.458496H11.4341C15.3998 0.458496 18.4681 3.9342 17.9762 7.86925L17.9008 8.47234C17.755 9.63914 18.1792 10.8049 19.0408 11.6051L19.092 11.6526C20.9742 13.4006 21.3394 16.2434 19.9603 18.4104C19.059 19.8267 17.5477 20.7276 15.8903 20.8586L15.6672 21.241C14.4856 23.2666 12.6446 24.3752 10.7114 24.3752C8.77815 24.3752 6.93714 23.2666 5.75555 21.241L5.53589 20.8645C3.5627 20.7417 1.79513 19.5664 0.924627 17.7718L0.804051 17.5232C-0.183708 15.4869 0.278403 13.044 1.9417 11.5091C2.74437 10.7684 3.14026 9.68585 3.00478 8.60207L2.9122 7.86142ZM7.60422 20.8752C8.49012 22.0858 9.63669 22.6252 10.7114 22.6252C11.786 22.6252 12.9326 22.0858 13.8185 20.8752H7.60422Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
    </svg>
  );
}

export default NotificationIcon