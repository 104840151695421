import React, { useState } from "react";
import PropTypes from "prop-types";

import ButtonComp from "../../../component/Button";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Typography,
  styled,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getSx } from "./style";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import infoicon from "../../../assets/images/svg/infoicon.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "800px",
    padding: "17px",
    borderRadius: "20px",
  },
  "& .MuiPaper-root": { maxWidth: "800px" },
  "& .MuiDialogActions-root": {},
}));
let cancelArrEO = [
  "Selected wrong location",
  "Wait time was too long",
  "No longer needed",
  "Other",
];
let cancelArrSP = ["Not Available", "Damage", "Maintenance", "Other"]

let reportArr = ["Technical Issues", "Feature Requests", "Account and Billing", "Event Setup Assistance", "General Support"]

let policyInfo = {
  Flexible: {
    "0-2 days": "Non Refundable",
    "3-6 days": "50% Refundable",
    "+7 days": "100% Refundable"
  },
  Moderate: {
    "0-6 days": "Non Refundable",
    "7-13 days": "50% Refundable",
    "+14 days": "100% Refundable"
  },
  Strict: {
    "0-20 days": "Non Refundable",
    "+21 days": "50% Refundable"
  },
  VeryStrict: {
    "0-29 days": "Non Refundable",
    "+30 days": "50% Refundable"
  },
  "Very Strict": {
    "0-29 days": "Non Refundable",
    "+30 days": "50% Refundable"
  }
};


export default function CancelPolicyModal({
  setCancelPopUp,
  cancelPopUp,
  setShowCancelInfo,
  cancellationPolicy,
  showCancelInfo,
  handleCancelApi,
  isReport,
  handleReportApi, btnloading
}) {
  const [selected, setSelected] = useState();
  const [desc, setDesc] = useState("");
  const classes = getSx();
  let role = localStorage.getItem("role");
  let cancelArr = role == "0" ? cancelArrEO : cancelArrSP;
  let policyOne = policyInfo[cancellationPolicy];

  const handleChange = (event) => {
    setSelected(event.target.value);
  };
  return showCancelInfo && role == "0" ? (
    <CustomDialog
      open={cancelPopUp}
      TransitionComponent={Transition}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "450px" } }}
    >
      <DialogContent sx={{ padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        } }}>
        <img alt=""
          src={crossicon}
             className="bookcrossicon"
          style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}
          onClick={() => { setCancelPopUp(false); setShowCancelInfo(true) }}
        />
        <Typography
          variant="h5"
          fontWeight="bold"
          display="flex"
          justifyContent={{ xs: "flex-start", md: "center" }}
          sx={{fontFamily:"Montserrat, sans-serif",}}
        >
          Cancellation Policy
        </Typography>
        <Box sx={{ height: { xs: "300px", md: "370px" }, overflowY: "scroll" }}>
          <Typography
            variant="body1"
            color="rgba(167, 167, 167, 1)"
            margin="10px 0px"
            sx={{fontFamily:"Montserrat, sans-serif",}}
          >
            Cancellation and payment refund requests will be calculated based on the policies previously established by the service provider and accepted at the time of purchase. Be aware that handling fees might be applied to the refund amount.
          </Typography>
          <Typography sx={{ margin: "10px 0px",fontFamily:"Montserrat, sans-serif", }}>{cancellationPolicy} Policy</Typography>
          <TableContainer
            component={Paper}
            style={{
              border: "2px solid rgba(218, 218, 218, 1)",
              background:
                "linear-gradient(to right, rgba(23, 186, 174, 0.1) , rgba(41, 234, 165, 0.1))",
              borderRadius: "10px",
            }}
          >
            <Table sx={{ minWidth: 300,textAlign:"center" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "rgba(218, 218, 218, 1)",
                      textAlign:"center",
                      fontWeight:"bold"
                    }}
                  >
                    Time to charge
                  </TableCell>
                  <TableCell
                  style={{
                    borderRightStyle: "solid",
                    borderRightColor: "rgba(218, 218, 218, 0.1)",
                    textAlign:"center",
                    fontWeight:"bold"
                  }}
                  >Refund</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(policyOne).map((timeFrame) => (
                  <TableRow key={timeFrame}>
                    <TableCell
                      sx={{
                        borderRight: "1px solid rgba(218, 218, 218, 1) !important",
                        borderBottom: "none !important",
                        textAlign:"center"
                      }}
                    >
                      {timeFrame}
                    </TableCell>
                    <TableCell
                    sx={{textAlign:"center",borderBottom: "none !important",}}
                    >{policyOne[timeFrame]}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
          {/* <Box
          sx={{
            marginTop: "2rem",
            display: "flex",
            backgroundColor: "rgba(246, 245, 245, 1)",
            padding: "10px",
            borderRadius: "20px",
          }}
        >
          <Typography fontSize="15px" fontWeight="bold" marginRight="10px">
            Note
          </Typography>
          <Typography variant="body2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem
          </Typography>
        </Box> */}
        </Box>
        <Box
          sx={{ margin: "1rem 0px" }}
          onClick={() => {
            setShowCancelInfo(false);
          }}
        >
          <ButtonComp width="100%" type="active" label="Agree & Continue" />
        </Box>
      </DialogContent>
    </CustomDialog>
  ) : (
    <CustomDialog
      open={cancelPopUp}
      TransitionComponent={Transition}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "700px" } }}
    >
      <DialogContent sx={{ padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        } }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt=""
            src={infoicon}
            style={{ height: "70px", width: "70px", marginBottom: "15px" }}
          />
        </Box>
        <Box sx={{ height: {
          xs:'calc(55vh - 100px)',
          sm:'calc(75vh - 200px)',
          md:'calc(50vh - 200px)',
          lg:'calc(100vh - 310px)',
          xl:'calc(85vh - 310px)',
          },overflow:'auto',padding:'0 12px'}}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selected}
            onChange={handleChange}
            height="300px"
          >
            <img alt=""
              src={crossicon}
              style={{ position: "absolute", right: "1rem", top: "1rem" }}
              onClick={() => { setCancelPopUp(false); setShowCancelInfo(true) }}
              className="crossicontiming bookcrossicon"
            />


            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                fontSize:{
                  xs:'16px',
                  sm:'18px',
                  md:'18px',
                  lg:'20px',
                  xl:'24px'
                }
              }}
              style={{ textAlign: "center",fontFamily:"Montserrat, sans-serif", }}
            >
              {isReport ? "Are you sure you want to Raise a Ticket ?" : "Are you sure you want to cancel?"}
            </Typography>
            <Typography
              fontWeight="500"
              variant="h6"
              sx={{
                fontSize:{
                  xs:'12px',
                  sm:'14px',
                  md:'14px',
                  lg:'15px',
                  xl:'16px'
                }
              }}
              style={{ margin: "10px 0" }}
            >
              {isReport ? "Please select the issue you are facing" : "Please select the reason for your cancellation"}
            </Typography>
            <Box style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent:'space-between' }}>
              {!isReport && cancelArr?.map((item) => {
                return (
                  <Box
                    key={item}
                    sx={
                      selected == item
                        ? {
                          ...classes.unselectbox,
                          ...classes.selectbox,
                          ...classes.selecteventradiobox,
                          display: "flex",
                          width: { xs: "100%", md: "48%" },
                        }
                        : {
                          ...classes.unselectbox,
                          ...classes.unselecteventradiobox,
                          display: "flex",
                          width: { xs: "100%", md: "48%" },
                        }
                    }
                  >
                    {item}
                    <Box sx={classes.radiobox}>
                      <FormControlLabel
                        value={item}
                        control={
                          <Radio
                            sx={{ color: "rgba(23, 186, 174, 1)" }}
                            checkedIcon={
                              <CheckCircleIcon
                                sx={{
                                  color: "rgb(22, 192, 152)",
                                }}
                              />
                            }
                          />
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
              {isReport && reportArr?.map((item) => {
                return (
                  <Box
                    sx={
                      selected == item
                        ? {
                          ...classes.unselectbox,
                          ...classes.selectbox,
                          ...classes.selecteventradiobox,
                          display: "flex",
                          width: { xs: "100%", md: "46%" },
                        }
                        : {
                          ...classes.unselectbox,
                          ...classes.unselecteventradiobox,
                          display: "flex",
                          width: { xs: "100%", md: "46%" },
                        }
                    }
                    key={item}
                  >
                    {item}
                    <Box sx={classes.radiobox}>
                      <FormControlLabel
                        value={item}
                        control={
                          <Radio
                            sx={{ color: "rgba(23, 186, 174, 1)" }}
                            checkedIcon={
                              <CheckCircleIcon
                                sx={{
                                  color: "rgb(22, 192, 152)",
                                }}
                              />
                            }
                          />
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </RadioGroup>
          <Typography variant="h6" fontWeight="500" style={{ margin: "10px" }}>
            Tell us the reason
          </Typography>
          <TextareaAutosize
            placeholder=" Describe your experience "
            maxLength={500}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "rgba(246, 245, 245, 1)",
              borderRadius: "15px",
              marginBotton: "3rem",
              fontSize: "15px",
              fontFamily: "Montserrat",
              border: "none",
              outline: "none",
              overflowY: "auto !important",
              maxHeight: "100px",
              fontWeight: "500",
              color: "#000"
            }}
             className="textArea"
            name="description"
            minRows={8}
            value={desc}
            onChange={(e) => { setDesc(e.target.value) }}
          />
          <Box sx={{ float: "right", color: "rgba(0,0,0,0.5)" }}>{desc.length}/500</Box>
        </Box>
        {!isReport && <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "center" }, width: "100%", marginTop: "1rem" }}>
          <Box sx={{ marginRight: { xs: "25px", sm: "10px" }, width: {xs:'100%', sm:"50%", md:'50%', lg:"50%", xl:"50%"} }}>
            <ButtonComp label="Yes" type={selected && desc ? "whitebtn" : "disabledbtn"} width="100%" disabled={selected && desc ? false : true}
              onClick={() => { handleCancelApi(selected, desc) }} />
          </Box>
          <Box sx={{ marginRight: "10px", width: "20%" }}>
            <ButtonComp label="No" type="active" width="100%" onClick={() => { setCancelPopUp(false) }} />
          </Box>
        </Box>}
        {
          isReport && <Box sx={{width: {xs:'100%', sm:"50%", md:'50%', lg:"50%", xl:"50%"}, display: "flex", justifyContent: "center", margin: "auto" }}>
            <ButtonComp label="Report Issue" type={selected && desc ? "active" : "disabled"} width="100%" loading={btnloading} onClick={() => {  handleReportApi(selected, desc) }} disabled={selected && desc ? false : true} />
          </Box>
        }
      </DialogContent>{" "}
    </CustomDialog>
  );
}

CancelPolicyModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  resolveButton: PropTypes.object,
  rejectButton: PropTypes.object,
  image: PropTypes.image,
  subText: PropTypes.string,
  successButton: PropTypes.object,
};

CancelPolicyModal.defaultProps = {
  resolveButton: {},
  rejectButton: {},
};
