import React, {  useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import { Box, Typography } from "@mui/material";
import RadioComp from "./RadioComp";
import { getSx } from "./style";

const SelectLang = ({
  setValue,
  setPage,
  handleData,
  data,
  selected,
  setSelected,
  handleClear,
}) => {
  const classes = getSx();
  const radioArr = [{ name: "", value: ["United States", "Mexico"] }];

  const handleChange = (event) => {
    let val = [];
    val[0] = event.target.value;
    setSelected(val);
  };
 
  const validationSchema = Yup.object({
    rfcNumber: Yup.string().required("Required").matches(
      /^[A-Za-z0-9]+$/,
      "Invalid Business Account Number"
    ).max(15, 'First name must be less than 15 characters'),
  });
 
  const formik = useFormik({
    initialValues: { rfcNumber: ""},
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  useEffect(()=>{
    setValue(60)
  formik.setFieldValue("rfcNumber",data?.rfcNumber)
  },[])
 
  return (
    <Box>
      <Box>
        <Typography sx={{...classes.heading}}>
          Choose the country where you will offer your services
        </Typography>
        <Box sx={classes.createFormMain}>
        <RadioComp
          selected={selected}
          handleChange={handleChange}
          classes={classes}
          item={radioArr[0]}
          data={data}
          setSelected={setSelected}
          index={0}
        />
      
        <ButtonComp className="height-50"
          label="continue"
          style={{marginTop:'20px', width: "100%",height:'60px', fontWeight:600,fontFamily:"Montserrat, sans-serif" }}
          disabled={(selected?.length == 1) ? false : true}
          type={selected?.length == 1 ? "active" : "disabled"}
          onClick={() => {
            handleData({ countryName: selected[0],rfcNumber:formik.values.rfcNumber });
           handleClear();
            setPage(5);
            
          }}
        />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectLang;
