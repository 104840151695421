import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ButtonComp from "../component/Button";
import CustomTextField from "../component/Textfield";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography, Checkbox } from "@mui/material";
import PrivacyPolicyInfo from "./PrivacyPolicyInfo";
import TermsandCondition from "./TermsandCondition";
import radiogreen from "../assets/images/radioicongreen.svg";
import emailIcon from "../assets/images/svg/emailiconsvg.svg";
import event2tlogo from "../assets/images/svg/event2tlogo.svg";
import passwordIconInput from "../assets/images/svg/passwordiconsvg.svg";
import { getFCMToken } from "../firebase";
import { getSx } from "../Login/style";
import { getRole, getLanguage } from "../utils/auth-token-utils/cookies";
import { signupApi } from "../api/Signup";
import { showToast, setTermsAccepted } from "../store/slices/common";
import { setProfileInfo } from "../store/slices/profileinfo";
import { routePaths } from "../Routes/route-paths";
import GoogleLoginInfo from "../component/GoogleLogin";
import FaceBookLogin from "../component/FaceBookLogin";

const Register = () => {
  const classes = getSx();
  const role = getRole();
  const langCode = getLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTermCondition, setIsTermCondition] = useState(false);
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleTerms = () => {
    if (role == "1") {
      setTerms(false);
      setPolicy(true);
    } else {
      setTerms(false);
    }
  };
  const handlePolicy = () => {
    setPolicy(false);
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password required")
      .matches(

        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,51}$/
        ,
        "Password must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Enter a Valid Email Address"
      ),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if ((role == "0" &&
        formik.isValid &&
        formik.dirty &&
        isTermCondition) ||
        (role == "1" && formik.isValid && formik.dirty))
        handleClick();

    }
  };
  const handleClick = async () => {
    setLoading(true);
    try {
      const resp = await signupApi({
        userType: Number(role) || 0,
        defaultLanguageCode: langCode || "en",
        email: formik.values.email,
        password: formik.values.password,
      });
      if (resp?.status == 200) {
        localStorage.setItem('role', 0)
       getFCMToken();
        navigate("/create-profile");
        dispatch(setProfileInfo({ screen:3, userId: resp?.data?.data?.userId }))
        localStorage.setItem("token", resp?.data?.data?.token);
        dispatch(setTermsAccepted(resp?.data?.data?.isTermsAccepted))
        dispatch(
          showToast({
            color: "success",
            msg: "Registered Successfully",
          })
        );
        localStorage.setItem("isLogin", true);
        formik.resetForm();
        setIsTermCondition(false);
      }
      setLoading(false);
      if (resp?.response?.status >= 400) {
        dispatch(
          showToast({
            color: "error",
            msg: resp?.response?.data?.message,
          })
        );
      }

    } catch (err) {
      dispatch(
        showToast({
          color: "success",
          msg: " Registered Successfully",
        })
      );
    }
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={2} margin="auto">
        <Box sx={classes.container}>
          <Box sx={classes.maincontainer}>
            <Box sx={classes.logo}>
              <img alt=""
                src={event2tlogo}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box
              sx={{
                ...classes.heading,
                fontSize: { xs: "20px", md: "24px", lg: '26px', xl: '34px' }, fontFamily: "Montserrat, sans-serif"
              }}
            >
              Welcome!
            </Box>
            <Box sx={{ ...classes.content, fontSize: { xs: "16px", md: "17px", lg: '18px', xl: '20px' }, marginBottom: "10px", fontFamily: "Montserrat, sans-serif" }}>Sign Up to your account</Box>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Box sx={classes.inputinfo}>
                <Typography
                  fontWeight={600}
                  color={"#1A1A1A"}
                  marginBottom={"12px"}
                  fontFamily={"Montserrat, sans-serif"}
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '15px',
                      lg: '15px',
                      xl: '18px'
                    },
                    lineHeight: {
                      xs: '19px',
                      sm: '19px',
                      md: '20px',
                      lg: '20px',
                      xl: '23px'
                    }
                  }}
                >
                  Email Address
                </Typography>
                <Typography
                  className="input-with-icon-div margintopp"
                  component="div"
                  position={"relative"}
                  sx={{
                    marginBottom: {
                      xs: '10px',
                      sm: '10px',
                      md: '15px',
                      lg: '15px',
                      xl: '20px'
                    },
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <CustomTextField
                    type="email"
                    variant="outlined"
                    placeholder="Enter Email Address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    errorMessage={formik.touched.email && formik.errors.email}
                    className={"email-with-icon"}
                    onKeyDown={handleKeyPress}
                  />
                  <Typography
                    sx={{ fontFamily: "Montserrat, sans-serif", }}
                    className="input-icon"
                    component="div"
                    position="absolute"
                  >
                    <img
                      src={emailIcon}
                      alt="Image"
                    />
                  </Typography>
                </Typography>
              </Box>
              <Box sx={classes.inputinfo}>
                <Typography
                  fontWeight={600}
                  color={"#1A1A1A"}
                  marginBottom={"12px"}
                  fontFamily={"Montserrat, sans-serif"}
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '15px',
                      lg: '15px',
                      xl: '18px'
                    },
                    lineHeight: {
                      xs: '19px',
                      sm: '19px',
                      md: '20px',
                      lg: '20px',
                      xl: '23px'
                    }
                  }}
                >
                  Password
                </Typography>
                <Typography
                  className="input-with-icon-div margintopp"
                  component="div"
                  position={"relative"}
                  sx={{
                    marginBottom: {
                      xs: '10px',
                      sm: '10px',
                      md: '15px',
                      lg: '15px',
                      xl: '20px'
                    },
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <CustomTextField
                    type="password"
                    variant="outlined"
                    placeholder="Enter Password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    errorMessage={
                      formik.touched.password && formik.errors.password
                    }
                    className={"email-with-icon"}
                    onKeyDown={handleKeyPress}
                  />
                  <Typography
                    sx={{ fontFamily: "Montserrat, sans-serif", }}
                    className="input-icon"
                    component="div"
                    position="absolute"
                  >
                    <img
                      src={passwordIconInput}
                      alt="Image"
                    />
                  </Typography>
                </Typography>
              </Box>
              <Box sx={classes.inputinfo}>
                <Typography
                  fontWeight={600}
                  color={"#1A1A1A"}
                  marginBottom={"12px"}
                  fontFamily={"Montserrat, sans-serif"}
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '15px',
                      lg: '15px',
                      xl: '18px'
                    },
                    lineHeight: {
                      xs: '19px',
                      sm: '19px',
                      md: '20px',
                      lg: '20px',
                      xl: '23px'
                    }
                  }}
                >
                  Retype Password
                </Typography>
                <Typography
                  className="input-with-icon-div margintopp"
                  component="div"
                  position={"relative"}
                  sx={{
                    marginBottom: {
                      xs: '10px',
                      sm: '10px',
                      md: '15px',
                      lg: '15px',
                      xl: '20px'
                    }, fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <CustomTextField
                    type="password"
                    variant="outlined"
                    placeholder="Re-Enter Password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeyPress}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    errorMessage={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    className={"email-with-icon"}
                  />
                  <Typography
                    sx={{ fontFamily: "Montserrat, sans-serif", }}
                    className="input-icon"
                    component="div"
                    position="absolute"
                  >
                    <img
                      src={passwordIconInput}
                      alt="Image"
                    />
                  </Typography>
                </Typography>
              </Box>


              <Grid sx={classes.checkboxinfo}>
                <Box display="flex" alignItems="flex-start">
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    checked={isTermCondition}
                    onKeyDown={handleKeyPress}
                    sx={{
                      padding: "0",
                      "& .MuiSvgIcon-root": {
                        borderRadius: "50%",
                        padding: "0px",
                      },
                    }}
                    icon={<img alt="" src={radiogreen} />}
                    checkedIcon={
                      <CheckCircleIcon
                        sx={{
                          color: "rgb(22, 192, 152)",
                        }}
                      />
                    }

                    onChange={(event) => {
                      setIsTermCondition(event.target.checked);
                    }}
                  />
                  <Typography
                    fontSize={{
                      xs: "12px",
                      sm: "14px",
                      md: "16px",
                      lg: "14px",
                      xl: "14px",
                    }}
                    variant="body1"
                    sx={{
                      color: "#64748B",
                      fontWeight: "400",
                      textAlign: "Left",
                      paddingLeft: '10px',
                      fontFamily: "Montserrat, sans-serif"
                    }}
                  >
                    By tapping here you agree to our
                    <span
                      to={routePaths.TERMS_AND_CONDITION}
                      style={{
                        cursor: "pointer",
                        color: "rgba(23, 186, 174, 1)",
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat, sans-serif"
                      }}
                      onClick={() => {
                        setTerms(true);
                      }}
                    >
                      {` `}Terms & Conditions {` `}
                    </span>
                    & {` `}
                    <span
                      underline="none"
                      to={routePaths.PRIVACY_POLICY}
                      style={{
                        cursor: "pointer",
                        color: "rgba(23, 186, 174, 1)",
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Montserrat, sans-serif"
                      }}
                      onClick={() => {
                        setPolicy(true);
                      }}
                    >
                      Privacy Policy.
                    </span>
                  </Typography>
                </Box>
              </Grid>


              <Box sx={{ marginTop: "1rem" }}>
                <ButtonComp
                  height={'60px'}
                  width="100%"
                  label="Sign Up"
                  loading={loading}
                  disabled={formik.isValid &&
                    formik.dirty && isTermCondition ? false : true}
                  type={formik.isValid &&
                    formik.dirty && isTermCondition ? "active" : "disabled"}
            
                  style={{
                    marginTop: role == "1" ? "0px" : "15px",
                    width: "100%",
                  }}
                  onClick={() => {
                    handleClick();
                  }}
                />
              </Box>

            </form>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: { xs: "0", md: "2rem 0rem", lg: "1rem 0", xl: "1rem 0rem" } }}>
              <hr class="styled-line" style={{ width: "80px", margin: "0px 15px" }} />
              or
              <hr class="styled-line" style={{ width: "100px", margin: "0px 15px" }} />
            </Box>
            <Box sx={classes.logins}>
              <GoogleLoginInfo setLoading={setLoading} />
              <FaceBookLogin  setLoading={setLoading}/>
            </Box>

            <Box sx={classes.donthaveacc}>
              Already have an account?
              <span
                style={{
                  color: "#16C098",
                  fontWeight: "600",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(routePaths.LOGIN);
                }}
              >
                {` `}
                Sign In
              </span>{" "}
            </Box>
          </Box>
        </Box>
      </Grid>
      <TermsandCondition terms={terms} setTerms={setTerms} handleTerms={handleTerms} />
      <PrivacyPolicyInfo policy={policy} handlePolicy={handlePolicy} handleClick={handleClick} />
    </>
  );
};

export default Register;
