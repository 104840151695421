import React,{useState,useEffect} from 'react'

import {
    Typography,
  } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import mappointer from "../../../assets/images/mappointer.svg";
import { mapContainerStyle,mapOptions } from '../../../common/constant';

const GoogleMapView = ({addService}) => {
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    
      useEffect(() => {
        let script; 
        if (!window.google || !window.google.maps) {
          script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
          script.async = true;
          script.onload = () => setIsGoogleMapsLoaded(true);
          document.body.appendChild(script);
        }
        else
        {
          setIsGoogleMapsLoaded(true);
        }
      
        return () => {
          if (script) {
            document.body.removeChild(script);
          }
        };
      }, []);
      
  return (
    
      isGoogleMapsLoaded&&<div style={{ overflow: "auto" }}>
          <Typography
            variant="body1"
            sx={{
                fontSize:{xs:"16px",md:"18px"},fontFamily:"Montserrat, sans-serif",marginBottom:'15px',fontWeight:700,marginTop:'15px'
            }}
          >
            Location
          </Typography>

          <Typography
            marginTop="24px"
            className="map"
          >
           <GoogleMap
              id="circle-example"
              mapContainerStyle={mapContainerStyle}
              defaultOptions={{
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              options={mapOptions}
              zoom={12.6}
              center={{lat:Number(addService?.location?.lat),lng:Number(addService?.location?.long)}}
            >
              <MarkerF
                position={{lat:Number(addService?.location?.lat),lng:Number(addService?.location?.long)}}
                icon={{
                  url: mappointer,
                }}
              />
            </GoogleMap>
          </Typography>
        </div>
      
  )
}

export default GoogleMapView