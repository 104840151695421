import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { Rating } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getSx } from "../../eventorganiser/dashboard/style";
import verifiedroundedtick from '../../../assets/images/verifiedroundedtick.svg'

const CardComp = ({ item, value }) => {
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    setMaxDate(item?.endDate);
    setMinDate(item?.startDate)
  }, [])
  const navigate = useNavigate();
  const classes = getSx();
  const sericecat = item?.serviceDetails?.services?.flatMap((item) =>
    item?.categories?.map((item1) => item1?.categoryId?.categoryName)
  );
  return (
    <Box
      sx={
        classes.cardInfo
      }
      onClick={() => {
        navigate(`/request-info?id=${item?._id}&startDate=${minDate}&endDate=${maxDate}`);
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex" }}>

          {value != 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 20px 0 4px",
                borderRight: "2px solid rgba(23, 186, 174, 0.1)",
                color: "rgba(85, 85, 85, 1)",
                fontSize: "15px",
              }}
            >
              <Box
                sx={{
                  color: "rgba(23, 186, 174, 1)",
                  fontWeight: 700,
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '20px',
                    xl: '24px'
                  },
                  lineHeight: {
                    xs: '16px',
                    sm: '16px',
                    md: '18px',
                    lg: '20px',
                    xl: '24px'
                  }
                }}
              >
                {dayjs(minDate).format("MMM DD").split(" ")[1]}
              </Box>
              <Box sx={{
                fontSize: {
                  xs: '12px',
                  sm: '13px',
                  md: '13px',
                  lg: '14px',
                  xl: '15px'
                },
                fontWeight: 400,
                lineHeight: '22px',
                color: '#555555',
                fontFamily: 'Montserrat, sans-serif',
              }}>
                {dayjs(minDate)
                  .format("MMM DD")
                  .split(" ")[0]
                  .substring(0, 3)}
              </Box>
              {minDate != maxDate &&
                <>
                  <Box sx={{ margin: '5px 0' }}>|</Box>
                  <Box sx={{
                    color: "rgba(23, 186, 174, 1)",
                    fontWeight: 700,
                    fontSize: {
                      xs: '16px',
                      sm: '16px',
                      md: '18px',
                      lg: '20px',
                      xl: '24px'
                    },
                    fontFamily: 'Montserrat, sans-serif',
                    lineHeight: {
                      xs: '16px',
                      sm: '16px',
                      md: '18px',
                      lg: '20px',
                      xl: '24px'
                    }
                  }}>{dayjs(maxDate).format("MMM DD").split(" ")[1]}</Box>
                  <Box sx={{
                    fontSize: {
                      xs: '12px',
                      sm: '13px',
                      md: '13px',
                      lg: '14px',
                      xl: '15px'
                    },
                    fontWeight: 400,
                    lineHeight: '22px',
                    color: '#555555',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>{dayjs(maxDate).format("MMM DD").split(" ")[0].substring(0, 3)}</Box>
                </>}
            </Box>
          )}

          <Box sx={{
            display: "flex", flexDirection: "column", width: {
              xs: 'auto',
              sm: 'calc(100% - 54px)',
              md: 'calc(100% - 54px)',
              lg: 'calc(100% - 54px)',
              xl: 'calc(100% - 54px)',
            }
          }}>
            <Box sx={{ padding: "0 4px 0 24px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h3"
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: '#1A1A1A',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      md: '16px',
                      lg: '18px',
                      xl: '20px'
                    }
                  }}
                >
                  <span><img alt="" src={item?.serviceDetails?.serviceImages[0]?.fileUrl} style={{ width: "35px", height: "35px", borderRadius: "50%", margin: "auto", marginRight: "5px", }} /></span>
                  <span style={{ display: "flex", alignItems: "center" }}>{item?.serviceDetails?.serviceTitle}
                    <img alt="" src={verifiedroundedtick} style={{ marginLeft: "5px", width: "24px", height: "24px" }} /></span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: {
                    xs: "5px 0",
                    sm: "5px 0",
                    md: "5px 0",
                    lg: "5px 0",
                    xl: "10px 0"
                  },
                }}
              >
                <Box sx={{
                  display: "flex", alignItems: 'center', fontFamily: 'Montserrat, sans-serif', fontWeight: 600, fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '14px',
                    lg: '14px',
                    xl: '16px'
                  }, lineHeight: '24px'
                }}>
                  {" "}
                  {item?.averageRating?.toFixed(1)}{" "}
                  <Rating
                    value={item?.averageRating}
                    precision={0.5}
                    readOnly={true}
                  />
                </Box>
                <AvatarGroup
                  max={4}
                  sx={{
                    marginLeft: "0.5rem",
                    "& .MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    },
                  }}
                >
                  {item?.ratings?.map((item) => {

                    return (
                      <Avatar
                        alt="Remy Sharp"
                        size={16}
                        src={item?.userDetails?.profileImage?.url}
                        key={item}
                      />
                    );
                  })}
                </AvatarGroup>
              </Box>
              {!((item?.status == "Pending" || item?.status == "Request" || item?.status == "Accepted" || item?.status == "Rejected") && item.serviceDetails.serviceName == "Venues") &&

                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif', fontSize: {
                      xs: '10px',
                      sm: '11px',
                      md: '11px',
                      lg: '12px',
                      xl: '14px'
                    }, lineHeight: '17px',
                    color: '#1A1A1A',
                    fontWeight: 400,
                    marginTop: {
                      xs: '5px',
                      sm: '5px',
                      md: '5px',
                      lg: '5px',
                      xl: '10px'
                    },
                    display: 'inline-block',
                    width: '80%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.serviceDetails.address},
                  {item?.serviceDetails?.state},
                  {item?.serviceDetails?.zipCode}
                </Typography>}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ...classes.servicecont, height: {
              xs: '38px',
              sm: '38px',
              md: '38px',
              lg: '38px',
              xl: "45px"
            }, overflowX: "auto", width: "100%", paddingTop: {
              xs: '10px',
              sm: '10px',
              md: '10px',
              lg: '10px',
              xl: '15px'
            }
          }}
        >
          {sericecat?.map((item) => {
            return <Box sx={classes.servicecontinfo} key={item}>{item}</Box>;
          })}
        </Box>
      </CardContent>
    </Box>
  );
};

export default CardComp;
