import React from "react";

import TermsandConditions from "../component/TermsandConditions";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "90% !important",
    padding: "17px",
    borderRadius: "20px",
    maxWidth: "90% !important",
  },
  "& .MuiPaper-root": { maxWidth: { xs: "auto", md: "97%" }, margin:'auto' },
  "& .MuiDialogActions-root": {},
}));


export default function TermsandCondition({ terms, handleTerms ,setTerms,fromMenu}) {
  return (
    <CustomDialog
      open={terms}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>{setTerms(false)}}
      sx={
        ({ "& .MuiPaper-root": { width: "100vw" }, width: "100vw" },
        {
          "& .MuiPaper-root": { width: { xs: "auto", lg: "90vw !important" } },
        })
      }
    >
      <DialogContent sx={{ padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        } ,width:{xs:"auto",lg:"100%"},height:"96%"}}>
        <TermsandConditions handleTerms={handleTerms} setTerms={setTerms} fromMenu={fromMenu}/>
      </DialogContent>
    </CustomDialog>
  );
}
