import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonComp from "../../../component/Button";
import NavBar from "../../../pages/eventorganiser/home";
import { Box, Typography, CircularProgress } from "@mui/material";
import Reports from "./Reports";
import { getSx } from "./style";
import redstaricon from '../../../assets/images/redstaricon.svg';
import greenblogicon from "../../../assets/images/svg/dashboardicons/blogicon.svg";
import greencalendericon from "../../../assets/images/svg/dashboardicons/greencalendericon.svg";
import greenstaricon from "../../../assets/images/svg/dashboardicons/greenstaricon.svg";
import greentickicon from "../../../assets/images/svg/dashboardicons/greentickicon.svg";
import { getCurrencyFormat } from "../../../common/constant";
import { dashboardstatsApi, graphstatsApi } from "../../../api/dashboardstats";
import { Container } from "@mui/system";

const ServiceDashBoardInfo = () => {
  const [dashboardStats, setDashboardStats] = useState();
  const [graphData, setGraphData] = useState([]);
  const [max, setMax] = useState(0);
  const navigate = useNavigate();
  const year = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [currentYear1, setCurrentYear1] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);

  const callApi = async () => {
    const response = await dashboardstatsApi();
    if (response.status === 200) setDashboardStats(response.data.data);
  };
  const callGraphDataApi = async () => {
    setLoading(true);
    const res = await graphstatsApi(currentYear1);
    if (res.status === 200) {
      let result = res.data?.data?.earningDetails?.reduce(
        (a, b) => Math.max(a, b.amount),
        200
      );
      setMax(result);
      const data1 = year.map((item, index) => {
        const year = res.data?.data[0]?._id?.year;
        const month = res.data?.data?.earningDetails?.find(
          (item) => item?.month == index + 1
        );
        if (month)
          return { month: item, totalEarnings: month.amount, year: year };
        else return { month: item, totalEarnings: 0, year: year };
      });
      setGraphData(data1);
    }
    setLoading(false);
  };
  useEffect(() => {
    callApi();
  }, []);
  useEffect(() => {
    callGraphDataApi();
  }, [currentYear1]);

  const classes = getSx();
  return (
    <Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: { xs: "15px 0", sm: "15px 0", md: '15px 0', lg: '15px 0', xl: '30px 0' } }}
      >
        <Box >
          <Typography variant="h5" fontWeight="bold" sx={{
            fontWeight: 700,
            fontSize: {
              xs: "20px",
              sm: "22px",
              md: "24px",
              lg: "25px",
              xl: "25px"
            }, color: '#111111', marginBottom: {
              xs: '10px',
              sm: '10px',
              md: '10px',
              lg: '15px',
              xl: '24px'
            }, fontFamily: 'Montserrat, sans-serif'
          }}>
            Dashboard
          </Typography>
        </Box>
        <Box
          sx={{ alignItems: "flex-end", height: { xs: "40px", md: "45px" } }}
        >
          <ButtonComp
            label="Add Service"
            type="active"
            height="100%"
            onClick={() => {
              navigate("/myservices", { state: { isOpenServiceModal: true } });
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ color: "blue", display: "flex", justifyContent: "center", alignItems: "center", heigth: "40vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={classes.info}>
          <Box sx={classes.left}>
            <Box sx={classes.revenueammount}>
              <Typography fontWeight="bold" sx={{
                fontSize: {
                  xs: '18px',
                  sm: '24px',
                  md: '24px',
                  lg: '28px',
                  xl: '40px'
                },
                color: '#1A1A1A', fontWeight: 700, fontFamily: 'Montserrat, sans-serif'
              }}>
                {dashboardStats?.earningDetails[0]?.totalAmount ? getCurrencyFormat(dashboardStats?.earningDetails[0]?.totalAmount) : "0.00"}
              </Typography>
              <Typography sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontSize: {
                  xs: '12px',
                  sm: '13px',
                  md: '14px',
                  lg: '15px',
                  xl: '20px'
                },
                color: '#1A1A1A'
              }} variant="body1" marginLeft="20px" >
                Total Revenue Generated
              </Typography>
            </Box>
            <Reports
              graphData={graphData}
              max={max}
              currentYear1={currentYear1}
              setCurrentYear1={setCurrentYear1}
            />
          </Box>
          <Box sx={classes.right}>
            <Box sx={classes.subbox} >
              <Box className="subbox1" sx={{ ...classes.subbox1, cursor: "pointer" }} onClick={() => { navigate("/booking-info?id=0") }}>
                {dashboardStats?.pendingCount > 0 && (
                  <Box sx={classes.count}>{dashboardStats?.pendingCount}</Box>
                )}
                <img alt="" src={greencalendericon} />
                <Typography
                  variant="h4"
                  fontWeight={700}
                  marginTop="12px"
                  marginBottom="12px"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '18px',
                      sm: '24px',
                      md: '24px',
                      lg: '28px',
                      xl: '40px'
                    },
                    color: '#1A1A1A',
                  }}
                >
                  {dashboardStats?.totalBookingCount}
                </Typography>
                <Typography sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '20px'
                  },
                  color: '#000000'
                }} variant="body1">My Bookings</Typography>
              </Box>
              <Box className="subbox1" sx={{ ...classes.subbox1, cursor: "pointer" }} onClick={() => { navigate("/booking-info?id=3") }}>
                <img alt="" src={greentickicon} />
                <Typography
                  variant="h4"
                  fontWeight={700}
                  marginTop="12px"
                  marginBottom="12px"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '18px',
                      sm: '24px',
                      md: '24px',
                      lg: '28px',
                      xl: '40px'
                    },
                    color: '#1A1A1A',
                  }}
                >
                  {dashboardStats?.totalCompletedBookingCount}
                </Typography>
                <Typography sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '20px'
                  },
                  color: '#000000'
                }} variant="body1">Completed Bookings</Typography>
              </Box>
            </Box>
            <Box sx={{ ...classes.subbox, cursor: "pointer" }}>
              <Box className="subbox1" sx={dashboardStats?.averageRating >= 3 || dashboardStats?.averageRating == 0 ? classes.subbox1 : { ...classes.subbox1, background: "rgba(234, 41, 41, 0.1)" }}
                onClick={() => { navigate("/ratings-and-reviews", { state: { rating: dashboardStats?.averageRating } }) }}
              >
                <img alt="" src={dashboardStats?.averageRating >= 3 || dashboardStats?.averageRating == 0 ? greenstaricon : redstaricon} />
                <Typography
                  variant="h4"
                  fontWeight={700}
                  marginTop="12px"
                  marginBottom="12px"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '18px',
                      sm: '24px',
                      md: '24px',
                      lg: '28px',
                      xl: '40px'
                    },
                    color: '#1A1A1A',
                  }}
                >
                  {dashboardStats?.averageRating.toFixed(1)}
                </Typography>
                <Typography sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '20px'
                  },
                  color: '#000000'
                }} variant="body1">Ratings & Reviews</Typography>
              </Box>
              <Box className="subbox1" sx={{ ...classes.subbox1, cursor: "default" }}>
                <Box sx={{ display: "flex" }}>
                  <img alt="" src={greenblogicon} />
                  <Box sx={{ color: "#17BAAE", fontSize: { xs: "11px", sm: "13px", md: "18px" }, marginLeft: "5px" }}>Coming Soon</Box>
                </Box>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  marginTop="12px"
                  marginBottom="12px"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '24px',
                      sm: '30px',
                      md: '33px',
                      lg: '36px',
                      xl: '40px'
                    },
                    color: '#1A1A1A',
                  }}
                >
                  25
                </Typography>
                <Typography sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '20px'
                  },
                  color: '#000000'
                }} variant="body1">Pro Tips and Blogs</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
const ServiceDashBoard = () => {
  return (
    <NavBar>
      <Container sx={{
        padding: {
          xs: '0 15px',
          sm: '0 30px',
          md: '0 30px',
          lg: '0 30px',
          xl: 0
        }
      }} maxWidth="xl">
        <ServiceDashBoardInfo />
      </Container>
    </NavBar>
  );
};

export default ServiceDashBoard;
