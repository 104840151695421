export const getSx=()=>{
  return {
    main: {
      height: {xs:"calc(100vh - 140px)",md:'calc(100vh - 190px)',sm:'calc(100vh - 119px)', lg:"calc(100vh - 150px)", xl:"calc(100vh - 230px)"},
      overflowY: "auto",
    },
    singlenot: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(23, 186, 174, 0.1)",
      padding: {
        xs:"10px 15px",
        sm:"10px 15px",
        md:"10px 15px",
        lg:"10px 15px",
        xl:"15px"
      },
      borderRadius: "11px",
      cursor: "pointer",
      boxShadow: " 0 10 20px 4px #DDE2FF63",
     border: "1px solid #00000026",
      marginBottom:{
        xs:'10px',sm:'10px',md:'10px', lg:'15px',xl:'20px'
      }

    },
    notifcationinfo:{
      fontSize:"16px",
      fontWeight: "500",
      color: "rgba(26, 26, 26, 1)",
       fontFamily:"Montserrat, sans-serif",
    },
    notifcationimg: {
      marginRight: "15px",
      alignItems:'center',
      display: 'flex'
    },
    time: {
      color: "rgba(25, 25, 25, 1)",
      fontSize: "12px",
      fontWeight: 600
    },
    notred:{
      width:"18px",
      height:"18px",
      borderRadius:"50%",
      backgroundColor:"red",
      textAlign:"center",
      color:"#fff"
    }
  };
}
