import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import instance from "../src/common/config/axios";

const firebaseConfig = {
  apiKey:process.env.REACT_APP_FIREBASE_API_KEY ,
  authDomain: "eventutti-e86c3.firebaseapp.com",
  projectId: "eventutti-e86c3",
  storageBucket: "eventutti-e86c3.appspot.com",
  messagingSenderId: "243226344435",
  appId: "1:243226344435:web:a09679de2d88ef9128f086",
  measurementId: "G-6Q5NCSMYBE",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-cloud-messaging-push-scope')
      .then((serviceWorker) => {
        if (serviceWorker) {
          return serviceWorker;
        }
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((serviceWorker) => {
            return serviceWorker;
          })
          .catch((err) => {
            console.log("Service Worker registration failed", err);
          });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};
const getFCMToken = async () => {
  await getOrRegisterServiceWorker(); 
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((token) => {
          if (token) {
            saveToken(token)
          } 
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export const saveToken = async (token) => {
  try {
  await instance.put("user/update", {
      deviceToken: token,
      deviceType: "Web",
    });
  } catch (err) {
    console.log("Error while saving token", err);
  }
};


export { getFCMToken, onMessage, messaging };
