import React from 'react'

import ButtonComp from '../../../../component/Button'
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Typography,Box } from '@mui/material'
import dumycardimg from '../../../../assets/images/dummycardimage.png'
import plusicon from '../../../../assets/images/svg/greenplusicon.svg'
import { getSx } from '../style'

const ServiceImageDesc = () => {
  const classes=getSx();
  return (
    <Box sx={classes.serviceimagedesc}>
      <Typography variant="h6" fontWeight="bold" sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"Montserrat, sans-serif",marginBottom:'15px'}}>
        Service Images
      </Typography>
      <Box sx={classes.serviceimages}>
        <Box sx={classes.addimgcont}>
          <input type="file" id="file" style={{ display: "none" }} />
          <label htmlFor="file">
            <img alt="" src={plusicon} />
          </label>
        </Box>
        <Box sx={classes.cardimages}>
          <Box sx={classes.singleimg}>
            <img alt=""
              src={dumycardimg}
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
          </Box>
          <Box sx={classes.singleimg}>
            <img alt=""
              src={dumycardimg}
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
          </Box>
          <Box sx={classes.singleimg}>
            <img alt=""
              src={dumycardimg}
              style={{ width: "200px", height: "200px", objectFit: "cover" }}
            />
          </Box>
        </Box>
      </Box>
      <Typography variant="h6" fontWeight="bold" marginTop="10px" sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"Montserrat, sans-serif",marginBottom:'15px'}}>
        Service Description
      </Typography>
      <TextareaAutosize
        placeholder="Enter Title Here"
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: "rgba(246, 245, 245, 1)",
          borderRadius: "15px",
          borderColor: "transparent",
          marginBotton: "3rem",
          fontSize: "15px",
          fontFamily: "Montserrat",
          marginBottom:"2rem"
        }}
        minRows={8}
        className='textArea'
      />
      <ButtonComp label="Add-On Service" width="100%"/> 
    </Box>
  );
}

export default ServiceImageDesc