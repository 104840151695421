import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import AboutBusiness from "./AboutBusiness";
import BankDetails from "./BankDetails";
import NameInfo from "./NameInfo";
import PhoneNumber from "./PhoneNumber";
import SelectLang from "./SelectLang";
import { getSx } from "./style";
import UploadBusinessFile from "./UploadFileSize/index";
import UploadProfile from "./UploadProfile";
import event2tlogo from "../../../assets/images/svg/event2tlogo.svg";
import rightarrowicon from "../../../assets/images/svg/rightarrowicon.svg";
import PrivacyPolicyInfo from "../../../Signup/PrivacyPolicyInfo";
import TermsandCondition from "../../../Signup/TermsandCondition";
import { getRole } from "../../../utils/auth-token-utils/cookies";
import { createProfileApi } from "../../../api/createprofile";
import { showToast, setTermsAccepted } from "../../../store/slices/common";
import { setProfileInfo } from "../../../store/slices/profileinfo";
import { callSwitchRoleApi } from "../../../api/profile";

const CreateProfile = () => {
  const [value, setValue] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [selected, setSelected] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [filesArr, setFilesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const role = getRole();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { fromSwitchProfile } = location.state || false;
  const { fromSocialMedia } = location.state || false;
  const userId = useSelector((state) => state.profile.profileInfo.userId);
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  const isTermsAccepted = useSelector((state) => state.common.isTermsAccepted);
  const [isAPIResponseReceived, setIsAPIResponseReceived] = useState([]);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: "rgba(23, 186, 174, 0.1) !important",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const handleArrowClick = () => {
    if (page == 4 && fromSwitchProfile) {
      navigate("/switch-profile");
    }
    else
      setPage((page) => page - 1);
  };
  const handleTerms = () => {
    setTerms(false);
    setPolicy(true);
  };
  const handlePolicy = () => {
    setPolicy(false);
  };
  const handleClick = async () => {
    const resp = await createProfileApi({ isTermsAccepted: true });
    if (resp?.status == 200) {
      setPolicy(false);
      dispatch(setTermsAccepted(true));
    }
  };
  const handleClear = () => {
    setFileNames([]);
    setFilesArr([]);
    setIsAPIResponseReceived([]);
  };
  const handleData = (values) => {
    const val = { ...data, ...values };
    setData(val);
    if (page == 3) setCountryName([]);
  };
  const saveProfile = (values) => {
    let val;
    if (role == "0")
      val = { ...data, ...values, countryCode: `+${data.countryCode}` };
    else
      val = { ...data, ...values };
    callCreateProfile(val);
  };
  const callCreateProfile = async (val) => {
    setLoading(true);
    const resp = await createProfileApi(val);
    if (role == "1")
      callSwitchRoleApi();
    if (resp?.status == 200) {
      dispatch(
        showToast({
          color: "success",
          msg: "Profile is created successfully",
        })
      );
      if (role == "0") {
        navigate("/profilecreationsucessful?id=1");
        dispatch(setProfileInfo({ screen: 1, userId: userId }));
      } else {
        navigate("/profilecreationsucessful?id=2");
        dispatch(setProfileInfo({ ...profileInfo, screen: 5 }))
      }
    } else if (resp?.response?.status >= 400) {
      dispatch(
        showToast({
          color: "error",
          msg: resp?.response?.data?.message,
        })
      );
    }
    setLoading(false);
  };
  const classes = getSx();
  // useEffect(() => {
  //   if (!isTermsAccepted && role == "1") setTerms(true);
  // }, []);
  useEffect(() => {
    if (fromSwitchProfile) {
      setPage(4);
      setValue(45);
    }
    if (fromSocialMedia) {
      setPage(2);
      setValue(75);
    }
  }, [])
  return (
    <>
      <Box sx={classes.maincontainer}>
        <Box sx={classes.container}>
          <Box sx={classes.rightarrowicon}>
            {((page != 1 && !fromSwitchProfile) || (fromSwitchProfile && page >= 4)) && !fromSocialMedia && (
              <img alt=""
                src={rightarrowicon}
                onClick={() => {
                  handleArrowClick();
                }}
              />
            )}
          </Box>
          <Box sx={classes.logo}>
            <img alt="" src={event2tlogo} style={{ width: "100%", height: "100%" }} />
          </Box>

          <Stack sx={{ width: "40%" }} spacing={3}>
            <BorderLinearProgress
              variant="determinate"
              value={value}
              sx={classes.progressbar}
              style={{
                backgroundColor:
                  "linear-gradient(to bottom,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1) !important",
              }}
            />
          </Stack>
          <Box sx={classes.infocontainer}>
            {page == 1 && (
              <NameInfo
                setPage={setPage}
                setValue={setValue}
                role={role}
                handleData={handleData}
                data={data}
                setSelectedDate={setSelectedDate}
              />
            )}
            {page == 2 && (
              <PhoneNumber
                setPage={setPage}
                setValue={setValue}
                role={role}
                handleData={handleData}
                data={data}
                fromSocialMedia={fromSocialMedia}
                saveProfile={saveProfile}
                loading={loading}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
              />
            )}
            {page == 3 && (
              <UploadProfile
                setPage={setPage}
                setValue={setValue}
                role={role}
                handleData={handleData}
                data={data}
                loader={loading}
                saveProfile={saveProfile}
              />
            )}
            {page == 4 && (
              <SelectLang
                setPage={setPage}
                setValue={setValue}
                role={role}
                handleData={handleData}
                selected={countryName}
                setSelected={setCountryName}
                data={data}
                handleClear={handleClear}
              />
            )}
            {page == 5 && (
              <UploadBusinessFile
                setValue={setValue}
                setPage={setPage}
                role={role}
                handleData={handleData}
                setFileNames={setFileNames}
                fileNames={fileNames}
                filesArr={filesArr}
                setFilesArr={setFilesArr}
                setIsAPIResponseReceived={setIsAPIResponseReceived}
                isAPIResponseReceived={isAPIResponseReceived}
                setSelected={setSelected}
                setCheckedValues={setCheckedValues}
              />
            )}
            {page == 6 && (
              <AboutBusiness
                setPage={setPage}
                setValue={setValue}
                role={role}
                setSelected={setSelected}
                selected={selected}
                handleData={handleData}
                loader={loading}
                saveProfile={saveProfile}
                setCheckedValues={setCheckedValues}
                checkedValues={checkedValues}
              />
            )}

            {page == 7 && <BankDetails />}
          </Box>
        </Box>
      </Box>
      <TermsandCondition terms={terms} handleTerms={handleTerms} />
      <PrivacyPolicyInfo
        policy={policy}
        handlePolicy={handlePolicy}
        handleClick={handleClick}
        setTerms={setTerms}
        setPolicy={setPolicy}
      />
    </>
  );
};

export default CreateProfile;
