import React, { useRef, useState } from "react";

import { IconButton, Paper, Slider, Typography } from "@mui/material";
import { Stack, styled } from "@mui/system";
import Delete from "../../../../assets/images/svg/crossicon.svg";
import fileIcon from "../../../../assets/images/svg/fileIcon.svg";
import { convertFileSize } from "../../../../helper/ConvertFileSize";

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
}));

const StyledSlider = styled(Slider)(({ completed, theme }) => ({
  height: "4px",
  paddingTop:"0px",
  "& .MuiSlider-thumb": {
    backgroundColor: completed
      ? "linear-gradient(to bottom,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))"
      : null,
    border: 0, 
    color: "rgba(23, 186, 174, 1) !important ",
    display:"none"
  },
  "& .MuiSlider-rail": {
    backgroundColor: completed
      ? "linear-gradient(to bottom,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))"
      : null,
    border: 0, 
  },
  "& .MuiSlider-track": {
    backgroundColor: completed
      ? "linear-gradient(to bottom,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))"
      : null,
    border: 0,
    color: "rgba(23, 186, 174, 1) !important ",
  },
  '& .percentagestatus':{
   padding:"5px 0px !important"
  }
}));

const PaperCard = ({
  selectedFile,
  onDelete,
  timeData,
  setTimeData,
  isAPIResponseReceived,
  fileNames,
  setSliderVal,
  sliderVal,
  index,
  fromBankDetails
}) => {
  const [sliderValue, setSliderValue] = useState(0);
  const timerRef = useRef();
  React.useEffect(() => {
    const mockAPIResponse = Array.from(
      { length: 100 },
      (_, index) => index + 1
    ).map((item) => {
      return item;
    });
    setTimeData(mockAPIResponse);

    if (!isAPIResponseReceived[index]) {
      timerRef.current = setInterval(() => {
        setSliderValue((prevValue) =>{
          {
            if (prevValue >= 95) {
              setSliderValue(99);
              clearInterval(timerRef.current); // Stop the interval when reaching 99%
            }
            return  (prevValue + 5) % 100;
          }
        })
      }, 700); 
    } 
    else {
      setSliderValue(100);
      let val=sliderVal;
      val[index]=100;
      setSliderVal(val)
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isAPIResponseReceived[index]]);

  return (
    <StyledPaper
      sx={{
        marginTop: "10px",
        borderRadius: "8px",
        boxShadow: "0 4px 14px 0 rgba(63, 73, 88, 0.05)",
        backgroundColor: "rgba(246, 245, 245, 1)",
        height: {xs:"90px",lg:"78px"}, 
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} component="div">
        <Stack style={{ margin: "7px" }} component="div">
          <img src={fileIcon} alt="File icon" />
        </Stack>
        <Stack
          component="div"
          style={{ margin: "7px" }}
          sx={{
            width: {
              xs: "100%",
              md: "100%",
              sm: "40%",
            },
          }}
        >
          <Typography
            variant="body1"
            color="rgba(167, 167, 167, 1)"
            fontWeight="bold"
            sx={{ fontSize: { xs: "10px",sm:"12px", md: "13px" } }}
          >
            {selectedFile?.name.slice(0,25)}{selectedFile?.name?.length>25 && "..."}
          </Typography>
          {!fromBankDetails&&
          <>
          <Typography
            variant="body2"
            color="rgba(167, 167, 167, 1)"
            fontWeight="500"
            sx={{ fontSize: { xs: "10px", md: "12px" },fontWeight:"Montserrat,sans-serif " }}
          >
            {convertFileSize(selectedFile?.size)}
          </Typography>
          <Stack direction={"row"} >
            <StyledSlider
              className={"percentagestatus"}
              aria-label="Volume"
              value={sliderValue}
              valueLabelDisplay="auto"
              max={fromBankDetails?1:timeData.length - 1}
              completed={sliderValue === 100 ? true : false}
            />
            <Typography
              variant="body2"
          
              sx={{ color: "black",marginTop:{lg:"-1px"},marginLeft:"5px !important" ,fontSize:{xs:'12px',md:"15px"}}}
            >
              {sliderValue}%
            </Typography>
          </Stack>
          </>
          }
        </Stack>
        <Stack style={{ margin: "5px" }}>
          <IconButton>
            <img
              src={Delete}
              alt="Delete icon"
              onClick={() => {
                onDelete(index);
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

export default PaperCard;
