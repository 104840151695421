import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ButtonComp from "./Button";
import { getSx } from "./style";
import { routePaths } from "../Routes/route-paths";
import { callSwitchProfileApi,getProfileApi } from "../api/profile";
import { setProfileInfo } from "../store/slices/profileinfo";
import { useDispatch } from "react-redux";

const InfoPage = ({
  image,
  mainHeading,
  smallHeading,
  content,
  buttonInfo,
  id
}) => {
  const classes = getSx();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const role=localStorage.getItem('role');
  const dispatch=useDispatch();
  const selectedServiceId=localStorage.getItem('selectedServiceId');
  const handleClick=()=>{
    if(id=="1" && role=="1")
    navigate("/myservices")
  else if(id=="1" && selectedServiceId)
    navigate(`/service-info?id=${selectedServiceId}`)
    else if(id==1)
   navigate(routePaths.EXPLORE_EVENT)
  else if(id=="2")
  {
  handleSwitchProfile()
  }
else
{
 navigate("/bank-details")
}

  }
  const handleSwitchProfile = async () => {
    setLoading(true);
    const response = await callSwitchProfileApi();
    localStorage.setItem("role", 0);
    if (response.status === 200) {
      localStorage.setItem("token", response?.data?.data?.token);
      const profileResponse = await getProfileApi();
      dispatch(setProfileInfo({screen:1,userId:profileResponse?.data?.data?._id}));
  }
    setLoading(false);
}

  return (
    <Box sx={classes.maincontainer}>
      <Box sx={classes.container}>
        <Box sx={{...classes.container,border:"none"}}>
        <img className="successfully-logo-icon" alt="" src={image} />
        <Typography
            sx={{
              fontFamily:"Montserrat, sans-serif",
              lineHeight:'30px',
              fontSize:{
                xs:'18px',
                sm:'20px',
                md:'22px',
                lg:'24px',
                xl:'30px'
              }
            }}
          variant="h4"
          fontWeight={600}
          marginBottom="30px"
          marginTop="10px"
        >
          {mainHeading}
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: "16px", sm:'18px', md: "20px", lg:'22px', xl:'24px' },fontFamily:"Montserrat, sans-serif", width: { sm: "100%", md: "75%" },color:'#111111',textAlign: "center" }}
          fontWeight={700}
          marginBottom="10px"
        >
          {smallHeading}
        </Typography>
        <Typography
          variant="body"
          color="#A7A7A7"
          sx={{ width: { sm: "100%", md: "62%" }, fontSize: { xs: "12px", sm:'14px', md: "14px", lg:'15px', xl:'16px' }, fontWeight:'500',fontFamily:"Montserrat, sans-serif" }}
          textAlign="center"
          marginBottom={{
            xs:'20px',
            sm:0,
            md:0,
            lg:0,
            xl:"10px"
          }}
        >
          {content}
        </Typography>
        <Box sx={{ width: { xs: "100%",sm:"60%", md: "50%",lg:"60%" }
              }}>
          <ButtonComp className="responsive-btn-class" label={buttonInfo} style={{height:'60px',fontWeight:600,fontFamily:"Montserrat, sans-serif",width:'100%' }}  type="active" onClick={handleClick} loading={loading} />
        </Box>
        {role=="1"&&id=="2" &&<Box sx={{marginTop:"10px"}}>Back as <span style={{color:"rgba(23, 186, 174, 1)",fontWeight:"bold",cursor:"pointer"}} onClick={()=>{handleSwitchProfile()}}>Event Organizer</span></Box>}
        </Box>
      </Box>
    </Box>
  );
};

export default InfoPage;
