import React from 'react'

const PetFriendly = ({value}) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5496 17.4938C27.1496 17.4938 28.7496 13.6938 28.7496 11.2938C28.7496 9.29385 27.7496 6.89385 25.7496 6.89385C23.1496 6.89385 21.5496 10.6938 21.5496 13.0938C21.5496 15.0938 22.5496 17.4938 24.5496 17.4938ZM16.9496 17.4938C18.9496 17.4938 19.9496 15.0938 19.9496 13.0938C19.9496 10.4938 18.3496 6.69385 15.7496 6.69385C13.7496 6.69385 12.7496 9.09385 12.7496 11.0938C12.5496 13.6938 14.1496 17.4938 16.9496 17.4938ZM31.7496 15.4938C29.1496 15.4938 27.3496 19.0938 27.3496 21.6938C27.3496 23.4938 28.1496 25.2938 29.9496 25.2938C32.5496 25.2938 34.3496 21.6938 34.3496 19.0938C34.3496 17.2938 33.3496 15.4938 31.7496 15.4938ZM14.3496 21.6938C14.3496 19.0938 12.3496 15.4938 9.94961 15.4938C8.14961 15.4938 7.34961 17.2938 7.34961 19.0938C7.34961 21.6938 9.34961 25.2938 11.7496 25.2938C13.5496 25.2938 14.3496 23.4938 14.3496 21.6938ZM20.7496 21.2938C16.7496 21.2938 11.3496 27.6938 11.3496 32.0938C11.3496 34.0938 12.7496 34.6938 14.3496 34.6938C16.7496 34.6938 18.5496 33.0938 20.7496 33.0938C22.7496 33.0938 24.5496 34.6938 26.7496 34.6938C28.3496 34.6938 30.1496 34.2938 30.1496 32.0938C30.1496 27.6938 24.7496 21.2938 20.7496 21.2938Z"
        fill={value ? "#17BAAE" : "black"}
      />
    </svg>
  );
}

export default PetFriendly