import React from "react";
import { Carousel } from "react-responsive-carousel";

import { Item } from "./Item";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Typography} from "@mui/material";

const CustomCarousel = ({items,fromModal,className}) => {

  return (
    <Carousel
      className={className}
      infiniteLoop={true}
      interval={4000}
      autoPlay={true}
      showStatus={false}
      style={{ height: "100%" }}
      
    >
      {items?.map((item) => (
        <>
          <Item key={item.key} item={item} />
          {!fromModal&&
          <div
            style={{
              position: "absolute",
              bottom: "5rem",
              left: "3rem",
              fontSize: "35px",
              width: "90%",
              fontFamily:"Montserrat, sans-serif"
            }}
          >
            <Typography variant="h5"  sx={{color: "#17BAAE",fontSize:'34px',fontWeight:600,lineHeight:'50px',margin:'0 0 30px', textAlign:'left',fontFamily:"Montserrat, sans-serif" }}>
              {item.heading}
            </Typography>
            <Typography
              style={{ fontSize: "20px", color: "#fff", textAlign: "left", lineHeight:'30px',fontWeight:500, marginBottom:0, fontFamily:'Montserrat, sans-serif' }}
            >
             {item.content}
            </Typography>
          </div>}
        </>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
