import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Box, Typography, Rating, CircularProgress } from "@mui/material";
import rightarrowicon from "../../../assets/images/svg/rightarrowicon.svg";

import { getRatingApi } from "../../../api/rating";

const ViewRating = ({ userId, setShowRating }) => {
  const [ratingArr, setRatingArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const[info,setInfo]=useState("");

  const callApi = async () => {
    setLoading(true);
    const resp = await getRatingApi(userId);
    if (resp?.status === 200) {
      setRatingArr(resp.data.data.list);
      setInfo(resp.data.data.ratingDetails[0]);
    }
    setLoading(false);
  };
  useEffect(() => {
    callApi();
  }, []);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        width: "98%",
        height: "80vh",
        padding: "20px",
        overflowY: "scroll",
        position: "relative",
        border: "1px solid rgba(0, 0, 0, 0.4)",
        borderRadius: "20px",
        margin: "10px auto",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "3rem",
          left: "8rem",
          cursor: "pointer",
        }}
      >
        {" "}
        <img
          onClick={() => {
            setShowRating(false);
          }}
          src={rightarrowicon}
        />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          flexDirection: "column",
        }}
      >
          {ratingArr?.length != 0 &&
          <>
        <Typography variant="h2" fontWeight="bold">
        {info?.averageRating?.toFixed(1)}
        </Typography>
        <Rating  precision={0.5} value={info?.averageRating} readOnly={true} />
        <Typography variant="body2" color="rgba(26, 26, 26, 0.5)">
          Based on {info?.totalRatings} reviews
        </Typography>
        </>
        }
      </Box>
      {ratingArr?.length == 0 ?
      <Box sx={{color:"black",fontWeight:"bold",display:"flex",justifyContent:"center",alignItems:"center",height:"30vh"}}>
        No Ratings Available</Box>:
      <Box sx={{ width: "100%" }}>
        <Box sx={{ height: { lg: "51vh", xl: "55vh" }, overflowY: "scroll" }}>
          {ratingArr?.map((item) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  cursor: item.replies.length == 0 ? "pointer" : "default",
                }}
                key={item}
              >
                <Box
                  sx={{
                    backgroundColor: "rgba(234, 253, 246, 1)",
                    padding: "20px",
                    borderRadius: "20px",
                    position: "relative",
                    bottom: "-14px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", margin: "15px" }}>
                      <img
                        src={item.createdBy.profileImage.url}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "10px",
                          marginRight: "15px",
                        }}
                      />
                      <Box>
                        <Typography>{item.createdBy.firstName}</Typography>
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Rating value={item.rating} precision={0.5} readOnly={true} />
                          <Box sx={{ marginLeft: "4px" }}>{item.rating.toFixed(1)}</Box>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontFamily: "Montserrat,san-serif",
                        fontSize: "14px",
                      }}
                    >
                      {dayjs(item.createdAt).format("MMMM DD,YYYY")}
                    </Box>
                  </Box>
                  <Typography variant="body1">{item.text}</Typography>
                </Box>
                {item.replies.length > 0 && (
                  <Box
                    sx={{
                      backgroundColor: "rgba(246, 245, 245, 1)",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", margin: "15px" }}>
                        <img
                          src={item.userId.profileImage.url}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "10px",
                            marginRight: "15px",
                          }}
                        />
                        <Box>
                          <Typography>{item.userId.firstName}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          color: "rgba(0, 0, 0, 0.7)",
                          fontFamily: "Montserrat,san-serif",
                          fontSize: "14px",
                        }}
                      >
                        {dayjs(item.replies[0].createdAt).format(
                          "MMMM DD,YYYY"
                        )}
                      </Box>
                    </Box>
                    <Typography variant="body1">
                      {item.replies[0].text}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>}
    </Box>
  );
};

export default ViewRating;
