import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import { useSelector ,useDispatch} from "react-redux";

import ButtonComp from "../../../component/Button";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import tickcircleicon from "../../../assets/images/tickcircleicon.svg";
import { getCurrencyFormat } from "../../../common/constant";
import { setBookServiceDates ,setMaxQuantityCountArr} from "../../../store/slices/bookserviceinfo";

const ServiceCategorySelection = ({
  serviceCategories,
  selectedCategories,
  setSelectedCategories,
  setPage,
  serviceTimings,
  setAvailableDays,
  availableDays,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setSelectedDatesArr,
  setTimings,
}) => {
  const [openCalendar, setOpenCalendar] = useState([false, false]);
  const dispatch = useDispatch();
  const checkIncludedCategory = (val) => {
    return selectedCategories.some((item) => item?._id === val?._id);
  };
  const handleSetSubCategory = (val) => {
    let isInCat = selectedCategories?.filter((item) => item?._id == val?._id);
    let filterData = selectedCategories?.filter(
      (item) => item?._id != val?._id
    );

    if (isInCat.length > 0) setSelectedCategories([...filterData]);
    else setSelectedCategories([...filterData, val]);
    
  };

  const handleSetSelectedCategory = () => {
    let cats = selectedCategories.map((item, index) => {
      return serviceCategories.find((element) => element?._id === item?._id);
    });
    let maxQuantity = cats.map(
      (item) => item.field3.value
    );
    dispatch(setMaxQuantityCountArr(maxQuantity));
    setSelectedCategories(cats);
    setSelectedDatesArr([]);
    setTimings([]);
  };

  const classes = getSx();
  useEffect(() => {
    const keys = Object.entries(serviceTimings)
      .filter(([key, value]) => value.isAvailable)
      .map(([key]) => key);
    setAvailableDays(keys);
  }, []);

  const catIds = useSelector(
    (state) => state.bookserviceinfo.serviceCategoryIds
  );
  return (
    <>
      <Box sx={{marginTop:{
        xs:'15px',
          sm:'20px',
          md:'20px',lg:'20px',
          xl:'30px'
        }}}>
        {catIds.length == 0 && (
          <>
            <Typography
              sx={{
                fontWeight:700,
                fontSize: {
                  xs:"14px",
                  sm:"15px",
                  md:"15px",
                  lg:"16px",
                  xl:"18px"
                },
                marginBottom: "10px",
                fontFamily:"Montserrat, sans-serif"
              }}
            >
              Start & End Date
            </Typography>
            <Box
              sx={{
                display: { xs: "flex", sm:'flex', md:'flex', lg: "flex", xl:'flex' },
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(246, 245, 245, 1)",
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                  marginRight: "8px",
                  width: { xs: "49%", sm:'49%', md: "49%",lg:'49%',xl:'49%' },
                   padding: {
                    xs:"10px",
                    sm:"10px",
                    md:"10px",
                    lg:"10px",
                    xl:"15px"
                  },
                }}
                onClick={() => {
                  setOpenCalendar((prevState) => [!prevState[0], false]);
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.5)",
                    marginRight: "6px",
                    fontSize: { xs: "9px", md: "10px", lg:'11px', xl:'13px' },
                    fontFamily:"Montserrat, sans-serif"
                  }}
                >
                  {startDate == null ? (
                    "Start Date"
                  ) : (
                    <span style={{ color: "black" }}>
                      {dayjs(startDate?.toDateString()).format("MMM DD  YYYY")}
                    </span>
                  )}
                </span>
              </Box>
              {openCalendar[0] && (
                <Box
                  sx={{
                    position: "absolute",
                    height: {
                      xs:"200px",
                      sm:"190px",
                      md:"180px",
                      lg:"180px",
                      xl:"200px"
                    },
                    padding: "10px",
                    top: {
                      xs:"9rem",
                      sm:"9rem",
                      md:"9rem",
                      lg:"9.3rem",
                      xl:"10.5rem"
                    },
                    zIndex: 111,
                    left: 0,
                    right:{
                      xs:0,
                      sm:'auto',
                      md:'auto',
                      lg:'auto',
                      xl:'auto'
                    }
                  }}
                  className="bookservicecalendar"
                >
                  <Calendar
                    onChange={(e) => {
                      setStartDate(e);
                      setOpenCalendar((prevState) => [!prevState[0], false]);
                    }}
                    minDate={new Date()}
                    maxDate={endDate}
                   calendarStartDay={1}
                 locale="en-GB"
                    value={startDate}
                    
                  />
                </Box>
              )}
              <Box
                sx={{
                  backgroundColor: "rgba(246, 245, 245, 1)",
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                  marginLeft: '8px',
                  width: { xs: "49%", sm:'49%', md: "49%",lg:'49%',xl:'49%' },
                  padding: {
                    xs:"10px",
                    sm:"10px",
                    md:"10px",
                    lg:"10px",
                    xl:"15px"
                  },
                }}
                onClick={() => {
                  if(startDate)
                  setOpenCalendar((prevState) => [false, !prevState[1]]);
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.5)",
                    marginRight: "6px",
                    fontSize: { xs: "9px", md: "10px",  lg: "11px",  xl: "13px" },
                    fontFamily:"Montserrat, sans-serif"
                  }}
                >
                  {endDate == null ? (
                    "End Date"
                  ) : (
                    <span style={{ color: "black" }}>
                      {dayjs(endDate?.toDateString()).format("MMM DD YYYY")}
                    </span>
                  )}
                </span>
              </Box>

              {openCalendar[1] && (
                <Box
                  sx={{
                    position: "absolute",
                    height: {
                      xs:"200px",
                      sm:"190px",
                      md:"180px",
                      lg:"180px",
                      xl:"200px"
                    },
                    padding: "10px",
                    top: {
                      xs:"9rem",
                      sm:"9rem",
                      md:"9rem",
                      lg:"9.3rem",
                      xl:"10.5rem"
                    },
                    right: 0,
                    zIndex: 111,
                    left:{
                      xs:0,
                      sm:'auto',
                      md:'auto',
                      lg:'auto',
                      xl:'auto'
                    }
                  }}
                  className="bookservicecalendar"
                >
                  <Calendar
                    minDate={startDate}
                    onChange={(e) => {
                      setEndDate(e);
                      setOpenCalendar((prevState) => [false, !prevState[1]]);
                    }}
                     calendarStartDay={1}
                 locale="en-GB"
                    value={endDate}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                color: "rgba(23, 186, 174, 1)",
                marginTop: "10px",
                display: "flex",
                overflowX: "auto",
                alignItems: "center",
              }}
            >
              {" "}
            Available Days :{" "}
              <Box sx={{ display: "flex", marginLeft: "5px" }}>
                {availableDays.map((item) => (
                <Box
                  key={item}
                    sx={{
                      marginRight: "3px",
                      textTransform: "capitalize",
                      background:
                        "linear-gradient(to right,rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",
                      borderRadius: "50%",
                      padding: "3px 7px",
                      color: "#fff",
                      fontSize:{
                        xs:'10px',
                        sm:'12px',
                        md:'13px',
                        lg:'14px',
                        xl:'16px'
                      }
                    }}
                  >
                    {item.substring(0, 2)}
                  </Box>
                ))}
              </Box>
            </Box>

            <Typography
              variant="h6"
              fontWeight="bold"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontFamily:"Montserrat, sans-serif",
                fontSize:{
                  xs:'14px',
                  sm:'16px',
                  md:'16px',
                  lg:'18px',
                  xl:'20px'
                },
                 marginTop:'10px',
              }}
            >
              Select Service Category
            </Typography>
          </>
        )}
        <Box sx={{minHeight:{
          xs:'calc(85vh - 400px)',
          sm:'auto',
          md:'auto',
          lg:'calc(100vh - 400px)',
          xl:'calc(100vh - 600px)',
          },
          maxHeight:{
          xs:'calc(85vh - 400px)',
          sm:'calc(100vh - 700px)',
          md:'calc(100vh - 700px)',
          lg:'calc(100vh - 400px)',
          xl:'calc(100vh - 600px)',
          },
          marginTop:{
          xs:'10px',
            sm:'10px',
            md:'10px',
            lg:'10px',
            xl:'20px'
          },
          overflow:'auto'
        }}>
          {serviceCategories.map((item) => {
          return catIds.includes(item._id) ? (
            <Box style={{cursor:'pointer'}} sx={classes.serviceinfodisabled}>
              <Box sx={{ marginRight: "15px", width: "78px", height: "65px", borderRadius:'14px',overflow:'hidden' }}>
                <img alt=""
                  src={item?.categoryId?.displayImage?.fileUrl}
                  style={{ width: "100%", height: "100%",objectFit:"cover", aspectRatio:'1/1' }}
                />
              </Box>
              <Box sx={{ fontWeight:700, color: "000000" }}>
                <Box sx={{
                  fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'13px',
                    sm:'14px',
                    md:'14px',
                    lg:'15px',
                    xl:'18px',
                  }
                }}>{item?.categoryId?.categoryName}</Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                  <Box sx={{fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'13px',
                    sm:'14px',
                    md:'14px',
                    lg:'15px',
                    xl:'18px',
                  },fontWeight:600, paddingRight:'5px' }}>
                    { getCurrencyFormat(item.field2.value)}
                  </Box> / <Box sx={{fontFamily:"Montserrat, sans-serif",paddingLeft:'5px',
                  fontSize:{
                    xs:"9px",
                    sm:"9px",
                    md:"9px",
                    lg:"10px",
                    xl:"12px"
                  },fontWeight:500,lineHeight:'12px' }}>{item.field1.value}</Box>
                </Box>
              </Box>
              {selectedCategories.includes(item) && (
                <Box sx={{ marginLeft: "auto" }}>
                  <img alt=""
                    src={tickcircleicon}
                    style={{ width: "30px", height: "30px",objectFit:'cover' }}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box
                style={{cursor:'pointer'}}
              sx={
                checkIncludedCategory(item)
                  ? classes.severicecatinfosel
                  : classes.severicecatinfo
              }
              onClick={() => {
                handleSetSubCategory(item);
              }}
            >
              <Box sx={{ marginRight: "15px", width: "78px", height: "65px", borderRadius:'14px',overflow:'hidden' }}>
                <img alt={" "}
                  src={item?.categoryId?.displayImage?.fileUrl}
                  style={{ width: "100%", height: "100%",objectFit:"cover",aspectRatio:'1/1' }}
                />
              </Box>
              <Box sx={{ fontWeight:700, color: "000000" }}>
                <Box sx={{
                  fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'14px',
                    sm:'15px',
                    md:'15px',
                    lg:'16px',
                    xl:'18px',
                  }
                }}>
                  {item?.categoryId?.categoryName}
                </Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                  <Box sx={{fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'14px',
                    sm:'15px',
                    md:'15px',
                    lg:'16px',
                    xl:'18px',
                  },fontWeight:600, paddingRight:'5px' }}>
                    {getCurrencyFormat(item?.field2?.value)}
                  </Box>
                  /
                  <Box sx={{fontFamily:"Montserrat, sans-serif",paddingLeft:'5px',
                  fontSize:"12px",fontWeight:500,lineHeight:'12px' }}>
                    {item?.field1?.value}
                  </Box>
                </Box>
              </Box>
              {checkIncludedCategory(item) && (
                <Box sx={{ margin: "auto 0px auto auto" }}>
                  <img alt=""
                    src={tickcircleicon}
                    style={{ width: "25px", height: "25px",objectFit:'cover' }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
        </Box>
      </Box>

      <Box sx={{ marginTop: {
        xs:"10px",
          sm:"10px",
          md:"10px",
          lg:"10px",
          xl:"1rem"
        } }}>
        <ButtonComp
          label="Continue"
          width="100%"
          type={
            (startDate && endDate && selectedCategories.length > 0) ||
            (catIds.length > 0 && selectedCategories.length > 0)
              ? "active"
              : "disabled"
          }
          disabled={
            (startDate && endDate && selectedCategories.length > 0) ||
            (catIds.length > 0 && selectedCategories.length > 0)
              ? false
              : true
          }
          onClick={() => {
            handleSetSelectedCategory();
            setPage(2);
            if (catIds.length == 0)
              dispatch(setBookServiceDates({ startDate, endDate }));
          
          }}
        />
      </Box>
    </>
  );
};

export default ServiceCategorySelection;
