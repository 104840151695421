import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { styled, Box, Typography, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SliderComp from "./Slider";
import radioicongreen from "../../../assets/images/radioicongreen.png";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import tickcircleicon from "../../../assets/images/tickcircleicon.png";
import { getAddressObject } from "../../../helper";
import { getCitiesList, getCategoryListApi } from "../../../api/addService";
import { setFilteredInfo } from "../../../store/slices/common";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getSx } from "./style";
import "react-calendar/dist/Calendar.css";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "675px",
    padding: "30px !important",
    borderRadius: "30px",
    backgroundColor: "#F6F5F5",
  },
  "& .MuiPaper-root": { maxWidth: "450px" },
  "& .MuiDialogActions-root": {},
}));

function FilterModal({
  openFilterModal,
  setOpenFilterModal,
  currentLocation,
  selectedValue,
  callServiceListApi,
  setCurrentLocation,
  setPage,
  isMapView,
  position,
  getCurrentLocation,
}) {
  let apikey = process.env.REACT_APP_GOOGLE_API_KEY;
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: apikey,
    options: {
      types: ["(cities)"],
      componentRestrictions: { country: "us" },
    },
  });
  const [openDropDown, setOpenDropDown] = useState([false, false, false]);
  const [categories, setCategories] = useState([]);
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState();
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cityLocation, setCityLocation] = useState();
  const dispatch = useDispatch();
  const filteredInfo = useSelector((state) => state.common.filteredInfo);
  const days = useSelector((state) => state.common.days);
  const classes = getSx();

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        dispatch(
          setFilteredInfo({
            ...filteredInfo,
            city: address?.city,
            lat: placeDetails.geometry.location.lat().toString(),
            lng: placeDetails.geometry.location.lng().toString(),
          })
        );
        setShowList(false);
        setCityLocation({
          lat: placeDetails.geometry.location.lat().toString(),
          lng: placeDetails.geometry.location.lng().toString(),
        });
      }
    );
  };

  const callApi = async () => {
    setLoader(true);
    const resp = await getCitiesList(currentLocation?.lat, currentLocation?.lng);
    const resp1 = await getCategoryListApi(selectedValue?._id);
    setCities(resp?.data?.data);
    setCategories(resp1?.data?.data);
    setLoader(false);
  };

  const handleDropDownClick = (index) => {
    let val = [...openDropDown];
    val[index] = !val[index];
    setOpenDropDown(val);
  };

  const handleClearAll = () => {
    dispatch(
      setFilteredInfo({
        guestCount: "",
        city: "",
        category: "",
        budget: [0, 10000],
        lat: "",
        lng: "",
      })
    );
    getCurrentLocation(true);
    setPage(1);
    setOpenFilterModal(false);
  };

  const handleFilterClick = () => {
    dispatch(setFilteredInfo({ isApiCalled: true }));
    setOpenFilterModal(false);

    setPage(1);

    let location = {};
    if (filteredInfo?.city) {
      location = {
        lat: filteredInfo?.city ? filteredInfo?.lat : cityLocation?.lat,
        lng: filteredInfo?.city ? filteredInfo?.lng : cityLocation?.lng,
      };
      setCurrentLocation({
        lat: filteredInfo?.lat,
        lng: filteredInfo?.lng,
      });
    } else {
      location = {
        lat: cityLocation?.city ? cityLocation?.lat : position?.lat,
        lng: cityLocation?.city ? cityLocation?.lng : position?.lng,
      };
    }

    callServiceListApi(
      {
        serviceId: selectedValue?._id,
        lat: location?.lat,
        long: location?.lng,
      },
      true,
      1,
      days,
      isMapView ? 50 : 1000
    );
  };

  const guestCountArr = ["0 - 100", "100 - 200", "200 - 300", "300 - 400", "400 - 500", "500+"];

  useEffect(() => {
    callApi();
    if (filteredInfo?.city) setValue(filteredInfo?.city);
  }, []);

  return (
    <CustomDialog open={openFilterModal} keepMounted sx={{ "& .MuiPaper-root": { maxWidth: "650px", height: "auto" } }}>
      <DialogContent
        sx={{
          padding: 0,
          position: "relative",
        }}
      >
        <img
          alt=""
          src={crossicon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            zIndex: "1111",
          }}
          onClick={() => {
            setOpenFilterModal(false);
            if (!filteredInfo.isApiCalled)
              dispatch(
                setFilteredInfo({
                  guestCount: "",
                  city: "",
                  category: "",
                  budget: [0, 10000],
                  isApiCalled: false,
                })
              );
          }}
        />
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: {
              xs: "20px",
              sm: "24px",
              lg: "28px",
            },
            lineHeight: {
              xs: "21px",
              sm: "25px",
              lg: "27px",
            },
            color: "#000000",
            fontFamily: "Montserrat, sans-serif",
            textAlign: "center",
          }}
        >
          Filter
        </Typography>
        <Box
          sx={{
            height: { xs: "55vh", md: "42vh", lg: "60vh", xl: "60vh" },
            overflow: "auto",
            marginTop: "30px",
            paddingBottom: {
              xs: "40px",
              sm: "50px",
            },
            paddingLeft: {
              xs: "10px",
              sm: 0,
            },
            paddingRight: {
              xs: "10px",
              sm: 0,
            },
          }}
        >
          <Box sx={{ width: "100%", position: "relative", marginBottom: "20px" }}>
            <Typography
              sx={{
                marginBottom: "10px",
                color: "#1A1A1A",
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              City
            </Typography>
            <CustomTextField
              size="large"
              value={value}
              autoFocus={value?.length > 0 ? true : false}
              backgroundColor="#fff"
              onChange={(evt) => {
                setValue(evt.target.value);
                if (evt.target.value.length == 0) dispatch(setFilteredInfo({ city: "" }));
                getPlacePredictions({ input: evt.target.value });
                if (!isPlacePredictionsLoading) setShowList(true);
              }}
              placeholder="Enter City"
            />
            {value !== "" && showList && placePredictions?.length > 0 && (
              <Box sx={classes.googleautosuggestionlist}>
                <List>
                  {placePredictions?.map((item) => (
                    <ListItem
                      key={item.place_id}
                      onClick={() => getDetails(item?.place_id)}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItemText primary={item.description} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
          {openDropDown[0] &&
            (loader ? (
              <Box
                sx={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  maxHeight: "165px",
                  overflow: "scroll",
                  width: "100%",
                  zIndex: "23",
                  padding: "15px",
                  border: "1px solid rgba(63, 73, 88, 0.1)",
                  borderRadius: "15px",
                  marginTop: "5px",
                }}
              >
                {cities?.map((item) => {
                  if (item?.city) {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "5px",
                          fontWeight: "500",
                        }}
                        key={item.city}
                        onClick={() => {
                          handleDropDownClick(0);
                          dispatch(setFilteredInfo({ city: item.city }));
                        }}
                      >
                        <Box>{item.city}</Box>
                        <Box>
                          <img
                            src={filteredInfo?.city == item?.city ? tickcircleicon : radioicongreen}
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                    );
                  }
                })}
              </Box>
            ))}
          <Box sx={{ width: "100%", position: "relative", marginBottom: "20px" }}>
            <Typography
              sx={{
                marginBottom: "10px",
                color: "#1A1A1A",
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Service Category
            </Typography>
            <CustomTextField
              type="dropdown"
              backgroundColor="#fff"
              width="100%"
              value={filteredInfo?.category?.categoryName}
              placeholder="Select Service Category"
              onClick={() => {
                handleDropDownClick(1);
              }}
            />
          </Box>
          {openDropDown[1] &&
            (loader ? (
              <Box
                sx={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{}} />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  maxHeight: "165px",
                  width: "100%",
                  zIndex: "23",
                  padding: "15px",
                  border: "1px solid rgba(63, 73, 88, 0.1)",
                  borderRadius: "15px",
                  marginTop: "5px",
                  overflow: "scroll",
                }}
              >
                {categories?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "5px",
                        fontWeight: "500",
                      }}
                      key={item?.categoryName}
                      onClick={() => {
                        handleDropDownClick(1);
                        dispatch(setFilteredInfo({ category: item }));
                      }}
                    >
                      <Box>{item?.categoryName}</Box>
                      <Box>
                        <img
                          src={
                            filteredInfo?.category?.categoryName == item?.categoryName ? tickcircleicon : radioicongreen
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ))}
          <Box sx={{ width: "100%", position: "relative", marginBottom: "20px" }}>
            <Typography
              sx={{
                marginBottom: "10px",
                color: "#1A1A1A",
                fontWeight: "600",
                fontSize: "16px",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Guest Count
            </Typography>
            <CustomTextField
              type="dropdown"
              backgroundColor="#fff"
              width="100%"
              value={filteredInfo?.guestCount}
              placeholder="Select Guest Count"
              onClick={() => {
                handleDropDownClick(2);
              }}
            />
          </Box>
          {openDropDown[2] &&
            (loader ? (
              <Box
                sx={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{}} />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  maxHeight: "165px",
                  width: "100%",
                  zIndex: "23",
                  padding: "15px",
                  border: "1px solid rgba(63, 73, 88, 0.1)",
                  borderRadius: "15px",
                  marginTop: "5px",
                  overflow: "scroll",
                }}
              >
                {guestCountArr?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "5px",
                        fontWeight: "500",
                      }}
                      key={item}
                      onClick={() => {
                        handleDropDownClick(2);

                        dispatch(setFilteredInfo({ guestCount: item }));
                      }}
                    >
                      <Box>{item}</Box>
                      <Box>
                        <img
                          alt=""
                          src={filteredInfo?.guestCount == item ? tickcircleicon : radioicongreen}
                          style={{ cursor: "pointer" }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ))}
          <Box sx={{ width: "100%", height: "150px" }}>
            <SliderComp />
          </Box>
        </Box>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: "#fff",
          position: "absolute",
          bottom: 0,
          left: 0,
          height: "100px",
          borderRadius: "30px",
          padding: "15px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            color:
              filteredInfo.guestCount != "" ||
              filteredInfo?.city ||
              filteredInfo?.category ||
              filteredInfo.budget[0] > 1 ||
              filteredInfo?.budget[1] < 10000
                ? "#1A1A1A"
                : "#1A1A1A30",
            fontWeight: "600",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            lineHeight: "17px",
            fontFamily: "Montserrat, sans-serif",
          }}
          onClick={() => {
            if (
              filteredInfo.guestCount != 0 ||
              filteredInfo?.guestCount ||
              filteredInfo?.city ||
              filteredInfo?.category ||
              filteredInfo.budget[0] > 1 ||
              filteredInfo?.budget[1] < 10000
            )
              handleClearAll();
          }}
        >
          Clear All
        </Box>
        <Box>
          <ButtonComp
            style={{
              background: " linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
            }}
            height={"60px"}
            label="View Services"
            type={
              filteredInfo.guestCount != "" ||
              filteredInfo?.city ||
              filteredInfo?.category ||
              filteredInfo.budget[0] > 0 ||
              filteredInfo?.budget[1] < 10000
                ? "active"
                : "disabled"
            }
            disabled={
              filteredInfo.guestCount > 0 ||
              filteredInfo?.city ||
              filteredInfo?.category ||
              filteredInfo?.guestCount != "" ||
              filteredInfo.budget[0] > 1 ||
              filteredInfo?.budget[1] < 10000
                ? false
                : true
            }
            onClick={() => {
              handleFilterClick();
            }}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default FilterModal;
