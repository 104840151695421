import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonComp from "../../../../component/Button";
import { modalVariant } from "../../../../component/Modal/Modalutils";
import { Typography, styled, Box, Grid, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import addcardplusicon from "../../../../assets/images/addcardplusicon.png";
import amex from "../../../../assets/images/cardicons/amex.png";
import diners from "../../../../assets/images/cardicons/diners.png";
import discovers from "../../../../assets/images/cardicons/discover.png";
import jcb from "../../../../assets/images/cardicons/jcb.png";
import mastercard from "../../../../assets/images/cardicons/mastercard.png";
import unioncard from "../../../../assets/images/cardicons/unioncard.png";
import visaicon from "../../../../assets/images/cardicons/visaicon.svg";
import radioicongreen from "../../../../assets/images/radioicongreen.svg";
import crossicon from "../../../../assets/images/svg/crossicon.svg";
import tickcircleicon from "../../../../assets/images/tickcircleicon.png";
import { getSx } from "../style";
import { listCardApi, makeFullPaymentApi } from "../../../../api/cardInfo";
import { showModal, showToast } from "../../../../store/slices/common";
import { deleteBookRequestApi, callCouponCodeApi } from "../../../../api/mybookings";
import { getCurrencyFormat } from "../../../../common/constant";
import CustomTextField from "../../../../component/Textfield";
import couponcodeimg from "../../../../assets/images/couponcodeimg.png";
import crossiconblack from '../../../../assets/images/crossiconblack.svg'

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "550px",
    padding: "30px",
    borderRadius: "20px",
    height: "auto",
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));

const cardImage = {
  Discover: discovers,
  "American Express": amex,
  MasterCard: mastercard,
  UnionCard: unioncard,
  Visa: visaicon,
  Diners: diners,
  Jcb: jcb,
};

const PaymentInfoModal = ({
  openPaymentInfoModal,
  setOpenPaymentInfoModal,
  setOpenCardModal,
  bookingId,
  fromRequest,
  finalData,
  handleCancelRequest
}) => {
  const classes = getSx();
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [couponcode, setCouponCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [discountEventuttiFee, setDiscountEventuttiFee] = useState(0);  // added
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const callApi = async () => {
    setLoading(true);
    const resp = await listCardApi();
    if (resp?.status === 200) {
      setCardList(resp?.data?.data);
    }
    setLoading(false);
  };

  const makePayment = async () => {
    setApiLoading(true);
    const resp = await makeFullPaymentApi({
      bookingServiceId: bookingId,
      cardId: selectedCard?.id,
      ...(couponcode ? { voucherCode: couponcode } : {}),
    });
    setApiLoading(false);
    if (resp?.status === 200) {
      setOpenPaymentInfoModal(false);
      if (!fromRequest)
        dispatch(
          showModal({
            modalType: modalVariant.PAYMENT_SUCCESSFULL,
            resolveCallback: () => {
              navigate(`/booking-confirmation?id=${bookingId}`);
              dispatch(showModal(null));
            },
            rejectCallback: () => {
              dispatch(showModal(null));
            },
          })
        );
      else {
        dispatch(
          showModal({
            modalType: modalVariant.PAYMENT_SUCCESSFULL,
            resolveCallback: () => {
              navigate(`/booking-confirmation?id=${bookingId}`);
              dispatch(showModal(null));
            },
            rejectCallback: () => {
              dispatch(showModal(null));
            },
          })
        );
      }
    } else if (resp?.response?.status >= 400) {
      {
        dispatch(
          showToast({ msg: resp.response.data.message, color: "error" })
        );
      }
    }
  };

  const handleCouponCode = async () => {
    setCouponLoading(true)
    const resp = await callCouponCodeApi(couponcode, Number(finalData?.totalAmountBeforeTax.toFixed(2)), Number(finalData?.taxPercentage), Number(finalData?.tax.toFixed(2)));
    if (resp?.status == 200) {
      dispatch(
        showToast({ msg: resp.data.message, color: "success" })
      );
      setIsCouponApplied(true);
      setDiscountAmount(resp?.data?.data?.discountAmount);
      setDiscountEventuttiFee(resp?.data?.data?.eventTuttiFee);  // added
      setTotalPaidAmount(resp?.data?.data?.totalPaidAmount);  // added
      setTax(resp?.data?.data?.tax);  // added
    }
    else if (resp?.response?.status >= 400) {
      dispatch(
        showToast({ msg: resp.response.data.message, color: "error" })
      );
    }
    setCouponLoading(false)
  }


  const handlePaymentCrossIcon = () => {
    setOpenPaymentInfoModal(false);
    const handleYes = async () => {
      dispatch(showModal(null));
      const resp = await deleteBookRequestApi(bookingId)
      if (resp?.status == 200) {
        handleCancelRequest();
      }
    }
    if (!fromRequest)
      dispatch(
        showModal({
          modalType: modalVariant.BOOK_REQUEST_CANCEL,
          resolveCallback: () => {
            handleYes();
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );

  }
  useEffect(() => {
    callApi();
  }, []);
  return (
    <CustomDialog
      open={openPaymentInfoModal}
      keepMounted
      sx={{
        "& .MuiPaper-root": { maxWidth: "700px", fontFamily: "Montserrat" },
      }}
      className="paymentmodalin"
    >
      <DialogContent sx={{
        padding: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0,
          xl: 0
        }, overflowY: "auto"
      }} className="paymentmodalxs">
        <img
          src={crossicon}
          alt="crossicon"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1.5rem",
            cursor: "pointer",
          }}
          className="bookcrossicon"
          onClick={() => handlePaymentCrossIcon()}
        />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography
              variant="h6"
              fontWeight="bold"
              color="#000"
              marginBottom="20px"
              marginTop="10px"
              textAlign="center"
              sx={{
                fontFamily: 'Montserrat,sans-serif',
                fontSize: {
                  xs: '16px',
                  sm: '18px',
                  md: '18px',
                  lg: '20px',
                  xl: '22px'
                }
              }}
            >
              Payment
            </Typography>
            <Typography sx={{ fontFamily: 'Montserrat,sans-serif' }} color="rgba(0, 0, 0, 0.6)" fontSize="14px">
              By clicking the button below, I agree to Eventutti's Terms &
              Conditions, Privacy Policy, and SP Refund Policy. I also
              authorize Eventutti to charge my payment method.
            </Typography>
            <Box sx={classes.taxinformation} className="paymentbox">
              {!isCouponApplied ?
                <>
                  <CustomTextField
                    placeholder="Enter Coupon Code"
                    value={couponcode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    sx={{ width: "100%", marginBottom: "10px" }}
                    type="couponcode"

                  />
                  <Box sx={{ fontSize: "13px", display: "flex", justifyContent: "right", float: "right", width: { xs: "60%", md: "31%", xl: "41%" } }}>
                    <ButtonComp label="Apply Now" disabled={couponcode.length > 0 ? false : true}
                      type={couponcode.length > 0 ? "active" : "disabled"}
                      loading={couponLoading}
                      fontWeight="500"
                      width="100%"
                      onClick={() => { handleCouponCode() }} />
                  </Box>
                </> :
                <Box sx={{ ...classes.taxinfo, alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img style={{ marginRight: "5px" }} src={tickcircleicon} />
                    Code {couponcode} applied !
                  </Box>
                  <Box>
                    <img src={crossiconblack} onClick={() => { setCouponCode(""); setIsCouponApplied(false); setDiscountAmount(0) }} style={{ margin: "0px 5px", cursor: "pointer" }} />
                    <img src={couponcodeimg} />
                  </Box>
                </Box>}
              <Typography
                color="rgba(0, 0, 0, 1)"
                fontSize="16px"
                fontWeight="bold"
                margin="2rem 0px 10px 0px"

                sx={{
                  fontFamily: 'Montserrat,sans-serif'
                }}
              >
                Payment details
              </Typography>
              <Grid item xs={12} md={12} sx={classes.catinfo1}>
                {isCouponApplied && <Box
                  sx={{ ...classes.catmain, color: "rgba(23, 186, 174, 1)" }}
                >
                  <Box sx={classes.catleft}>Coupon ({couponcode})</Box>
                  <Box
                    sx={{
                      ...classes.catright,
                      color: "rgba(23, 186, 174, 1)",
                      fontWeight: "500",
                    }}
                  >
                    -{getCurrencyFormat(discountAmount)}
                  </Box>
                </Box>}
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Eventutti Fee</Box>
                  <Box sx={{ ...classes.catright, fontWeight: "bold" }}>
                    {isCouponApplied ? getCurrencyFormat(discountEventuttiFee) : getCurrencyFormat(finalData?.eventTuttiFee.toFixed(2))}
                  </Box>
                </Box>
                {(tax > 0 || finalData?.tax > 0) && <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Tax</Box>
                  <Box sx={{ ...classes.catright, fontWeight: "bold" }}>
                    {isCouponApplied ? tax?.toFixed(2) : getCurrencyFormat(finalData?.tax.toFixed(2))}
                  </Box>
                </Box>}
                {/* {isCouponApplied && <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Amount</Box>
                  <Box sx={{ ...classes.catright, fontWeight: "bold" }}>
                    {getCurrencyFormat(finalData?.totalPaidAmount?.toFixed(2))}
                  </Box>
                </Box>} */}
                <hr
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    height: "1px",
                    border: "none",
                  }}
                />
                <Box sx={classes.catmain}>
                  <Box sx={classes.catleft}>Total Amount</Box>
                  <Box sx={{ ...classes.catright, fontWeight: "bold" }}>
                    {isCouponApplied ? getCurrencyFormat(totalPaidAmount) : getCurrencyFormat(finalData?.totalPaidAmount - discountAmount)}
                  </Box>
                </Box>
              </Grid>
              <Typography
                color="rgba(0, 0, 0, 1)"
                fontSize="16px"
                fontWeight="bold"
                margin="10px 0px"
                sx={{ fontFamily: 'Montserrat,sans-serif' }}
              >
                Select Card
              </Typography>
              {cardList?.map((item) => {
                return (
                  <>
                    <Box
                      sx={{
                        ...classes.taxinfo,
                        background:
                          item == selectedCard
                            ? "rgba(23, 186, 174, 0.1)"
                            : "#fff",
                        borderColor:
                          item == selectedCard
                            ? "rgba(23, 186, 174, 0.3)"
                            : "rgba(218, 218, 218, 1)",
                      }}
                      onClick={() => {
                        setSelectedCard(item);
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={cardImage[item?.brand]}
                          style={{ width: "50px", height: "40px" }}
                          className="cardimage"
                        />
                        <Box sx={classes.carddots}>.... </Box>{" "}
                        <Box sx={classes.carddots}>.... </Box>{" "}
                        <Box sx={classes.carddots}>.... </Box>{" "}
                        <Box
                          sx={{
                            ...classes.carddots,
                            fontSize: { xs: "12px", md: "17px" },
                            marginTop: "0px",
                            marginRight: "2px",
                          }}
                        >
                          {item.last4}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img alt=""
                          src={
                            item == selectedCard
                              ? tickcircleicon
                              : radioicongreen
                          }
                        />
                      </Box>
                    </Box>
                  </>
                );
              })}
              {cardList.length < 5 && (
                <>
                  <Box
                    sx={{
                      ...classes.paybtn,
                      margin: "10px 0px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenCardModal(true);
                      setOpenPaymentInfoModal(false);
                    }}
                  >
                    <img src={addcardplusicon} />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={classes.paybtn}
                    fontWeight="bold"
                    fontFamily="Montserrat, sans-serif"
                    marginBottom={"20px"}
                  >
                    Add New Card
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={classes.paybtn}>
              <ButtonComp
                label="Pay"
                width="200px"
                disabled={!selectedCard}
                loading={apiLoading}
                type={selectedCard ? "active" : "disabled"}
                onClick={() => {
                  makePayment();
                }}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default PaymentInfoModal;
