import axios from "axios";
//apis for eventorganiserservice
export const getServiceListEventApi = async (obj,radius,page,limit) => {
   let token=localStorage.getItem("token");
   let guestToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";
   
  try{
    let config = {
      headers: {
        "accessToken": token? `Bearer ${token}`:`Bearer ${guestToken}`,
      }
    }
    const  response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/service/list-services-for-eo`,{...obj,radius:radius,page:page,limit:limit},config);
      return response;
    } catch (error) {
      return error;
    }
  };