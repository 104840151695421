import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import bookserviceinfoReducer from './slices/bookserviceinfo'
import BreadCrumbReducer from './slices/breadcrumb'
import commonReducer from "./slices/common";
import profileReducer from "./slices/profileinfo";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, profileReducer);

const reducers = combineReducers({
  profile: persistedReducer,
  common: commonReducer,
  BreadCrumbs:BreadCrumbReducer,
  bookserviceinfo:bookserviceinfoReducer
  
});

const store = configureStore({
  reducer: reducers,
  devTools: true,
});
const persistor = persistStore(store);

export { store, persistor };
