import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

import CustomTextField from "../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import { getCurrencyFormat } from "../../../common/constant";


function Reports({ graphData, max, currentYear1, setCurrentYear1 }) {
  let [position, setPosition] = useState({});
  let [isOpen, setIsOpen] = useState(false);
  const classes = getSx();
  const currentYear = new Date().getFullYear();


  const yearArray = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
  const CustomTooltip = ({ active, payload, label, ...rest }) => {
    if (active && payload?.length) {
      setPosition({
        y: 0,
        x: payload[0]?.value
          ? rest.coordinate?.x - 55
          : rest.coordinate?.x - 50,
      });
      return (
        <Box className="custom-tooltip">
          <Box className="custom-val">
            {payload[0]?.payload?.month} -
            {getCurrencyFormat(payload[0]?.value)}
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className="report-wrapper">
      <Box className="report-page">
        <Box className="d-flex flex-wrap justify-content-between">
          <Box sx={classes.contentleft}>
            <Box sx={classes.headinginfo}>
              <Typography variant="h6" fontWeight={700} sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '14px',
                  lg: '16px',
                  xl: '20px'
                },
                color: '#1A1A1A'
              }}>
                Earning Report
              </Typography>
              <Box className="listyears" style={{ width: "150px" }}>
                <Box
                  onClick={() => {
                    setIsOpen((prev) => !prev)
                  }}
                >
                  <CustomTextField
                    variant="outlined"
                    placeholder="Select Year"
                    type="dropdown"
                    backgroundColor="#fff"
                    readOnly={true}
                    value={currentYear1}
                    className="dropdownselection"
                  />
                </Box>

                {isOpen && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      height: "165px",
                      width: "153px",
                      zIndex: "23",
                      padding: "15px",
                      border: "1px solid rgba(63, 73, 88, 0.1)",
                      borderRadius: "15px",
                      marginTop: "5px",
                    }}
                  >
                    {yearArray.map((item) => {
                      return (
                        <Box
                          key={item}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "5px",
                            fontWeight: "500",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setCurrentYear1(item);
                            setIsOpen(false);
                          }}
                        >
                          <Box sx={{ color: currentYear1 == item ? "green" : "black" }}>{item}</Box>

                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={classes.responsiveContainer} className="responsivecontainer">
              <ResponsiveContainer
                minWidth={450}
                width="100%"
                height="100%"
              >
                <AreaChart
                  data={graphData}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="rgba(27, 194, 173, 1)" />
                      <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="rgba(27, 194, 173, 1)" />
                      <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
                    </linearGradient>
                  </defs>
                  <XAxis
                    padding={{ left: 2, right: 15 }}
                    dataKey="month"
                    axisLine={false}
                    tickLine={false}
                    className="mb-3"
                  />
                  <YAxis
                    tickLine={false}
                    // dx={-11}
                    tick={{ fontSize: 13 }}
                    tickCount={10}
                    axisLine={false}
                    tickFormatter={(item) =>
                      item == 0
                        ? 0
                        : item > 999
                          ? `$${(item / 1000).toFixed(1)}k`
                          : `$${item}`
                    }
                    domain={[0, Math.ceil(max / 50) * 50 || 100]}
                  />
                  <CartesianGrid strokeDasharray="0" />
                  <Tooltip
                    content={CustomTooltip}
                    cursor={{ fill: "transparent" }}
                    position={position}
                    separator="false"
                  />
                  <Area
                    type="monotone"
                    dataKey="totalEarnings"
                    stroke="rgba(167, 167, 167, 1)"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    strokeWidth={2}
                    padding={{ bottom: 10 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Reports;
