import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../component/Button";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import greenediticon from "../../../../assets/images/greenediticon.svg";
import { getSx } from "../style";
import addonserviceimg from '../../../../assets/images/addonserviceimg.svg';


import { getAddOnServiceList } from "../../../../api/addService";

const AddonServiceList = ({ servId, setPage, handleRest, setAddService, setCategory, setSubCategory }) => {
  const [addOnList, setAddOnList] = useState([]);
  const [loading, setLoading] = useState(true);
  const callApi = async () => {
    setLoading(true)
    const resp = await getAddOnServiceList(servId);
    setAddOnList(resp?.data?.data?.services);
    setLoading(false)
  };
  const handleClick = () => {
    setPage(1);
  };
  const handleEditInfo = (item) => {
    let val = item?.categories?.map((item1) => {
      return {
        ...item1,
        _id: item1?.categoryId?._id,
        categoryName: item1?.categoryId?.categoryName,
      };
    });
    let value = { ...item, categories: val };
    setAddService(value);
    setSubCategory([...val]);
    setCategory(item?.serviceId)
    setPage((prev) => prev + 1)
  }

  const classes = getSx();
  useEffect(() => {
    callApi();
  }, []);
  return (
    <Box>
      {loading ? <Box sx={{
        height: { xs: "60vh", md: "66vh", lg: "56vh", xl: "61vh" },
        width: "100%",
        display: "flex", justifyContent: "center", alignItems: "center"
      }} >
        <CircularProgress size={40} />
      </Box> :
        <Box
          sx={{
            height: { xs: "60vh", md: "66vh", lg: "56vh", xl: "61vh" },
            overflow: "scroll",
            marginTop: { xs: "1rem", md: "0rem" }
          }}
        >
          {addOnList?.length < 1 ?
            <Box sx={classes.main}  >
              <Box sx={classes.addonserviceimg}>
                <img alt="" src={addonserviceimg} />
              </Box>
              <Typography textAlign='center' variant="body" fontFamily="Montserrat,sans-serif" fontWeight="bold">
                No Service Added Yet!
              </Typography>
            </Box>

            : addOnList?.slice(1,)?.map((item) => {
              return (
                <Box sx={classes.mainwithspace}>
                  <Box sx={classes.leftinfo}>
                    <Box sx={classes.leftimg}>
                      <img
                        src={item?.addOnServiceImages[0]?.fileUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "13px",
                        }}
                      />
                    </Box>
                    <Box sx={classes.listcat}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        marginTop="10px"
                      >
                        {item?.serviceId?.name}
                      </Typography>
                      <Box sx={classes.catlist}>
                        {item.categories.map((item) => {
                          return (
                            <div
                              style={{
                                margin: "5px",
                                borderRadius: "10px",
                                padding: "3px 3px",
                                fontSize: "12px",
                                background:
                                  "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                              }}
                            >
                              {" "}
                              {item?.categoryId?.categoryName}
                            </div>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={classes.rightinfo}>
                    <img src={greenediticon} onClick={() => { handleEditInfo(item) }} />
                  </Box>
                </Box>
              );
            })}
        </Box>}
      <Box sx={classes.buttoncomp}>
        <ButtonComp
          label="Add-on Service"
          width="100%"
          type="active"
          onClick={handleClick}
        />
      </Box>
      <Box sx={classes.buttoncomplist}>
        <ButtonComp
          label="Add Later"
          width="100%"
          type="whitebtn"
          onClick={handleRest}
        />
      </Box>
    </Box>
  );
};

export default AddonServiceList;


