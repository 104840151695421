import React from "react";

import { Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import AddCardModalContent from "./AddCardModalContent";
import rightarrow from "../../../../assets/images/rightarrow.svg";
import crossicon from "../../../../assets/images/svg/crossicon.svg";
import { getSx } from "../style";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "550px",
    padding: "30px",
    borderRadius: "20px",
    height: "550px",
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));

const AddCardModal = ({
    openCardModal,
    setOpenCardModal,
    setOpenPaymentInfoModal
}) => {
  const classes = getSx();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  

  return (
    <CustomDialog
      open={openCardModal}
      keepMounted
      sx={{
        "& .MuiPaper-root": { maxWidth: "700px", fontFamily: "Montserrat" },
      }}
    >
      <DialogContent sx={{ padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        }, overflowY: "hidden" }}>
      <img alt=""
              src={rightarrow}
              className="bookrightcrossicon"
              style={{
                position: "absolute",
                left: "1rem",
                top: "1.9rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenCardModal(false);
                setOpenPaymentInfoModal(true);
              }}
            />
        <img
          src={crossicon}
          alt="crossicon"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1.5rem",
            cursor: "pointer",
          }}
          className="bookcrossicon"
          onClick={() => {setOpenCardModal(false)
            setOpenPaymentInfoModal(true)
          }

          }
        />
         <Typography variant="h6" sx={classes.paybtn} fontWeight="bold" fontFamily="Montserrat" marginTop="-10px">
               Add New Card
              </Typography>
        <Elements stripe={stripePromise}>
        <AddCardModalContent setOpenPaymentInfoModal={setOpenPaymentInfoModal} setOpenCardModal={setOpenCardModal}  />
        </Elements>
      
      </DialogContent>
    </CustomDialog>
  );
};

export default AddCardModal;
