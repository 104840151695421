import React from 'react'
import googleicon from '../../assets/images/googlelogin.svg'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { callSocialMediaPost, getProfileApi } from '../../api/profile';
import { setProfileInfo } from '../../store/slices/profileinfo';
import { getFCMToken } from '../../firebase';
import { handleProfileInfo } from '../../utils/handleProfileInfo';
import { setUserIds } from '../../store/slices/profileinfo';


const GoogleLoginInfo = ({ setLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signUpWithGoogle = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                setLoading(true);

                const userInfo = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${tokenResponse.access_token}`,
                            Accept: "application/json",
                        },
                    }
                );
                const payload = {
                    provider: 'google',
                    email: userInfo?.data?.email,
                    token: userInfo?.data?.id,
                    firstName: userInfo?.data?.given_name,
                    lastName: userInfo?.data?.family_name,
                    profileImage: { url: userInfo?.data?.picture, key: `${userInfo?.data?.id}fileName` }

                };
                const response = await callSocialMediaPost(payload);
                if (response?.status == 200) {
                    localStorage.setItem("token", response?.data?.token);
                    localStorage.setItem('role', 0);
                    localStorage.setItem("isLogin", true);
                    dispatch(setUserIds(response?.data?.data?.userIds))
                    const fcmtoken = getFCMToken();
                    const profileResponse = await getProfileApi();
                    if (profileResponse?.status == 200) {
                        handleProfileInfo(profileResponse?.data?.data, dispatch, setProfileInfo, 0)
                        if (!profileResponse?.data?.data?.phoneNumber) {
                            navigate("/create-profile", { state: { fromSocialMedia: true } })
                        }
                    }
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    });

    return (
        <>
            <img src={googleicon} onClick={() => { signUpWithGoogle() }} />
        </>
    );
}

export default GoogleLoginInfo