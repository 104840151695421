import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import dummyprofilepic from "../../../assets/images/dummyprofilepic.svg";
import { getSx } from "../../eventorganiser/dashboard/style";

const CardComp = ({ item, value }) => {
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();

  useEffect(() => {
    setMaxDate(item?.endDate);
    setMinDate(item?.startDate)
  }, [])
  const navigate = useNavigate();
  const classes = getSx();
  const sericecat = item.serviceDetails.services.flatMap((item) => item.categories.map((item) => item.categoryId.categoryName))
  return (
    <Box
      sx={classes.cardInfo}
      onClick={() => {
        navigate(`/request-info?id=${item?._id}`)
      }}>
      <CardContent>
        <Box sx={{ display: "flex" }}>
          {value != 0 && <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 20px 0 4px",
              borderRight: "2px solid rgba(23, 186, 174, 0.1)",
              color: "rgba(85, 85, 85, 1)",
              fontSize: "15px",
            }}
          >
            <Box sx={{
              color: "rgba(23, 186, 174, 1)",
              fontWeight: 700,
              fontFamily: 'Montserrat, sans-serif',
              fontSize: {
                xs: '14px',
                sm: '16px',
                md: '18px',
                lg: '20px',
                xl: '24px'
              },
              lineHeight: {
                xs: '14px',
                sm: '16px',
                md: '18px',
                lg: '20px',
                xl: '24px'
              }
            }}>{dayjs(minDate).format("MMM DD").split(" ")[1]}</Box>
            <Box sx={{
              fontSize: {
                xs: '12px',
                sm: '13px',
                md: '14px',
                lg: '15px',
                xl: '15px'
              },
              fontWeight: 400,
              lineHeight: '22px',
              color: '#555555',
              fontFamily: 'Montserrat, sans-serif',
            }}>{dayjs(minDate).format("MMM DD").split(" ")[0].substring(0, 3)}</Box>

            {minDate != maxDate &&
              <>
                <Box sx={{ margin: '5px 0' }}>|</Box>
                <Box sx={{
                  color: "rgba(23, 186, 174, 1)",
                  fontWeight: 700,
                  fontSize: {
                    xs: '14px',
                    sm: '16px',
                    md: '18px',
                    lg: '20px',
                    xl: '24px'
                  },
                  fontFamily: 'Montserrat, sans-serif',
                  lineHeight: {
                    xs: '14px',
                    sm: '16px',
                    md: '18px',
                    lg: '20px',
                    xl: '24px'
                  }
                }}>{dayjs(maxDate).format("MMM DD").split(" ")[1]}</Box>
                <Box sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '15px'
                  },
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#555555',
                  fontFamily: 'Montserrat, sans-serif',
                }}>{dayjs(maxDate).format("MMM DD").split(" ")[0].substring(0, 3)}</Box>
              </>}
          </Box>}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ padding: "0 4px 0 24px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h3"
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: '#1A1A1A',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: {
                      xs: '15px',
                      sm: '15px',
                      md: '16px',
                      lg: '18px',
                      xl: '20px'
                    }
                  }}
                >
                  {item?.serviceDetails?.serviceTitle}
                </Typography>
              </Box>
              <Box
                sx={{
                  dispaly: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '15px',
                    lg: '16px',
                    xl: '20px'
                  },
                  fontWeight: "bold",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {" "}
                  <img alt=""
                    src={item?.status != "Pending" && item?.status != "Accepted" ? item?.requestedBy[0]?.profileImage?.url : dummyprofilepic}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "15px",
                    }}
                  /> <Box>{item?.requestedBy[0]?.firstName} {item?.status != "Pending" && item.status != "Accepted" && item?.requestedBy[0]?.lastName}</Box>
                </Box>
              </Box>

              <Typography
                variant="body2"
                color="rgba(26, 26, 26, 0.8)"
                marginTop="6px"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '13px',
                    md: '14px',
                    lg: '15px',
                    xl: '15px'
                  },
                  fontFamily: 'Montserrat, sans-serif',
                  display: 'inline-block',
                  width: { xs: "80%", lg: '73%', xl: "80%" },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.serviceDetails.address},{item?.serviceDetails?.state},{item?.serviceDetails?.zipCode}
              </Typography>
            </Box>


          </Box>

        </Box>
        <Box sx={{ ...classes.servicecont, height: "45px", overflowX: "auto", width: "100%", paddingTop: '15px' }}>
          {sericecat.map((item) => { return (<Box sx={classes.servicecontinfo}>{item}</Box>) }
          )}

        </Box>
      </CardContent>
    </Box>
  );
};

export default CardComp;
