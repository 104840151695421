import React from "react";
import { BrowserRouter } from "react-router-dom";

import RootView from "./Routes/routes";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <RootView />
    </BrowserRouter>
  );
}

export default App;
