import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../component/Button";
import CustomTextField from "../component/Textfield";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import MyTimer from "./Timer";
import emailIcon from "../assets/images/svg/emailiconsvg.svg";
import event2tlogo from "../assets/images/svg/event2tlogo.svg";
import resetpasswordimg from "../assets/images/svg/resetpasswordimg.svg";
import { getRole } from "../utils/auth-token-utils/cookies";
import { resetPasswordApi } from "../api/Signup";
import { showToast } from "../store/slices/common";

const RestPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState();
  const role = getRole();
  const[loading,setLoading]=useState(false);
  const [expiryTime, setExpiryTime] = useState("");
  const classes = getSx();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (emailSent) setExpiryTimer();
  }, [emailSent]);

  const setExpiryTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 30);
    setExpiryTime(time);
  };

  const callRestPsswordApi = async () => {
    setLoading(true)
    const resp = await resetPasswordApi({
      email: email,
      userType: Number(role),
    });
    if (resp?.status == 200) {
      setEmailSent(true)
      setExpiryTimer();
      dispatch(
        showToast({
          color: "success",
          msg: "Mail Sent Successfully",
        })
      );
    }
    if (resp?.response?.status >= 400) {
      dispatch(
        showToast({
          color: "error",
          msg: resp?.response?.data?.message,
        })
      );
    }
    setLoading(false)
  };
  return (
    <>
      {emailSent ? (
        <Box sx={classes.resetcontainer}>
          <Box sx={classes.resetinsidecontainer}>
           
            <Box sx={classes.resetmaincontainer}>
              <Box>
                <img src={resetpasswordimg} />
              </Box>
              <Box sx={classes.resetheading}>Email has been sent</Box>
              <Box sx={{ ...classes.resetcontent }}>
                Please check your inbox. We have sent a new password to your
                email address.
              </Box>
              <ButtonComp
                width="100%"
                disabled={false}
                type="active"
                label="Sign In"
                onClick={() => {
                  navigate("/login");
                }}
              />
              <Box
                sx={
                  expiryTime
                    ? { ...classes.backsigin, opacity: "0.2" }
                    : { ...classes.backsigin }
                }
              >
                Didn’t receive password? {` `}
                <span
                  style={{
                    color: "rgba(23, 186, 174, 1)",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!expiryTime) callRestPsswordApi();
                  }}
                >
                  Resend
                </span>{" "}
                {` `}
              </Box>
              {expiryTime && <MyTimer setExpiryTime={setExpiryTime} />}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={classes.resetcontainer}>
          <Box sx={classes.resetinsidecontainer}>
            <Box sx={classes.resetmaincontainer}>
              <Box sx={classes.logo}>
                <img style={{width:'100%', height:'100%'}} alt="" src={event2tlogo} />
              </Box>
              <Box sx={classes.resetheading}>Reset your password</Box>
              <Box sx={classes.resetcontent}>
                Enter the email address associated with your account and get a
                new password
              </Box>
              <Box sx={classes.inputinforeset}>
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={"#1A1A1A"}
                  lineHeight={'23px'}
                  marginBottom={"12px"}
                  fontFamily={"Montserrat, sans-serif"}
                >
                  Email Address
                </Typography>
                <Typography
                  className="input-with-icon-div margintopp"
                  component="div"
                  position={"relative"}
                  sx={{
                    marginBottom:'40px',
                    fontFamily:"Montserrat, sans-serif",
                  }}
                >
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Email Address"
                    value={email}
                    position={""}
                    marginTop=""
                    className={"email-with-icon"}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <Typography
                    className="input-icon"
                    component="div"
                    position="absolute"
                    sx={{fontFamily:"Montserrat, sans-serif",}}
                  >
                    <img
                      src={emailIcon}
                      alt="Image"
                    />
                  </Typography>
                </Typography>
              </Box>
              <ButtonComp
                width="100%"
                disabled={email ? false : true}
                type={email ? "active" : "disabled"}
                loading={loading}
                label="Send"
                onClick={() => callRestPsswordApi()}
              />
              <Box sx={classes.backsigin}>
                Back to
                <span
                  style={{
                    color: "#17BAAE",
                    fontWeight: 600,
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Sign in
                </span>{" "}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RestPassword;
