import instance from "../common/config/axios";
//apis for my bookings
export const callMyBookingListApi = async (body) => {
  try {
    const response = await instance.post(`/service/list-for-service-provider`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export const callMyBookingListEPApi = async (body) => {
  try {
    const response = await instance.post(`/service/list-for-event-organizer`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export const callAcceptOrRejectApi = async (id, body) => {
  try {
    const response = await instance.post(`/service/update-status/${id}`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export const callCancelApiForEO = async (id, body) => {
  try {
    const response = await instance.put(`/service/cancel-booked-service-by-eo/${id}`, { body });
    return response;
  } catch (error) {
    return error;
  }
}
export const callCancelApiForSP = async (id, body) => {
  try {
    const response = await instance.put(`/service/cancel-booked-service-by-sp/${id}`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export const generateOtpEoApi = async (id) => {
  try {
    const response = await instance.put(`/service/send-confirmation-otp`, { serviceId: id });
    return response;
  } catch (error) {
    return error;
  }
}
export const verifyOtpSpApi = async (id, body) => {
  try {
    const response = await instance.put(`/service/verify-booking-otp`, body);
    return response;
  } catch (error) {
    return error;
  }
}
export const reportApi = async (body) => {
  try {
    const response = await instance.post(`ticket/report`, body);
    return response;
  } catch (error) {
    return error;
  }
}

export const deleteBookRequestApi = async (serviceId) => {
  try {
    const response = await instance.delete(`service/delete-booked-service/${serviceId}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const callCouponCodeApi = async (code, amount, taxPercentage, tax) => {
  try {
    const response = await instance.post(`service/validate-coupon`, { voucherCode: code, amount: amount, taxPercentage: taxPercentage, tax: tax });
    return response;
  } catch (error) {
    return error;
  }
}
