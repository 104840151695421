import instance from "../common/config/axios";
//apis for profile
export const changePasswordApi=async(body)=>{
    try {
      const response = await instance.put(`user/change-password`,body);
      return response;
    } catch (error) {
      return error;
    }
  }
  export const transactionHistoryApi=async(body)=>{
    try {
      const response = await instance.get(`user/transactions-history`,body);
      return response;
    } catch (error) {
      return error;
    }
  }
  export const getProfileApi=async()=>{
    try {
      const response = await instance.get(`user/profile`);
      return response;
    } catch (error) {
      return error;
    }
  }
  export const updateProfileApi=async(body)=>{
    try {
      const response = await instance.put(`user/update`,body);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const getFavouritesApi=async()=>{
    try {
      const response = await instance.get(`user/list-fav-services`);
      return response;
    } catch (error) {
      return error;
    }
  }
 

  export const getCardListApi=async()=>{
    try {
      const response = await instance.get(`payment/card-list`);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const deleteCardApi=async(id)=>{
    try {
      const response = await instance.delete(`payment/delete-card?cardId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const deleteAccountApi=async()=>{
    try {
      const response = await instance.delete(`user/delete`);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const updateBankDetailsApi=async()=>{
    try {
      const response = await instance.put(`user/update-banking-details`);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const getListFavApi=async(lat,long)=>{
    try {
      const response = await instance.get(`user/list-fav-services?lat=${lat}&long=${long}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  export const addFavApi=async(id)=>{
    try {
      const response = await instance.post(`user/add-fav-service`,{serviceId:id});
      return response;
    } catch (error) {
      return error;
    }
  }

  export const removeFavApi=async(id)=>{
    try {
      const response = await instance.post(`user/remove-from-fav`,{serviceId:id});
      return response;
    } catch (error) {
      return error;
    }
  }

  export const callLogoutApi=async()=>{
    try {
      const response = await instance.put(`user/logout`);
      return response;
    } catch (error) {
      return error;
    }
  }
  export const callSwitchRoleApi=async()=>{
    try {
    await instance.post(`user/switch-role`);
    } catch (error) {
      return error;
    }
  }

  export const callSwitchProfileApi=async()=>{
    try {
   const resp= await instance.post(`user/switch-profile`);
    return resp;
    } catch (error) {
      return error;
    }
  }
  
  export const callSocialMediaPost=async(val)=>{
    try {
  const resp=  await instance.post(`user/social-login`,val);
  return resp;
    } catch (error) {
      return error;
    }
  }