import React from "react";
import PropTypes from "prop-types";

import { CircularProgress, Button as MuiButton } from "@mui/material";
import { getSx } from "./style";

function ButtonComp({
  variant,
  label,
  width,
  onClick,
  disabled = false,
  loading,
  type = "disabled",
  borderRadius,
  typeButton,
  height,
    className,
  ...props
}) {
  const classes = getSx();
  const getStyles = () => {
    if (type == "error")
      return { ...classes.muiButton, ...classes.errorButton };
    else if(type == "disabledbtn")
      return { ...classes.disabledbtn,opacity: "0.3",cursor:"not-allowed" };
    else if (type == "inactive")
      return { ...classes.muiButton, opacity: "0.2" };
    else if (type == "disabled")
      return { ...classes.muiButton, ...classes.disabled };
    else if (type == "whitebtn")
      return { ...classes.muiButton, ...classes.whitebtn };
    else return classes.muiButton;
  };
  return (
    <MuiButton
        className={className}
      sx={getStyles()}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      style={{ width: `${width}`, height: `${height}` ,borderRadius:`${borderRadius}`, fontWeight:600,fontFamily:"Montserrat, sans-serif"}}
      type={typeButton === "submit" ? typeButton : "button"}
      {...props}
    >
      {loading ? (
        <CircularProgress size={40} style={{ color: "#FFFFFF" }} />
      ) : (
        label
      )}
    </MuiButton>
  );
}
export default ButtonComp;
MuiButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};
