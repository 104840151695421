
export const getSx = () => {
  return {
    title: { my: "10px" },
    subTitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      position:"relative"
    },
    subTitle: {
      color: "#000",
      margin: "20px 0",
      fontWeight: 600,
      textAlign: "center",
      fontSize: {
        xs:'14px',
        sm:'16px',
        md:'16px',
        lg:'18px',
        xl:"20px"
      },
      fontFamily:"Montserrat, sans-serif"
    },
    subText: {
      color: "#687F89",
      mb: "20px",
      fontSize: {
        xs:'12px',
        sm:'14px',
        md:'15px',
        lg:'16px',
        xl:"16px"
      },
      fontWeight: 400,
      textAlign: "center",
      fontFamily:"Montserrat, sans-serif"
    },
    buttonWrapper1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      button: {
        background:
          "linear-gradient(to right,rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",
        color: "#fff",
        "&:hover": {
          background:
            "linear-gradient(to right,rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",
          color: "#fff",
        },
      },
    },
    button: {
      borderRadius: "30px",
      height: "60px",
      width: "190px",
      color: "rgba(23, 186, 174, 1)",
      backgroundColor: "#fff",
      fontSize: {
        xs:'12px',
        sm:'14px',
        md:'16px',
        lg:'16px',
        xl:"18px"
      },
      fontWeight: "bold",
      boxShadow:'none',
      fontFamily:"Montserrat, sans-serif",
      "&:hover": {
        backgroundColor: "#fff",
        border: "1px solid rgba(23, 186, 174, 1)",
        color: "rgba(23, 186, 174, 1)",
        boxShadow:'none',
      },
    },
    cancelButton: {
      color: "#ffffff",
      borderRadius: "30px",
      border: "none",
      height: "60px",
      fontSize: {
        xs:'12px',
        sm:'14px',
        md:'16px',
        lg:'16px',
        xl:"18px"
      },
      boxShadow:'none',
      fontWeight: "bold",
      textTransform:"capitalize",
      fontFamily:"Montserrat, sans-serif",
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",

      "&:hover": {
        background:
          "linear-gradient(to right,rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",
        border: "none",
        color: "#ffffff",
        boxShadow:'none',
      },
    },
  };
};
