import React from "react";

import PrivacyPolicy from "../component/PrivacyPolicy";
import TermsandConditions from "../component/TermsandConditions";
import ChangePasswordInfo from "../pages/ChangePasswordInfo";
import BookConfirmation from "../pages/eventorganiser/BookServiceModal/BookingConfirmation";
import CreateProfile from "../pages/eventorganiser/createprofile";
import BankDetails from "../pages/eventorganiser/createprofile/BankDetails";
import DashBoard from "../pages/eventorganiser/dashboard";
import EventInfo from "../pages/eventorganiser/dashboard/EventInfo";
import ViewRating from "../pages/eventorganiser/dashboard/ViewRating";
import Explore from "../pages/eventorganiser/explore";
import Home from "../pages/eventorganiser/home";
import Messages from "../pages/eventorganiser/messages";
import Notification from "../pages/eventorganiser/Notification";
import Settings from "../pages/eventorganiser/profile";
import ProfileSucessfullPage from "../pages/eventorganiser/ProfileSucessfullPage";
import LanguageSelect from "../pages/languageselect";
import ProviderSelector from "../pages/providerselection";
import ServiceDashBoard from "../pages/serviceprovider/Dashboard";
import BlogImages from "../pages/serviceprovider/Dashboard/BlogImages";
import ViewRatingsSp from "../pages/serviceprovider/Dashboard/ViewRatingsSp";
import MyBookings from "../pages/serviceprovider/MyBookings";
import RequestInfo from "../pages/serviceprovider/MyBookings/RequestInfo";
import MyServices from '../pages/serviceprovider/MyService';
import { ServiceInfo } from "../pages/serviceprovider/MyService/ServiceInfo";
import Login from "../Login";
import RestPassword from "../Login/RestPassword";
import Signup from "../Signup";
import SwitchProfileInfo from "../pages/eventorganiser/home/SwtichProfileInfo";
import { routePaths } from "./route-paths";

const routes = [
  {
    path: routePaths.LANG_SELECT,
    element: () => <LanguageSelect />,
  },
  {
    path: routePaths.PROVIDER_SELECT,
    element: () => <ProviderSelector />,
  },
  {
    path: routePaths.SIGNUP,
    element: () => <Signup />,
  },
  {
    path: routePaths.PRIVACY_POLICY,
    element: () => <PrivacyPolicy />,
  },
  {
    path: routePaths.TERMS_AND_CONDITION,
    element: () => <TermsandConditions />,
  },
  {
    path: routePaths.LOGIN,
    element: () => <Login />,
  },
  {
    path: routePaths.RESET_PASSWORD,
    element: () => <RestPassword />,
  },
  {
    path: routePaths.CREATE_PROFILE_EVENT_ORGANISER,
    element: () => <CreateProfile />,
  },
  {
    path: routePaths.HOME,
    element: () => <Home />,
  },
  {
    path: routePaths.PROFILECREATEDPAGE,
    element: () => <ProfileSucessfullPage />,
  },
  {
    path: routePaths.SETTINGS,
    element: () => <Settings />,
  },
  {
    path: routePaths.EVENT_DASHBOARD,
    element: () => <DashBoard />,
  },
  {
    path: routePaths.EVENT_INFO,
    element: () => <EventInfo />,
  },
  {
    path: routePaths.NOTIFICATIONS,
    element: () => <Notification />,
  },
  {
    path: routePaths.MESSAGES,
    element: () => <Messages />,
  },
  {
    path: routePaths.VIEW_RATING,
    element: () => <ViewRating />,
  },
  {
    path: routePaths.SERVICE_DASHBOARD,
    element: () => <ServiceDashBoard />,
  },
  {
    path: routePaths.BLOG_IMAGES,
    element: () => <BlogImages />,
  },
  {
    path: routePaths.MY_SERVICES,
    element: () => <MyServices />,
  },
  {
    path: routePaths.CHANGE_PASSWORD,
    element: () => <ChangePasswordInfo />,
  },
  {
    path: routePaths.EXPLORE_EVENT,
    element: () => <Explore />,
  },
  {
    path: routePaths.BANK_DETAILS,
    element: () => <BankDetails />,
  },
  {
    path: routePaths.SERVICE_INFO,
    element: () => <ServiceInfo />,
  },
  {
    path: routePaths.BOOKING_CONFIRMATION,
    element: () => <BookConfirmation />,
  },
  {
    path: routePaths.BOOKING_INFO,
    element: () => <MyBookings />,
  },
  {
    path: routePaths.REQUEST_INFO,
    element: () => <RequestInfo />,
  },
  {
    path: routePaths.RATINGS_AND_REVIEWS_SP,
    element: () => <ViewRatingsSp />,
  },
  {
    path: routePaths.NOTIFICATIONS_NEW,
    element: () => <Notification />,
  },
  {
    path: routePaths.SWITCH_PROFILE,
    element: () => <SwitchProfileInfo />,
  }
  
];

export default routes;
