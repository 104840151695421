import React from "react";
import OtpInput from "react-otp-input";

import ButtonComp from "../../../component/Button";
import { getSx } from "../../../component/Modal/style";
import { Box, Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import otppopupicon from '../../../assets/images/otppopupicon.svg'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "400px",
    padding: "30px",
    borderRadius: "20px",
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));
const handleKeyPress = (event) => {
  const charCode = event.charCode;
  if (charCode < 48 || charCode > 57) {
    event.preventDefault();
  }
};

export default function ShareOtpPopUp({otp,setOtp,openOtpModal,setOpenOtpModal,handleOtpInfo}) {
  const classes = getSx();
  return (
    <CustomDialog
      open={openOtpModal}
      TransitionComponent={Transition}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogContent sx={{ padding: {
            xs:0,
          sm:0,
          md:0,
          lg:0,
          xl:0
        } }}>
        <Box sx={classes.subTitleContainer}>
          <img
            src={otppopupicon}
            alt="logo"
            style={{ marginBottom: "10px", height: "100px", width: "100px" }}
          />

          <Typography variant="h4" sx={classes.subTitle}>
            Enter OTP{" "}
          </Typography>

          <Typography variant="h6" sx={classes.subText}>
            Please type the verification code given by the Event Organizer{" "}
          </Typography>

         
            <OtpInput
              value={otp}
              onChange={setOtp}
              isInputNum={true}
              numInputs={4}
               renderInput={(props) => <input {...props}   onKeyPress={handleKeyPress} />}
              separator={<span style={{ width: "8px" }}></span>}
              inputStyle={{
                border: "1px solid transparent",
                background: "rgba(246, 245, 245, 1)",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "15px",
                color: "#000",
                fontWeight: "500",
                caretColor: "blue",
                margin: "10px",
              }}
              focusStyle={{
                border: "none",
                outline: "none",
              }}
              hoverStyle={{
                border: "none",
              }}
              focusVisibleStyle={{ borderColor: "transparent" }}
            />
        </Box>
        <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",marginTop:'10px'}}>
       <ButtonComp label="Cancel" type="whitebtn" width="45%" onClick={()=>{setOpenOtpModal(false);setOtp("")}}/>
       <ButtonComp label="Done" type={otp.length==4?"active":"disabled"} disabled={otp?.length==4?false:true} width="45%" onClick={()=>{handleOtpInfo()}}/>
        </Box>
      </DialogContent>
    
    </CustomDialog>
  );
}

