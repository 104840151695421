import React from 'react'

const ProfileIcon = ({val}) => {
  return val ? (
    <svg
    className='profile-icon'
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5026 0.333496C6.44203 0.333496 3.96094 2.81458 3.96094 5.87516C3.96094 8.93574 6.44203 11.4168 9.5026 11.4168C12.5632 11.4168 15.0443 8.93574 15.0443 5.87516C15.0443 2.81458 12.5632 0.333496 9.5026 0.333496Z"
        fill="url(#paint0_linear_1811_68298)"
      />
      <path
        d="M6.0026 13.1668C2.94203 13.1668 0.460938 15.6479 0.460938 18.7085C0.460938 21.7691 2.94203 24.2502 6.0026 24.2502H13.0026C16.0632 24.2502 18.5443 21.7691 18.5443 18.7085C18.5443 15.6479 16.0632 13.1668 13.0026 13.1668H6.0026Z"
        fill="url(#paint1_linear_1811_68298)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1811_68298"
          x1="0.460938"
          y1="12.2918"
          x2="18.5443"
          y2="12.2918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1811_68298"
          x1="0.460938"
          y1="12.2918"
          x2="18.5443"
          y2="12.2918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      className='profile-icon'
      viewBox="0 0 19 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5026 0.333496C6.44203 0.333496 3.96094 2.81458 3.96094 5.87516C3.96094 8.93574 6.44203 11.4168 9.5026 11.4168C12.5632 11.4168 15.0443 8.93574 15.0443 5.87516C15.0443 2.81458 12.5632 0.333496 9.5026 0.333496Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
      <path
        d="M6.0026 13.1668C2.94203 13.1668 0.460938 15.6479 0.460938 18.7085C0.460938 21.7691 2.94203 24.2502 6.0026 24.2502H13.0026C16.0632 24.2502 18.5443 21.7691 18.5443 18.7085C18.5443 15.6479 16.0632 13.1668 13.0026 13.1668H6.0026Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
    </svg>
  );
}

export default ProfileIcon