import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import GoogleMapView from "./GoogleMapView";
import { getSx } from "./style";
import crosswhiteicon from "../../../assets/images/crosswhiteicon.svg";
import plusicongreen from "../../../assets/images/plusicongreen.svg";
import { getAddressObject } from "../../../helper";

import { uploadImageApi } from "../../../api/Signup";
import { showToast } from "../../../store/slices/common";

const DropZone = styled("div")(() => ({
  cursor: "pointer",
  width: "165px",
  height: "165px",
  border: "2px solid #17BAAE",
  borderStyle: "dashed",
  borderRadius: "33px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const AddressImage = ({
  setPage,
  addService,
  setAddService,
  isAddOn,
  callAddOnApi,
  loader,
  isEdit
}) => {
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const classes = getSx();
  let apikey = process.env.REACT_APP_GOOGLE_API_KEY;
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: apikey,
    options: {
      // componentRestrictions: { country: "ca" },
    },
  });
  const onDrop = (acceptedFiles) => {
    if (addService.serviceImages.length + acceptedFiles.length > 10) {
      dispatch(
        showToast({
          color: "error",
          msg: "You can upload a maximum of 10 files.",
        })
      );
      return;
    }
    acceptedFiles.forEach((item, index) => {
      if (validateFileExtension(item?.name)) {
        handleImages(item, index, acceptedFiles.length);
      } else {
        dispatch(
          showToast({
            color: "error",
            msg: "Please Upload Valid Document in png/jpg format",
          })
        );
      }
    });
  };


  const validationSchema = Yup.object({
    address: Yup.string()
      .required("Address Required")
      .matches(/^[A-Za-z0-9 ]+$/, "Invalid address"),
    state: Yup.string()
      .required("State Required")
      .matches(/^[A-Za-z ]+$/, "Invalid state"),
    zipCode: Yup.string()
      .required("Postal Code Required")
      .matches(/^[A-Za-z0-9 ]+$/, "Invalid zipCode"),
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 15,
    accept: "image/*",
  });
  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        formik.setFieldValue("address", placeDetails?.name);
        formik.setFieldValue("state", address?.region);
        formik.setFieldTouched("state", true);
        if (address?.postal_code.length > 0) {
          formik.setFieldTouched("zipCode", true);
          formik.setFieldValue("zipCode", address?.postal_code);
        }
        formik.setFieldTouched("address", true);
        formik.setErrors({});
        formik.validateForm().then(() => {
          formik.setErrors({});
        });
        setAddService({
          ...addService,
          location: {
            lat: placeDetails.geometry.location.lat().toString(),
            long: placeDetails.geometry.location.lng().toString(),
          },
          address: placeDetails?.name,
          state: address?.region,
          zipCode: address?.postal_code,
          city: address?.city,
        });
        setShowList(false);
      }
    );
  };
  function validateFileExtension(fileName) {
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.indexOf(fileExtension) !== -1;
  }
  const formik = useFormik({
    initialValues: {
      address: addService?.address,
      state: addService?.state,
      zipCode: addService?.zipCode,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });
  const handleImages = async (file, index, len) => {
    if (index == 0) setLoading(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => { };
      reader.readAsDataURL(file);
    }
    let formData = new FormData();
    formData.append("file", file);
    const response = await uploadImageApi(formData);
    if (response.type === "success") {
      setAddService((prev) => ({
        ...prev,
        serviceImages: [
          ...prev.serviceImages,
          { key: response?.data?.key, fileUrl: response?.data?.fileUrl },
        ],
      }));
    }
    if (index + 1 == len) setLoading(false);
  };



  const handleDeleteImage = (index) => {
    const imgs = addService.serviceImages.filter((item, i) => index != i);
    setAddService({ ...addService, serviceImages: [...imgs] });
  };
  const checkIsAlphabets = (value) => {
    let lines = value.split('\n');
    let cleanedLines = lines.map(line => line.trim()).filter(line => line.length > 0);
    let cleanedText = cleanedLines.join(' ');
    if (cleanedText.trim().match(/^[A-Za-z,./?!$&:;'"[\]{}() ]+$/)) {
      setError("")
    }
    else {
      setError("Only Alphabets are allowed")
    }
  }

  useEffect(() => {
    setValue(addService?.address);

  }, []);

  return (
    <>
      {<Typography
        component="div"
        variant="div"
        marginTop="20px"
        sx={isEdit ? {
          ...classes.addressmainedit, height: isEdit ? "auto" :
            {
              xs: "calc(90vh - 200px)",
              sm: 'calc(100vh - 200px)',
              md: 'calc(100vh - 250px)',
              lg: 'calc(100vh - 220px)',
              xl: "calc(100vh - 400px)"
            }
        } : {
          ...classes.addressmain, height: {
            xs: "calc(90vh - 200px)",
            sm: 'calc(100vh - 200px)',
            md: 'calc(100vh - 250px)',
            lg: 'calc(100vh - 220px)',
            xl: "calc(100vh - 400px)"
          }
        }}
      >
        <Typography
          component="div"
          variant="body"
          fontWeight="bold"
          sx={{ fontSize: { xs: "13px", md: "16px" }, fontFamily: "Montserrat, sans-serif", marginBottom: '15px' }}
        >
          Service Images
        </Typography>
        <Box sx={classes.serviceimages}>
          {addService?.serviceImages?.length < 10 &&
            (loading ? (
              <Box
                sx={{
                  width: "165px",
                  height: "165px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <DropZone {...getRootProps()}>
                <Box>
                  <img alt="" src={plusicongreen} />
                  <input
                    {...getInputProps()}
                    multiple
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                </Box>
              </DropZone>
            ))}

          <Box
            sx={
              addService?.serviceImages?.length < 10
                ? { ...classes.cardimages }
                : { ...classes.cardimages, width: "100% !important" }
            }
            className={addService?.serviceImages?.length > 1 ? "scrollableinfo" : ""}
          >
            {addService?.serviceImages?.map((item, index) => {
              return (
                <Box sx={classes.singleimg} key={item}>
                  <img alt=""
                    src={crosswhiteicon}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                      top: "1rem",
                      width: "15px",
                      height: "15px",
                    }}
                    onClick={() => {
                      handleDeleteImage(index);
                    }}
                  />
                  {index == 0 && <Box

                    style={{
                      position: "absolute",
                      left: "1rem",
                      cursor: "pointer",
                      top: "1rem",
                      width: "fit-content",
                      fontSize: "12px",
                      background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
                      color: "#fff",
                      padding: "2px 12px",
                      borderRadius: "6px"
                    }}

                  > Primary</Box>}
                  <img alt=""
                    src={item?.fileUrl}
                    style={{
                      width: "165px",
                      height: "165px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ color: "rgba(23, 186, 174, 1)", margin: "0.5rem 0px", fontFamily: "Montserrat, sans-serif" }}>
          *Please upload minimum two images,
          To ensure your listing stands out, please upload high-quality pictures. The recommended format is a 2:1 aspect ratio with a minimum resolution of 800px by 551px.
        </Box>
        {isAddOn ? (
          <Box>
            <Typography
              component="div"
              variant="body"
              fontWeight="bold"
              marginTop="10px"
              sx={{ fontSize: { xs: "16px", md: "18px" }, fontFamily: "Montserrat, sans-serif", marginBottom: '15px' }}
            >
              Service Description
            </Typography>
            <TextareaAutosize
              placeholder="Enter Description "
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "rgba(246, 245, 245, 1)",
                borderRadius: "15px",
                marginBotton: "3rem",
                fontSize: "15px",
                fontFamily: "Montserrat",
                border: "none",
                outline: "none",
                overflowY: "auto !important",
                maxHeight: "200px",
              }}
              name="description"
              className="textArea"
              value={formik.values.description}
              onChange={(e) => {
                checkIsAlphabets(e.target.value)
                setAddService({ ...addService, description: e.target.value });
              }}
              minRows={8}
            />
            {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
          </Box>
        ) : (
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            direction={"column"}
            spacing={2}
            width="100%"
            marginBottom="20px"
          >
            <Grid item>
              <div className="map_search">
                <Box sx={{ position: "relative" }}>
                  <Typography
                    sx={{
                      ...classes.smallheading,
                      fontSize: { xs: "16px", md: "18px" }, fontFamily: "Montserrat, sans-serif", marginBottom: '15px', fontWeight: 700
                    }}
                  >
                    Address
                  </Typography>
                  <CustomTextField
                    size="large"
                    value={value}
                    autoFocus={value?.length > 0 ? true : false}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                      setAddService({ ...addService, address: evt.target.value })

                      formik.setFieldValue("address", evt.target.value);
                      getPlacePredictions({ input: evt.target.value });
                      if (!isPlacePredictionsLoading) setShowList(true);
                    }}
                    onKeyDown={(event) => {
                      if (event?.keyCode === 8 || event?.keyCode === 46) {
                        formik.setFieldValue("state", "");
                        formik.setFieldValue("zipCode", "");
                      }
                    }}
                    placeholder="Enter Address"
                  />
                  {value !== "" && showList && placePredictions?.length > 0 && (

                    <Box sx={classes.googleautosuggestionlist}>

                      <List>
                        {placePredictions?.map((item) => (
                          <ListItem
                            key={item.place_id}
                            onClick={() => getDetails(item?.place_id)}
                            style={{ cursor: "pointer" }}
                          >
                            <ListItemText primary={item.description} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
              </div>

              <Stack
                marginTop="24px"
                direction={"row"}
                spacing={1}
                style={{ justifyContent: "space-between" }}
              >
                <Stack width="48%">
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="State"
                    name="state"
                    value={formik.values.state}
                    onChange={(e) => {
                      formik.setFieldTouched("state", true);
                      formik.handleChange(e);
                      if (isEdit)
                        setAddService({ ...addService, state: e.target.value })
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    errorMessage={formik.touched.state && formik.errors.state}
                  />
                </Stack>

                <Stack width="48%">
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Zip Code"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={(e) => {
                      formik.setFieldTouched("zipCode", true);
                      formik.handleChange(e);
                      setAddService({ ...addService, zipCode: e.target.value })
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    errorMessage={
                      formik.touched.zipCode && formik.errors.zipCode
                    }
                  />
                </Stack>
              </Stack>

            </Grid>

            {addService?.location?.lat &&
              <Box sx={{ marginBottom: "2rem" }}>
                <GoogleMapView addService={addService} />
              </Box>
            }
          </Grid>

        )}
      </Typography>}
      {!isEdit && (isAddOn ? (
        <ButtonComp
          label="Add-On Service"
          type={
            addService?.serviceImages?.length > 1 &&
              addService?.description.length > 0 && !error &&
              !loading
              ? "active"
              : "disabled"
          }
          disabled={
            addService?.serviceImages?.length > 1 &&
              addService?.description.length > 0 && !error &&
              !loading
              ? false
              : true
          }
          width="100%"
          loading={loader}
          onClick={() => {
            callAddOnApi();
          }}
        />
      ) : (
        <ButtonComp
          label="Next"
          width="100%"
          type={
            (addService?.serviceImages?.length > 1 &&
              formik.isValid &&
              formik.dirty &&
              formik.values.zipCode.length > 0 &&
              formik.values.state.length > 0 &&
              !loading) ||
              (addService?.serviceImages?.length > 1 &&
                formik.isValid &&
                addService.address &&
                formik.values.zipCode.length > 0 &&
                formik.values.state.length > 0 &&
                !loading)
              ? "active"
              : "disabled"
          }
          disabled={
            (addService?.serviceImages?.length > 1 &&
              formik.isValid &&
              formik.dirty &&
              formik.values.zipCode.length > 0 &&
              formik.values.state.length > 0 &&
              !loading) ||
              (addService?.serviceImages?.length > 1 &&
                formik.isValid &&
                addService.address &&
                formik.values.zipCode.length > 0 &&
                formik.values.state.length > 0 &&
                !loading)
              ? false
              : true
          }
          onClick={() => {
            setPage((prev) => prev + 1);
            setAddService({
              ...addService,
              amenities: [],
              cancellationPolicy: "",
            });
          }}
        />
      ))}
    </>
  );
};

AddressImage.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  mapValue: PropTypes.string,
  setMapValue: PropTypes.func,
};
