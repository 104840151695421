
export const getSx = () => {
  return {
    main: {
      display: "flex",
      overflow: "hidden",
      padding:{
        xs:'15px 0',
        sm:'15px 0',
        md:'15px 10px',
        lg:'15px 10px',
        xl:'50px 10px'
      }
    },
    userName: {
      color: "#fff",
      display: "flex",
      height: "45px",
      minWidth: "100px",
      maxWidth: "100%",
      backgroundColor: "#000",
      borderRadius: "15px",
      padding: "10px",
    },

    sideNav: {
      width: { xs: "64px", md: "300px",lg: "300px", xl: "300px" },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: { xs: "15px", sm: "30px 15px", md:'30px', lg:'30px', xl:'30px'},
      height:{
        xs:'calc(100vh - 170px)',
        sm:'calc(100vh - 200px)',
        md:'calc(100vh - 230px)',
        lg:'calc(100vh - 150px)',
        xl:'calc(100vh - 270px)',
      },
      boxShadow: " 0px 0px 20px 0px #0000000D",
      borderRadius: "25px",
      background:"#ffffff"
    },
    info: {
      width: { xs: "calc(100% - 64px)", md: "calc(100% - 300px)",lg:"calc(100% - 290px)", xl: "calc(100% - 290px)" },
      height: {
        xs:'calc(100vh - 100px)',
        sm:'calc(100vh - 140px)',
        md:'calc(100vh - 140px)',
        lg:'calc(100vh - 130px)',
        xl:'calc(100vh - 170px)',
      },
      padding: {
        xs:'15px 0 15px 10px',
        sm:'0 15px',
        md:'0 15px',
        lg:'0 30px',
        xl:'0 60px'
      },
      overflow: "auto",
    },
    info1: {
      width: { xs: "calc(100% - 64px)", md: "calc(100% - 300px)",lg:"calc(100% - 290px)", xl: "calc(100% - 290px)" },
      height: {
        xs:'calc(100vh - 100px)',
        sm:'calc(100vh - 140px)',
        md:'calc(100vh - 140px)',
        lg:'calc(100vh - 130px)',
        xl:'calc(100vh - 170px)',
      },
      overflow: "auto",
      padding: {
        xs:'15px 0 15px 10px',
        sm:'0 15px',
        md:'0 15px',
        lg:'0 30px',
        xl:'0 60px'
      },
    },
    contactusimg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    contactusimg1: {
      width: { xs: "150px", md: "250px", lg: "175px", xl:'250px' },
      height: { xs: "150px", md: "250px", lg: "175px",xl:'250px' },
    },
    singleTab: {
      padding: { xs: "5px", md: "11px 15px" },
      marginBottom: "15px",
      background: "#F9F9F9",
      borderRadius: "15px",
      width: '100%',
      display: "flex",
      color: "black",
      fontWeight:700,
      cursor:"pointer",
      alignItems:'center',
      fontSize:'16px',
      fontFamily:"Montserrat, sans-serif"
    },
    title: {
      display: { xs: "none", md: "block" },
    },
    selsingleTab: {
      padding: { xs: "2px", md: "11px 15px" },
      marginBottom: "15px",
      borderRadius: "15px",
      width: '100%',
      color: "white",
      fontWeight: 700,
      display: "flex",
      alignItems:'center',
      fontSize:'16px',
      fontFamily:"Montserrat, sans-serif",
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
    },
    header: {
      height: {
        xs: "70px",
        sm: "70px",
        md: "120px",
      },
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(37, 37, 37, 0.25)",
      position: "fixed",
      width: "100%",
      px: {
        xs: "20px",
        sm: "20px",
        md: "30px",
        lg: "50px",
      },
      py: {
        sm: "20px",
        md: "20px",
      },
      active: {
        color: "red",
      },
      pending: {
        color: "green",
      },
      backgroundicon: {
        borderRadius: "50%",
        width: "90px",
        height: "90px",
        backgroundColor: "#ffff",
        display: "flex",
      },
    },
    subform: {
      width: "100%",
      display: { xs: "block", sm: "flex" },
    },
    subform1: {
      width: { xs: "100%", md: "50%" },
    },
    subform2: {
      width: { xs: "100%", md: "49%" },
      marginLeft: "1%",
    },
    inputStyle: {
      width: {
        xs:'100%',
        sm:"100%",
        md:"100%",
        lg:"100%",
        xl:"100%"
      },
      height: {
        xs:'50px',
        sm:"60px",
        md:"60px",
        lg:"60px",
        xl:"60px"
      },
      borderRadius: "15px",
      border: "1px solid rgba(217, 217, 217, 1)",
      backgroundColor: "transparent",
      color: "#365462",
      fontSize: {
        xs:'14px',
        sm:'15px',
        md:"16px",
        xl:"16px",
        lg:"16px"
      },
      fontWeight: 400,
      fontFamily:"Montserrat, sans-serif"
    },
    savebtn: {
      width: { xs: "100%", sm: "205px", lg:'205px', xl:'205px' },
      marginTop: '20px',
    },
    profileimg: {
      width: { xs: "30px", md: "20px" },
      height: { xs: "30px", md: "20px" },
      padding:{
        xs:'5px',
        sm:0,
        md:0,
        lg:0,
        xl:0
      }
    },
    bankCont: {
      width: {xs:"100%",sm:"100%",md:'100%', lg:"60%" ,xl:'50%'},
    },
    servicecont: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "12px",
      flexDirection: "row",
      height:"30px",
      overflow:"auto"
    },
    servicecontinfo: {
      color:"rgba(0, 0, 0, 0.5)",
      background:
        "linear-gradient(rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
      width: "fit-content",
      padding: "3px 8px",
      borderRadius: "20px",
      fontWeight:"600",
      margin:"3px 3px 3px 0",
      fontSize:"12px",
      fontFamily:'Montserrat,sans-serif'
    },
    profileimg1: {
       width: "100%",
      },
      smallheading: {
        fontSize: {xs: "13px", md: "15px", sm:'14px', lg:'15px', xl:'18px' },
        fontWeight: 600,
        marginBottom: "12px",
        color: "rgba(26, 26, 26, 1)",
        fontFamily: "Montserrat , sans-serif !important",
      },
    plusicon: {
       position: {xs:"absolute",sm:"relative"},
       left: {xs:"30%",md:"6%"},
       top:{xs:"64%",lg:"-32px"},
       width:{xs:"27px",md:"50px"}
    },
  };
};
