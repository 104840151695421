import instance from "../common/config/axios";
//apis for notifications
export const getNotificationListApi = async (page) => {
    try {
      const response = await instance.get(`notification/list?pageNumber=${page}`);
      return response;
    } catch (error) {
      return error;
    }
  };

  export const getNotificationCountApi = async () => {
    try {
      const response = await instance.get(`notification/count`);
      return response;
    } catch (error) {
      return error;
    }
  };

  export const callReadNotificationApi = async () => {
      await instance.put(`notification/read`);

  };
  export const callSingleReadNotificationApi = async (id) => {
    await instance.put(`notification/view-notification-details/${id}`);

};