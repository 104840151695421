import React from "react";
import Calendar from "react-calendar";

import { styled, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import crossicon from '../../../assets/images/svg/crossicon.svg';
import { useDispatch } from "react-redux";
import "react-calendar/dist/Calendar.css";
import { Box } from "@mui/system";
import { setStartTime,setEndTime } from "../../../store/slices/common";
const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "450px",
    padding: "30px",
    borderRadius: "24px",
  },
  "& .MuiPaper-root": { maxWidth: "450px" },
  "& .MuiDialogActions-root": {},
}));

function CalendarInfo({ openCalendar, setOpenCalendar, isStartTime, endTime, startTime }) {
  const dispatch=useDispatch();
  return (
    <CustomDialog
      open={openCalendar}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "450px" } }}
    >
      <DialogContent sx={{
        padding: {
          xs: 0, sm: 0, md: 0, lg: 0, xl: 0
        }
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{
            fontWeight: 700,
            fontSize: {
              xs: '20px',
              sm: '24px',
              md: '24px',
              lg: '28px',
              xl: '28px'
            },
            lineHeight: {
              xs: '21px',
              sm: '25px',
              md: '25px',
              lg: '27px',
              xl: '27px'
            },
            color: '#000000',
            fontFamily: "Montserrat, sans-serif"

          }}>Availability</Typography>

          <img
            src={crossicon}
            style={{ cursor: "pointer" }}
            onClick={() => { setOpenCalendar(false) }}
          />
        </Box>
        <Box sx={{
          marginTop: {
            xs: '15px',
            sm: '24px',
            md: '24px',
            lg: '24px',
            xl: '24px'
          }
        }}>
          <div className="app">
            <div className="calendar-container">
              {isStartTime ? <Calendar
                onChange={(e) => { dispatch(setStartTime(e)); setOpenCalendar(false) }}
                value={startTime}
                className="nobordercalendar"
                calendarStartDay={1}
                maxDate={endTime}
                 locale="en-GB"
                minDate={new Date()}
              /> : <Calendar
                onChange={(e) => { dispatch(setEndTime(e));setOpenCalendar(false) }}
                value={endTime}
                minDate={startTime}
                className="nobordercalendar"
                calendarStartDay={1}
                 locale="en-GB"
              />}
            </div>
          </div>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
}

export default CalendarInfo;
