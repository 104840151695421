import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";

import ButtonComp from "../../../component/Button";
import { Box, Typography, Grid, TextField } from "@mui/material";
import { getSx } from "./style";
import "react-phone-input-2/lib/style.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const PhoneNumber = ({ setPage, setValue, role, handleData, data, fromSocialMedia, saveProfile, loading, selectedDate, setSelectedDate }) => {
  const classes = getSx();
  const handleContinue = () => {
    if (fromSocialMedia)
      saveProfile();
    else {
      handleData({ profileImage: null });
      setPage(3);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleData({ DOB: dayjs(date).format("MM/DD/YYYY") })
    setOpen(false);
  }
  const [open, setOpen] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (role == "0") {
      if (data?.phoneNumber?.length == 10 && selectedDate)
        setValue(60)
      else
        setValue(30)
    }
    else
      setValue(24)
  }, [data?.phoneNumber, selectedDate])

  return (
    <Box sx={classes.phoneinfo}>
      <Typography sx={{ ...classes.heading }}>Enter your phone number and DOB</Typography>
      <Box sx={classes.createFormMain}>
        <Grid className="flag-ui-box" item sx={classes.phoneNumberWrapper}>
          <Box width={"100%"} position="relative">
            <Typography sx={{ ...classes.smallheading }}>Phone Number</Typography>
            <PhoneInput
              country={"us"}
              sx={classes.phoneinput}
              value={`${data?.countryCode + data?.phoneNumber}`}
              onChange={(value, country) => {
                handleData({ countryCode: country?.dialCode, phoneNumber: value.slice(country?.dialCode?.length) })
              }}
              inputStyle={{
                ...classes.inputStyle,
              }}
              containerStyle={classes.containerStyle}
            />
          </Box>
        </Grid>
        <Box sx={{ margin: '20px 0px' }}>
          <Typography sx={classes.smallheading}>Date of Birth</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              value={selectedDate ? dayjs(selectedDate) : null}
              onChange={handleDateChange}
              ref={datePickerRef}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
              onClick={() => { setOpen(true) }}
              format="MM/DD/YYYY"
              sx={{
                width: "100%",
                borderRadius: "18px",
                height: "60px",
                "& fieldset": {
                  border: "1px solid #EBEBEB",
                  height: "60px",
                  borderRadius: "18px",
                },
                "& fieldset:hover": {
                  border: "1px solid #EBEBEB",
                  height: "60px",
                  borderRadius: "18px",
                },
                "& fieldset:focus": {
                  border: "1px solid #EBEBEB",
                  height: "60px",
                  borderRadius: "18px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#EBEBEB !important",
                },
                "& input": {
                  fontWeight: 600,
                },
              }}
              maxDate={dayjs().endOf('day')}
              slotProps={{
                textField: {
                  onClick: () => setOpen(true),
                  readOnly: true

                },
              }}
              renderInput={(props) => (

                <TextField
                  inputProps={{ readOnly: true }}
                  onClick={() => setOpen(true)}
                  onKeyDown={(e) => e.preventDefault()}
                  {...props}
                  variant="outlined"
                  fullWidth
                />

              )}
            />
          </LocalizationProvider>
        </Box>
        <ButtonComp
          className="responsive-btn-class"
          width="100%"
          disabled={
            data?.phoneNumber?.length == 10 && selectedDate ? false : true
          }
          loading={loading}
          type={
            data?.phoneNumber?.length == 10 && selectedDate
              ? "active"
              : "disabled"
          }
          label="Continue"
          style={{ marginTop: "40px", width: "100%", height: '60px', fontWeight: 600, fontFamily: "Montserrat, sans-serif" }}
          onClick={() => {
            handleContinue();

          }}
        />
      </Box>
    </Box>
  );
};

export default PhoneNumber;
