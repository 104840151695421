
export const getSx = () => {
  return {
    maincontainer: {
      width: "100%",
      height: "100vh",
      display: "flex",
    },
    leftBox: {
      width: {
        xs:'100%',
        sm:'100%',
        md:'100%',
        lg:'50%'
      },
      height: "100vh",
      background: "white",
      overflow: "auto",
    },
    checkboxinfo: {
      margin: "10px",
    },
    rightBox: {
      width: { xs: 0,sm:0, md: 0, lg: "50%",xl:'50%' },
      height: "100vh",
      overflow: "hidden",
    },
  };
};
