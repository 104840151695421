import React, { useEffect, useState, useRef } from "react";

import { Box } from "@mui/material";
import { getSx } from "./style";
import TimeInfo from "./TimeInfo";
import sendbtnimg from "../../../assets/images/sendbtnimg.png";

const ChatInfo = ({
  newChatUser,
  messages,
  senderId,
  setNewMessage,
  handleSendMsg,
  message,
  page,
  setPage,
  callRecieveMessage,
  isMsgRecieved
}) => {
  const classes = getSx();
  const [timeoutVal, setTimeoutVal] = useState(1000);
  const [sectionsinfo, setSectionsinfo] = useState([]);
  const listInnerRef = useRef();
  
  useEffect(() => {
    
      setTimeout(() => {
        setTimeoutVal(100);
        const messagechat = document.getElementsByClassName("messagechat");
        messagechat[0]?.scroll({ top: messagechat[0]?.scrollHeight });
      }, timeoutVal);
  }, [isMsgRecieved]);

  useEffect(() => {
    const groupedMessages = groupMessages(messages);
    setSectionsinfo(groupedMessages);
    callRecieveMessage(newChatUser?.userId);
  }, [messages]);

  function formatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  const handleSetPage = () => {
    setPage(messages?.length / 10 + 1);
  };

  function groupMessages(messages) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const groupedMessages = {};

    const weekDayNames = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
    ];

    messages.forEach((msg) => {
      const msgDate = new Date(msg.createdAt);
      const msgDay = msgDate.toDateString();

      if (msgDay === today.toDateString()) {
        if (!groupedMessages["Today"]) groupedMessages["Today"] = [];
        groupedMessages["Today"].push(msg);
      } else if (msgDay === yesterday.toDateString()) {
        if (!groupedMessages["Yesterday"]) groupedMessages["Yesterday"] = [];
        groupedMessages["Yesterday"].push(msg);
      } else if (
        msgDate >
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      ) {
        const dayName = weekDayNames[msgDate.getDay()];
        if (!groupedMessages[dayName]) {
          groupedMessages[dayName] = [];
        }
        groupedMessages[dayName].push(msg);
      } else {
        const formattedDate = formatDate(msgDate);
        if (!groupedMessages[formattedDate]) {
          groupedMessages[formattedDate] = [];
        }
        groupedMessages[formattedDate].push(msg);
      }
    });

    const result = Object.keys(groupedMessages).map((key) => ({
      key,
      value: groupedMessages[key],
    }));

    return result;
  }

  return (
    <Box>
      <Box sx={classes.messageinfo}>
        <Box sx={classes.messageheading}>
          <Box sx={classes.messageimg}>
            <img src={newChatUser?.userDetails?.profileImage?.url} alt="User" />
          </Box>
          <Box sx={classes.messagename}>
            {newChatUser?.userDetails?.firstName}{" "}
            {newChatUser?.userDetails?.lastName}
          </Box>
        </Box>
        <Box
          sx={classes.messagechat}
          className="messagechat"
          ref={listInnerRef}
          onScroll={() => {
            if (
              listInnerRef.current.scrollTop === 0 &&
              messages.length > 9 &&
              messages.length % 10 == 0
            ) {
              handleSetPage();
            }
           
          }}
        >
          <Box sx={classes.messageinfocontent}>
            {sectionsinfo?.map((section, sectionIndex) => (
              <React.Fragment key={`section-${section.key}`}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {section.key}
                </Box>
                {section.value.map((message, messageIndex) => (
                  <Box
                    key={`message-${message?.from?._id}`}
                    sx={classes.messagecontent}
                    style={{
                      display: "flex",
                      flexDirection:
                        message?.from?._id === senderId ? "row-reverse" : "row",
                      margin: "5px",
                    }}
                  >
                    {message?.from?._id !== senderId && (
                      <Box sx={classes.userimg}>
                        <img
                          src={newChatUser?.userDetails?.profileImage?.url}
                          alt="User"
                        />
                      </Box>
                    )}
                    <Box sx={classes.userinfo}>
                      <Box sx={classes.usercontent2}>
                        <Box sx={classes.username}>
                          {message?.from?._id === senderId
                            ? "You"
                            : `${newChatUser?.userDetails?.firstName} ${newChatUser?.userDetails?.lastName}`}
                        </Box>
                        <TimeInfo timeval={message?.createdAt} />
                      </Box>
                      <Box
                        sx={{
                          ...classes.usercontent,
                          backgroundColor:
                            message?.from?._id === senderId
                              ? "rgba(23, 186, 174, 1)"
                              : "#F6F5F5",
                          color:
                            message?.from?._id === senderId
                              ? "#fff"
                              : "initial",
                        }}
                      >
                        {message?.message}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
       {newChatUser?.isDisabled?<Box   sx={{...classes.inputtag,fontWeight:"bold",display:"flex",alignItems:"center",justifyContent:"center"}} >Message service is not available since there is no active request for a service.</Box>: 
      <Box sx={classes.inputtag}>
        <Box sx={classes.sendbtnimg}>
          <img
            src={sendbtnimg}
            alt="Send"
            onClick={() => {
              if (message?.trim()?.length > 0) handleSendMsg();
            }}
          />
        </Box>
        <input
          style={{
            height: "66px",
            width: "100%",
            borderRadius: "10px",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            padding: "10px",
            outline: "none",
            paddingRight: "4rem",
          }}
          placeholder="Enter Message Here"
          value={message}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          className="messageinput chat-message"
          onKeyPress={(event)=>{
            let regex = new RegExp(/^[A-Za-z,./?!&:;'"[\]{}() ]+$/);
            let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
               event.preventDefault();
            }
           
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && message.trim()) {
              handleSendMsg(e);
            }
          }}
        />
      </Box>}
    </Box>
  );
};

export default ChatInfo;
