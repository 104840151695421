import {  useEffect, useState } from "react";
import io from "socket.io-client";

const useSocket = () => {
  const token = localStorage.getItem("token");
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (!socket && token) {

      setSocket(
        io(process.env.REACT_APP_SOCKET_URL, { query: { accessToken: token } })
      );
    }
  }, []);

  return socket;
};

export default useSocket;
