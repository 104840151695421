import instance from "../common/config/axios";
//apis for dashboard stats
export const dashboardstatsApi = async () => {
  try {
    const response = await instance.get(`/service/dashboard-stats`);
    return response;
  } catch (error) {
    return error;
  }
};

export const graphstatsApi = async (year) => {
  try {
    const response = await instance.get(`/service/earning-stats?targetYear=${year}`);
    return response;
  } catch (error) {
    return error;
  }
};

