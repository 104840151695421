export const handleProfileInfo=(profileResp,dispatch,setProfileInfo,role)=>{

    if (role == "0" && (profileResp?.firstName && profileResp?.lastName && profileResp?.profileImage?.url && !profileResp?.phoneNumber))
      dispatch(setProfileInfo({screen:2,userId:profileResp?._id}));
    else if (role == "0" && !(profileResp?.firstName && profileResp?.lastName && profileResp?.phoneNumber && profileResp?.profileImage?.url))
      dispatch(setProfileInfo({screen:3,userId:profileResp?._id}));
    else if (role == "0" && profileResp?.firstName && profileResp?.lastName && profileResp?.phoneNumber && profileResp?.profileImage?.url)
      dispatch(setProfileInfo({screen:1,userId:profileResp?._id}));
    else if (role == "1" && !(profileResp?.businessDocuments?.length > 0 || profileResp?.questions?.length > 0))
      dispatch(setProfileInfo({screen:4,userId:profileResp?._id}));
    else if (role == "1" && profileResp?.status == "Pending")
      dispatch(setProfileInfo({screen:5,userId:profileResp?._id}));
    else if (role == "1" && profileResp?.status == "Approved" && (profileResp?.bankingDetails?.address == null || profileResp?.bankingDetails?.accountNumber == null))
      dispatch(setProfileInfo({screen:6,userId:profileResp?._id}));
    else if (role == "1" && profileResp?.status == "Approved" && (profileResp?.bankingDetails?.address != null && profileResp?.bankingDetails?.accountNumber != null && profileResp?.bankingDetails.status != "Pending"))
      dispatch(setProfileInfo({screen:7,userId:profileResp?._id}));
    else
      dispatch(setProfileInfo({screen:1,userId:profileResp?._id}));
}