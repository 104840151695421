import React from 'react'
import { Box } from '@mui/system';
import dayjs from 'dayjs';

const DaysMapping = ({ allDays, availableDates, selectedDatesArr, handleSelectedCatDates, setIsButtonClicked, index, isButtonClicked }) => {
    const containerRef = React.useRef(null);
    const handleWheel = (event) => {
        const container = containerRef.current;
        container.scrollLeft += event.deltaY;
    }
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                overflowX: "auto",
                marginTop: {
                    xs: '5px',
                    sm: '10px',
                    md: '10px',
                    lg: '10px',
                    xl: '15px'
                },
                marginBottom: "10px"
            }}
            ref={containerRef}
            onWheel={handleWheel}
            className="scrollableinfo"
        >
            {allDays.map((item) => {
                return (
                    <>
                        {availableDates.includes(item) ? (
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    padding: {
                                        xs: "5px",
                                        sm: "5px",
                                        md: "5px",
                                        lg: "8px",
                                        xl: "10px"
                                    },
                                    minWidth: "78px",
                                    height: {
                                        xs: "30px",
                                        sm: "35px",
                                        md: "35px",
                                        lg: "40px",
                                        xl: "45px"
                                    },
                                    cursor: "pointer",
                                    margin: {
                                        xs: "5px",
                                        sm: "5px",
                                        md: "5px",
                                        lg: "8px",
                                        xl: "10px"
                                    },
                                    fontSize: { xs: "14px", md: "15px" }, fontFamily: "Montserrat, sans-serif",
                                    background: selectedDatesArr[index]?.includes(
                                        item
                                    )
                                        ? "linear-gradient(to bottom,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))"
                                        : "rgba(246, 245, 245, 1)",
                                    color: selectedDatesArr[index]?.includes(
                                        item
                                    )
                                        ? "#fff"
                                        : "black",
                                }}
                                onClick={() => {
                                    handleSelectedCatDates(item, index);
                                    let clickbtnArray = isButtonClicked;
                                    clickbtnArray[index] = false
                                    setIsButtonClicked([...clickbtnArray])
                                }}
                            >
                                {dayjs(item).format("MMM DD")}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    padding: {
                                        xs: "5px",
                                        sm: "5px",
                                        md: "5px",
                                        lg: "8px",
                                        xl: "10px"
                                    },
                                    minWidth: "78px",
                                    height: {
                                        xs: "30px",
                                        sm: "35px",
                                        md: "35px",
                                        lg: "40px",
                                        xl: "45px"
                                    },
                                    margin: {
                                        xs: "5px",
                                        sm: "5px",
                                        md: "5px",
                                        lg: "8px",
                                        xl: "10px"
                                    },
                                    background: "rgba(0, 0, 0, 0.2)",
                                    cursor: "not-allowed",
                                    opacity: "0.2",
                                    color: "black",
                                    fontSize: { xs: "12px", md: "15px" }, fontFamily: "Montserrat, sans-serif"
                                }}
                                onClick={() => {
                                    let clickbtnArray = isButtonClicked;
                                    clickbtnArray[index] = true
                                    setIsButtonClicked([...clickbtnArray])
                                    //  setIsButtonClicked(true)
                                }}
                            >
                                {dayjs(item).format("MMM DD")}
                            </Box>
                        )}
                    </>
                );
            })}
        </Box>
    )
}

export default DaysMapping