import React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, styled, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import ButtonComp from "../../../component/Button";
import crossiconblack from "../../../assets/images/crossiconblack.svg";
import eventtutilogo from "../../../assets/images/evlogo.png";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
    "& .MuiDialog-paper": {
        width: "400px",
        padding: "30px",
        borderRadius: "20px",
    },
    "& .MuiPaper-root": { maxWidth: "350px" },
    "& .MuiDialogActions-root": {},
}));

export default function LocationModal({
    locationModal, handleClose, handleOk
}) {
    //const classes = getSx(successButton);
    const dispatch = useDispatch();
    return (
        <CustomDialog
            open={locationModal}
            // TransitionComponent={Transition}
            keepMounted
            // onClose={handleClose}
            sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
        >
            <DialogContent sx={{
                padding: {
                    xs: "8px 0",
                    sm: "8px 10px",
                    md: "8px 10px",
                    lg: "8px 20px",
                    xl: "8px 20px"
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <Box sx={{ width: "100%", alignItems: "center", justifyContent: "center", textAlign: "center" }}>

                    <Typography variant="h5" sx={{ fontWeight: "bold", width: "100%", textAlign: "center", fontFamily: "Montserrat" }}>Location Services</Typography>
                    <Typography variant="h6" sx={{ textAlign: "center", fontFamily: "Montserrat" }}>
                        May we use your location when you use the app?
                    </Typography>
                    <img src={eventtutilogo} alt="locationicon" style={{ width: "100px", height: "100px", margin: "20px 0px" }} />
                    <Typography variant="body1" sx={{ textAlign: "center", fontFamily: "Montserrat" }} >
                        Eventutti uses your location to find the best event services near you! It is only used to enhance your app experience and is never shared.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions
                disableSpacing={false}
                sx={{ justifyContent: "center", padding: "0px 20px 20px 20px", display: "flex", flexDirection: "column" }
                }
            >
                <Box sx={{ marginBottom: "10px", width: "100%" }}>
                    <ButtonComp label="Yes, Sounds Good" type="active" width="100%" onClick={handleOk} />
                </Box>

                <ButtonComp label="No, Thanks" type="active" backgroundColor="#fff" width="100%" onClick={handleClose} />
            </DialogActions>
        </CustomDialog>
    );
}


