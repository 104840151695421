import { createTheme } from "@mui/material";
export const theme = createTheme({
   typography: {
    fontFamily: [
      'Montserrat', 
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat,sans-serif",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Montserrat,sans-serif",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat,sans-serif",
        },
        input: {
          fontFamily: "Montserrat,sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "21.6px",
          color: "#0d2645",
          marginBottom: "20px",
          fontFamily: "Montserrat,sans-serif",
          textAlign: "left",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#17baae",
    },
  },
});
