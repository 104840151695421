import React from "react";
import { useLocation } from "react-router-dom";

import InfoPage from "../../component/InfoPage";
import verifiedTimer from "../../assets/images/svg/verifedtimer.svg";
import verifiedtick from "../../assets/images/svg/verifiedtick.svg";

const ProfileSucessfullPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role=localStorage.getItem("role")
  const id = queryParams.get("id");
  const handleFunc=()=>{
    if(id==1)
    return (
      <InfoPage
        image={verifiedtick}
        mainHeading="Whoopee!!!"
        smallHeading="Your account has been created"
        content={role=="0"?"You can now create your event! Let's get started":"Congratulations! You are now an Eventutti Partner. Start creating your service listing today!"}
        buttonInfo="Get Started"
        id="1"
      />
    );
    else if(id==2)
    return (
      <InfoPage
        image={verifiedTimer}
        smallHeading="Your verification request is in process"
        content="We are validating your information. An email will be sent once the process has finished."
        buttonInfo="Continue"
        id="2"
      />
    );
    else
    return (
      <InfoPage
        image={verifiedtick}
        smallHeading="Your profile has been approved"
        content="Exciting news! You're almost ready to showcase your services!"
        buttonInfo="Get Started"
        id="3"
      />
    );
  }
  return handleFunc();
};

export default ProfileSucessfullPage;
