import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, IconButton, Checkbox, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../component/Button";
import switchprofilesp from "../../../assets/images/switchprofilesp.svg";
import rightarrowicon from "../../../assets/images/rightarrowicon.svg";
import { callSwitchProfileApi } from "../../../api/profile";
import { setProfileInfo } from "../../../store/slices/profileinfo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import radiogreen from "../../../assets/images/radioicongreen.svg";
import TermsandCondition from "../../../Signup/TermsandCondition";
import PrivacyPolicyInfo from "../../../Signup/PrivacyPolicyInfo";
import { routePaths } from "../../../Routes/route-paths";
import { getRole } from "../../../utils/auth-token-utils/cookies";

const SwitchProfileInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = getRole();

  const [isTermCondition, setIsTermCondition] = React.useState(false);
  const [terms, setTerms] = React.useState(false);
  const [policy, setPolicy] = React.useState(false);

  const handleSwitchProfile = async () => {
    const response = await callSwitchProfileApi();
    if (response.status === 200) {
      navigate("/");
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem("role", 0);
      dispatch(setProfileInfo({ screen: 1, userId: response?.data?.data?.userId }));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (
        (role == "0" && formik.isValid && formik.dirty && isTermCondition) ||
        (role == "1" && formik.isValid && formik.dirty)
      )
        handleClick();
    }
  };

  const handleClick = async () => {};

  const handleTerms = () => {
    if (role == "1") {
      setTerms(false);
      setPolicy(true);
    } else {
      setTerms(false);
    }
  };

  const handlePolicy = () => {
    setPolicy(false);
  };

  return (
    <Grid container sx={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Grid
        item
        xs={12}
        md={10}
        lg={9}
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          boxShadow: 3,
          display: { xs: "block", md: "flex" },
          overflow: "hidden",
          padding: { md: "2rem", lg: "5rem" },
        }}
      >
        <Grid item xs={12} md={4} sx={{ position: "relative" }}>
          <img
            src={switchprofilesp}
            alt="Service Provider"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            lineHeight: 1.5,
            p: 4,
          }}
        >
          <IconButton
            sx={{ color: "black", margin: "20px 0px" }}
            onClick={() => {
              handleSwitchProfile();
            }}
          >
            <img src={rightarrowicon} />
            <Box sx={{ marginLeft: "10px", fontSize: "18px", fontWeight: "bold", fontFamily: "Gotham,Montserrat" }}>
              Go Back
            </Box>
          </IconButton>
          <Typography variant="h5" gutterBottom fontWeight="bold" fontSize="32px">
            Become an Eventutti Partner
          </Typography>
          <Typography variant="body1" gutterBottom color="rgba(70, 70, 70, 0.5)" lineHeight="26px">
            Join our network and showcase your services to event creators seeking unique experiences.
          </Typography>

          <Grid sx={{ margin: "10px" }}>
            <Box display="flex" alignItems="flex-start">
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                checked={isTermCondition}
                onKeyDown={handleKeyPress}
                sx={{
                  padding: "0",
                  "& .MuiSvgIcon-root": {
                    borderRadius: "50%",
                    padding: "0px",
                  },
                }}
                icon={<img alt="" src={radiogreen} />}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
                onChange={(event) => {
                  setIsTermCondition(event.target.checked);
                }}
              />
              <Typography
                fontSize={{
                  xs: "12px",
                  sm: "14px",
                  md: "16px",
                  lg: "14px",
                  xl: "14px",
                }}
                variant="body1"
                sx={{
                  color: "#64748B",
                  fontWeight: "400",
                  textAlign: "Left",
                  paddingLeft: "10px",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                By tapping here you agree to our
                <span
                  to={routePaths.TERMS_AND_CONDITION}
                  style={{
                    cursor: "pointer",
                    color: "rgba(23, 186, 174, 1)",
                    fontWeight: "bold",
                    fontSize: "15px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  onClick={() => {
                    setTerms(true);
                  }}
                >
                  {` `}Terms & Conditions {` `}
                </span>
                & {` `}
                <span
                  underline="none"
                  to={routePaths.PRIVACY_POLICY}
                  style={{
                    cursor: "pointer",
                    color: "rgba(23, 186, 174, 1)",
                    fontWeight: "bold",
                    fontSize: "15px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  onClick={() => {
                    setPolicy(true);
                  }}
                >
                  Privacy Policy.
                </span>
              </Typography>
            </Box>
          </Grid>
          <Box sx={{ width: "50%", marginTop: "3rem" }}>
            <ButtonComp
              label=" Get Started"
              type="active"
              width="100%"
              disabled={!isTermCondition}
              onClick={() => {
                navigate("/create-profile", { state: { fromSwitchProfile: true } });
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <TermsandCondition fromMenu terms={terms} setTerms={setTerms} handleTerms={handleTerms} />
      <PrivacyPolicyInfo
        fromMenu
        policy={policy}
        setPolicy={setPolicy}
        setTerms={setTerms}
        handleClick={handleClick}
        handlePolicy={handlePolicy}
      />
    </Grid>
  );
};

export default SwitchProfileInfo;
