//service information page
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../component/BreadCrumb";
import ButtonComp from "../../../component/Button";
import ImagesView from "../../../component/ImagesView";
import { modalVariant } from "../../../component/Modal/Modalutils";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ShareIcon from "@mui/icons-material/Share";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  Typography,
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
} from "@mui/material";
import FitnessSymbol from "./aminites/FitnessSymbol";
import HotTub from "./aminites/HotTub";
import Parking from "./aminites/Parking";
import PetFriendly from "./aminites/PetFriendly";
import Pool from "./aminites/Pool";
import Wifi from "./aminites/Wifi";
import EditServiceModal from "./EditServiceModal";
import ImageModal from "./ImageModal";
import { getSx } from "./style";
import editicongreen from "../../../assets/images/editiconservice.png";
import locationgray from "../../../assets/images/locationgray.svg";
import searchicon from "../../../assets/images/searchicon.png";
import timinggreen from '../../../assets/images/timinggreen.png'
import usersthree from "../../../assets/images/usersthree.png";
import BookServiceModal from "../../eventorganiser/BookServiceModal";
import AddCardModal from "../../eventorganiser/BookServiceModal/PaymentModal.js/AddCardModal";
import PaymentInfoModal from "../../eventorganiser/BookServiceModal/PaymentModal.js/PaymentInfoModal";
import ShowTimingModal from "../../eventorganiser/BookServiceModal/PaymentModal.js/ShowTimingModal";
import { EventInfoData } from "../../eventorganiser/dashboard/EventInfo";
import ViewRating from "../../eventorganiser/dashboard/ViewRating";
import NavBar from "../../eventorganiser/home";
import {
  getServiceDetails,
  getBookServiceAmountApi, callRequestServiceApi
} from "../../../api/addService";
import { removeFavApi, addFavApi } from "../../../api/profile";
import { pushBreadCrums } from "../../../store/slices/breadcrumb";
import { showModal, showToast } from "../../../store/slices/common";
import { setFinalBookServiceInfo, setAddOnCategoryIds, setSelectedCategoryIds, setBookServiceDates, setServiceInfo } from "../../../store/slices/bookserviceinfo";
import { Container } from "@mui/system";
import { getCurrencyFormat } from "../../../common/constant";

const aminities = {
  Wifi: <Wifi />,
  Pool: <Pool />,
  "Pet Friendly": <PetFriendly />,
  Parking: <Parking />,
  "Hot tub": <HotTub />,
  Gym: <FitnessSymbol />,
};

const cancelPolicy = {
  Flexible:
    "Full refund for cancellations made for at least 7 days before the event data, Refund 50% for cancellations made between 3-6 days before the event",
  Moderate:
    "Full refund for cancellations made for at least 14 days before the event data, Refund 50% for cancellations made between 7-13 days before the event. No refund for cancellations made for 6 days or less before the event",
  Strict:
    "Refund 50% for cancellations made for at least 21 days before the event data, No refund for cancellations made 20 days or less before the event.",
  "Very Strict":
    "Refund 50% for cancellations made for at least 30 days before the event data, No refund for cancellations made 29 days or less before the event",
};

function ServiceInfoContent() {
  const [info, setInfo] = useState();
  const [readMore, setReadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [isAddOn, setAddOn] = useState(false);
  const [addOnServiceInfo, setAddOnServiceInfo] = useState();
  const [editServiceModal, setEditServiceModal] = useState(false);
  const [openBookServiceModal, setOpenBookServiceModal] = useState(false);
  const [openPaymentInfoModal, setOpenPaymentInfoModal] = useState(false);
  const [avail, setAvail] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [finalObject, setFinalObject] = useState({});
  const [requestLoading, setRequestLoading] = useState(false)
  const [addService, setAddService] = useState();
  const role = localStorage.getItem("role");
  const [showAmount, setShowAmount] = useState(false);
  const [descLength, setDescLength] = useState(300);
  const [amount, setAmount] = useState();
  const classes = getSx();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const id = queryParams.get("id");
  const milesAway = useSelector((state) => state.common.milesAway);
  const [bookingId, setBookingId] = useState();
  const bookingData = useSelector(
    (state) => state.bookserviceinfo.finalBookServiceInfo
  );
  const [isFav, setIsFav] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userIds = useSelector((state) => state.profile.userIds)
  const isRoleSwitched = useSelector((state) => state.profile.role)


  //after cancel request to clear the old data the function is called
  const handleCancelRequest = () => {
    setShowAmount(false)
    dispatch(setBookServiceDates({}));
    dispatch(setFinalBookServiceInfo(null));
    dispatch(setAddOnCategoryIds([]))
    dispatch(setSelectedCategoryIds([]))
  }

  //api call to get the service details
  const callApi = async () => {
    setLoading(true);
    const resp = await getServiceDetails(id);

    if (resp?.status == 200) {
      let uId = resp?.data?.data?.userId;
      if ((userIds?.includes(uId)) && role == "0")
        navigate("/")
      else {
        setInfo(resp?.data?.data);
        setIsFav(resp?.data?.data?.isFav);
        if (resp?.data?.data?.description.length > 300) {
          let desc = resp?.data?.data?.description.substring(300)
          let desc1 = desc.toString().indexOf('\n');
          if (desc1)
            setDescLength(desc1 + 300);
          else
            setDescLength(300);
        }
        if (role == "1")
          dispatch(
            pushBreadCrums([
              { icon: searchicon, name: "My Services", path: "/myservices" },
              { name: `${resp?.data?.data?.title}`, path: "/service-info" },
            ])
          );
        else
          dispatch(
            pushBreadCrums([
              { icon: searchicon, name: "Explore", path: "/" },
              { name: `${resp?.data?.data?.title}`, path: "/service-info" },
            ])
          );
      }

    }
    setLoading(false);
    localStorage.removeItem("selectedServiceId", "");
  };

  //api call to remove the service from favourites
  const callRemoveFavApi = async (id) => {
    const handleDispatch = async () => {
      const resp = await removeFavApi(id);
      if (resp?.status == 200) {
        setIsFav(false);
      }
    };
    dispatch(
      showModal({
        modalType: modalVariant.FAVOURITES_POPUP,
        resolveCallback: () => {
          handleDispatch();
          dispatch(showModal(null));
        },
        rejectCallback: () => {
          dispatch(showModal(null));
        },
      })
    );
  };

  //api call to add the service to favourites
  const callAddFavApi = async (id) => {
    if (token) {
      const resp = await addFavApi(id);
      if (resp?.status == 200) {
        setIsFav(true);
      }
    }
    else {
      handleBookServiceDispatch();
    }
  };

  //api call to request the service
  const handleRequest = async () => {
    setRequestLoading(true);
    const resp = await callRequestServiceApi({ ...finalObject, ...finalData });
    if (resp?.status == 200) {
      setBookingId(resp?.data?.data?._id);
      if (info?.isInstaBook) setOpenPaymentInfoModal(true);
      else
        dispatch(
          showModal({
            modalType: modalVariant.REQUEST_SERVICE_SUCCESS,
            resolveCallback: () => {
              dispatch(showModal(null));
              navigate("/event-dashboard?id=2");
            },
            rejectCallback: () => {
              dispatch(showModal(null));
            },
          })
        );
    }
    setRequestLoading(false);
  };
  //api call to get the amount of the service
  const callBookSserviceAmountApi = async (finalObj) => {
    setLoading(true);
    const resp = await getBookServiceAmountApi({ ...finalObj, parentServiceId: info?._id });
    if (resp?.status == 200) {
      setShowAmount(true);
      setAmount(resp?.data?.data);
      let val = {
        serviceId: info?._id,
        ...finalData,
        amountData: resp?.data?.data,
        serviceDetails: {
          ...finalObj,
          serviceName: info?.services[0]?.serviceId?.name,
          serviceTitle: info?.title,
          cancellationPolicy: info?.cancellationPolicy,
          serviceImages: info?.serviceImages,
        },
      };
      setFinalObject(val);
    }
    setLoading(false);
  };

  const handleBookServiceDispatch = () => {
    dispatch(
      showModal({
        modalType: modalVariant.EO_SIGN,
        resolveCallback: () => {
          navigate("/signup");
          dispatch(showModal(null));
        },
        rejectCallback: () => {
          navigate("/login");
          dispatch(showModal(null));
        }
      })
    );
  }
  //function to copy the link to clipboard
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(showToast({ msg: "Link copied sucessfully!", color: "success" }))
    } catch (err) {
      dispatch(showToast({ msg: "Try Again!", color: "error" }))
    }
  };

  //function to handle the copy click
  const handleCopyClick = () => {
    let url = `${process.env.REACT_APP_WEB_URL}service-info?id=${info?._id}`;
    copyToClipboard(url);
  };

  const handleBreadCrum = () => {
    setAddOn(false);
  };
  useEffect(() => {
    if (!editServiceModal) callApi();
  }, [editServiceModal, isRoleSwitched]);

  useEffect(() => {
    if (isAddOn) {
      role == "0"
        ? dispatch(
          pushBreadCrums([
            { icon: searchicon, name: "Explore", path: "/" },
            { name: `${info?.title}`, breadFunc: handleBreadCrum },
            { name: "Add-On Services", breadFunc: handleBreadCrum },
          ])
        )
        : dispatch(
          pushBreadCrums([
            { icon: searchicon, name: "My Services", path: "/myservices" },
            { name: `${info?.title}`, breadFunc: handleBreadCrum },
            { name: "Add-On Services", breadFunc: handleBreadCrum },
          ])
        );
    }
  }, [isAddOn]);

  useEffect(() => {
    if (bookingData) callBookSserviceAmountApi(bookingData);
  }, [bookingData]);

  return (
    <Box sx={{ width: "100%", padding: { xs: "15px 0 0", sm: "15px 0 0", md: '15px 0 0', lg: '15px 0 0', xl: '30px 0 0' } }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        showRating ? <ViewRating userId={info?.userId} setShowRating={setShowRating} /> :
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb />
              {role == "1" && !isAddOn && (
                <img alt=""
                  src={editicongreen}
                  style={{ width: "27px", height: "27px", cursor: "pointer" }}
                  onClick={() => {
                    setEditServiceModal(true);
                  }}
                />
              )}
              {role == "0" && (

                <Box>
                  {!isFav ?
                    <FavoriteBorderIcon
                      style={{ color: "red", marginRight: "2px", cursor: "pointer", fontFamily: "Montserrat, sans-serif" }}
                      onClick={() => { callAddFavApi(info?._id) }}
                    /> : <FavoriteOutlinedIcon sx={{ color: "red", cursor: 'pointer' }} onClick={() => { callRemoveFavApi(info?._id) }} />}
                  <ShareIcon style={{ color: "rgba(23, 186, 174,1)", cursor: "pointer" }} onClick={() => { handleCopyClick() }} />
                </Box>
              )}
            </Box>
            {!isAddOn ? (
              <>
                <Box
                  sx={{
                    height: { xs: "calc(100vh - 127px)", sm: 'calc(100vh - 166px)', md: 'calc(100vh - 166px)', lg: "calc(100vh - 166px)", xl: "calc(100vh - 191px)" },
                    overflow: "auto",
                    position: "relative",
                    paddingBottom:
                      role == "0" ? "65px" : 0
                  }}
                >

                  <ImagesView
                    info={info?.serviceImages}
                    setImageModal={setImageModal}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      sx={{
                        fontSize: { xs: "16px", sm: '18px', md: "20px", lg: '24px', xl: '32px' }, fontFamily: "Montserrat, sans-serif", marginBottom: {
                          xs: '5px',
                          sm: '10px',
                          md: '10px',
                          lg: '10px',
                          xl: '15px'
                        }
                      }}
                    >
                      {info?.title}
                    </Typography>
                    <Typography sx={{
                      display: "flex", fontFamily: 'Montserrat,sans-serif', fontSize: {
                        xs: '12px',
                        sm: '13px',
                        md: '14px',
                        lg: '15px',
                        xl: '16px'
                      },
                      alignItems: 'center'
                    }}>
                      {info?.isInstaBook && role == "1" && <Box sx={{

                        background:
                          "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
                        padding: {
                          xs: "5px 8px",
                          sm: "8px",
                          md: "8px",
                          lg: "8px",
                          xl: "8px",
                        },
                        borderRadius: "15px",
                        fontWeight: 700,
                        fontFamily: "Montserrat, sans-serif",
                        height: {
                          xs: '30px',
                          sm: '35px',
                          md: '38px',
                          lg: '40px', xl: '40px'
                        },
                        marginRight: "10px",
                        color: "#fff"
                      }}
                      >
                        Party Now</Box>}

                      {((role == "0" && info?.isInstaBook) || role == "1") && (
                        <Box
                          sx={{
                            background:
                              "linear-gradient(rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                            padding: {
                              xs: "5px 8px",
                              sm: "8px",
                              md: "8px",
                              lg: "8px",
                              xl: "8px",
                            },
                            borderRadius: "15px",
                            fontWeight: 700,
                            fontFamily: "Montserrat, sans-serif",
                            height: {
                              xs: '30px',
                              sm: '35px',
                              md: '38px',
                              lg: '40px', xl: '40px'
                            },
                          }}
                        >
                          {role == "0" && info?.isInstaBook && "Party Now"}
                          {role == "1" && info?.services[0]?.serviceId?.name}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      fontFamily: "Montserrat, sans-serif",
                      alignItems: "center",
                      flexWrap: "wrap",
                      color: "black",
                    }}
                    className="serviceaddinfo"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "0.5rem",
                        color: "rgba(26, 26, 26, 1)",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      <img alt="" src={locationgray} />
                      {role == "1" ? (
                        <span>
                          {" "}
                          {info?.address}, {info?.state}, {info?.zipCode}
                        </span>
                      ) : (
                        <span>
                          {milesAway}
                        </span>
                      )}
                      <span style={{ color: "gray", margin: "0px 1rem" }}>|</span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "0.5rem",
                      }}
                    >
                      <img alt="" src={usersthree} />
                      {info?.maximumGuestCapacity}
                      <span style={{ color: "gray", margin: "0px 1rem" }}>|</span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "0.5rem",
                        fontFamily: "Montserrat,sans-serif",
                        fontWeight: "bold",
                        cursor: "pointer"
                      }}
                      onClick={() => { setAvail(true) }}
                    >
                      <img alt="" src={timinggreen} />
                      <Box sx={{ color: "rgba(23, 186, 174, 1)", marginLeft: "2px" }}>Show Availability {'>'}</Box>
                      <span style={{ color: "gray", margin: "0 1rem" }}>|</span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontFamily: "Montserrat, sans-serif"
                      }}
                      onClick={() => {
                        if (!token)
                          navigate("/login")
                        if (role == 1)
                          navigate("/ratings-and-reviews")
                        else
                          setShowRating(true)
                      }}
                    >
                      {info?.averageRating?.toFixed(1)}
                      <StarRateIcon
                        style={{
                          color: "rgba(244, 181, 55, 1)",
                          marginLeft: "0.5rem",
                          fontSize: "18px",
                          fontFamily: "Montserrat, sans-serif"
                        }}
                      />
                      <AvatarGroup
                        max={4}
                        sx={{
                          marginLeft: "0.5rem",
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            fontSize: 16,
                          },
                        }}
                      >
                        {info?.ratings?.map((item) => {
                          return (
                            <Avatar alt="Remy Sharp" size={16} src={item?.userDetails?.profileImage?.url} />
                          )
                        })}

                      </AvatarGroup>
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      margin: {
                        xs: "10px 0 5px",
                        sm: "10px 0 5px",
                        lg: "10px 0 5px",
                        xl: "15px 0 5px",
                        md: "10px 0 5px"
                      },
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: {
                        xs: '14px',
                        sm: '15px',
                        md: '15px',
                        lg: '16px',
                        xl: '18px'
                      }
                    }}
                  >
                    Description
                  </Typography>
                  <Box
                    sx={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: "15px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      width: "100%",
                    }}
                    className="break-word"
                  >
                    {info?.description.substring(0, descLength).split("\n").map((item) => { return <p>{item}</p> })}

                    <span>  {info?.description?.length > 300 &&
                      (readMore ? (
                        info?.description?.substring(descLength).split("\n").map((item) => { return <p>{item}</p> })
                      ) : (
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setReadMore(true);
                          }}
                        >
                          Read More..
                        </span>
                      ))}
                    </span>
                  </Box>
                  {!showAmount && (
                    <>
                      {info?.amenities?.length > 0 && (
                        <Typography
                          variant="h6"
                          sx={{
                            color: "black",
                            fontWeight: "bold",
                            margin: {
                              xs: "10px 0 5px",
                              sm: "10px 0 5px",
                              lg: "10px 0 5px",
                              xl: "15px 0 5px",
                              md: "10px 0 5px"
                            },
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: {
                              xs: '14px',
                              sm: '15px',
                              md: '15px',
                              lg: '16px',
                              xl: '18px'
                            }
                          }}
                        >
                          Amenities{" "}
                        </Typography>
                      )}

                      <Box sx={{ display: "flex", overflow: "auto" }}>
                        {info?.amenities?.map((item) => {
                          return (
                            <Box
                              sx={{
                                border: "1px solid rgba(218, 218, 218, 1)",
                                borderRadius: "10px",
                                padding: "15px",
                                display: "flex",
                                alignItems: "center",
                                width: "170px",
                                marginRight: "10px",
                              }}
                              key={item}
                            >
                              {aminities[item]}
                              <span
                                style={{ marginLeft: "5px", fontWeight: 600, fontFamily: "Montserrat, sans-serif", }}
                              >
                                {item}
                              </span>
                            </Box>
                          );
                        })}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          margin: {
                            xs: "10px 0 5px",
                            sm: "10px 0 5px",
                            lg: "10px 0 5px",
                            xl: "15px 0 5px",
                            md: "10px 0 5px"
                          },
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: {
                            xs: '14px',
                            sm: '15px',
                            md: '15px',
                            lg: '16px',
                            xl: '18px'
                          }
                        }}
                      >
                        Service Categories{" "}
                      </Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {info?.services[0]?.categories?.map((item) => {
                          return (
                            <Box
                              sx={{
                                border: "1px solid rgba(218, 218, 218, 1)",
                                borderRadius: "18px",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                margin: { xs: "10px 0px", sm: "5px" },
                                maxWidth: "300px",
                              }}
                              key={item?.categoryId?.categoryName}
                            >
                              <Box sx={{ width: "80px", height: "70px" }}>
                                <img alt=""
                                  src={item?.categoryId?.displayImage?.fileUrl}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "15px",
                                    objectFit: 'cover'
                                  }}
                                />
                              </Box>
                              <Box sx={{ paddingLeft: '10px' }}>
                                <Box
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    fontFamily: "Montserrat, sans-serif",
                                  }}
                                >
                                  {item?.categoryId?.categoryName}
                                </Box>
                                <Box
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    fontFamily: "Montserrat, sans-serif",
                                  }}
                                >
                                  {getCurrencyFormat(item?.field2["value"])}{" "}
                                  <span
                                    style={{ color: "gray", fontWeight: "500", fontFamily: "Montserrat, sans-serif", }}
                                  >
                                    /{item?.field1["value"]}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      {info?.services?.length > 1 && (
                        <Typography
                          variant="h6"
                          sx={{
                            color: "black",
                            fontWeight: "bold",
                            margin: {
                              xs: "10px 0 5px",
                              sm: "10px 0 5px",
                              lg: "10px 0 5px",
                              xl: "15px 0 5px",
                              md: "10px 0 5px"
                            },
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: {
                              xs: '14px',
                              sm: '15px',
                              md: '15px',
                              lg: '16px',
                              xl: '18px'
                            }
                          }}
                        >
                          Add-on Services{" "}
                        </Typography>
                      )}
                      <Box
                        sx={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                      >
                        {info?.services?.slice(1).map((item) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                border: "1px solid rgb(218,218,218)",
                                padding: "10px",
                                borderRadius: "15px",
                                margin: { xs: "10px 0px", sm: "5px" },
                                width: { xs: "100%", md: "40%" },
                                height: "100px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setAddOnServiceInfo(item);
                                setAddOn(true);
                              }}
                              key={item?.serviceId?.name}
                            >
                              <Box sx={{}}>
                                <img alt=""
                                  src={item?.addOnServiceImages[0]?.fileUrl}
                                  style={{
                                    width: "110px",
                                    height: "80px",
                                    borderRadius: "13px",
                                    objectFit: 'cover'
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{ ...classes.listcat, marginLeft: "10px" }}
                              >
                                <Typography
                                  variant="body1"
                                  marginTop="10px"
                                  sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    margin: {
                                      xs: "10px 0 5px",
                                      sm: "10px 0 5px",
                                      lg: "10px 0 5px",
                                      xl: "15px 0 5px",
                                      md: "10px 0 5px"
                                    },
                                    fontFamily: "Montserrat, sans-serif",
                                    fontSize: {
                                      xs: '14px',
                                      sm: '15px',
                                      md: '15px',
                                      lg: '16px',
                                      xl: '18px'
                                    }
                                  }}
                                >
                                  {item?.serviceId?.name}
                                </Typography>
                                <Box sx={classes.catlist}>
                                  {item?.categories?.map((item1) => {
                                    return (
                                      <div
                                        style={{
                                          margin: "5px",
                                          borderRadius: "10px",
                                          padding: "3px 3px",
                                          fontSize: "12px",
                                          fontFamily: "Montserrat, sans-serif",
                                          background:
                                            "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                                        }}
                                        key={item1?.categoryId?.categoryName}
                                      >
                                        {item1?.categoryId?.categoryName}
                                      </div>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          margin: {
                            xs: "10px 0 5px",
                            sm: "10px 0 5px",
                            lg: "10px 0 5px",
                            xl: "15px 0 5px",
                            md: "10px 0 5px"
                          },
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: {
                            xs: '14px',
                            sm: '15px',
                            md: '15px',
                            lg: '16px',
                            xl: '18px'
                          }
                        }}
                      >
                        Cancellation Policy{" "}
                        <span style={{ fontWeight: "500", fontFamily: "Montserrat, sans-serif", }}>
                          ({info?.cancellationPolicy})
                        </span>
                      </Typography>
                      <Box
                        sx={{
                          color: "rgba(0, 0, 0, 0.5)",
                          fontFamily: "Montserrat,sans-serif",
                          fontSize: "15px",
                          marginBottom: "3rem",
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                            lg: '50%',
                            xl: '50%'
                          }
                        }}
                      >
                        {cancelPolicy[`${info?.cancellationPolicy}`]}
                      </Box>
                    </>
                  )}
                  {showAmount && bookingData != null && (
                    <EventInfoData
                      bookingData={bookingData}
                      info={info}
                      setOpenBookServiceModal={setOpenBookServiceModal}
                      amount={amount}
                    />
                  )}
                </Box>
                {role != "1" &&
                  (showAmount && role == "0" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: {
                          xs: 'space-between',
                          sm: "flex-end",
                          md: "flex-end",
                          lg: "flex-end",
                          xl: "flex-end"
                        },
                        fontSize: { xs: "12px", md: "18px" },
                        fontFamily: "Montserrat, sans-serif",
                        width: { xs: "calc(100% - 30px)", sm: '30%', md: "25%", lg: '20%', xl: '15%' },
                        position: 'fixed',
                        bottom: {
                          xs: '30px',
                          sm: '45px',
                          md: '45px',
                          lg: '45px',
                          xl: '45px'
                        },
                        right: {
                          xs: '15px',
                          sm: '30px',
                          md: '30px',
                          lg: '30px',
                          xl: '175px'
                        },
                        zIndex: 999,
                        margin: {
                          xs: 'auto',
                          sm: 0,
                          md: 0,
                          lg: 0,
                          xl: 0
                        }
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          float: "right",
                          alignItems: "center",
                          justifyContent: {
                            xs: 'space-between',
                            sm: 'flex-end',
                            md: 'flex-end',
                            lg: 'flex-end',
                            xl: 'flex-end'
                          },
                          width: {
                            xs: '100%',
                            sm: 'auto',
                            md: 'auto',
                            lg: 'auto'
                          }
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span
                            style={{
                              color: "black",
                              fontWeight: "600",
                              marginRight: "10px",
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            Total
                          </span>
                          <span
                            style={{
                              color: "rgba(23, 186, 174, 1)",
                              marginRight: "10px",
                              fontWeight: "600",
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >

                            {getCurrencyFormat(amount?.totalAmount.toFixed(2))}
                          </span>
                        </Box>

                        <Box
                          sx={{
                            width: { xs: "200px", md: "250px", lg: "350px" },
                            color: "#FFFFFF",
                            background:
                              "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
                            padding: "10px",
                            borderRadius: "23px",
                            fontSize: { xs: "15px", md: "19px" },
                            textAlign: "center",
                            cursor: "pointer",
                            height: { xs: "40px", md: "50px" }
                          }}
                          onClick={() => {
                            handleRequest();
                          }}
                        >
                          {requestLoading ? <CircularProgress size={30} style={{ color: "#fff" }} /> : "Request Service(s)"}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="fixed-btn-div"
                      sx={{
                        width: { xs: "calc(100% - 30px)", sm: '30%', md: "25%", lg: '20%', xl: '15%' },
                        position: 'fixed',
                        bottom: {
                          xs: '30px',
                          sm: '45px',
                          md: '45px',
                          lg: '45px',
                          xl: '45px'
                        },
                        right: {
                          xs: '15px',
                          sm: '30px',
                          md: '30px',
                          lg: '30px',
                          xl: '175px'
                        },
                        zIndex: 999,
                        margin: {
                          xs: 'auto',
                          sm: 0,
                          md: 0,
                          lg: 0,
                          xl: 0
                        }
                      }}
                    >
                      <ButtonComp
                        label="Book Service"
                        type="active"
                        width="100%"
                        onClick={() => {
                          if (token) {
                            setOpenBookServiceModal(true);
                            dispatch(setServiceInfo(info));
                          }
                          else {
                            handleBookServiceDispatch();
                            localStorage.setItem("selectedServiceId", id)
                          }
                        }}
                      />
                    </Box>
                  ))}
              </>
            ) : (
              <>
                <Box
                  sx={{ height: { xs: "73vh", xl: "70vh" }, overflowY: "scroll" }}
                >
                  <ImagesView
                    info={addOnServiceInfo?.addOnServiceImages}
                    setImageModal={setImageModal}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "10px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      sx={{
                        fontSize: { xs: "16px", sm: '18px', md: "20px", lg: '24px', xl: '32px' }, fontFamily: "Montserrat, sans-serif", marginBottom: {
                          xs: '5px',
                          sm: '10px',
                          md: '10px',
                          lg: '10px',
                          xl: '15px'
                        }
                      }}
                    >
                      {addOnServiceInfo?.serviceId?.name}
                    </Typography>
                    <Box
                      sx={{
                        background:
                          "linear-gradient(rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                        padding: "8px",
                        borderRadius: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {addOnServiceInfo?.serviceId?.name}
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      margin: {
                        xs: "10px 0 5px",
                        sm: "10px 0 5px",
                        lg: "10px 0 5px",
                        xl: "15px 0 5px",
                        md: "10px 0 5px"
                      },
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: {
                        xs: '14px',
                        sm: '15px',
                        md: '15px',
                        lg: '16px',
                        xl: '18px'
                      }
                    }}
                  >
                    Description
                  </Typography>
                  <Box
                    sx={{
                      color: "rgba(0, 0, 0, 0.5)",
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: "15px",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      width: "100%",
                    }}
                  >
                    {addOnServiceInfo?.description.substring(0, 300).split("\n").map((item) => { return <p>{item}</p> })}
                    {addOnServiceInfo?.description?.length > 300 &&
                      (readMore ? (
                        addOnServiceInfo?.description?.substring(301).split("\n").map((item) => { return <p>{item}</p> })
                      ) : (
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setReadMore(true);
                          }}
                        >
                          Read More..
                        </span>
                      ))}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      margin: {
                        xs: "10px 0 5px",
                        sm: "10px 0 5px",
                        lg: "10px 0 5px",
                        xl: "15px 0 5px",
                        md: "10px 0 5px"
                      },
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: {
                        xs: '14px',
                        sm: '15px',
                        md: '15px',
                        lg: '16px',
                        xl: '18px'
                      }
                    }}
                  >
                    Service Categories{" "}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {addOnServiceInfo?.categories?.map((item) => {
                      return (
                        <Box
                          sx={{
                            border: "1px solid rgba(218, 218, 218, 1)",
                            borderRadius: "18px",
                            padding: "15px",
                            display: "flex",
                            alignItems: "center",
                            margin: "5px",
                            maxWidth: "300px",
                          }}
                        >
                          <Box sx={{ width: "80px", height: "70px" }}>
                            <img alt=""
                              src={item?.categoryId?.displayImage?.fileUrl}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "15px",
                              }}
                            />
                          </Box>
                          <Box sx={{
                            paddingLeft: '10px'
                          }}>
                            <Box
                              style={{
                                fontWeight: 600,
                                fontSize: "15px",
                                fontFamily: "Montserrat, sans-serif",
                              }}
                            >
                              {item?.categoryId?.categoryName}
                            </Box>
                            <Box
                              style={{
                                fontWeight: 600,
                                fontSize: "12px",
                                fontFamily: "Montserrat, sans-serif",
                              }}
                            >
                              {getCurrencyFormat(item.field2["value"])}{" "}
                              <span style={{ color: "gray", fontWeight: "500" }}>
                                /{item?.field1["value"]}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </>
            )}
          </Box>
      )}
      {avail && (<ShowTimingModal avail={avail} setAvail={setAvail} timing={info?.serviceTiming} />)}
      {imageModal && (
        <ImageModal
          setImageModal={setImageModal}
          imageModal={imageModal}
          serviceImages={
            isAddOn ? addOnServiceInfo?.addOnServiceImages : info?.serviceImages
          }
        />
      )}
      {editServiceModal && (
        <EditServiceModal
          editServiceModal={editServiceModal}
          setEditServiceModal={setEditServiceModal}
          addService={addService}
          setAddService={setAddService}
          serviceLoading={loading}
          setServiceLoading={setLoading}
          callUpdateApi={callApi}
        />
      )}
      {openBookServiceModal && (
        <BookServiceModal
          openBookServiceModal={openBookServiceModal}
          info={{ ...info }}
          setOpenBookServiceModal={setOpenBookServiceModal}
          callBookSserviceAmountApi={callBookSserviceAmountApi}
          showAmount={showAmount}
          setFinalData={setFinalData}
        />
      )}
      {openPaymentInfoModal && (
        <PaymentInfoModal
          openPaymentInfoModal={openPaymentInfoModal}
          setOpenPaymentInfoModal={setOpenPaymentInfoModal}
          finalData={finalObject.amountData}
          bookingId={bookingId}
          setOpenCardModal={setOpenCardModal}
          handleCancelRequest={handleCancelRequest}
        />
      )}
      {openCardModal && (
        <AddCardModal
          openCardModal={openCardModal}
          setOpenPaymentInfoModal={setOpenPaymentInfoModal}
          setOpenCardModal={setOpenCardModal}
        />
      )}



    </Box>
  );
}

export function ServiceInfo() {
  return (
    <NavBar>
      <Container sx={{
        padding: {
          xs: '0 15px',
          sm: '0 30px',
          md: '0 30px',
          lg: '0 30px',
          xl: 0
        }
      }} maxWidth="xl">
        <ServiceInfoContent />
      </Container>
    </NavBar>
  );
}
