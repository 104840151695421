
export const getSx = () => {
  return {
    main: {
      width: '100%',
      height: "auto",
      margin: 0,
      padding:"15px 0 0"
    },
    messagecontainer: {
      display: "flex",
      height: "99%",
      width: "100%",
    },
    messagecontent1: {
      flex: { xs: "2", sm:"1.5",md: "2",lg:"1.2" },
    },
    messagecontent2: {
      flex: "4",
      marginLeft: "10px",
      border: "2px solid #ECECEE",
      borderRadius: "8px",
      padding: "5px 15px 15px 15px",
      position: "relative",
    },
    singlechatinfo: {
      display: "flex",
      flexDirection: { xs: "row", md: "row" },
      
    },
    sidechat: {
      height: {
        xs:"calc(100vh - 220px)",
        sm:"calc(100vh - 220px)",
        md:"calc(100vh - 220px)",
        lg:"calc(100vh - 220px)",
        xl:"calc(100vh - 220px)"
      },
      overflowY: "auto",
    },
    sidechatuseractive: {
      border: "2px solid rgba(23, 186, 174, 1)",
    },
    sidechatuser: {
      display: "flex",
      border: "2px solid #ECECEE",
      marginBottom: "10px",
      borderRadius: "15px",
      padding: "15px",
      fontSize: {xs:"9px",md:"12px"},
      position: "relative",
      cursor: "pointer",
      flexDirection: { xs: "row", md: "row" },
      fontFamily:"Montserrat, sans-serif"
    },
    profileimg: {
      marginRight: "10px",
      width:{xs:"41px",md: "45px"},
       height: {xs:"30px",md: "45px"},
       display:{xs:"none",sm:"block"},
    },
    img: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
    },
    profileinfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    profilename: {
      color: "#000000",
      fontWeight: 600,
      fontSize: {xs:"14px",md:"16px"},
      fontFamily:"Montserrat, sans-serif"
    },
    profilemsg: {
      color: "#A1A1AA",
      fontWeight: 500,
      fontSize: {xs:"15px",md:"13px"},
      fontFamily:"Montserrat, sans-serif"
    },
    readinfo: {
      position: "absolute",
      right: "1rem",
      top: {xs:"0.5rem",md:"1rem"},
      backgroundColor: "#DB524E",
      width: {xs:"15px",md:"20px"},
      height: {xs:"15px",md:"20px"},
      borderRadius: "50%",
      color: "#F3F5F7",
      fontSize: {xs:"14px",md:"13px"},
      textAlign: "center",
      fontFamily:"Montserrat, sans-serif"
    },
    profiletime: {
      display: "flex",
      alignItems: "flex-end",
    },
    time: {
      position: "absolute",
      right: "1rem",
      bottom: {xs:"3px",md:"6px",lg:"20px"},
    },

    messageinfo: {
      height: {xs:"calc(100vh - 320px)",sm:"calc(100vh - 320px)",md:"calc(100vh - 320px)",lg:"calc(100vh - 320px)",xl:"calc(100vh - 320px)"},
      overflow:'auto'
    },
    messageheading: {
      display: "flex",
      paddingBottom: "5px",
      borderBottom: "2px solid #ECECEE",
      alignItems: "center",
      height: "70px",
    },
    messageimg: {
      img: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        marginRight: "10px",
        marginTop: "11px",
      },
    },
    messagename: {
      color: "#000000",
      fontWeight: 600,
      fontFamily:"Montserrat, sans-serif"
    },
    messagechat: {
      height: "calc(100% - 98px)",
      overflowY: "auto",
      marginBottom: "10px",
    },
    messageinfocontent: {
      marginTop: "10px",
    },
    messagecontent: {
      display: "flex",
      alignItems: "center",
    },
    userimg: {
      img: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
      },
    },
    userinfo: {
      display: "flex",
      flexDirection: "column",
      minWidth:{
        xs:'auto',sm:'auto',md:'auto',lg:'auto',xl:'auto'
      },
      maxWidth:{
        xs:'auto',sm:'50%',md:'40%',lg:'30%',xl:'30%'
      }
    },
    usertimename: {
      display: "flex",
      justifyContent: "flex-end",
    },
    usertime: {
      color: "#A1A1AA",
      fontSize: "12px",
      fontFamily:"Montserrat, sans-serif"
    },
    user: {
      fontSize: "12px",
      marginLeft: "10px",
      fontFamily:"Montserrat, sans-serif"
    },
    usercontent: {
      marginLeft: "10px",
      backgroundColor: "#F3F5F7",
      borderRadius: "20px 20px 20px 0px",
      padding: "10px",
      fontSize: "14px",
      height: "fit-content",
      wordBreak: "break-word",
      fontFamily:"Montserrat, sans-serif"
    },
    usercontent1: {
      backgroundColor: "#19B792",
      borderRadius: "20px 20px 20px 0px",
      padding: "10px",
      fontSize: "14px",
      fontFamily:"Montserrat, sans-serif",
      color: "#ECECEE",
    },
    usercontent3: {
      backgroundColor: "#FFF !important",
    },
    usercontent2: {
      fontSize: "12px",
      display: "flex",
      alignItems: "flex-start",
      paddingLeft: "10px",
      fontFamily:"Montserrat, sans-serif",
      justifyContent:'space-between',
      width:{
        xs:'100%',
        sm:'220px',
        md:'220px',
        lg:'250px',
        xl:'220px'
      }
    },
    username: {
      color: "black",
      fontWeight: 600,
      marginRight: "10px",
      fontFamily:"Montserrat, sans-serif",
      display:'inline-block',
      whiteSpace:'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '48%'

    },
    fileinput: {
      display: "none !important",
    },
    input: {
      height: "45px",
      borderRadius: "20px",
    },
    inputtag: {
      position: "relative",
    },
    sendbtnimg:{
      position: "absolute",
       right:0 ,
        top: 0,
        padding:"10px 8px 10px 10px ",
        cursor:"pointer"
    }
  };

};
