/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography, Box, CircularProgress } from "@mui/material";
import { getSx } from "./style";
import notificationempty from "../../../assets/images/notificationempty.png";
import handleScroll from "../../../common/handleScroll";
import NavBar from "../home";
import dayjs from "dayjs";
import {
  getNotificationListApi,
  callReadNotificationApi,
  callSingleReadNotificationApi,
} from "../../../api/notifications";
import { Container, useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { showToast } from "../../../store/slices/common";

function formatTime(date, smScreen) {
  const now = new Date();
  const givenDate = new Date(date);
  const differenceInMs = now - givenDate;
  const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
  const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  if (differenceInMinutes < 60) {
    return differenceInMinutes < 1 ? "Just now" : `${differenceInMinutes}m`;
  } else if (differenceInHours < 24) {
    return differenceInHours === 1 ? "1h" : `${differenceInHours}h`;
  } else if (differenceInDays === 1) {
    return smScreen ? "Yest" : "Yesterday";
  } else if (differenceInDays < 7) {
    const dayOfWeek = givenDate.toLocaleDateString("en-US", {
      weekday: smScreen ? "short" : "long",
    });
    return `${dayOfWeek}`;
  } else if (differenceInDays == 7) {
    return "1w";
  } else {
    const day = givenDate.getDate();
    const month = givenDate.toLocaleDateString("en-US", { month: "long" });
    const year = givenDate.getFullYear();
    return smScreen ? dayjs(givenDate).format("MM/DD/YY") : `${month} ${day}, ${year}`;
  }
}

const NotificationInfo = () => {
  const classes = getSx();
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const role = localStorage.getItem("role");
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    callApi();
  }, [page]);

  const handleSetPage = () => {
    setPage(notificationList?.length / 10 + 1);
  };

  const callApi = async () => {
    setLoading(true);
    const response = await getNotificationListApi(page);
    await callReadNotificationApi();
    if (response.status === 200) {
      if (page == 1) setNotificationList(response.data.data);
      else setNotificationList([...notificationList, ...response.data.data]);
    }
    setLoading(false);
  };

  const handleNavigation = async (item) => {
    if (!item?.isDetailsViewed) {
      callSingleReadNotificationApi(item?._id);
    }
    if (
      item.type == "SERVICE_UPCOMING" ||
      item.type == "BOOKING_ACCEPTED" ||
      item.type == "SERVICE_CONFIRMATION" ||
      item.type == "SERVICE_APPLICATION" ||
      item.type == "SERVICE_BOOKING_ACCEPTED" ||
      (item.type == "BOOKING_CANCELLED" && role == "1") ||
      (item.type == "ACCEPTED_BOOKING_CANCELLED" && role == "1")
    ) {
      if (
        dayjs(item.startDate).format("MM/DD/YYYY") < dayjs(new Date()).format("MM/DD/YYYY") &&
        item.type == "SERVICE_APPLICATION"
      )
        dispatch(
          showToast({
            color: "error",
            msg: "Service date expired",
          })
        );
      else {
        navigate(`/request-info?id=${item?.linkId}`, { state: { from: "notification" } });
      }
    } else if (item.type == "RATING") {
      navigate(`/ratings-and-reviews`, { state: { id: item?.linkId } });
    } else if (item.type == "MESSAGE") {
      let value = {
        firstName: item?.sender?.firstName,
        lastName: item?.sender?.lastName,
        profileImage: item.sender?.profileImage,
        _id: item.sender?._id,
      };

      navigate("/messages", {
        state: {
          val: value,
        },
      });
    } else if (item.type == "BOOKING_REJECTED" || (item.type == "BOOKING_CANCELLED" && role == "0")) {
      navigate(`/`);
    }
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "15px 0",
          sm: "15px 0",
          md: "15px 0",
          lg: "15px 0",
          xl: "30px 30px",
        },
      }}
    >
      <Typography
        variant="h5"
        fontWeight="700"
        sx={{
          fontSize: {
            xs: "20px",
            sm: "22px",
            md: "24px",
            lg: "25px",
            xl: "25px",
          },
          color: "#111111",
          marginBottom: {
            xs: "10px",
            sm: "10px",
            md: "10px",
            lg: "15px",
            xl: "24px",
          },
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        Notification
      </Typography>
      {loading && page == 1 ? (
        <Box
          sx={{
            height: "50vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : notificationList?.length == 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            flexDirection: "column",
          }}
        >
          <img alt="" src={notificationempty} style={{ width: "185px", height: "185px" }} />
          <Typography sx={{ fontFamily: "Montserrat, sans-serif" }} variant="h5" fontWeight="bold" margin="10px 0px">
            You don't have any Notifications yet!
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={classes.main}
            ref={listInnerRef}
            onScroll={() => {
              handleScroll(listInnerRef, notificationList?.length, handleSetPage);
            }}
          >
            {notificationList.map((item) => {
              if (item.type == "ACCEPTED_BOOKING_CANCELLED") {
                console.log(item, "item");
              }
              return (
                <Box
                  key={item?._id}
                  sx={
                    !item?.isDetailsViewed
                      ? classes.singlenot
                      : {
                          ...classes.singlenot,
                          backgroundColor: "#fff",
                          border: "1px solid rgba(221, 226, 255, 1)",
                        }
                  }
                  onClick={() => {
                    handleNavigation(item);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={classes.notifcationimg}>
                        <img
                          alt=""
                          src={item?.sender?.profileImage?.url}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "11px",
                            objectFit: "cover",
                            aspectRatio: "1/1",
                          }}
                        />
                      </Box>
                      <Box sx={classes.notifcationinfo}>{item?.message}</Box>
                    </Box>
                    <Box>
                      <Box sx={classes.time}>{formatTime(item?.createdAt, smScreen)}</Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          {loading && page >= 1 && (
            <Box
              sx={{
                height: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
const Notification = () => {
  return (
    <NavBar>
      <Container
        sx={{
          padding: {
            xs: "0 15px",
            sm: "0 30px",
            md: "0 30px",
            lg: "0 30px",
            xl: 0,
          },
        }}
        maxWidth="xl"
      >
        <NotificationInfo />
      </Container>
    </NavBar>
  );
};
export default Notification;
