import React from "react";

import { Typography, styled, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import showavailability from "../../../../assets/images/showavailability.png";
import crossicon from "../../../../assets/images/svg/crossicon.svg";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "500px",
    padding: "30px",
    borderRadius: "20px",
    height: "auto",
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));

const showTimingModal = ({ avail, setAvail, timing }) => {
  return (
    <CustomDialog
      open={avail}
      keepMounted
      sx={{
        "& .MuiPaper-root": { maxWidth: "700px", fontFamily: "Montserrat, sans-serif" },
      }}
    >
      <DialogContent sx={{ padding: {
        xs:"0",
          sm:"0",
          md:"0",
          lg:"0",
          xl:"0"
        } }}>
        <img
          src={crossicon}
          alt="crossicon"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1.5rem",
            cursor: "pointer",
          }}
          className="crossicontiming"
          onClick={() => setAvail(false)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={showavailability}
            alt="showavailability"
            style={{ width: "50px", height: "50px" }}
          />
          <Typography
            variant="h5"
            sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000000",
                margin:"15px 0 20px",
                fontFamily:"Montserrat, sans-serif"
          }}
          >
            Availability
          </Typography>
          <Box sx={{ minHeight: {
                xs: "auto",
                sm: 'auto',
                md: 'auto',
                lg: 'calc(100vh - 250px)',
                xl: "calc(100vh - 700px)"
            },maxHeight:{
              xs: "calc(100vh - 400px)",
                sm: 'calc(100vh - 400px)',
                md: 'calc(100vh - 350px)',
                lg: 'calc(100vh - 250px)',
                xl: "calc(100vh - 700px)"
              }, overflow: "auto",width:'100%' }}>
          {/* .filter(([day, details]) => details.isAvailable) */}
          {timing && Object.entries(timing)
        .map(([day, details]) => (
          <Box
            key={day}
            sx={{ display: "flex", justifyContent: "space-between", marginBottom: 1,width:"100%",background:"rgba(26, 26, 26, 0.1)",padding:"10px",borderRadius:"15px" ,fontSize:{xs:"12px",sm:"15px"}}}
          >
            <Box >{day.charAt(0).toUpperCase() + day.slice(1)}</Box>
           {details.isAvailable?<Box sx={{color:"rgba(23, 186, 174, 1)"}}>{details.startTime} - {details.endTime}</Box>:<Box  sx={{color:"red"}}>Not Available</Box>}
          </Box>
        ))}
        </Box>
        
         
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default showTimingModal;
