import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import ButtonComp from "../../../../component/Button";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PaperCard from "./PaperCard";
import CloudArrowUp from "../../../../assets/images/CloudUpload.png";
import { getSx } from "../style";

import { uploadImageApi } from "../../../../api/Signup";
import { showToast } from "../../../../store/slices/common";

const DropZone = styled("div")(({ theme }) => ({
    width: {
        xs: "100%", sm: "100%", md: "100%", xl: "600px", lg: "600px",
    },
    height: "17vh",
    border: `2px dashed rgba(41, 234, 165, 1)`,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(23, 186, 174, 0.1)",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "12px", // Rounded border
}));

const UploadBusinessFile = ({
    data,
    setValue,
    setPage,
    fileNames,
    setFileNames,
    filesArr,
    setFilesArr,
    handleData,
    isAPIResponseReceived,
    setIsAPIResponseReceived,
    setSelected,
    setCheckedValues,
}) => {
    const [loading, setLoading] = React.useState(false);
    const [sliderVal, setSliderVal] = useState([]);
    const dispatch = useDispatch();
    const [timeData, setTimeData] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        if (fileNames.length + acceptedFiles.length > 10) {
            dispatch(showToast({
                color: "error", msg: "You can upload a maximum of 10 files.",
            }));
            return;
        }
        acceptedFiles.forEach((item) => {
            if (validateFileExtension(item?.name)) {
                const file = item;
                setFilesArr((prev) => [...prev, item]);
                Upload(file);
            } else {
                dispatch(showToast({
                    color: "error", msg: "Please Upload Valid Document in png/jpg/pdf format",
                }));
            }
        });
    }, [fileNames.length]);

    const Upload = async (file) => {
        setLoading(true);
        const filedata = new FormData();
        filedata.append("file", file);
        const response = await uploadImageApi(filedata);
        setFileNames((prev) => [...prev, { key: response?.data?.key, url: response?.data?.fileUrl },]);
        setIsAPIResponseReceived((prev) => [...prev, true]);
        setLoading(false);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop, maxFiles: 20, accept: ".pdf, image/*",
    });

    const onDelete = (currIndex) => {
        const resp = filesArr.filter((item, index) => index != currIndex);
        setFilesArr(resp);
        setFileNames(resp);
        let val = isAPIResponseReceived;
        val?.length > 0 && val.splice(currIndex, 1);
        setIsAPIResponseReceived(val);
    };

    function validateFileExtension(fileName) {
        const allowedExtensions = ["pdf", "png", "jpg", "jpeg", "gif"];
        const fileExtension = fileName.split(".").pop().toLowerCase();
        return allowedExtensions.indexOf(fileExtension) !== -1;
    }

    const classes = getSx();
    useEffect(() => {
        setValue(80);
    }, []);

    return (<Typography
        component="div"
        variant="div"
        width="90%"
        margin="auto"
    >
        <Typography
            sx={{
                ...classes.heading,
            }}
        >
            Start your Eventutti Partner Journey Now!
        </Typography>
        <Box sx={{ ...classes.createFormMain, marginTop: { xs: '30px', lg: "15px", xl: "30px" } }} >
            <Typography
                sx={{
                    color: "rgba(0, 0, 0, 0.5)",
                    fontSize: { xs: "12px", md: "14px", xl: "15px" },
                    fontWeight: "500",
                    fontFamily: "Poppins, sans-serif !important",
                    marginBottom: "4px",
                }}
                textAlign="first"
            >
                <Box className="scroll-show-div" sx={{
                    height: { xs: "9vh", lg: "9vh", xl: "9vh", xxl: "15vh" },
                    overflowY: "auto",
                    lineHeight: "15px",
                    marginBottom: '15px'
                }}>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}> Please upload the following Documents:</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>Government ID/ Passport</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>Copy IRS Employer Identification Number (EIN)/ If Applicable</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>W-9, click here to download </Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>(<a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target="_blank"
                        rel="noopener noreferrer">https://www.irs.gov/pub/irs-pdf/fw9.pdf</a>)</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>Any applicable business licenses</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>Proof of address</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: "#1A1A1A",
                        fontFamily: "Montserrat , sans-serif",
                        marginBottom: '5px'
                    }}>Note: Please ensure all documents are uploaded before continuing. This will expedite your
                        verification process.</Typography>
                </Box>
            </Typography>
            {!loading ? (<DropZone {...getRootProps()}>
                <>
                    <Stack
                        direction={"column"}
                        className={"uploadlicence"}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: loading ? "not-allowed" : "pointer",
                        }}
                    >
                        <Box
                            sx={{
                                cursor: filesArr?.length > 10 ? "not-allowed" : "pointer",
                            }}
                        >
                            <img
                                alt=""
                                src={CloudArrowUp}
                                style={{ height: "48px", objectFit: "contain" }}
                            />
                            <input
                                {...getInputProps()}
                                multiple
                                style={{ display: "none" }}
                                accept="image/*, .pdf"
                            />
                            <Typography
                                variant="h5"
                                textAlign="center"
                                fontSize="16px"
                                fontWeight="500"
                                lineHeight="130%"
                                color="#A7A7A7"
                                fontFamily="Montserrat, sans-serif !important"
                            >
                                Upload Document
                            </Typography>
                            <Typography
                                variant="body1"
                                textAlign="center"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="130%"
                                color="#A7A7A7"
                                fontFamily="Montserrat, sans-serif !important"
                            >
                                *PDF / Image only
                            </Typography>
                        </Box>
                    </Stack>
                </>
            </DropZone>) : (<Box
                sx={{
                    height: { xs: "10vh", md: '12vh', lg: '10vh', xl: "12vh" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: `2px dashed rgba(41, 234, 165, 1)`,
                    backgroundColor: "rgba(23, 186, 174, 0.1)",
                    borderRadius: "12px",
                }}
            >
                <CircularProgress />
            </Box>)}

            <Box sx={{ height: { xs: "9vh", md: '10vh', lg: '14vh', xl: "12vh" }, overflowY: "auto", marginBottom: '15px' }}>
                {filesArr?.map((item, index) => {
                    return (<PaperCard
                        key={item}
                        selectedFile={item}
                        onDelete={onDelete}
                        timeData={timeData}
                        setTimeData={setTimeData}
                        isAPIResponseReceived={isAPIResponseReceived}
                        index={index}
                        fileNames={fileNames}
                        setSliderVal={setSliderVal}
                        sliderVal={sliderVal}
                    />);
                })}
            </Box>
            <ButtonComp className="height-50"
                style={{ marginTop: '20px', width: "100%", height: '60px', fontWeight: 600, fontFamily: "Montserrat, sans-serif" }}
                label="continue"
                disabled={!(fileNames?.length > 0 && !loading && sliderVal[fileNames?.length - 1] == 100)}
                type={fileNames?.length > 0 && !loading && sliderVal[filesArr?.length - 1] == 100 ? "active" : "disabled"}
                onClick={() => {
                    setPage(6);
                    handleData({ businessDocuments: fileNames });
                    setSelected([]);
                    setCheckedValues([]);
                }}
            />
        </Box>
    </Typography >);
};

export default UploadBusinessFile;
