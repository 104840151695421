import React from 'react'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import AddCardModalContent from '../BookServiceModal/PaymentModal.js/AddCardModalContent';

const AddCard = ({setOpenAddCard}) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  return (
    <Elements stripe={stripePromise}>
    <AddCardModalContent fromSettings={true}  setOpenCardModal={setOpenAddCard}  />
    </Elements>
  )
}

export default AddCard