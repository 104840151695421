import React, { useState } from "react";
import TermsandCondition from "../../../Signup/TermsandCondition";
import PrivacyPolicyInfo from "../../../Signup/PrivacyPolicyInfo";
import {  Box} from "@mui/material";

const Footer = () => {
  const [terms, setTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const role=localStorage.getItem("role")
  const handleTerms = () => {
    if (role == "1") {
      setTerms(false);
      setPolicy(true);
    } else {
      setTerms(false);
    }
  };
  const handlePolicy = () => {
      setPolicy(false);
  };
  return (
    <>
      <Box
        sx={{
          color: "rgba(0, 0, 0, 0.8)",
          fontWeight: 500,
          fontSize: {xs:"12px",md:"16px",lg:"17px",xl:"17px"},
            fontFamily:"Montserrat, sans-serif",
            width:'100%',
            left:0,
            bottom:0,
            background:'#ffffff',
            zIndex:99,
            textAlign:'center',
            padding:0
        }}
      >
        <span
        onClick={()=>{setTerms(true)}}
        style={{cursor:"pointer"}}
        >
          {" "}
          Terms and Conditions{" "}
        </span>
        & <span style={{cursor:"pointer"}} onClick={()=>{setPolicy(true)}}>Privacy Policy</span>
      </Box>
      <TermsandCondition terms={terms} setTerms={setTerms} handleTerms={handleTerms} fromMenu={true} />
      <PrivacyPolicyInfo policy={policy} handlePolicy={handlePolicy} fromMenu={true}  />
     
    </>
  );
};

export default Footer;
