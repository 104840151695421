
export const handleRouting = (
  info,
  navigate,
  routePaths,
  selectedServiceId
) => {
  if (selectedServiceId && info?.screen == 1)
    navigate(`/service-info?id=${selectedServiceId}`);

  else if(info?.screen==2)
    navigate(routePaths.CREATE_PROFILE_EVENT_ORGANISER, { state: { fromSocialMedia: true } });
  else if (info?.screen == 3)
    navigate(routePaths.CREATE_PROFILE_EVENT_ORGANISER);
  else if (info?.screen == 1)
    navigate(routePaths.EXPLORE_EVENT);
  else if(info?.screen==4)
    navigate("/switch-profile")
  else if(info?.screen==5)
    navigate("/profilecreationsucessful?id=2");
  else if(info?.screen==6)
    navigate("/profilecreationsucessful?id=3");
  else if(info?.screen==7) 
    navigate("/myservices");
  else
    navigate("/");

 
};
