import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../../component/BreadCrumb";
import NavBar from "../../../pages/eventorganiser/home";
import { Box, Typography, Rating, CircularProgress } from "@mui/material";
import { getSx } from "./style";
import dashboardbreadcrum from "../../../assets/images/dashboardbreadcrum.png";
import sendbtnimg from "../../../assets/images/sendbtnimg.png";
import { getRatingApi, addReplyApi } from "../../../api/rating";
import { pushBreadCrums } from "../../../store/slices/breadcrumb";
import { Container } from "@mui/system";

const ViewRating = () => {
  const [ratingArr, setRatingArr] = useState([]);
  const [viewReply, setViewReply] = useState(false);
  const [selectedRating, setSelectedRating] = useState({});
  const [message, setMessage] = useState("");
  const [isReplied, setIsReplied] = useState(false);
  const userId = useSelector((state) => state.profile.profileInfo.userId);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [earningDetails, setEarningDetails] = useState();
  const dispatch = useDispatch();
  const classes = getSx();
  let val = location?.state?.id;

  const callApi = async () => {
    setLoading(true);
    const resp = await getRatingApi(userId);
    if (resp?.status === 200) {
      setRatingArr(resp.data.data.list);
      setEarningDetails(resp.data.data.ratingDetails[0]);
    }
    setLoading(false);
  };

  const handleBreadCrum = () => {
    setViewReply(false);
    val = null;
  };

  const callAddReplyApi = async () => {
    const resp = await addReplyApi(selectedRating._id, message);
    if (resp?.status === 200) {
      setIsReplied(true);
    }
  };

  const handleNavigation = async () => {
    const resp = await getRatingApi(userId);
    setViewReply(true);
    setSelectedRating(resp.data.data.list.find((item) => item._id == val));
  };

  useEffect(() => {
    callApi();
  }, [viewReply]);
  useEffect(() => {
    if (val) {
      handleNavigation();
    }
  }, []);

  useEffect(() => {
    if (!viewReply) {
      dispatch(
        pushBreadCrums([
          {
            icon: dashboardbreadcrum,
            name: "Dashboard",
            path: "/serviceprovider-dashboard",
          },
          { name: `Ratings and Reviews` },
        ])
      );
    } else {
      dispatch(
        pushBreadCrums([
          {
            icon: dashboardbreadcrum,
            name: "DashBoard",
            path: "/serviceprovider-dashboard",
          },
          { name: `Ratings and Reviews`, breadFunc: handleBreadCrum },
          { name: `Ratings`, breadFunc: handleBreadCrum },
        ])
      );
    }
  });

  return (
    <Container
      sx={{
        padding: {
          xs: "0 15px",
          sm: "0 30px",
          md: "0 30px",
          lg: "0 30px",
          xl: 0,
        },
      }}
      maxWidth="xl"
    >
      <Box sx={{ padding: { xs: "15px 0", sm: "15px 0", md: "15px 0", lg: "15px 0", xl: "30px 0" } }}>
        <Breadcrumb />
        {!viewReply ? (
          loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : ratingArr?.length == 0 ? (
            <Box sx={{ fontWeight: "bold", display: "flex", justifyContent: "center" }}>No Rating Available</Box>
          ) : (
            <Box
              sx={{
                padding: "10px",
                border: "1px solid rgba(0, 0, 0, 0.4)",
                borderRadius: "20px",
                margin: "10px auto",
                width: "98%",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                {ratingArr?.length != 0 && (
                  <>
                    <Typography variant="h2" fontWeight="bold">
                      {earningDetails?.averageRating?.toFixed(1)}
                    </Typography>
                    <Rating precision={0.5} readOnly={true} value={earningDetails?.averageRating} />
                    <Typography variant="body2" color="rgba(26, 26, 26, 0.5)">
                      Based on {earningDetails?.totalRatings} reviews
                    </Typography>
                  </>
                )}
              </Box>
              <Box sx={{ height: { lg: "51vh", xl: "55vh" }, overflowY: "scroll" }}>
                {ratingArr.map((item) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        marginBottom: "10px",
                        cursor: item.replies.length == 0 ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (item.replies.length == 0) {
                          setViewReply(true);
                          setSelectedRating(item);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "rgba(234, 253, 246, 1)",
                          padding: "20px",
                          borderRadius: "20px",
                          position: "relative",
                          bottom: "-14px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", margin: "15px" }}>
                            <img
                              src={item?.createdBy?.profileImage?.url}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "10px",
                                marginRight: "15px",
                              }}
                            />
                            <Box>
                              <Typography>{item?.createdBy?.firstName}</Typography>
                              <Typography sx={{ display: "flex", alignItems: "center" }}>
                                <Rating value={item.rating} precision={0.5} readOnly={true} />
                                <Box sx={{ marginLeft: "4px" }}>{item?.rating?.toFixed(1)}</Box>
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              color: "rgba(0, 0, 0, 0.7)",
                              fontFamily: "Montserrat,san-serif",
                              fontSize: "14px",
                            }}
                          >
                            {dayjs(item?.createdAt).format("MMMM DD,YYYY")}
                          </Box>
                        </Box>
                        <Typography variant="body1">{item.text}</Typography>
                      </Box>
                      {item?.replies?.length > 0 && (
                        <Box
                          sx={{
                            backgroundColor: "rgba(246, 245, 245, 1)",
                            padding: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box sx={{ display: "flex", margin: "15px" }}>
                              <img
                                src={item?.userId?.profileImage?.url}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                  marginRight: "15px",
                                }}
                              />
                              <Box>
                                <Typography>{item?.userId?.firstName}</Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontFamily: "Montserrat,san-serif",
                                fontSize: "14px",
                              }}
                            >
                              {dayjs(item?.replies[0]?.createdAt).format("MMMM DD,YYYY")}
                            </Box>
                          </Box>
                          <Typography variant="body1">{item?.replies[0]?.text}</Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )
        ) : (
          <Box
            sx={{
              padding: "10px",
              border: "1px solid rgba(0, 0, 0, 0.4)",
              borderRadius: "20px",
              margin: "10px auto",
              width: "98%",
              height: { xs: "75vh", sm: "82vh", lg: "75vh" },
              overflowY: "scroll",
            }}
          >
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <Box
                sx={{
                  backgroundColor: "rgba(234, 253, 246, 1)",
                  padding: "20px",
                  borderRadius: "20px",
                  position: "relative",
                  bottom: "-14px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", margin: "15px" }}>
                    <img
                      src={selectedRating?.createdBy?.profileImage?.url}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "10px",
                        marginRight: "15px",
                      }}
                    />
                    <Box>
                      <Typography>{selectedRating?.createdBy?.firstName}</Typography>
                      <Typography sx={{ display: "flex", alignItems: "center" }}>
                        <Rating value={selectedRating?.rating} precision={0.5} readOnly={true} />
                        <Box sx={{ marginLeft: "4px" }}>{selectedRating?.rating?.toFixed(1)}</Box>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "rgba(0, 0, 0, 0.7)",
                      fontFamily: "Montserrat,san-serif",
                      fontSize: "14px",
                    }}
                  >
                    {dayjs(selectedRating?.createdAt).format("MMMM DD,YYYY")}
                  </Box>
                </Box>
                <Typography variant="body1">{selectedRating?.text}</Typography>
              </Box>
              {(isReplied || selectedRating?.replies[0]?.text) && (
                <Box
                  sx={{
                    backgroundColor: "rgba(246, 245, 245, 1)",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", margin: "15px" }}>
                      <img
                        src={selectedRating?.userId?.profileImage?.url}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "10px",
                          marginRight: "15px",
                        }}
                      />
                      <Box>
                        <Typography>{selectedRating?.userId?.firstName}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        fontFamily: "Montserrat,san-serif",
                        fontSize: "14px",
                      }}
                    >
                      {dayjs(new Date()).format("MMMM DD,YYYY")}
                    </Box>
                  </Box>
                  <Typography variant="body1">{selectedRating?.replies[0]?.text || message}</Typography>
                </Box>
              )}
            </Box>
            {!(isReplied || selectedRating?.replies[0]?.text) && (
              <Box sx={classes.inputtag}>
                <Box sx={classes.sendbtnimg}>
                  <img
                    src={sendbtnimg}
                    onClick={() => {
                      if (message) callAddReplyApi();
                    }}
                  />
                </Box>
                <input
                  style={{
                    height: "55px",
                    width: "98%",
                    borderRadius: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.15) !important",
                    padding: "10px",
                    paddingRight: "4rem",
                    outline: "none",
                  }}
                  className="review-input"
                  placeholder="Enter Message Here"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && message.trim()) {
                      if (message) callAddReplyApi();
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

const ViewRatingsSp = () => {
  return (
    <NavBar>
      <ViewRating />
    </NavBar>
  );
};

export default ViewRatingsSp;
