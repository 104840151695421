export const getSx = () => {
  return {
    muiButton: {
      textTransform: "capitalize",
      height: {
        xs: "50px",
        sm: "50px",
        md: "50px",
      },
      borderRadius: "40px",
      padding: "20px",
      fontSize: { xs: "16px", lg: "18px", xl: "20px" },
      color: "#FFFFFF",
      background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily:"Montserrat, sans-serif",

      fontWeight: 500,
      "&.Mui-disabled": {
        background: "#0094D9",
        opacity: "0.3",
        color: "#FFFFFF",
      },

      "&:hover": {
        color: "#FFFFFF",
        background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
      },
    },
    successButton: {
      color: "#FFFFFF",
      width: "600px",
      background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
      fontWeight: 500,
      "&.Mui-disabled": {
        backgroundColor: "#0094D9",
      },

      "&:hover": {
        background: "#0094D9",
      },
    },
    errorButton: {
      background: "transparent",
      color: "#FFFFF",
      border: "1px solid rgba(247, 17, 17, 0.40)",

      "&.Mui-disabled": {
        opacity: "0.3",
        color: "#F71111",
        background: "transparent",
        border: "1px solid rgba(247, 17, 17, 0.40)",
      },

      "&:hover": {
        color: "#FFFF",
        background: "transparent",
        border: "1px solid rgba(247, 17, 17, 0.40)",
      },
    },
    whitebtn: {
      border: "1px solid ",
      borderColor:
        "linear-gradient(to right,rgba(23, 186, 174),rgba(41, 234, 165))",
      background: "white",
      color: "rgba(23, 186, 174, 1)",
      fontSize: { xs: "12px", md: "18px" },
      fontFamily:"Montserrat, sans-serif",
      "&:hover": {
        background: "white",
        fontSize: { xs: "12px", md: "18px" },
        fontFamily:"Montserrat, sans-serif"
      },
    },
    disabledbtn: {
  
        border: "1px solid ",
        borderColor:"rgba(23, 186, 174, 1)",
              background: "white",
      color: "rgba(23, 186, 174, 1) !important",
      fontSize: { xs: "12px", md: "18px" },
      fontFamily:"Montserrat, sans-serif",
      opacity: "0.3",
      borderRadius: "40px",
      fontWeight:"500",
      height:"50px",

      "&:hover": {
        background: "white",
        fontSize: { xs: "12px", md: "18px" },
        fontFamily:"Montserrat, sans-serif"
      },
    },
    disabled: {
      color: "#FFFFFF",
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 0.4),rgba(41, 234, 165, 0.4))",

      "&.Mui-disabled": {
        background:
          "linear-gradient(to right,rgba(23, 186, 174, 0.4),rgba(41, 234, 165, 0.4))",
        color: "#FFFFFF",
      },

      "&:hover": {
        background:
          "linear-gradient(to right,rgba(23, 186, 174, 0.4),rgba(41, 234, 165, 0.4))",
      },
    },
  };
};
