import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import ButtonComp from "../../../component/Button";
import { modalVariant } from "../../../component/Modal/Modalutils";
import CustomSwitch from "../../../component/Switch";
import { Typography, styled, Box, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AddressImage } from "./AddressImage";
import AddService from "./AddService";
import AddServiceCategory from "./AddServiceCategory";
import AddServiceDesc from "./AddServiceDesc";
import FitnessSymbol from "./aminites/FitnessSymbol";
import HotTub from "./aminites/HotTub";
import Parking from "./aminites/Parking";
import PetFriendly from "./aminites/PetFriendly";
import Pool from "./aminites/Pool";
import Wifi from "./aminites/Wifi";
import Aminities from "./Aminities";
import AddAddOnSubCategory from "./edit-add-on-service/AddAddOnSubCategory";
import AddAddOnSubInfo from "./edit-add-on-service/AddAddOnSubInfo";
import AddOnAddService from "./edit-add-on-service/AddOnAddService";
import ImagesAndDesc from "./edit-add-on-service/ImagesAndDesc";
import CustomEditServiceTiming from "./CustomEditServiceTiming";
import Policy from "./Policy";
import { getSx } from "./style";
import editicongreen from "../../../assets/images/editiconservice.png";
import instabookimg from "../../../assets/images/instabookcal.svg";
import plusicongreen from "../../../assets/images/plusicongreen.svg";
import rightarrow from "../../../assets/images/rightarrow.svg";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import { cancelPolicy } from "../../../common/constant";
import { getCurrencyFormat } from "../../../common/constant";

import {
  addAddOnServiceApi,
  deleteAddServiceApi,
  deleteAddOnServiceApi,
  updateEditAddService,
  updateEditAddOnService,
  getServiceDetails,
} from "../../../api/addService";
import { showToast, showModal } from "../../../store/slices/common";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "600px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  "& .MuiPaper-root": { maxWidth: "800px" },
  "& .MuiDialogActions-root": {},
}));
const aminities = {
  Wifi: <Wifi />,
  Pool: <Pool />,
  "Pet Friendly": <PetFriendly />,
  Parking: <Parking />,
  "Hot tub": <HotTub />,
  Gym: <FitnessSymbol />,
};

const EditServiceModal = ({
  editServiceModal,
  setEditServiceModal,
  addService,
  setAddService,
  callUpdateApi,
  setServiceLoading,
}) => {
  const [page, setPage] = useState(1);
  const classes = getSx();
  const [category, setCategory] = useState({});
  const [services, setServices] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isDayAvail, setIsDayAvail] = useState(Array(7).fill(false));
  const [addOnCat, setAddOnCat] = useState();
  const [addonsubCategory, setaddonSubCategory] = useState([]);
  const [addOnValue, setAddOnValue] = useState({ addOnServiceImages: [] });
  const [addOnEdit, setAddOnEdit] = useState(false);
  const [editPage, setEditPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [serviceSelected, setServiceSelected] = useState([]);
  const [errors, setErrors] = useState(false);
  const [checkAlpha, setCheckAlpha] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const handleCategory = (e) => {
    setCategory(e);
    setSubCategory([]);
    setAddService({ ...addService, categories: [] });
  };

  const handleAddOnCategory = (e) => {
    setAddOnCat(e);
  };
  const handleAddOnEditClick = (item) => {
    let val = item?.categories?.map((item1) => {
      return {
        ...item1,
        _id: item1?.categoryId?._id,
        categoryName: item1?.categoryId?.categoryName,
      };
    });
    let value = { ...item, categories: val };
    setAddOnValue(value);
    setaddonSubCategory(val);
    setAddOnCat(item?.serviceId);
    setPage(9);
    setEditPage(9);
    setAddOnEdit(true);
  };

  const handleDeleteService = async () => {
    setLoading(true)
    dispatch(showModal(null));
    const resp = await deleteAddServiceApi(addService?._id);
    if (resp?.status == 200) {
      setLoading(false);
      navigate("/myservices");
      setServiceLoading(false);
      dispatch(
        showToast({
          color: "success",
          msg: "Service Deleted Sucessfully",
        })
      );
    }
    setLoading(false);
  };

  const handleDeleteAddService = () => {
    dispatch(
      showModal({
        modalType: modalVariant.DELETESERVICE_POPUP,
        resolveCallback: () => {
          handleDeleteService();
        },
        rejectCallback: () => {
          dispatch(showModal(null));
        },
      })
    );
  };

  const handleEditSubInfo = async (num, value) => {
    setLoading(true);
    let val, resp;
    if (num == 1) {
      val = addService?.services;
      val[0].categories = subCategory;
      val[0].serviceId = category;
      resp = await updateEditAddService(addService?._id, { services: val, amenities: [] });
    }
    else if (num == 2) {
      resp = await updateEditAddService(addService?._id, { serviceTiming: value });
    }
    else if (num == 3) {
      resp = await updateEditAddService(addService?._id, { amenities: addService?.amenities });
    }
    else if (num == 4) {
      resp = await updateEditAddService(addService?._id, { cancellationPolicy: value });
    }
    if (resp?.status == 200)
      callApi();
  };

  const callUpdateAddOnApi = async () => {
    setLoading(true);
    let cat = addonsubCategory?.map((item) => {
      return { ...item, categoryId: item.categoryId };
    });
    let val = {
      description: addOnValue.description,
      addOnServiceImages: addOnValue.addOnServiceImages,
      categories: cat,
      serviceId: addOnCat?._id,
      isAdOnService: true,
    };
    const resp = await updateEditAddOnService(
      addService?._id,
      addOnValue?._id,
      val
    );
    if (resp?.status == 200) {
      let res = resp?.data?.data?.services?.slice(1);

      setAddService({
        ...addService,
        services: [addService?.services[0], ...res],
      });
      setEditServiceModal(false);
      dispatch(
        showModal({
          modalType: modalVariant.ADDONUPDATE_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            setPage(1);
            setEditServiceModal(true);
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    }
    setLoading(false);
  };
  const callAddOnApi = async () => {
    setLoading(true);
    let val = {
      description: addOnValue.description,
      addOnServiceImages: addOnValue.addOnServiceImages,
      categories: addonsubCategory,
      serviceId: addOnCat?._id,
    };
    const resp = await addAddOnServiceApi(addService?._id, val);
    if (resp?.status == 200) {
      let val = resp?.data?.data?.services.slice(1);
      let disableService = resp?.data?.data?.services.map((item) => { return item?.serviceId?._id });
      setServiceSelected(disableService);
      setAddService({
        ...addService,
        services: [addService?.services[0], ...val],
      });
      dispatch(
        showModal({
          modalType: modalVariant.ADDON_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            setPage(1);
            setEditServiceModal(true);
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    }
    setLoading(false);
  };

  const callApi = async () => {
    setLoading(true);
    const resp = await getServiceDetails(id);
    if (resp?.status == 200) {
      setAddService(resp?.data?.data);
      setCategory(resp?.data?.data?.services[0]?.serviceId);
      let disableService = resp?.data?.data?.services.map((item) => { return item?.serviceId?._id });
      setServiceSelected(disableService)
      let val = resp?.data?.data?.services[0]?.categories?.map((item) => {
        return {
          ...item,
          categoryName: item.categoryId.categoryName,
          _id: item.categoryId._id,
        };
      });
      setSubCategory(val);
    }
    setLoading(false);
  };
  const callDeleteAddonApi = async () => {
    setPage(1);
    setLoading(true);
    const resp = await deleteAddOnServiceApi(addService?._id, addOnValue?._id);
    if (resp?.status == 200) {
      setLoading(false);
      dispatch(
        showToast({
          color: "success",
          msg: "Add-On Service Deleted Sucessfully",
        })
      );
      let addOnServicesList = addService.services.slice(1,)?.filter(
        (item) => item?._id != addOnValue?._id
      );
      let disableService = serviceSelected.filter((id) => id != addOnValue?.serviceId?._id);
      setServiceSelected(disableService)
      setAddService({ ...addService, services: [addService.services[0], ...addOnServicesList] });
    }
  };

  const handleAddServiceUpdate = async () => {
    setEditServiceModal(false);
    setServiceLoading(true);
    const resp = await updateEditAddService(addService?._id, addService);
    if (resp?.status == 200) {
      dispatch(
        showToast({
          color: "success",
          msg: "Service Updated Sucessfully",
        })
      );
      callUpdateApi();
    }
  };

  const hadleAddOnDelete = async () => {
    dispatch(
      showModal({
        modalType: modalVariant.DELETESERVICE_POPUP,
        resolveCallback: () => {
          callDeleteAddonApi();
          dispatch(showModal(null));
        },
        rejectCallback: () => {
          dispatch(showModal(null));
        },
      })
    );
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <CustomDialog
      open={editServiceModal}
      keepMounted
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "580px",
          padding: { xs: "1rem" },
          maxHeight: "90%",
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      }}
    >
      <DialogContent sx={{ padding: { xs: "8px 0", sm: "8px 10px", md: "8px 10px", lg: "8px 20px" }, overflow: "hidden", height: "100%" }}>
        <img
          src={crossicon}
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setEditServiceModal(false);
          }}
        />
        {page != 1 && (
          <img
            src={rightarrow}
            style={{
              position: "absolute",
              left: "1rem",
              top: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              if (editPage == 5) {
                callApi();
                setPage(1)
              }
              else if (editPage == 3 && page != 3) setPage((prev) => prev - 1);
              else if (editPage == 8 && page != 8) setPage((prev) => prev - 1)
              else if (editPage == 9 && page != 9) setPage((prev) => prev - 1)
              else
                setPage(1);

            }}
          />
        )}
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign="center"
          marginBottom="30px"
          fontSize={{ xs: "16px", sm: '18px', md: "18px", lg: '20px', xl: '24px' }}
          sx={{ fontFamily: "Montserrat, sans-serif" }}
        >
          {page == 8 || page == 9 || page == 10 || page == 11
            ? !addOnEdit
              ? "Add-On Services"
              : "Edit Add-On Services"
            : "Edit Service"}
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress sx={{ color: "black" }} />
          </Box>
        ) : (
          <>
            {page == 1 && (
              <Box sx={{ fontFamily: "Montserrat, sans-serif" }}>
                <Box
                  sx={{
                    height: {
                      xs: "calc(90vh - 320px)",
                      sm: 'calc(90vh - 200px)',
                      md: 'calc(95vh - 250px)',
                      lg: 'calc(100vh - 350px)',
                      xl: "calc(100vh - 400px)"
                    }, overflow: "auto"
                  }}
                >
                  <Box sx={{ ...classes.instabookcont, padding: "0 1rem" }}>
                    <Box sx={classes.leftinfoinsta}>
                      <img alt="" src={instabookimg} style={{ margin: "0px 10px" }} />
                      <span
                        style={{
                          textAlign: "center",
                          marginLeft: "10px",
                          fontWeight: "600",
                          fontFamily: "Montserrat, sans-serif"
                        }}
                      >
                        Party Now
                      </span>
                    </Box>

                    <CustomSwitch
                      checked={addService?.isInstaBook}
                      onChange={(e) => {
                        setAddService({
                          ...addService,
                          isInstaBook: e.target.checked,
                        });
                      }}
                    />
                  </Box>
                  <Box sx={classes.editheading}>
                    <Typography sx={classes.editheadinginfo}>
                      Selected Service
                    </Typography>
                    <img alt=""
                      src={editicongreen}
                      onClick={() => {
                        setPage(2);
                        setEditPage(2);
                        setCategory(addService?.services[0]?.serviceId);
                      }}
                      style={{ cursor: 'pointer', width: "20px", height: "20px" }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box sx={{ ...classes.singleItem, ...classes.singleItemsel }}>
                      {addService?.services[0]?.serviceId?.name}
                    </Box>
                  </Box>
                  <Box sx={classes.editheading}>
                    <Typography sx={classes.editheadinginfo}>
                      Selected Service Categories
                    </Typography>
                    <img
                      src={editicongreen}
                      style={{ cursor: "pointer", width: "20px", height: "20px" }}
                      onClick={() => {
                        setPage(3);
                        setEditPage(3);
                        let val = addService?.services[0]?.categories?.map(
                          (item) => {
                            return {
                              ...item,
                              categoryName: item.categoryId.categoryName,
                              _id: item.categoryId._id,
                            };
                          }
                        );
                        setSubCategory(val);
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {addService?.services[0]?.categories?.map((item) => {
                      return (
                        <Box
                          sx={{ ...classes.singleItem, ...classes.singleItemsel }}
                          key={item?.categoryId?.categoryName}
                        >
                          {item?.categoryId?.categoryName}
                        </Box>
                      );
                    })}
                  </Box>
                  <Box sx={classes.editheading}>
                    <Typography sx={classes.editheadinginfo}>
                      Service Fee
                    </Typography>
                    <img
                      src={editicongreen}
                      style={{ cursor: "pointer", width: "20px", height: "20px" }}
                      onClick={() => {
                        setPage(4);
                        setEditPage(4);
                        let val = addService?.services[0]?.categories?.map(
                          (item1) => {
                            return {
                              ...item1,
                              _id: item1?.categoryId?._id,
                              categoryName: item1?.categoryId?.categoryName,
                            };
                          }
                        );
                        setSubCategory(val);
                      }}
                    />
                  </Box>
                  {addService?.services[0]?.categories?.map((item, index) => {
                    return (
                      <Typography sx={classes.editfeeinfo} key={item?.categoryId?.categoryName}>
                        <span style={{ paddingRight: "7px" }}> {item?.categoryId?.categoryName}</span>-<span style={{ paddingLeft: "7px" }}>{getCurrencyFormat(item?.field2?.value)}{" "}(
                          {item?.field1?.value})</span>
                      </Typography>
                    );
                  })}
                  <AddServiceDesc
                    addService={addService}
                    isEdit={true}
                    setAddService={setAddService}
                    setErrors={setErrors}
                    setCheckAlpha={setCheckAlpha}

                  />
                  <Box sx={classes.editheading}>
                    <Typography sx={classes.editheadinginfo}>
                      Service Timmings
                    </Typography>
                    <img
                      src={editicongreen}
                      style={{ cursor: "pointer", width: "20px", height: "20px" }}
                      onClick={() => {
                        setPage(5);
                        setEditPage(5);
                      }}
                    />
                  </Box>
                  <Box sx={classes.edittimelist}></Box>
                  {addService?.serviceTiming &&
                    Object.keys(addService?.serviceTiming)?.map((day) => {
                      const { isAvailable, startTime, endTime } =
                        addService?.serviceTiming[day];
                      if (isAvailable && startTime?.includes("AM") || startTime?.includes("PM")) {
                        return (
                          <Box
                            key={day}
                            sx={{
                              ...classes.editfeeinfo,
                              display: "flex",
                              justifyContent: "space-between",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontSize: { xs: "12px", md: "16px" }
                            }}
                          >
                            <Box sx={classes.editday}>{day}</Box>{" "}
                            <Box sx={classes.edittime}>
                              {startTime} - {endTime}
                            </Box>{" "}
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    })}
                  <AddressImage
                    addService={addService}
                    setAddService={setAddService}
                    isEdit={true}
                  />
                  {addService?.services[0]?.serviceId?.name == "Venues" && (
                    <>
                      {" "}
                      <Box sx={classes.editheading}>
                        <Typography sx={classes.editheadinginfo}>
                          Amenities
                        </Typography>
                        <img
                          src={editicongreen}
                          style={{ cursor: "pointer", width: "20px", height: "20px" }}
                          onClick={() => {
                            setPage(7);
                            setEditPage(7);
                          }}
                        />
                      </Box>
                      {addService?.amenities?.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            overflowX: "auto",
                            overflowY: "hidden",
                            height: "90px",
                            width: "100%",
                          }}
                        >
                          {addService?.amenities?.map((item) => {
                            return (
                              <Box
                                sx={{
                                  border: "1px solid rgba(218, 218, 218, 1)",
                                  borderRadius: "10px",
                                  padding: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "10px",
                                  width: "fit-content",
                                  whiteSpace: "nowrap"
                                }}
                                key={item}
                              >
                                {aminities[`${item}`]}

                                <span
                                  style={{ marginLeft: "5px", fontWeight: "600" }}
                                >
                                  {item}
                                </span>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </>
                  )}

                  <Box sx={{ ...classes.editheading, marginTop: "10px" }}>
                    <Typography sx={classes.editheadinginfo}>
                      Add-on Services
                    </Typography>
                    <img
                      src={plusicongreen}
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAddOnEdit(false);
                        setPage(8);
                        setEditPage(8);
                        setAddOnCat();
                        setAddOnValue({ addOnServiceImages: [] });
                        setaddonSubCategory([]);
                      }}
                    />
                  </Box>
                  {addService?.services?.slice(1)?.map((item) => {
                    return (
                      <Box sx={classes.mainwithspace} key={item?.serviceId?.name}>
                        <Box sx={classes.leftinfo}>
                          <Box sx={classes.leftimg}>
                            <img
                              src={item?.addOnServiceImages[0]?.fileUrl}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "13px",
                              }}
                            />
                          </Box>
                          <Box sx={classes.listcat}>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              marginTop="10px"
                            >
                              {item?.serviceId?.name}
                            </Typography>
                            <Box sx={classes.catlist}>
                              {item?.categories?.map((item1) => {
                                return (
                                  <div
                                    style={{
                                      margin: "5px",
                                      borderRadius: "10px",
                                      padding: "3px 3px",
                                      fontSize: "12px",
                                      background:
                                        "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                                    }}
                                    key={item1?.categoryId?.categoryName}
                                  >
                                    {" "}
                                    {item1?.categoryId?.categoryName}
                                  </div>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={classes.rightinfo}>
                          <img
                            src={editicongreen}
                            style={{ cursor: "pointer", width: "20px", height: "20px" }}
                            className="editicon"
                            onClick={() => {
                              handleAddOnEditClick(item);
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                  <Box sx={classes.editheading}>
                    <Typography sx={classes.editheadinginfo}>
                      Selected Cancellation Policy
                    </Typography>
                    <img
                      src={editicongreen}
                      style={{ cursor: "pointer", width: "20px", height: "20px" }}
                      onClick={() => {
                        setPage(6);
                        setEditPage(6);
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid rgba(218, 218, 218, 1)",
                      borderRadius: "10px",
                      padding: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {addService?.cancellationPolicy}
                    </Typography>
                    {cancelPolicy[`${addService?.cancellationPolicy}`]}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: { xs: "0px", sm: "0rem 3rem" },
                    margin: "14px 0px",
                  }}
                >
                  <Box sx={{ width: "40%" }}>
                    <ButtonComp
                      label="Delete"
                      width="100%"
                      type="whitebtn"
                      onClick={() => {
                        handleDeleteAddService();
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "55%" }}>
                    <ButtonComp
                      label="Update"
                      width="100%"
                      type={!errors && addService?.zipCode && addService?.address && addService?.state && addService.serviceImages?.length >= 2 && !checkAlpha ? "active" : "disabled"}
                      disabled={!errors && addService?.zipCode && addService?.address && addService?.state && addService.serviceImages?.length >= 2 && !checkAlpha ? false : true}
                      onClick={() => {
                        handleAddServiceUpdate();
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {page == 2 && (
              <AddService
                isEdit={true}
                services={services}
                setPage={setPage}
                setServices={setServices}
                handleCategory={handleCategory}
                category={category}
                serviceSelected={serviceSelected}
                setServiceSelected={setServiceSelected}
              />
            )}
            {page == 3 && (
              <AddServiceCategory
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                setPage={setPage}
                setSubCategories={setSubCategories}
                subCategories={subCategories}
                setAddService={setAddService}
                isEdit={true}
                category={category}
                addService={addService}
                handleDelete={hadleAddOnDelete}
              />
            )}
            {page == 4 && (
              <AddAddOnSubInfo
                setaddonSubCategory={setSubCategory}
                setPage={setPage}
                addonsubCategory={subCategory}
                setAddService={setAddService}
                addService={addService}
                isEdit={true}
                isAddOn={false}
                handleEditSubInfo={handleEditSubInfo}
              />
            )}
            {page == 5 && (
              <CustomEditServiceTiming
                addService={addService}
                setAddService={setAddService}
                isDayAvail={isDayAvail}
                setIsDayAvail={setIsDayAvail}
                setPage={setPage}
                handleEditSubInfo={handleEditSubInfo}
              />
            )}
            {page == 6 && (
              <Policy
                addService={addService}
                setAddService={setAddService}
                setPage={setPage}
                isEdit={true}
                handleEditSubInfo={handleEditSubInfo}
              />
            )}
            {page == 7 && (
              <Aminities
                addService={addService}
                setAddService={setAddService}
                setPage={setPage}
                isEdit={true}
                handleEditSubInfo={handleEditSubInfo}
              />
            )}
            {page == 8 && (
              <AddOnAddService
                addOnCat={addOnCat}
                handleAddOnCategory={handleAddOnCategory}
                setPage={setPage}
                serviceSelected={serviceSelected}
                isEdit={true}
              />
            )}
            {page == 9 && (
              <AddAddOnSubCategory
                addonsubCategory={addonsubCategory}
                setaddonSubCategory={setaddonSubCategory}
                setPage={setPage}
                addOnCat={addOnCat}
                isEdit={addOnEdit}
                handleDelete={hadleAddOnDelete}
              />
            )}
            {page == 10 && (
              <AddAddOnSubInfo
                setPage={setPage}
                addonsubCategory={addonsubCategory}
                setaddonSubCategory={setaddonSubCategory}
                isEdit={addOnEdit}
                isAddOn={true}
              />
            )}
            {page == 11 && (
              <ImagesAndDesc
                setPage={setPage}
                addService={addOnValue}
                setAddService={setAddOnValue}
                isEdit={addOnEdit}
                isAddOn={true}
                callAddOnApi={callAddOnApi}
                callUpdateAddOnApi={callUpdateAddOnApi}
                loader={loading}
              />
            )}
          </>
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default EditServiceModal;
