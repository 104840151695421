import React,{useEffect} from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import ButtonComp from "../component/Button";
import CustomTextField from "../component/Textfield";
import { getSx } from "../pages/eventorganiser/profile/style.js";
import { Box, Typography } from "@mui/material";
import event2tlogo from "../assets/images/svg/event2tlogo.svg";
import passwordIconInput from "../assets/images/svg/passwordiconsvg.svg";
import { resetUserPasswordApi,verifyPasswordToken } from "../api/Signup.js";
import { showToast } from "../store/slices/common.js";
import { routePaths } from "../Routes/route-paths.js";

const ChangePassword = () => {
  const classes = getSx();
  const location = useLocation();
  const dispatch=useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
    const tokenId=queryParams.get("token");
    useEffect(()=>{validateToken()},[])
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\><':;|_~`=+-./])[a-zA-Z\d!@#$%^&*[\]{}()?"\\><':;|_~`=+-./]{8,99}$/,
        "Password must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });
  
  const saveApi = async() => {
    const resp =await resetUserPasswordApi({
      token: tokenId,
      password: formik.values.password,
    });
    
      if (resp?.status == 200) {
         navigate(routePaths.LOGIN);
        dispatch(
          showToast({
            color: "success",
            msg: "Change password  successfull",
          })
        );
        formik.resetForm();
      }
    if (resp?.response?.status >= 400) {
      dispatch(
        showToast({
          color: "error",
          msg: resp?.response?.data?.message,
        })
      );
    }
   
  };
  const validateToken = async () => {
    const resp = await verifyPasswordToken({ token: tokenId });
      if (resp?.response?.status >= 400) {
       navigate(routePaths.LOGIN);
        dispatch(
          showToast({
            color: "error",
            msg: resp?.response?.data?.message,
          })
        );
      }
  };

  const formik = useFormik({
    initialValues: { confirmPassword: "", password: "" },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: { xs: "98%", sm: "50%" },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            width: { xs: "100px", md: "300px" },
            height: { xs: "100px", md: "150px" },
          }}
        >
          <img src={event2tlogo} style={{ width: "100%", height: "100%" }} />
        </Box>

        <Typography sx={{fontFamily:"Montserrat, sans-serif",}} variant="h5" fontWeight="bold">
          Reset Password
        </Typography>

        <Typography
          sx={{ ...classes.smallheading, width: { sm: "50%", xs: "100%" },fontFamily:"Montserrat, sans-serif", }}
          style={{ margin: "10px" }}
        >
          New password
        </Typography>
        <Box sx={classes.subform1}>
          <Typography
            className="input-with-icon-div margintopp"
            component="div"
            position={"relative"}
            sx={{fontFamily:"Montserrat, sans-serif",}}
          >
            <CustomTextField
              type="password"
              variant="outlined"
              placeholder="Enter New Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              errorMessage={formik.touched.password && formik.errors.password}
              className={"email-with-icon"}
              maxLength={50}
            />
            <Typography
              className="input-icon"
              component="div"
              position="absolute"
              sx={{fontFamily:"Montserrat, sans-serif",}}
            >
              <img
                src={passwordIconInput}
                style={{ opacity: "0.6" }}
                alt="Image"
              />
            </Typography>
          </Typography>
        </Box>
        <Typography
          sx={{ ...classes.smallheading,fontFamily:"Montserrat, sans-serif", width: { sm: "50%", xs: "100%" } }}
          style={{ margin: "10px" }}
        >
          Confirm Password
        </Typography>
        <Box sx={classes.subform1}>
          <Typography
            className="input-with-icon-div margintopp"
            component="div"
            position={"relative"}
            sx={{fontFamily:"Montserrat, sans-serif",}}
          >
            <CustomTextField
              type="password"
              variant="outlined"
              placeholder="Re-type new password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              errorMessage={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              className={"email-with-icon"}
              maxLength={50}
            />
            <Typography
              className="input-icon"
              component="div"
              position="absolute"
              sx={{fontFamily:"Montserrat, sans-serif",}}
            >
              <img
                src={passwordIconInput}
                style={{ opacity: "0.6" }}
                alt="Image"
              />
            </Typography>
          </Typography>
          <Box sx={{ marginTop: "20px", }}>
            <ButtonComp
              label="save"
              width="100%"
              type={formik.isValid && formik.dirty ? "active" : "disabled"}
              disabled={!(formik.isValid && formik.dirty)}
              onClick={() => {
                saveApi();
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
