import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {Box, Typography, CircularProgress} from '@mui/material'
import Favourites from './Favourites'
import nofavourite from '../../../assets/images/nofavourite.png'

import {getListFavApi} from '../../../api/profile'

const FavouritesInfo = () => {
    const [favouritesList, setFavourites] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationInfo = useSelector((state) => state.common.location);
    const callApi = async () => {
        setLoading(true);
        const resp = await getListFavApi(locationInfo?.lat ? locationInfo?.lat : "25.7825389", locationInfo?.long ? locationInfo?.long : "-80.311861");
        if (resp?.status === 200) setFavourites(resp?.data?.data)
        setLoading(false);
    }
    useEffect(() => {
        callApi()
    }, [])

    return (loading ? <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh"
        }}><CircularProgress/></Box> : <>
            <Typography variant="h5" sx={{
                fontSize: {
                    xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '28px'
                }, lineHeight: {
                    xs: '26px', sm: '28px', md: '32px', lg: '36px', xl: '40px'
                }, fontWeight: 700, marginBottom: '30px', fontFamily: "Montserrat, sans-serif"
            }}>
                Favorites
            </Typography>
            {favouritesList.length == 0 ? <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                    flexDirection: "column"
                }}>
                    <img alt="" src={nofavourite} style={{width: "185px", height: "185px"}}/>
                    <Typography sx={{fontFamily: "Montserrat, sans-serif"}} variant="h5" fontWeight="bold" margin="10px 0px">Ohh!</Typography>
                    <Typography sx={{fontFamily: "Montserrat, sans-serif"}} variant="h6" color="rgba(0, 0, 0, 0.5)" margin="10px 0px">No Favorite Service Added
                        yet</Typography>

                </Box> :

                <Box sx={{
                    display: "flex", flexWrap: "wrap", gap: "1rem", width: "100%", overflow: "auto", maxHeight: {
                        xs: 'calc(100vh - 170px)',
                        sm: 'calc(100vh - 200px)',
                        md: 'calc(100vh - 200px)',
                        lg: 'calc(100vh - 200px)',
                        xl: 'calc(100vh - 200px)',
                    },
                }}>
                    {favouritesList.map((item) =>

                        <Favourites inMap={false} isExplore={true} item={item} callFavApi={callApi}
                                    fromSettings={true}/>)}
                </Box>}
        </>)
}

export default FavouritesInfo