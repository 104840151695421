import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonComp from "../../../component/Button";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import AddServiceModal from "./AddServiceModal";
import { getSx } from "./style";
import addaserviceimage from "../../../assets/images/addaserviceimage.svg";
import NavBar from "../../eventorganiser/home";
import Favourites from "../../eventorganiser/profile/Favourites";
import { getServiceList } from "../../../api/addService";
import { Container } from "@mui/system";

const MyServicesInfo = () => {
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [page, setPage] = useState(1);
  const [isAddOn, setIsAddOn] = useState(false);
  const [servId, setServId] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceSelected, setServiceSelected] = useState([]);
  const location = useLocation();
  const { isOpenServiceModal } = location.state || {};
  const classes = getSx();
  const callApi = async () => {
    setLoading(true);
    const resp = await getServiceList();
    setServiceList(resp?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    callApi();
    if (isOpenServiceModal) {
      setAddServiceModal(true);
    }
    localStorage.setItem("role", 1)
  }, []);


  return (
    <Box sx={classes.container}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ ...classes.addserviceheading, padding: { xs: "15px 0", sm: "15px 0", md: '15px 0', lg: '15px 0', xl: '30px 0' } }}>
            <Typography variant="h5" sx={classes.addserheding}>
              My Services
            </Typography>
            <Box>
              <ButtonComp
                label="Add Service"
                height="45px"
                type="active"
                onClick={() => {
                  setAddServiceModal(true);
                }}
              />
            </Box>
          </Box>
          {!loading && serviceList?.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                margin: "10px 0",
                overflow: "auto",
                maxHeight: {
                  xs: 'calc(100vh - 155px)',
                  sm: 'calc(100vh - 208px)',
                  md: 'calc(100vh - 208px)',
                  lg: 'calc(100vh - 208px)',
                  xl: 'calc(100vh - 208px)'
                }
              }}
            >
              {serviceList.map((item) => {
                return <Favourites item={item} />;
              })}
            </Box>
          ) : (
            <>
              <img alt="" src={addaserviceimage} />
              <Box sx={classes.info}>
                <Typography textAlign='center' fontFamily="Montserrat,sans-serif" variant="h6" fontWeight="bold" marginBottom="15px">
                  No Service Added Yet!
                </Typography>
                <Typography
                  variant="body2"
                  color="rgba(0, 0, 0, 0.6)"
                  fontWeight="500"
                  sx={{
                    fontSize: {
                      xs: "15px",
                      md: "17px",
                      lg: "15px",
                      xl: "17px",
                    },
                  }}
                  marginBottom="20px"
                  fontFamily="Montserrat,sans-serif"
                >
                  Click below to begin creating your service listing.
                </Typography>
                <ButtonComp
                  type="active"
                  width="80%"
                  label="Add Service"
                  onClick={() => {
                    setAddServiceModal(true);
                  }}
                />
              </Box>
            </>
          )}
        </>
      )}
      {addServiceModal && (
        <AddServiceModal
          addServiceModal={addServiceModal}
          setAddServiceModal={setAddServiceModal}
          page={page}
          setPage={setPage}
          setIsAddOn={setIsAddOn}
          isAddOn={isAddOn}
          setServId={setServId}
          servId={servId}
          setServiceSelected={setServiceSelected}
          serviceSelected={serviceSelected}
          callApi={callApi}
        />
      )}
    </Box>
  );
};
const MyServices = () => {
  return (
    <NavBar>
      <Container sx={{
        padding: {
          xs: '0 15px',
          sm: '0 30px',
          md: '0 30px',
          lg: '0 30px',
          xl: 0
        }
      }} maxWidth="xl">
        <MyServicesInfo />
      </Container>
    </NavBar>
  );
};

export default MyServices;
