import React, { useState } from "react";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import FitnessSymbol from "./aminites/FitnessSymbol";
import HotTub from "./aminites/HotTub";
import Parking from "./aminites/Parking";
import PetFriendly from "./aminites/PetFriendly";
import Pool from "./aminites/Pool";
import Wifi from "./aminites/Wifi";
import { getSx } from "./style";

const aminitiesArr = [
  { name: "Wifi", component: <Wifi />, selComponent: <Wifi value={true} /> },
  {
    name: "Gym",
    component: <FitnessSymbol />,
    selComponent: <FitnessSymbol value={true} />,
  },
  {
    name: "Parking",
    component: <Parking />,
    selComponent: <Parking value={true} />,
  },
  {
    name: "Hot tub",
    component: <HotTub />,
    selComponent: <HotTub value={true} />,
  },
  {
    name: "Pet Friendly",
    component: <PetFriendly />,
    selComponent: <PetFriendly value={true} />,
  },
  {
    name: "Pool",
    component: <Pool />,
    selComponent: <Pool value={true} />,
  },
];

const Aminities = ({ setPage, addService, setAddService ,isEdit,handleEditSubInfo}) => {
  const classes = getSx();
  const[filterData,setFilteredData]=useState(aminitiesArr);
  const [value,setValue]=useState();
  const handleFilter=(val)=>{
    const resp=aminitiesArr.filter((item)=>{
      if(item.name.toLowerCase().includes(val.toLowerCase()))
      return item;
       })
       setFilteredData(resp)
  }
  const handleClick = (item) => {
    if (!addService?.amenities?.includes(item.name)) {
      setAddService({
        ...addService,
        amenities: [...addService?.amenities, item.name],
      });
    }
    else
    {
      const resp=addService?.amenities?.filter((item1)=>item1!=item.name);
      setAddService({...addService,amenities:resp})
    }
  };
  return (
    <Box sx={classes.main}>
     <Box sx={classes.search}>
           <CustomTextField
          backgroundColor="rgba(246, 245, 245, 1)"
          placeholder="Search Amenities"
          type="text"
          name="category"
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            handleFilter(e.target.value)
          }}
        
        />
        </Box>
      <Typography variant="body" fontWeight="bold" position="relative" >
        Add Aminities{!isEdit &&
        <Typography sx={{color:"rgba(23, 186, 174, 1)",position:'absolute',top:"0rem",right:{xs:"-4rem",lg:"-6rem"}, cursor:'pointer'}} onClick={()=>{setPage(prev=>prev+1); setAddService({ ...addService, cancellationPolicy: "" });}}>Skip</Typography>}
      </Typography>
    
      <Box sx={{
          minHeight: {
              xs: "auto",
              sm:'auto',
              md:'auto',
              lg: "auto",
              xl: "auto"
          },
          maxHeight: {
              xs: "calc(100vh - 350px)",
              sm:'calc(100vh - 500px)',
              md:'calc(100vh - 600px)',
              lg: "calc(100vh - 350px)",
              xl: "calc(100vh - 470px)"
          },
          overflow:'auto'
      }}>
        <Box sx={classes.aminitiesbox}>
          {filterData.map((item) => {
            return (
              <Box
                sx={
                  !addService?.amenities?.includes(item.name)
                    ? classes.aminities
                    : {
                        ...classes.aminities,
                        border: "none",
                        background:
                          "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
                      }
                }
                key={item.name}
                onClick={() => {
                  handleClick(item);
                }}
              >
                {addService?.amenities?.includes(item.name)
                  ? item.selComponent
                  : item.component}
                <Box
                  sx={
                    !addService?.amenities?.includes(item.name)
                      ? { ...classes.aminitiname }
                      : {
                          ...classes.aminitiname,
                          color: "rgba(23, 186, 174, 1)",
                        }
                  }
                >
                  {item.name}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      <ButtonComp
        label={isEdit?"Update":"Next"}
        width="90%"
        disabled={addService?.amenities?.length > 0 || isEdit ? false : true}
        type={addService?.amenities?.length > 0|| isEdit ? "active" : "disabled"}
        onClick={() => {
          if(!isEdit)
          {
          setPage((prev) => prev + 1);
          setAddService({ ...addService, cancellationPolicy: "" });
          }
          else
          {
            setPage(1)
            handleEditSubInfo(3)
          }
        }}
      />
    </Box>
  );
};

export default Aminities;
