import React, { useState, useEffect } from "react";
import ButtonComp from "../../../component/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getSx } from "../../eventorganiser/createprofile/style";

const cancelPolicy = [
  {
    key: "Flexible",
    value: "Full refund for cancellations made for at least 7 days before the event date, Refund 50% for cancellations made between 3-6 days before the event, No Refund for the cancellation made between 0-2 days of the Event."
  },
  {
    key: "Moderate",
    value: "Full refund for cancellations made for at least 14 days before the event date, Refund 50% for cancellations made between 7-13 days before the event. No refund for cancellations made for 6 days or less before the event"
  },
  {
    key: "Strict",
    value: "Refund 50% for cancellations made for at least 21 days before the event date, No refund for cancellations made 20 days or less before the event."
  },
  {
    key: "Very Strict",
    value: "Refund 50% for cancellations made for at least 30 days before the event date, No refund for cancellations made 29 days or less before the event"
  },
];
const Policy = ({ setPage, setAddService, addService, isEdit, handleEditSubInfo }) => {
  const [policyVal, setPolicyVal] = useState();
  const classes = getSx();
  const handleChange = (event) => {
    setPolicyVal(event.target.value)

  };
  useEffect(() => {
    setPolicyVal(addService?.cancellationPolicy)
  }, [])

  return (
    <Box>
      <Box
        sx={{
          margin: "10px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body" fontWeight="bold" fontSize="18px">
          {isEdit ? "Update Cancellation Policy" : "Choose Cancellation Policy"}
        </Typography>
      </Box>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={policyVal || addService?.cancellationPolicy}
        onChange={handleChange}
        sx={{
          minHeight: {
            xs: "auto",
            sm: 'auto',
            md: 'auto',
            lg: 'auto',
            xl: "auto"
          },
          maxHeight: {
            xs: "calc(100vh - 400px)",
            sm: 'calc(100vh - 400px)',
            md: 'calc(100vh - 600px)',
            lg: 'calc(100vh - 345px)',
            xl: "calc(100vh - 450px)"
          },
          flexWrap: "nowrap",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {cancelPolicy.map((item) => {
          return (
            <Box
              key={item}
              sx={
                item?.key == policyVal
                  ? {
                    ...classes.unselectbox,
                    ...classes.selectbox,
                    margin: "0 0 10px",
                    border: "1px solid #DADADA",
                    height: 'auto',
                  }
                  : {
                    ...classes.unselectbox,
                    height: 'auto',
                    margin: "0 0 10px",
                  }
              }
              className="radioboxinfo"
            >
              <Typography
                variant="h2"
                fontWeight="bold"
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "18px",
                    lg: "20px",
                    xl: "22px"
                  },
                  padding: '5px 10px 5px 0',
                  fontFamily: 'Montserrat, sans-serif',
                  width: '30%'
                }}
              >
                {item?.key}
              </Typography>
              <Box sx={{ width: { xs: "60%", sm: "60%", md: '60%', lg: '60%', xl: '60%' }, display: 'inline-block' }}>
                <Typography
                  variant="div"
                  fontWeight="200"
                  fontSize="12px"
                  width="80%"
                  sx={{
                    fontFamily: 'Montserrat, sans-serif'
                  }}
                >
                  {item?.value}
                </Typography>
              </Box>
              <Box sx={classes.radiobox}>
                <FormControlLabel
                  value={item?.key}
                  control={
                    <Radio
                      className="radioinput"
                      sx={{ color: "rgba(23, 186, 174, 1)" }}
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{
                            color: "rgb(22, 192, 152)",
                          }}
                        />
                      }
                    />
                  }
                />
              </Box>
            </Box>
          );
        })}
      </RadioGroup>
      <ButtonComp
        label={isEdit ? "Update" : "Next"}
        width="100%"
        type={policyVal ? "active" : "disabled"}
        disabled={policyVal ? false : true}
        onClick={() => {
          setAddService({ ...addService, cancellationPolicy: policyVal });
          if (isEdit) {
            setPage(1)
            handleEditSubInfo(4, policyVal)
          }
          else
            setPage((prev) => prev + 1);
        }}
      />
    </Box>
  );
};

export default Policy;
