import React from "react";

import NavBar from "../../../pages/eventorganiser/home";
import { Box, Grid } from "@mui/material";
import { getSx } from "./style";
import blogimage from "../../../assets/images/blogimage.svg";

const BlogImagesInfo = () => {
  const classes = getSx();
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          width: "99%",
          margin: "0px",
          display: "flex",
          boxSizing: "border-box",
        }}
        sx={classes.catinfo}
      >
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "2rem",
              color: "#fff",
              left: "3rem",
              fontWeight: "bold",
              marginBottom: "15px",
              fontSize: "19px",
            }}
          >
            Venu Visionary Views
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "1rem",
              color: "#fff",
              left: "3rem",
            }}
          >
            1H ago{" "}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <img
            src={blogimage}
            style={{
              width: "100%",
              height: "250px",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
const BlogImages = () => {
  return (
    <NavBar>
      <BlogImagesInfo />
    </NavBar>
  );
};

export default BlogImages;
