import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { callSocialMediaPost, getProfileApi } from '../../api/profile';
import { setProfileInfo } from '../../store/slices/profileinfo';
import facebookicon from '../../assets/images/facebooklogin.svg';
import { getFCMToken } from '../../firebase';
import { handleProfileInfo } from '../../utils/handleProfileInfo';
import { setUserIds } from '../../store/slices/profileinfo';
const FaceBookLogin = ({ setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseFacebook = async (response) => {
    if (response?.id) {
      try {
        setLoading(true);
        if (response.id) {
          const payload = {
            provider: 'facebook', //"Google" | "Email"
            email: response?.email,
            token: response?.id,
            firstName: response?.name?.trim().split(" ")[0],
            lastName: response?.name?.trim().split(" ")[1],
            profileImage: { url: response?.picture?.data?.url, key: `${response?.id}fileName` }

          };
          const resp = await callSocialMediaPost(payload);
          if (resp?.status == 200) {
            localStorage.setItem("token", resp?.data?.token);
            localStorage.setItem("role", 0);
            localStorage.setItem("isLogin", true);
            dispatch(setUserIds(resp?.data?.data?.userIds));
            getFCMToken();
            handleSaveProfileInfo();
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSaveProfileInfo = async () => {
    const profileResponse = await getProfileApi();
    if (profileResponse?.status == 200) {
      handleProfileInfo(profileResponse?.data?.data, dispatch, setProfileInfo, 0)
      if (!profileResponse?.data?.data?.phoneNumber) {
        navigate("/create-profile", { state: { fromSocialMedia: true } })
      }
    }
    localStorage.setItem("role", 0)
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name, email, picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <Box
          component="img"
          sx={{
            marginLeft: '30px',
            width: { xs: 35, md: 44 },
            height: { xs: 35, md: 44 },
            cursor: 'pointer',
          }}
          src={facebookicon}
          alt="facebook icon"
          onClick={renderProps.onClick}
          className={'facebook-icon'}
        />
      )}
    />
  );
};

export default FaceBookLogin;
