import React from "react";

const BookingsIcon = (val) => {
  return val.val ? (
    <svg
      className='profile-icon'
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6673 4.66634H21.5007V3.49967C21.5007 2.79967 21.034 2.33301 20.334 2.33301C19.634 2.33301 19.1673 2.79967 19.1673 3.49967V4.66634H9.83398V3.49967C9.83398 2.79967 9.36732 2.33301 8.66732 2.33301C7.96732 2.33301 7.50065 2.79967 7.50065 3.49967V4.66634H6.33398C4.35065 4.66634 2.83398 6.18301 2.83398 8.16634V9.33301H26.1673V8.16634C26.1673 6.18301 24.6507 4.66634 22.6673 4.66634ZM2.83398 22.1663C2.83398 24.1497 4.35065 25.6663 6.33398 25.6663H22.6673C24.6507 25.6663 26.1673 24.1497 26.1673 22.1663V11.6663H2.83398V22.1663ZM20.334 13.9997C21.034 13.9997 21.5007 14.4663 21.5007 15.1663C21.5007 15.8663 21.034 16.333 20.334 16.333C19.634 16.333 19.1673 15.8663 19.1673 15.1663C19.1673 14.4663 19.634 13.9997 20.334 13.9997ZM20.334 18.6663C21.034 18.6663 21.5007 19.133 21.5007 19.833C21.5007 20.533 21.034 20.9997 20.334 20.9997C19.634 20.9997 19.1673 20.533 19.1673 19.833C19.1673 19.133 19.634 18.6663 20.334 18.6663ZM14.5007 13.9997C15.2007 13.9997 15.6673 14.4663 15.6673 15.1663C15.6673 15.8663 15.2007 16.333 14.5007 16.333C13.8007 16.333 13.334 15.8663 13.334 15.1663C13.334 14.4663 13.8007 13.9997 14.5007 13.9997ZM14.5007 18.6663C15.2007 18.6663 15.6673 19.133 15.6673 19.833C15.6673 20.533 15.2007 20.9997 14.5007 20.9997C13.8007 20.9997 13.334 20.533 13.334 19.833C13.334 19.133 13.8007 18.6663 14.5007 18.6663ZM8.66732 13.9997C9.36732 13.9997 9.83398 14.4663 9.83398 15.1663C9.83398 15.8663 9.36732 16.333 8.66732 16.333C7.96732 16.333 7.50065 15.8663 7.50065 15.1663C7.50065 14.4663 7.96732 13.9997 8.66732 13.9997ZM8.66732 18.6663C9.36732 18.6663 9.83398 19.133 9.83398 19.833C9.83398 20.533 9.36732 20.9997 8.66732 20.9997C7.96732 20.9997 7.50065 20.533 7.50065 19.833C7.50065 19.133 7.96732 18.6663 8.66732 18.6663Z"
        fill="#17BAAE"
      />
    </svg>
  ) : (
    <svg
     className='profile-icon'
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6673 4.66634H21.5007V3.49967C21.5007 2.79967 21.034 2.33301 20.334 2.33301C19.634 2.33301 19.1673 2.79967 19.1673 3.49967V4.66634H9.83398V3.49967C9.83398 2.79967 9.36732 2.33301 8.66732 2.33301C7.96732 2.33301 7.50065 2.79967 7.50065 3.49967V4.66634H6.33398C4.35065 4.66634 2.83398 6.18301 2.83398 8.16634V9.33301H26.1673V8.16634C26.1673 6.18301 24.6507 4.66634 22.6673 4.66634ZM2.83398 22.1663C2.83398 24.1497 4.35065 25.6663 6.33398 25.6663H22.6673C24.6507 25.6663 26.1673 24.1497 26.1673 22.1663V11.6663H2.83398V22.1663ZM20.334 13.9997C21.034 13.9997 21.5007 14.4663 21.5007 15.1663C21.5007 15.8663 21.034 16.333 20.334 16.333C19.634 16.333 19.1673 15.8663 19.1673 15.1663C19.1673 14.4663 19.634 13.9997 20.334 13.9997ZM20.334 18.6663C21.034 18.6663 21.5007 19.133 21.5007 19.833C21.5007 20.533 21.034 20.9997 20.334 20.9997C19.634 20.9997 19.1673 20.533 19.1673 19.833C19.1673 19.133 19.634 18.6663 20.334 18.6663ZM14.5007 13.9997C15.2007 13.9997 15.6673 14.4663 15.6673 15.1663C15.6673 15.8663 15.2007 16.333 14.5007 16.333C13.8007 16.333 13.334 15.8663 13.334 15.1663C13.334 14.4663 13.8007 13.9997 14.5007 13.9997ZM14.5007 18.6663C15.2007 18.6663 15.6673 19.133 15.6673 19.833C15.6673 20.533 15.2007 20.9997 14.5007 20.9997C13.8007 20.9997 13.334 20.533 13.334 19.833C13.334 19.133 13.8007 18.6663 14.5007 18.6663ZM8.66732 13.9997C9.36732 13.9997 9.83398 14.4663 9.83398 15.1663C9.83398 15.8663 9.36732 16.333 8.66732 16.333C7.96732 16.333 7.50065 15.8663 7.50065 15.1663C7.50065 14.4663 7.96732 13.9997 8.66732 13.9997ZM8.66732 18.6663C9.36732 18.6663 9.83398 19.133 9.83398 19.833C9.83398 20.533 9.36732 20.9997 8.66732 20.9997C7.96732 20.9997 7.50065 20.533 7.50065 19.833C7.50065 19.133 7.96732 18.6663 8.66732 18.6663Z"
        fill="rgb(0,0,0,0.2"
      />
    </svg>
  );
};

export default BookingsIcon;
