import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {modalVariant} from "../../../component/Modal/Modalutils";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import ProfileSettings from "./ProfileSettings";
import {sidebarItems} from "./propsItems";
import {getSx} from "./style";
import editicon from "../../../assets/images/svg/profileicons/editicongreen.svg";
import NavBar from "../home";
import {deleteAccountApi} from "../../../api/profile";
import {clearBreadCrum} from "../../../store/slices/breadcrumb";
import {showModal, showToast} from "../../../store/slices/common";
import {Container} from "@mui/system";

export const EditSettings = () => {
    const [selected, setSelected] = useState({
        title: "Edit Profile", activeRoute: "Edit Profile", icon: editicon, component: <ProfileSettings/>,
    });
    const classes = getSx();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = localStorage.getItem("role");
    const deleteAccount = async () => {
        const resp = await deleteAccountApi();
        if (resp.status === 200) {
            dispatch(showToast({msg: "Account Deleted Successfully", color: "error"}));
            localStorage.clear();
            navigate("/");
        }
    }
    const handleClick = (item) => {
        const handleDisptach = async () => {
            localStorage.clear();
            navigate("/");
            dispatch(showModal(null));
        }
        dispatch(clearBreadCrum());
        if (item?.title != "Delete Account" && item?.title != "Logout") setSelected(item);
        if (item?.title == "Logout") {
            dispatch(showModal({
                modalType: modalVariant.LOGOUT, resolveCallback: () => {

                    handleDisptach();
                }, rejectCallback: () => {
                    dispatch(showModal(null));
                },
            }));
        }
        if (item?.title == "Delete Account") {
            dispatch(showModal({
                modalType: modalVariant.DELETE_ACCOUNT_POPUP, resolveCallback: () => {
                    dispatch(showModal(null));
                    deleteAccount();
                }, rejectCallback: () => {
                    dispatch(showModal(null));
                },
            }));
        }
    };
    return (<>
          
            <Box sx={classes.main}>
                <Box sx={classes.sideNav}>
                    <Stack sx={{width:'100%', height:'100%', overflow:'auto'}}>
                        {sidebarItems.map((item) => {
                            if ((role == "1" && item?.title != "Payment Method" && item?.title != "Favorites" && item?.title !="Change Password" && item.title !="Delete Account") || (role == "0" && item?.title != "Bank Details")) return (
                                <Box
                                    onClick={() => {

                                        handleClick(item);
                                    }}
                                    key={item?.title}
                                    sx={selected?.title == item?.title ? {...classes.selsingleTab} : {...classes.singleTab}}
                                >
                                    <>
                                        <Box
                                            sx={{
                                                borderRadius: "50%",
                                                width: {xs: "30px", md: "28px"},
                                                height: {xs: "30px", md: "28px"},
                                                backgroundColor: item?.title == selected?.title ? "#ffff" : "rgba(41, 234, 165, 0.1)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <Box sx={classes.profileimg}>
                                                <img alt={""}
                                                    src={item?.icon}
                                                    style={{width: "100%", height: "100%"}}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={classes.title}>{item?.title}</Box>
                                    </>
                                </Box>);
                        })}
                    </Stack>

                 
                </Box>
                <Box
                    sx={selected?.title == "Bank Details" || selected?.title == "Transaction History" ? classes.info1 : classes.info}>{selected?.component}</Box>
            </Box>
        </>);
};

const Settings = () => {
    return (<NavBar>
            <Container sx={{
                padding: {
                    xs: '0 15px', sm: '0 30px', md: '0 30px', lg: '0 30px', xl: 0
                }
            }} maxWidth="xl">
                <EditSettings/>
            </Container>
        </NavBar>);
};

export default Settings;
