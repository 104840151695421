
export const getSx = () => {
  return {
    maincontainer: {
      width: {
        xs:'calc(100% - 30px)',
        sm:'calc(100% - 60px)',
        md:'100%',
        lg:'100%',
        xl:'100%'
      },
      height:  '100vh',
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin:'auto'
    },
    maincontainer1:{
      width: {
        xs:'calc(100% - 30px)',
        sm:'calc(100% - 60px)',
        md:'100%',
        lg:'100%',
        xl:'100%'
      },
      boxSizing: "border-box",
      margin:'auto'
    },
    container1:{
      width: {
        xs: "100%",
        sm: "100%",
        md: "100%",
        lg:"100%",
        xl:"100%",
      },
      height: {
        xs: "calc(90vh - 200px)",
        sm:"calc(100vh - 140px)",
        md: "calc(100vh - 140px)",
        lg:"calc(100vh - 140px)",
        xl:"calc(100vh - 180px)",
      },
      margin:'auto',
    },
    container: {
      width: {
        xs: "100%",
        sm: "100%",
        md: "80%",
        lg:"80%",
        xl:"80%",
      },
      height: {
        xs: "calc(100vh - 160px)",
        sm:"calc(100vh - 140px)",
        md: "calc(100vh - 140px)",
        lg:"calc(100vh - 100px)",
        xl:"calc(100vh - 180px)",
      },
      margin:'auto',
      border: "1px solid rgba(233, 233, 233, 1)",
      padding: {
        xs:'15px',
        sm:'20px 30px',
        md:'20px 30px',
        lg:"20px 50px",
        xl:"50px"
      },
      borderRadius: "15px",
      display: "flex",
      justifyContent: { xs: "center", lg: "flex-start" },
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      overflow:'auto'
    },
    rightarrowicon: {
      position: "absolute",
      left:{xs:'15px', sm:'30px', md:'45px', lg:'72px',xl:'72px'},
      top: {xs:'15px', sm:'30px', md:'45px', lg:'72px',xl:'72px'},
    },
    progressbar: {
      span: {
        background:
          "linear-gradient(to bottom right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1)) !important",
      },
    },
    logo: {
      margin: {
        xs:"0 0 10px",
        sm:"0 0 15px",
        md:"0 0 15px",
        lg:"0 0 15px",
        xl:"0 0 30px",
      },
      width: { xs: "150px", sm: "180px", md:'200px', lg:'200px', xl:'300px' },
      justifyContent: "center",
      display: "flex",
      height:'65px'
    },
    event2tlogo: {
      width: "100%",
      height: "100%",
    },
    infocontainer: {
      width: { xs: "100%", sm: "60%", lg: "42%" },
      dispay: "flex",
      justifyContent: "center",
      marginTop:{
        xs:'15px',
        sm:'15px',
        md:'15px',
        lg:'15px',
        xl:'30px'
      }
    },
    heading: {
      fontSize: { xs: "16px", md: "18px",lg:"20px",xl:"28px" },
      fontWeight:700,
      textAlign: {
        xs:"center",
        sm:"center",
        md:"center",
        lg:'center',
        xl:'center'
      },
      fontFamily: "Montserrat , sans-serif !important",
    },
    smallheading: {
      fontSize: { xs: "13px", md: "15px", sm:'14px', lg:'15px', xl:'18px' },
      fontWeight: 600,
      marginBottom: {
        xs:'5px',
        sm:'5px',
        md:'6px',
        lg:'7px',
        xl:"12px"
      },
      color: "#1A1A1A",
      fontFamily: "Montserrat , sans-serif !important",
    },
    createFormMain:{
       marginTop: {
         xs:'15px',
         sm:'15px',
         md:'20px',
         lg:'20px',
         xl:"40px"
       },
    },
    phoneinput: {
      width: "100%",
    },
    phoneNumberWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      position: "relative",
    },
    inputStyle: {
      width: "100%",
      height: {
        xs:'40px',
        sm:'40px',
        md:'45px',
        lg:'50px',
        xl:"60px"
      },
      borderRadius: "15px",
      border: "1px solid rgba(217, 217, 217, 1)",
      backgroundColor: "transparent",
      color: "#365462",
      fontSize: {xs: "12px", md: "14px", sm:'14px', lg:'14px', xl:'16px'},
      fontWeight: 400,
    },
    containerStyle: { width: "100%" },
    imageinfo: {
      color: "#A7A7A7",
      lineHeight: { xs: "20px", sm: "27px",lg:"27px",xl:"27px" },
      textAlign: "center",
      fontSize:{xs:"12px",md:'14px', lg:'14px', xl:'16px'},
      fontWeight:500,
      width:{
        xs:'100%',
        sm:'100%',
        md:'100%',
        lg:'100%',
        xl:'90%'
      },
      margin:'10px auto 0'
    },
    profileimg: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height:"100%"
    },
    plusicon: {
      position: "absolute",
      right: {
        xs:"-3px",
        sm:"-3px",
        md:"-3px",
        lg:"-3px",
        xl:"8px"
      },
      bottom:{
        xs:"-2px",
        sm:"-2px",
        md:"-2px",
        lg:"-2px",
        xl:"8px"
      },
      zIndex:1
    },
    profimgdoted:{
      width:{
        xs:'100px',
        sm:'110px',
        md:'110px',
        lg:'120px',
        xl:"200px"
      },
      height:{xs:'100px',
        sm:'110px',
        md:'110px',
        lg:'120px',
        xl:"200px"},
      background: 'linear-gradient(90deg, rgba(23, 186, 174, 0.1) 0%, rgba(41, 234, 165, 0.1) 100%)',
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"50%",
      position:'relative',
      zIndex:1,
      '&:before':{
        content:'""',
        position:'absolute',
        top:0,
        right:0,
        bottom:0,
        left:0,
        width:'calc(100% - 4px)',
        height:'calc(100% - 4px)',
        margin:'auto',
        background:'#ffffff',
        zIndex: 0,
        borderRadius:'50%'
      }
    },

    profimgback:{
      width:{xs:'70px',
        sm:'80px',
        md:'80px',
        lg:'80px',
        xl:"140px"},
      height:{xs:'70px',
        sm:'80px',
        md:'80px',
        lg:'80px',
        xl:"140px"},
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"50%",
      background:"linear-gradient(90deg, rgba(23, 186, 174, 0.1) 0%, rgba(41, 234, 165, 0.1) 100%)",
    },

    profimg: {
      width: {xs:'50px',
        sm:'60px',
        md:'60px',
        lg:'65px',
        xl:"107px"},
      height:{xs:'50px',
        sm:'60px',
        md:'60px',
        lg:'65px',
        xl:"107px"},
    },
    unselectbox: {
      backgroundColor: "rgba(246, 245, 245, 1)",
      padding: "10px 15px",
      borderRadius: "12px",
      position: "relative",
      margin: "10px 0",
      fontSize: {xs:"11px",md:"15px"},
      color: "#1A1A1A",
      fontWeight: 600,
      display:'flex',
      alignItems:'center',
      height:{xs:"50px",sm:"50px", md:'50px', lg:'50px', xl:'60px'},
      fontFamily:'Montserrat,sans-serif'
    },
    selecteventradiobox: {
      width: "340px",
    },
    unselecteventradiobox: {
      width: "340px",
    },
    radiobox: {
      position: "absolute",
      right: "0",
      top: 0,
      bottom:0,
      margin:'auto',
      display:'flex',
      alignItems:'center'
    },
    selectbox: {
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
    },
    subcontainer: {
      height: {xs:"45vh",sm:"45vh", md:'45vh',lg:"38vh",xl:'38vh'},
      overflowY: "auto",
    },
    bankcontainer1: {
      height: { xs: "calc(100vh - 380px)",sm:'calc(100vh - 320px)', md:'calc(100vh - 360px)', lg: "calc(100vh - 330px)", xl: "calc(100vh - 520px)" },
      overflowY: "auto",
    },

    bankcontainer: {
      height: { xs: "calc(100vh - 380px)",sm:'calc(100vh - 320px)', md:'calc(100vh - 360px)', lg: "calc(100vh - 330px)", xl: "calc(100vh - 520px)"  },
      overflowY: "auto",
    },
    googleautosuggestionlist: {
      marginTop: "0px",
      width: "100%",
      height: "154px",
      display: "flex",
      flex: "1 1 0%",
      flexDirection: "column",
      marginBottom: "108px",
      position: "absolute",
      zIndex: "1123",
      background: "#fff",
      padding: "0 10px",
      overflowY: "auto",
    },
    profileImgbox:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      width:"auto",
      margin:{
        xs:'0 auto 10px',
        sm:'0 auto 15px',
        md:'0 auto 15px',
        lg:'0 auto 15px',
        xl:'0 auto 40px'
      },
      position:'relative'
    }
  };
};
