import React from "react";

import ButtonComp from "../../../component/Button";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import evlogo from '../../../assets/images/evlogo.png'

const ContactUs = () => {
  const classes = getSx();
  return (
    <Box sx={classes.infocontainer}>
      <Typography variant="h5" sx={{
                        fontSize: {
                            xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '28px'
                        }, lineHeight: {
                            xs: '26px', sm: '28px', md: '32px', lg: '36px', xl: '40px'
                        }, fontWeight: 700, marginBottom: '30px', fontFamily: "Montserrat, sans-serif"
                    }}>
        Contact Us
      </Typography>
      <Box sx={classes.contactusimg}>
        <Box sx={classes.contactusimg1}>
        <img alt="" src={evlogo} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Typography
          variant={{ xs: "h5", md: "h4" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="40px 0px"
          fontSize={{ xs: "18px", md: "25px" }}
          sx={{fontFamily: "Montserrat, sans-serif"}}
          fontWeight="bold"
        >
          Email:
          <Typography
            variant="body"
            color="black"
            fontWeight="600"
            fontSize={{ xs: "15px", md: "20px" }}
            sx={{fontFamily:"Montserrat, sans-serif",}}
          >
            Info@eventutti.io
          </Typography>
        </Typography>
        <Box sx={{ width: { xs: "100%", md: "205px" } }}>
          <a
            href="mailto: Info@eventutti.io"
            style={{
              color: "black",
              fontWeight: "bold !important",
              textAlign: "center",
              fontSize: "15px",
              textDecoration: "none",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonComp label="Send Email" type="active" style={{fontSize:"18px",fontFamily: "Montserrat, sans-serif",fontWeight:600, width:'100%', height:'60px'}} />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
