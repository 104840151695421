import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const CustomTimePicker = ({ handleTimeChange, index, selindex, currentDate, isStartTime, handleDropDownStart, timings, fromComp }) => {
    const [time, setTime] = useState(null);
    const [noonVal, setNoonVal] = useState(null);
    const timeArr = ["12:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", 0, 0];
    const noonArr = ['AM', 'PM', 0, 0];
    const containerRef = useRef(null);
    const noonRef = useRef(null)
    const convertToHourFormat = (time) => {
        if (time) {
            let utcTime = dayjs.utc(time).local();
            let formattedTime = utcTime.format("hh:mm A");
            if (formattedTime.startsWith("0"))
                formattedTime = formattedTime.substring(1,)
            return formattedTime;
        }
        else
            return ""
    }

    const onChange = (time) => {
        setTime(time);
        let top ;
        if (time == "12:00") {
            top = 0;
        }
        else if (time == "1:00") {
            top = 43;
        }
        else if (time == "2:00") {
            top = 86;
        }
        else if (time == "3:00") {
            top = 128;
        }
        else if (time == "4:00") {
            top = 172;
        }
        else if (time == "5:00") {
            top = 215;
        }
        else if (time == "6:00") {
            top = 258;
        }
        else if (time == "7:00") {
            top = 301;
        }
        else if (time == "8:00") {
            top = 344;
        }
        else if (time == "9:00") {
            top = 387;
        }
        else if (time == "10:00") {
            top = 430;
        }
        else if (time == "11:00") {
            top = 473;
        }
        else
        {
            top=0;
        }


        containerRef.current?.scrollTo({
            top: top,
            behavior: "smooth"
        })

    }
    const handleNoonChange = (noon) => {
        setNoonVal(noon);
        let top = 0;
         if (noon == "PM") {
            top = 42
        }
        noonRef.current?.scrollTo({
            top: top,
            behavior: "smooth"
        })
    }
    const handleOk = () => {
        if (fromComp == "Add Service" || fromComp =="Edit Service") {
            handleDropDownStart(index, true)
            if (noonVal && time) {
                let val = `${time} ${noonVal}`;
                handleTimeChange(val, index)
            }
        }
        else {
            handleDropDownStart(index, selindex, true);
            if (noonVal && time) {
                let val = `${time} ${noonVal}`;
                handleTimeChange(val, index, selindex, currentDate, isStartTime)
            }
        }

    }

    const weekDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const checkObjectType=(day1)=>{
        if(isStartTime)
        return typeof timings[day1].startTime == "object"
        else
        return typeof timings[day1].endTime == "object"
    }
    const handleAddServiceComp = () => {
        let day1 = weekDays[index];
        day1 = day1?.toLowerCase();
        if (timings[day1] && timings[day1].startTime && timings[day1].endTime) {
            let val = "";
            if ((fromComp == "Add Service" || fromComp == "Edit Service") && checkObjectType(day1) )
                val = isStartTime ? convertToHourFormat(timings[day1].startTime) : convertToHourFormat(timings[day1].endTime);
            else
                val = isStartTime ? timings[day1].startTime : timings[day1].endTime
            let splitVal = val.split(" ");
            if(splitVal[0] && splitVal[1])
            {
            onChange(splitVal[0])
            handleNoonChange(splitVal[1]);
            }
        }
    }

    useEffect(() => {
        if (!fromComp) {
            if (timings && timings[index] && timings[index][selindex] && timings[index][selindex] != null) {
                let val;
                if (isStartTime) {
                    val = convertToHourFormat(timings[index][selindex].startTime);
                    let splitVal = val.split(" ");
                    onChange(splitVal[0])
                    handleNoonChange(splitVal[1]);
                }
                else if (timings[index][selindex]?.endTime) {
                    val = convertToHourFormat(timings[index][selindex].endTime);
                    let splitVal = val.split(" ");
                    onChange(splitVal[0])
                    handleNoonChange(splitVal[1]);
                }
            }
        }
        else if (fromComp == "Add Service" || fromComp == "Edit Service") {
            handleAddServiceComp()
        }
    }, [])
    return (
        <Box
            sx={{
                border: "2px solid #D4D4D4",
                display: "flex",
                color: "black",
                borderRadius: "20px",
                backgroundColor: "white",
                width: fromComp == "Add Service" || fromComp == "Edit Service" ? "100%" : "150px",
                marginBottom: "60px",
                height: '200px'
            }}
        >
            <Box
                sx={{
                    height: "170px",
                    width: fromComp == "Add Service" || fromComp == "Edit Service" ? "50%" : "80px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: "15px",
                    paddingRight: "0px",
                    marginBottom: "100px",
                    cursor: "pointer"
                }}
                ref={containerRef}
            >
                {timeArr?.map((item) => (

                    <Box
                        key={item}
                        sx={{
                            margin: "15px 15px 15px 15px",
                            fontSize: "18px",
                            color: "black",
                            borderBottom: item != 0 ? "1px solid gray" : "transparent",
                            visibility: item != 0 ? "visible" : "hidden",
                            fontWeight: time === item ? "600" : "500",

                        }}

                        onClick={() => { if (item != 0) onChange(item) }}
                        data-time={item}
                    >
                        {item}
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    height: "170px",
                    width: fromComp == "Add Service" || fromComp == "Edit Service" ? "40%" : "60px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: "15px",
                    paddingLeft: "0px",
                    cursor: "pointer"
                }}
                ref={noonRef}

            >

                {noonArr?.map((item) => (
                    <Box
                        key={item}
                        sx={{
                            margin: "15px 15px 15px 15px",
                            fontSize: "18px",
                            color: "black",
                            borderBottom: item != 0 ? "1px solid gray" : "transparent",
                            visibility: item != 0 ? "visible" : "hidden",
                            fontWeight: noonVal === item ? "600" : "500",
                        }}

                        onClick={() => { if (item != 0) handleNoonChange(item) }}
                        data-time={item}
                    >
                        {item}
                    </Box>
                ))}
            </Box>
            <Box sx={{ position: "absolute", bottom: "4rem", right: "1rem", color: "blue", cursor: "pointer" }} onClick={() => { handleOk() }}>OK</Box>
        </Box>
    );
}


export default CustomTimePicker;
