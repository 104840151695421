import instance from "../common/config/axios";
//apis for rating
export const addRatingApi = async (body) => {
  try {
    const response = await instance.post(`rating/create`,body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getRatingApi = async (id) => {
  try {
    const response = await instance.get(`rating/list-for-user/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const addReplyApi = async (id,msg) => {
  try {
    const response = await instance.put(`rating/reply/${id}`,{text:msg});
    return response;
  } catch (error) {
    return error;
  }
};

