import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const TimeInfo = ({ timeval }) => {
  const [val, setVal] = useState("");
  const CallFunc = () => {
    const checkDate = dayjs(new Date(timeval));
    setVal(checkDate.format('hh:mm a'));
  }

  useEffect(() => {
    CallFunc();
  }, [timeval]);

  return (
    <div>{val}</div>
  )
}

export default TimeInfo;