import React, { useEffect, useState } from "react";

const MyTimer = ({ setExpiryTime }) => {
  const [time, setTime] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => setTime((prev) => prev - 1), 1000);

    setTimeout(() => {
      clearInterval(timer);
      setExpiryTime("");
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "10px" }}>
      <div style={{ fontSize: "16px", color: "#231F20",fontFamily:"Montserrat, sans-serif" }}>
        00:<span>{time < 10 ? `0${time}` : time}</span>
      </div>
    </div>
  );
};

export default MyTimer;
