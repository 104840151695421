import React from 'react'

const FitnessSymbol = ({value}) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3333 37.25L20 34.9167L25.9167 29L11.75 14.8333L5.83333 20.75L3.5 18.4167L5.83333 16L3.5 13.6667L7 10.1667L4.66667 7.75L7 5.41667L9.41667 7.75L12.9167 4.25L15.25 6.58333L17.6667 4.25L20 6.58333L14.0833 12.5L28.25 26.6667L34.1667 20.75L36.5 23.0833L34.1667 25.5L36.5 27.8333L33 31.3333L35.3333 33.75L33 36.0833L30.5833 33.75L27.0833 37.25L24.75 34.9167L22.3333 37.25Z"
        fill={value ? "#17BAAE" : "black"}
      />
    </svg>
  );
}

export default FitnessSymbol