import React from "react";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import {modalVariant} from "../../../component/Modal/Modalutils";
import CustomTextField from "../../../component/Textfield";
import {Box, Typography} from "@mui/material";
import {getSx} from "./style";
import passwordIconInput from "../../../assets/images/svg/passwordiconsvg.svg";

import {changePasswordApi} from "../../../api/profile";
import {showModal} from "../../../store/slices/common";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const classes = getSx();
    const validationSchema = Yup.object({
        password: Yup.string().required("Required"),
        newPassword: Yup.string()
            .required('Password is required')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,20}$/, 'Must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {password: "", newPassword: "", confirmPassword: ""},
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: true,
    });
    const callApi = async () => {
        const resp = await changePasswordApi({
            "oldPassword": formik.values.password, "newPassword": formik.values.newPassword
        })
        if (resp.status === 200) {
            dispatch(showModal({
                modalType: modalVariant.CHANGE_PASSWORD_SUCCESS, resolveCallback: () => {
                    dispatch(showModal(null));
                }
            }));
            formik.resetForm()
        }
    }

    return (<>
            <Typography variant="h5"
                        sx={{
                            fontSize: {
                                xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '28px'
                            }, lineHeight: {
                                xs: '26px', sm: '28px', md: '32px', lg: '36px', xl: '40px'
                            }, fontWeight: 700, marginBottom: {
                                xs:'15px',
                                sm:'15px',
                                md:'15px',
                                lg:'20px',
                                xl:'30px'
                            }, fontFamily: "Montserrat, sans-serif"
                        }}
            >
                Change Password
            </Typography>
            <Box sx={{
                marginBottom:{
                    xs:'10px',
                    sm:'10px',
                    md:'10px',
                    lg:'15px',
                    xl:'20px'
                }
            }}>
                <Typography sx={classes.smallheading}>
                Old Password
            </Typography>
            <Box sx={classes.subform1}>
                <Typography
                    className="input-with-icon-div margintopp"
                    component="div"
                    position={"relative"}
                    sx={{fontFamily:"Montserrat, sans-serif",}}
                >
                    <CustomTextField
                        type="password"
                        variant="outlined"
                        placeholder="Enter  Password"
                        name="password"
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        errorMessage={formik.touched.password && formik.errors.password}
                        className={"email-with-icon"}
                        onChange={(e) => {
                            formik.setFieldTouched("password", true);
                            formik.handleChange(e)
                        }}
                    />
                    <Typography
                        className="input-icon"
                        component="div"
                        position="absolute"
                        sx={{fontFamily:"Montserrat, sans-serif",}}
                    >
                        <img
                            src={passwordIconInput}
                            style={{opacity: "0.6"}}
                            alt="Image"
                        />
                    </Typography>
                </Typography>
            </Box>
            </Box>
            <Box sx={{
                marginBottom:{
                    xs:'10px',
                    sm:'10px',
                    md:'10px',
                    lg:'15px',
                    xl:'20px'
                }
            }}>
                <Typography sx={classes.smallheading}>
                New Password
            </Typography>
            <Box sx={classes.subform1}>
                <Typography
                    className="input-with-icon-div margintopp"
                    component="div"
                    position={"relative"}
                    sx={{fontFamily:"Montserrat, sans-serif",}}
                >
                    <CustomTextField
                        type="password"
                        variant="outlined"
                        placeholder="Enter New  Password"
                        name="newPassword"
                        value={formik.values.newPassword}
                        onBlur={formik.handleBlur}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        errorMessage={formik.touched.newPassword && formik.errors.newPassword}
                        className={"email-with-icon"}
                        onChange={(e) => {
                            formik.setFieldTouched("newPassword", true);
                            formik.handleChange(e)
                        }}
                    />
                    <Typography
                        className="input-icon"
                        component="div"
                        position="absolute"
                        sx={{fontFamily:"Montserrat, sans-serif",}}
                    >
                        <img
                            src={passwordIconInput}
                            style={{opacity: "0.6"}}
                            alt="Image"
                        />
                    </Typography>
                </Typography>
            </Box>
            </Box>
            <Box sx={{
                marginBottom:{
                    xs:'10px',
                    sm:'10px',
                    md:'10px',
                    lg:'15px',
                    xl:'20px'
                }
            }}>
                <Typography sx={classes.smallheading}>
                    Confirm Password
                </Typography>
                <Box sx={classes.subform1}>
                <Typography
                    className="input-with-icon-div margintopp"
                    component="div"
                    position={"relative"}
                    sx={{fontFamily:"Montserrat, sans-serif",}}
                >
                    <CustomTextField
                        type="password"
                        variant="outlined"
                        placeholder="Re-type new password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        errorMessage={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        className={"email-with-icon"}
                        onChange={(e) => {
                            formik.setFieldTouched("confirmPassword", true);
                            formik.handleChange(e)
                        }}
                    />
                    <Typography
                        className="input-icon"
                        component="div"
                        position="absolute"
                        sx={{fontFamily:"Montserrat, sans-serif",}}
                    >
                        <img
                            src={passwordIconInput}
                            style={{opacity: "0.6"}}
                            alt="Image"
                        />
                    </Typography>
                </Typography>
            </Box>
            </Box>
                <Box sx={{...classes.savebtn, marginTop:{
                    xs:'15px',
                        sm:'15px',
                        md:'20px',
                        lg:'20px',
                        xl:'40px'
                    }}}>
                    <ButtonComp label="save" style={{
                        fontFamily:'Montserrat,sans-serif',
                        height:'60px',
                        width:'100%',
                        fontWeight:600
                    }} disabled={!(formik.isValid && formik.dirty)}
                                type={(formik.isValid && formik.dirty) ? "active" : "disabled"} onClick={() => {
                        callApi()
                    }}/>
                </Box>
        </>);
};

export default ChangePassword;
