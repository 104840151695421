import instance from "../common/config/axios";
//apis for card info
export const addCardApi = async (val) => {
    try {
      const response = await instance.post(`payment/add-card`,{token:val});
      return response;
    } catch (error) {
      return error;
    }
  };

  export const listCardApi = async () => {
    try {
      const response = await instance.get(`payment/card-list`);
      return response;
    } catch (error) {
      return error;
    }
  };

  export const makeFullPaymentApi = async (data) => {
    try {
      const response = await instance.post(`payment/make-full-payment`,data);
      return response;
    } catch (error) {
      return error;
    }
  };