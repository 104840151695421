import React, {  useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
  
} from "@mui/material";
import { getSx } from "./style";
import { getAddressObject } from "../../../helper";

import GoogleMapView from "../../serviceprovider/MyService/GoogleMapView";

const AddressandDesc = ({
  addBookService,
  setAddBookService,
  handleSave,
  serviceName,
}) => {
  const [showList, setShowList] = useState(false);
  const [error,setError]=useState("")
  const [value, setValue] = useState();
  const classes = getSx();
  let apikey = process.env.REACT_APP_GOOGLE_API_KEY;
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: apikey,
    options: {
      // componentRestrictions: { country: "ca" },
    },
  });

  const validationSchema = Yup.object({
    address: Yup.string()
      .required("Address Required")
      .matches(/^[A-Za-z0-9 ]+$/, "Invalid address"),
    state: Yup.string()
      .required("State Required")
      .matches(/^[A-Za-z ]+$/, "Invalid state"),
    zipCode: Yup.string()
      .required("ZipCode Required")
      .matches(/^[A-Za-z0-9 ]+$/, "Invalid zipCode"),
  });
 
  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        formik.setFieldValue("address", placeDetails?.name);
        formik.setFieldValue("state", address?.region);
        formik.setFieldTouched("state", true);
        if (address?.postal_code.length > 0) {
          formik.setFieldTouched("zipCode", true);
          formik.setFieldValue("zipCode", address?.postal_code);
        }
        formik.setFieldTouched("address", true);
        formik.setErrors({});
        formik.validateForm().then(() => {
          formik.setErrors({});
        });
        setAddBookService({
          ...addBookService,
          location: {
            lat: placeDetails.geometry.location.lat(),
            long: placeDetails.geometry.location.lng(),
          },
          address: placeDetails?.name,
          state: address?.region,
          zipCode: address?.postal_code,
          city: address?.city,
        });
        setShowList(false);
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      address: "",
      state: "",
      zipCode: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });
  const checkIsAlphabets=(value)=>{
    let lines = value.split('\n');
    let cleanedLines = lines.map(line => line.trim()).filter(line => line.length > 0);
    let cleanedText = cleanedLines.join(' ');
if(cleanedText.trim().match(/^[A-Za-z,./?!&$:;'"[\]{}() ]+$/))
{
  setError("")
}
else
{   
  setError("Only Alphabets are allowed")
}
  }

  return (
    <>
      <Typography
        component="div"
        variant="div"
        height={{ xs: "auto", sm:'auto', md:'auto', lg: "calc(100vh - 200px)", xl: "calc(100vh - 550px)" }}
        sx={{ overflow: "auto" }}
      >
        <Typography
          varaint="h5"
          fontWeight="bold"
          fontFamily="Montserrat,sans-serif"
          sx={{
            margin:{
              xs:'15px 0 10px',
              sm:'15px 0 10px',
              md:'15px 0 10px',
              lg:'15px 0 10px',
              xl:'30px 0 10px'
            }
          }}
        >
          Event Description
        </Typography>

        <TextareaAutosize
          placeholder="Describe Description "
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "rgba(246, 245, 245, 1)",
            borderRadius: "15px",
            marginBotton: "3rem",
            fontSize: "15px",
            fontFamily: "Montserrat",
            border: "none",
            outline: "none",
            overflowY: "auto !important",
            maxHeight: "200px",
            fontWeight: "500",
            color: "#000",
          }}
          className="textArea"
          name="description"
          value={formik.values.description}
          onChange={(e) => {
            formik.setFieldTouched("description", true);
            formik.handleChange(e);
            checkIsAlphabets(e.target.value)  
            setAddBookService({
              ...addBookService,
              description: e.target.value,
            });
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          errorMessage={formik.touched.description && formik.errors.description}
          minRows={8}
        />
        {error && <Box sx={{color:"red",fontSize:"14px"}}>Only Alphabets are allowed</Box>}

       {serviceName!="Venues" &&<Grid
          xs={12}
          sm={12}
          md={12}
          lg={10}
          xl={10}
          direction={"column"}
          spacing={2}
          width="100%"
          sx={{marginBottom: {
            xs:'20px',
              sm:'20px',
              md:'30px',
              lg:0,
              xl:0
            }
                }}
        >
          <Grid item>
            <div className="map_search">
              <Box sx={{ position: "relative" }}>
                <Typography
                  sx={{
                    ...classes.smallheading,
                    fontWeight: "600",
                    color: "500",
                    fontFamily:'Montserrat,sans-serif'
                  }}
                >
                  Address
                </Typography>
                <CustomTextField
                  size="large"
                  value={value}
                  autoFocus={value?.length > 0 ? true : false}
                  onChange={(evt) => {
                    setValue(evt.target.value);
                    setAddBookService({
                      ...addBookService,
                      address: evt.target.value,
                    });

                    formik.setFieldValue("address", evt.target.value);
                    getPlacePredictions({ input: evt.target.value });
                    if (!isPlacePredictionsLoading) setShowList(true);
                  }}
                  onKeyDown={(event) => {
                    if (event?.keyCode === 8 || event?.keyCode === 46) {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("zipCode", "");
                    }
                  }}
                  placeholder="Enter Address"
                />
                {value !== "" && showList && placePredictions?.length > 0 && (
                  <Box sx={classes.googleautosuggestionlist}>
                    <List>
                      {placePredictions?.map((item) => (
                        <ListItem
                          key={item.place_id}
                          onClick={() => getDetails(item?.place_id)}
                          style={{ cursor: "pointer" }}
                        >
                          <ListItemText primary={item.description} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
              </Box>
            </div>

            <Stack
              sx={{
                marginTop:{
                  xs:"10px",
                  sm:"10px",
                  md:"15px",
                  lg:"15px",
                  xl:"24px"
                }
              }}
              direction={"row"}
              spacing={1}
              style={{ justifyContent: "space-between" }}
            >
              <Stack width="48%">
                <CustomTextField
                  type="text"
                  variant="outlined"
                  placeholder="State"
                  name="state"
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldTouched("state", true);
                    formik.handleChange(e);
                    formik.setFieldValue("state", e.target.value);
                    setAddBookService({...addBookService,state:e.target.value})

                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  errorMessage={formik.touched.state && formik.errors.state}
                />
              </Stack>

              <Stack width="48%">
                <CustomTextField
                  type="text"
                  variant="outlined"
                  placeholder="Zip Code"
                  name="zipCode"
                  maxLength={8}
                  value={formik.values.zipCode}
                  onChange={(e) => {
                    formik.setFieldTouched("zipCode", true);
                    formik.handleChange(e);
                    formik.setFieldValue("zipCode", e.target.value);
                    setAddBookService({...addBookService,zipCode:e.target.value})
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.zipCode && Boolean(formik.errors.zipCode)
                  }
                  errorMessage={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Stack>
            </Stack>
          </Grid>

          {addBookService?.location?.lat && serviceName!="Venues" && (
            <Box sx={{ marginBottom: "2rem" }}>
              <GoogleMapView addService={addBookService} />
            </Box>
          )}
        </Grid>}
      </Typography>

      <ButtonComp
        label="Book Service"
        type={
          (serviceName != "Venues" &&
            formik?.dirty &&
            formik?.values?.zipCode.length > 0 &&
            formik?.values?.state.length > 0 && !error &&
            addBookService?.description?.length > 0) ||
          (serviceName == "Venues" && addBookService?.description?.length && !error> 0)
            ? "active"
            : "disabled"
        }
        disabled={
          (serviceName != "Venues" &&
            formik.dirty &&
            formik?.values.zipCode.length > 0 &&
            formik.values.state.length > 0 && !error &&
            addBookService?.description?.length > 0) ||
          (serviceName == "Venues" && addBookService?.description?.length > 0 && !error)
            ? false
            : true
        }

        width="100%"
        onClick={() => {
          handleSave();
        }}
      />
    </>
  );
};
export default AddressandDesc;
AddressandDesc.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  mapValue: PropTypes.string,
  setMapValue: PropTypes.func,
};
