import axios from "axios";
import instance from "../common/config/axios";
//apis for addService
export const getServiceListApi = async () => {
  let token = localStorage.getItem("token");
  let guestToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";
  if (token) {
    try {
      const response = await instance.get(`ad-on-service/list-for-user`);
      return response;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/ad-on-service/list-for-user`,
        { headers: { accessToken: `Bearer ${guestToken}` } }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
};
export const getCategoryListApi = async (id) => {
  let token = localStorage.getItem("token");
  let guestToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";
  if (token) {
    try {
      const response = await instance.get(`category/list-for-user/${id}`);
      return response;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/category/list-for-user/${id}`,
        { headers: { accessToken: `Bearer ${guestToken}` } }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
 
};

export const addServiceApi = async (data) => {
  try {
    const response = await instance.post(`service/add`, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const addAddOnServiceApi = async (serviceId, data) => {
  try {
    const response = await instance.put(
      `service/add-addon-service/${serviceId}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAddOnServiceList = async (serviceId) => {
  try {
    const response = await instance.get(
      `/service/service-details/${serviceId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const getServiceList = async () => {
  try {
    const response = await instance.get(`service/list`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getServiceDetails = async (id) => {
  let token = localStorage.getItem("token");
  let guestToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";
  if (token) {
    try {
      const response = await instance.get(`/service/service-details/${id}`);
      return response;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/service-details/${id}`,
        { headers: { accessToken: `Bearer ${guestToken}` } }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
};

export const updateEditAddOnService = async (
  serviceId,
  addonServiceId,
  val
) => {
  try {
    const response = await instance.put(
      `service/update-addon-service/${serviceId}/${addonServiceId}`,
      val
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateEditAddService = async (serviceId, val) => {
  try {
    const response = await instance.put(`service/update/${serviceId}`, val);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAddServiceApi = async (serviceId) => {
  try {
    const response = await instance.delete(`service/delete/${serviceId}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const deleteAddOnServiceApi = async (serviceId, addonServiceId) => {
  try {
    const response = await instance.delete(
      `service/delete-addon-service/${serviceId}/${addonServiceId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCitiesList = async (lat, lng) => {
  let token = localStorage.getItem("token");
  let guestToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";
  if (token) {
    try {
      const response = await instance.get(
        `service/cities?lat=${lat}&long=${lng}&radius=1000`
      );
      return response;
    } catch (error) {
      return error;
    }
  } else {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/service/cities?lat=${lat}&long=${lng}&radius=1000`,
        { headers: { accessToken: `Bearer ${guestToken}` } }
      );
      return response;
    } catch (error) {
      return error;
    }
  }
};

export const getBookServiceAmountApi = async (data) => {
  try {
    const response = await instance.post(
      `service/calculate-total-amount`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const callRequestServiceApi = async (data) => {
  try {
    const response = await instance.post(`service/book`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const callBookingConfirmationApi = async (id) => {
  try {
    const response = await instance.get(
      `service/get-booked-service-details/${id}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
