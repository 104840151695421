import React, { useState, useEffect,useRef } from "react";

import { Box } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import blacklocationicon from "../../../assets/images/blacklocationicon.svg";
import newMarker from "../../../assets/images/redlocationicon.svg";
import { mapOptions } from "../../../common/constant";

const MapFile = ({
  filterServiceList,
  servicesList,
  callServiceListApi,
  selectedValue,
  currentLocation,
  setCurrentLocation,
  days,
  currentService,
  setCurrentService
}) => {
  const mapRef = useRef();
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const handleMapCenterChanged = () => {
    if (mapRef?.current) {
      const newCenter = mapRef?.current?.getCenter()?.toJSON();
      callServiceListApi({serviceId:selectedValue?._id, lat:newCenter?.lat,long:newCenter?.lng},true,1,[...days],50);
      setCurrentLocation(newCenter);
      setCurrentService();
    }
  };

 
  const mapContainerStyle = {
    width: "100%",
    height:"100%",
    borderRadius: "1.25rem",
    background: "#fff",
    margin: "auto",
    boxShadow: '0px 10px 40px 0px #00000012'
  };

  useEffect(() => {
    let script; 
    if (!window.google || !window.google.maps) {
      script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      script.onload = () => setIsGoogleMapsLoaded(true);
      document.body.appendChild(script);
    } else {
      setIsGoogleMapsLoaded(true);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);
 
  return (
    <div>
      {isGoogleMapsLoaded && (
        <Box sx={{height: {xs: "23vh",sm:'25vh', md: "62vh", lg:'52vh', xl:'64vh'},}}>
        <GoogleMap
          mapContainerStyle={{
            ...mapContainerStyle,
          }}
          center={{ lat: Number(currentLocation?.lat), lng: Number(currentLocation?.lng) }}
          zoom={10}
          ref={mapRef}
          options={mapOptions}
          onDragEnd={handleMapCenterChanged}
          onLoad={(map) => (mapRef.current = map)}
          mapContainerClassName='map-container'
        >
          {servicesList?.map((marker, index) => (
            <>
            <MarkerF
              onClick={() => {
                setCurrentService(marker);
                let latVal= marker?.isAccepted?Number(marker?.location?.lat):Number(marker?.location?.newLat);
                let longVal= marker?.isAccepted?Number(marker?.location?.long):Number(marker?.location?.newLong);
                filterServiceList(
                  latVal,
                  longVal
                );
                setCurrentLocation({
                  lat:latVal,
                  lng: longVal,
                });
              }}
              key={marker}
              position={{
                lat: marker?.isAccepted?Number(marker?.location?.lat):Number(marker?.location?.newLat),
                lng:   marker?.isAccepted?Number(marker?.location?.long):Number(marker?.location?.newLong),
              }}
              icon={{
                url: currentService?._id == marker?._id
                    ? newMarker
                    : blacklocationicon,
              }}
            />
            </>
          ))}
        </GoogleMap>
        </Box>
      )}
    </div>
  );
};

export default MapFile;
