import * as React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Slider, {SliderThumb} from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {setFilteredInfo} from '../../../store/slices/common';

function ValueLabelComponent(props) {
    const {children, value} = props;

    return (<Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>);
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired, value: PropTypes.number.isRequired,
};

const AirbnbSlider = styled(Slider)(({theme}) => ({
    color: 'rgba(23, 186, 174, 1)', height: 3, padding: '13px 0', '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: '#d8d8d8',
        border: '1px solid rgba(41, 234, 165, 1)',
        boxShadow: "none !important",
        '&:hover': {
            boxShadow: "none !important",
        },
        '&:focus': {
            boxShadow: "none !important",
        },
        '&:active': {
            boxShadow: "none !important",
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'linear-gradient(rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))',
            marginLeft: 1,
            marginRight: 1,
            display: 'none',
        },
    }, '& .MuiSlider-track': {
        height: 10,
    }, '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 10,
    }, "& .MuiSlider-valueLabelOpen": {
        color: "black !important", backgroundColor: "rgba(246, 245, 245, 1) !important", fontWeight: "600"
    }
}));

function AirbnbThumbComponent(props) {
    const {children, ...other} = props;
    return (<SliderThumb {...other}>
            {children}
            <span className="airbnb-bar"/>
            <span className="airbnb-bar"/>
            <span className="airbnb-bar"/>
        </SliderThumb>);
}

AirbnbThumbComponent.propTypes = {
    children: PropTypes.node,
};
const minDistance = 10;

export default function SliderComp() {
    const filteredInfo = useSelector((state) => state.common.filteredInfo);
    const dispatch = useDispatch();
    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            let val = [Math.min(newValue[0], filteredInfo?.budget[1] - minDistance), filteredInfo?.budget[1]];
            dispatch(setFilteredInfo({budget: val}))
        } else {
            let val = [filteredInfo?.budget[0], Math.max(newValue[1], filteredInfo?.budget[0] + minDistance)];
            dispatch(setFilteredInfo({budget: val}))
        }
    };

    const valueLabelFormat = (value) => {
        return `$ ${numberWithCommas(value)}`;
    }

    function numberWithCommas(x) {
        let parts = x.toString().split(".");
        let regex=/\B(?=(\d{3})+(?!\d))/g
        parts[0] = parts[0].replace(regex, ",");
        return parts.join(".");
    }

    return (<Box sx={{width: "100%"}}>
            <Typography gutterBottom sx={{
                marginBottom: "40px",
                color: "#1A1A1A",
                fontWeight: "600",
                fontSize: '16px',
                fontFamily: "Montserrat, sans-serif",
            }}>Budget</Typography>
            <Box sx={{padding: {xs: "0px 30px 20px 30px", md: "0px 30px"}}}>
                <AirbnbSlider
                    slots={{thumb: AirbnbThumbComponent}}
                    getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                    value={filteredInfo?.budget}
                    onChange={handleChange1}
                    valueLabelDisplay="on"
                    valueLabelFormat={valueLabelFormat}
                    step={10}
                    min={0}
                    max={10000}
                    disableSwap
                />
            </Box>
        </Box>);
}
