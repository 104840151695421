import React from 'react'

import BankDetailsScreen from "./BankDetailsScreen";
import ChangePassword from "./ChangePassword";
import ContactUs from "./ContactUs";
import FavouritesInfo from './FavouritesInfo';
import PaymentMethod from "./PaymentMethod";
import ProfileSettings from "./ProfileSettings";
import TransactionHistory from "./TransactionHistory";
import passwordicon from "../../../assets/images/svg/profileicons/changepasswordicon.svg";
import contactusicon from "../../../assets/images/svg/profileicons/contactusicon.svg";
import crediticon from "../../../assets/images/svg/profileicons/creditcardicon.svg";
import deleteicon from "../../../assets/images/svg/profileicons/deleteicon.svg";
import editicon from "../../../assets/images/svg/profileicons/editicongreen.svg";
import favouritesicon from "../../../assets/images/svg/profileicons/favouritesicon.svg";
import logouticon from "../../../assets/images/svg/profileicons/logouticon.svg";
import transactionion from "../../../assets/images/svg/profileicons/transactionhistoryicon.svg";

export const sidebarItems = [
  {
    title: "Edit Profile",
    activeRoute: "Edit Profile",
    icon: editicon,
    component: <ProfileSettings />,
  },
  {
    title: "Change Password",
    activeRoute: "Change Password",
    icon: passwordicon,
    component: <ChangePassword />,
  },
  {
    title: "Favorites",
    activeRoute: "Favorites",
    icon: favouritesicon,
    component: <FavouritesInfo />,
  },
  {
    title: "Payment Method",
    activeRoute: "Payment Method",
    icon: crediticon,
    component: <PaymentMethod />,
  },
  {
    title: "Bank Details",
    activeRoute: "Bank Details",
    icon: crediticon,
    component: <BankDetailsScreen />,
  },
  {
    title: "Transaction History",
    activeRoute: "Transaction History",
    icon: transactionion,
    component: <TransactionHistory />,
  },
  {
    title: "Contact Us",
    activeRoute: "Contact Us",
    icon: contactusicon,
    component: <ContactUs />,
  },
  {
    title: "Logout",
    activeRoute: "Logout",
    icon: logouticon,
  },
  {
    title: "Delete Account",
    activeRoute: "Delete Account",
    icon: deleteicon,
  },
];
