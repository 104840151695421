export const convertFileSize = (sizeInBytes) => {
  const kilobytes = sizeInBytes / 1024;
  if (kilobytes < 1) {
    return sizeInBytes.toFixed(2) + " B";
  } else if (kilobytes < 1024) {
    return kilobytes.toFixed(2) + " KB";
  } else {
    const megabytes = kilobytes / 1024;
    return megabytes.toFixed(2) + " MB";
  }
};
