
export const getSx=()=>{
    return {
        severicecatinfo :{
            border:"1px solid rgba(218, 218, 218, 1)",
            display:"flex",
            borderRadius:"18px",
            padding:"11px 20px",
            marginBottom:"15px"
        },
        severicecatinfosel:{
            marginBottom:"15px",
            border:"1px solid rgba(41, 234, 165, 1)",
            display:"flex",
            borderRadius:"18px",
            padding:"11px 20px",
            background:"linear-gradient(to right,rgba(23, 186, 174, 0.1), rgba(41, 234, 165, 0.1))"
        },
        serviceinfodisabled:{
           opacity:0.1,
           cursor:"not-allowed",
           border:"1px solid #dadada",
           display:"flex",
           borderRadius:"18px",
           padding:{
               xs:"4px 10px",
               sm:"6px 15px",
               md:"6px 15px",
               lg:"6px 15px",
               xl:"11px 20px"
           },
           marginBottom:{
               xs:"10px",
               sm:"10px",
               md:"10px",
               lg:"10px",
               xl:"15px"
           }
        },
        singlebox: {
            width: "100%",
            borderRadius: "15px",
            padding: {
                xs:"15px 15px 15px 10px",
                sm:"15px 15px 15px 10px",
                md:"20px 20px 20px 10px",
                lg:"20px 20px 20px 10px",
                xl:"25px 25px 25px 10px"
            },
            border: "1px solid rgba(218, 218, 218, 1)",
            position: "relative",
            paddingTop:{
                xs: "15px",
                sm: "15px",
                md: "20px",
                lg: "20px",
                xl: "1.5rem"
            },
          },
          headinfo: {
            position: "absolute",
            top: "-1.5rem",
            backgroundColor: "#E9FCF6",
            width: "90%",
            margin:'auto',
            borderRadius: "14px",
            minHeight: {
                xs:'auto',
                sm:'auto',
                md:'auto',
                lg:"50px",
                xl:"60px"
            },
            padding: {
                xs:"5px 10px",
                sm:"5px 10px",
                md:"6px 10px",
                lg:"8px 10px",
                xl:"10px"
            },
              display:'flex',
              alignItems:'center'
          },
          subcatinfo: {
            marginTop: "2rem",
          },
          googleautosuggestionlist: {
            marginTop: "0px",
            width: "100%",
            height: "154px",
            display: "flex",
            flex: "1 1 0%",
            flexDirection: "column",
            marginBottom: "108px",
            position: "absolute",
            zIndex: "1123",
            background: "#fff",
            padding: "0 10px",
            overflowY: "auto",
          },
          taxinfo: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            border:"1px solid rgba(218, 218, 218, 1)",
            borderRadius:"19px",
            padding:"10px 20px",
          },
          taxinformation:{
            height:{xs:"317px",sm:"29vh",md:"25vh",lg:"53vh",xl:"50vh"},
            overflowY:"auto",
          },
          catinfo1: {
            background: "rgba(23, 186, 174, 0.1)",
            height: "auto",
            borderRadius: "15px",
            padding: "20px",
                },
          catmain: {
            display: "flex",
            justifyContent: "space-between",
            marginTop:"10px",
            fontFamily:'Montserrat,sans-serif',
          },
       
          catleft: {
            fontSize: {xs:"12px",md:"14px"}, fontFamily:'Montserrat,sans-serif'         },
          catright: {
            color: "black",
            fontWeight: "600",
            fontSize: {xs:"10px",md:"14px"},
          fontFamily:'Montserrat,sans-serif'
          },
          paybtn:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          },
          carddots:{
            fontSize:{xs:"12px",md:"24px"},
            marginTop:"-12px",
             marginLeft:"10px",
              fontFamily:'Montserrat,sans-serif'
          },
          bookcontainer:{
            borderRadius:"20px",
            padding:{
                xs:"10px",
                sm:"15px",
                md:"15px",
                lg:"15px",
                xl:"30px"
            },
            height:{
                xs:"calc(100vh - 210px)",
                sm:"calc(100vh - 170px)",
                md:"calc(100vh - 170px)",
                lg:'calc(100vh - 170px)',
                xl:'calc(100vh - 200px)'
            },
            overflowY:"auto",
            margin:{
                xs:"10px 0 0",
                sm:"10px 0 0",
                md:"10px 0 0",
                lg:"10px 0 0",
                xl:"15px 0 0",
            },
            position:"relative",
            fontFamily:'Montserrat,sans-serif'
          },
          bookmain:{display:{xs:"block",lg:"flex"},height:{xs:"auto"}},
          bookleft:{flex:"2",padding:"1rem 1rem 1rem 0px",borderRight:{
              xs:0,
                  sm:0,
                  md:0,
                  lg:"1px solid rgba(0, 0, 0, 0.1)",
                  xl:"1px solid rgba(0, 0, 0, 0.1)"
              },height:"100%"},
          bookright:{flex:"3",padding:"1rem",height:"100%"},
    }}