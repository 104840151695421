import React from 'react';
import {useSelector,useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { popBreadCrum } from '../../store/slices/breadcrumb';

function Breadcrumb() {
   const navigate=useNavigate();
   const dispatch=useDispatch();
   const rep=useSelector((state)=>state.BreadCrumbs)
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {rep?.map((route, index) => {
        const last = index === rep.length - 1;
        return last ? (
          <Typography sx={{
              fontSize:{
                  xs:'13px',
                  sm:'14px',
                  md:'14px',
                  lg:'16px',
                  xl:'16px'
              }
          }} key={route.path} color="black"  fontWeight="bold" display="flex" textTransform="capitalize" fontFamily="Montserrat, sans-serif ">
            <>
           
            {route?.name}
            </>
          </Typography>
        ) : (
          <Typography   to={route.path} color="rgba(0,0,0,0.5)" fontFamily="Montserrat, sans-serif " sx={{cursor:"pointer",textDecoration:"none",fontSize:{
                  xs:'13px',
                  sm:'14px',
                  md:'14px',
                  lg:'16px',
                  xl:'16px'
              }, display:"flex",alignItems:"center",'&:hover': {
            textDecoration: 'underline',
            color: 'black', // Change color on hover
          },}} 
          onClick={()=>{if(route.breadFunc)
            {
            route.breadFunc();
            dispatch(popBreadCrum())
            }
            else
            {
            navigate(route.path)
          }}}>
             {route?.icon&&<img alt="" src={route?.icon} style={{marginRight:"5px"}} />}
            {route.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;