import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography, Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import greencircleicon from '../../../assets/images/radioicongreen.svg'

const RadioComp = ({ selected, handleChange ,classes,data,item,index}) => {
  return (
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={selected[index]}
      onChange={(e) => {
        handleChange(e, index);
      }}
    >
      <Typography
        sx={{
          display: "flex",
       fontSize: { xs: "14px", sm:'14px', md: "15px", lg:'16px', xl:'18px' },
      fontWeight: 600,
      marginBottom: {
              xs:0,
          sm:0,
          md:0,
          lg:0,
          xl:"12px"
      },
      color: "#1A1A1A",
      fontFamily: "Montserrat , sans-serif !important",}}
        variant="body1"
      >
        
        <span>
          {item?.name &&
          `${index+1 }.`}&nbsp;
        </span>{" "}
        <Typography
            sx={{
       fontSize: { xs: "14px", sm:'14px', md: "15px", lg:'16px', xl:'18px' },
      fontWeight: 600,
      marginBottom: "12px",
      color: "#1A1A1A",
      fontFamily: "Montserrat , sans-serif !important",}}
          variant="body1"
        >
          {item?.name}
        </Typography>
      </Typography>

      {item?.value?.map((val) => {
        return (
          <>
            <Box
              key={val}
              sx={
                selected[index] == val
                  ? { ...classes.unselectbox, ...classes.selectbox }
                  : classes.unselectbox
              }
            >
              {val}
              <Box sx={classes.radiobox}>
              {val=="Mexico" && <span style={{color:"gray",marginRight:"10px"}}>Coming Soon</span>}
                <FormControlLabel
                  value={val}
                  control={
                    <Radio
                    disabled={val=="Mexico"?true:false}
                      sx={{ color: "rgba(23, 186, 174, 1)" }}
                      icon={<img alt="" src={greencircleicon}/>}
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{
                            color: "rgb(22, 192, 152)",
                          }}
                        />
                      }
                    />
                  }
                />
              </Box>
              
            </Box>
          </>
        );
      })}
    </RadioGroup>
  );
};

export default RadioComp;
