import faviconpopup from '../../assets/images/faviconpopup.svg';
import otpiconpopup from "../../assets/images/otpiconpopup.svg";
import popupdeleteicon from "../../assets/images/popupdeleteicon.svg";
import infoiconpopup from "../../assets/images/svg/infoiconpopup.svg";
import tickIconSvg from "../../assets/images/tickicon.svg";

export const modalVariant = {
  LOGOUT: "LOGOUT",
  GENERATE_OTP: " GENERATE_OTP",
  ADDON_SUCCESS: "addon_success",
  DELETESERVICE_POPUP: "DELETESERVICE_POPUP",
  ADDONUPDATE_SUCCESS: "ADDONUPDATE_SUCCESS",
  REQUEST_SERVICE_SUCCESS: "REQUEST_SERVICE_SUCCESS",
  ADDCARD_SUCCESS: "ADDCARD_SUCCESS",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  REJECT_BOOKING: "REJECT_BOOKING",
  ACCEPT_SERVICE_SUCCESS: "ACCEPT_SERVICE_SUCCESS",
  PAYMENT_SUCCESSFULL: "PAYMENT_SUCCESSFULL",
  REQUEST_CANCEL_SUCCESS: "REQUEST_CANCEL_SUCCESS",
  OTP_SHARE_SUCCESS: "OTP_SHARE_SUCCESS",
  VERIFY_OTP: "VERIFY_OTP",
  SERVICE_COMPLETE: "SERVICE_COMPLETE",
  CANCEL_BOOKING: "CANCEL_BOOKING",
  ADD_REVIEW_SUCCESS: "ADD_REVIEW_SUCCESS",
  DELETE_CARD_POPUP: "DELETE_CARD_POPUP",
  DELETE_ACCOUNT_POPUP: "DELETE_ACCOUNT_POPUP",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  FAVOURITES_POPUP: "FAVOURITES_POPUP",
  OTP_COMPLETE_SUCCESS: "OTP_COMPLETE_SUCCESS",
  REPORT_SUCCESSFULL: "REPORT_SUCCESSFULL",
  EO_SIGN: "EO_SIGN",
  BOOK_REQUEST_CANCEL: "BOOK_REQUEST_CANCEL",
  REQUEST_REJECT: "REQUEST_REJECT",
};

export const getModalProps = ({
  modalType,
  resolveCallback,
  rejectCallback,
}) => {
  const modalProps = {
    resolveCallback,
    rejectCallback,
  };
  switch (modalType) {
    case modalVariant.LOGOUT:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to log out from this device?",
        subText: "",
        successButton: true,
        image: infoiconpopup,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.GENERATE_OTP:
      return {
        ...modalProps,
        subTitle: "Share OTP?",
        subText:
          "Please share otp sent to you to the service provider to complete this service",
        successButton: true,
        image: otpiconpopup,
        resolveButton: {
          label: "Cancel",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "Generate OTP",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.VERIFY_OTP:
      return {
        ...modalProps,
        subTitle: "Enter OTP?",
        subText:
          "Please type the verification code given by the Event Organizer",
        successButton: true,
        image: otpiconpopup,
        isOtp: true,
        resolveButton: {
          label: "Cancel",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "Done",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.EO_SIGN:
      return {
        ...modalProps,
        subTitle: "Sign In/Sign Up to Book a Service",
        successButton: true,
        image: infoiconpopup,
        resolveButton: {
          label: "Sign Up",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "Sign In",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.REJECT_BOOKING:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to reject this booking?",
        subText: "",
        successButton: true,
        image: infoiconpopup,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.CANCEL_BOOKING:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to Cancel this booking?",
        subText: "",
        successButton: true,
        image: infoiconpopup,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.ADDON_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Add-On Service Added Successfully!",
        subText: "",
        successButton: true,
        image: tickIconSvg,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Password Changed Successfully!",
        subText: "",
        successButton: true,
        image: tickIconSvg,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.ADD_REVIEW_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Review Submitted Sucessful",
        subText:
          "Thank you for your review! Your feedback helps our Eventutti partners enhance their services.",
        successButton: true,
        image: tickIconSvg,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.ADDONUPDATE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Add-On Service Updated Successfully!",
        subText: "",
        successButton: true,
        image: tickIconSvg,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };

    case modalVariant.ADDSERVICE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Service Added Successfully!!",
        subText: "",
        successButton: true,
        image: tickIconSvg,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.ACCEPT_SERVICE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Accepted Successfully",
        subText:
          "Booking confirmed! Get ready to shine—coordinate with the event creator, bring your best, and make it unforgettable!",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.REQUEST_REJECT:
      return {
        ...modalProps,
        subTitle: "Profile Rejected",
        subText:
          "Your Profile has been rejected by the admin. Please contact the admin for more details.",
        successButton: true,
        image: infoiconpopup,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.REQUEST_CANCEL_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Request Cancelled Successfully",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.OTP_SHARE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "OTP has been successfully sent via email.",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.OTP_COMPLETE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Completed Successfully",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.SERVICE_COMPLETE:
      return {
        ...modalProps,
        subTitle: "Completed Successful",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.REQUEST_SERVICE_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Your service request has been sent!",
        subText: "",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.ADDCARD_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Card Added Successfully!",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Okay",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.PAYMENT_SUCCESS:
      return {
        ...modalProps,
        subTitle: "Your Service Request Accepted Successfully",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.PAYMENT_SUCCESSFULL:
      return {
        ...modalProps,
        subTitle: "Payment Successfull",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.REPORT_SUCCESSFULL:
      return {
        ...modalProps,
        subTitle: "Reported Successfully",
        successButton: true,
        image: tickIconSvg,
        isSingleButton: true,
        resolveButton: {
          label: "Done",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
      };

    case modalVariant.DELETESERVICE_POPUP:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to delete this service?",
        subText: "",
        successButton: true,
        image: popupdeleteicon,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };

    case modalVariant.DELETE_CARD_POPUP:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to delete this card?",
        subText: "",
        successButton: true,
        image: popupdeleteicon,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.DELETE_ACCOUNT_POPUP:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to delete your account?",
        subText: "",
        successButton: true,
        image: popupdeleteicon,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.BOOK_REQUEST_CANCEL:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to cancel your request?",
        subText: "",
        successButton: true,
        image: infoiconpopup,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
    case modalVariant.FAVOURITES_POPUP:
      return {
        ...modalProps,
        subTitle: "Are you sure you want to remove this service from favorites?",
        subText: "",
        successButton: true,
        image: faviconpopup,
        resolveButton: {
          label: "Yes",
          variant: "contained",
          onClick: resolveCallback,
          size: "small",
          fullWidth: true,
        },
        rejectButton: {
          label: "No",
          variant: "contained",
          color: "info",
          onClick: rejectCallback,
          size: "small",
          fullWidth: true,
        },
      };
  }
};
