export const getSx = () => {
  return {
    maincontainer: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
       fontFamily:"Montserrat, sans-serif "
    },
    maincontainer1:{
      overflow: "scroll",
      padding:"15px"
    },
    container1:{
      height:"100vh",
      overflow:"scroll",
    },

    container: {
      padding: {
        xs:"15px 10px",
        sm:"20px 30px 15px",
        lg:"20px 75px 15px",
        md:"20px 30px 15px",
        xl:"45px 75px 25px"
      },
      width: {xs:"100%",md:"98%"},
      height: "95%",
      boxSizing: "border-box",
      border: "1px solid #E9E9E9",
      borderRadius: "12px",

      img: {
        position: "absolute",
        left: { xs: "2rem", sm:'4rem', md: "4rem", lg: "75px",xl: "75px" },
      },
    },
    heading: {
      fontSize: { xs: "18px", md: "20px", lg:'24px', xl:'28px'},
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#0F172A",
      fontWeight:700,
      marginBottom:'10px',
      fontFamily:"Montserrat, sans-serif"
    },
    spanheading: {
      color: "rgba(23, 186, 174, 1) !important",
      marginLeft: "10px",
    },
    smallheading: {
      color: "#0F172A",
      marginLeft: "10px",
      fontSize:'12px',
      fontWeight:500,
      lineHeight:'16px',
      marginBottom: {
        xs:'20px',
        sm:'20px',
        md:'20px',
        lg:'20px',
        xl:'40px'
      },
      fontFamily:"Montserrat, sans-serif"
    },
    spansmallheading: {
      color: "rgba(167, 167, 167, 1)",
    },
    content1:{
      height:"95vh",
      padding:"20px"
    },
    content: {
      height: {
        xs: "calc(100vh - 370px)",
        sm: "calc(100vh - 350px)",
        md: "calc(100vh - 350px)",
        lg: "calc(100vh - 370px)",
        xl: "calc(100vh - 370px)",
      },
      overflowY: "auto",
      width: "100%",
    },
    question: {
      fontSize: {
        xs:'15px',
        sm:'16px',
        md:'16px',
        lg:'18px',
        xl:"22px"
      },
      fontWeight:600,
      color: "#000000",
      marginBottom: "10px",
      lineHeight:"22px",
      fontFamily:"Montserrat, sans-serif"
    },
    answer: {
      fontSize: {
        xs:'13px',
        sm:'14px',
        md:'14px',
        lg:'14px',
        xl:"16px"
      },
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight:500,
      marginBottom: "30px",
      lineHeight:  {
        xs:'21px',
        sm:'22px',
        md:'22px',
        lg:'22px',
        xl:"26px"
      },
      fontFamily:"Montserrat, sans-serif"
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop:'10px'
    },
  };
};
