import React from "react";
import ButtonComp from "../../../component/Button";
import CustomSwitch from "../../../component/Switch";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import instabookimg from "../../../assets/images/instabookcal.svg";

const InstaBook = ({ addService, setAddService, callAddServiceApi, loading }) => {
  const handleChange = (e) => {
    setAddService({ ...addService, isInstaBook: e.target.checked });
  };
  const classes = getSx();
  return (
    <Box sx={classes.main}>
      <Typography sx={{fontFamily:'Montserrat, sans-serif'}} variant="h6" fontWeight="bold" marginTop="1rem">
        Party Now
      </Typography>
      <Box sx={classes.instainfo}>
        Activate Party Now: When you enable this feature, service requests are automatically accepted, and you must render services within 1-2 hours from the request. Let's get the party started!
      </Box>
      <Box
        sx={{
            minHeight: {
              xs: "auto",
              sm:'auto',
              md:'auto',
              lg:'auto',
              xl: "auto"
            },
            maxHeight: {
              xs: "calc(100vh - 450px)",
              sm:'calc(100vh - 450px)',
              md:'calc(100vh - 600px)',
              lg:'calc(100vh - 400px)',
              xl: "calc(100vh - 450px)"
            },
            width: "100%",
            overflow:'auto',
            marginBottom:'20px'
      }}
      >
        <Box sx={classes.instabookcont}>
          <Box sx={classes.leftinfoinsta}>
            <img alt="" src={instabookimg} style={{ margin: "0px 10px" }} />
            <span style={{ textAlign: "center", marginLeft: "10px", fontWeight: "600",fontFamily:'Montserrat, sans-serif' }}>Party Now</span>
          </Box>
          <CustomSwitch checked={addService.isInstaBook} onChange={handleChange} />
        </Box>
      </Box>
      <Box sx={{ ...classes.buttoncomp }}>
        <ButtonComp
          label="Add Service"
          type="active"
          width="100%"
          onClick={callAddServiceApi}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default InstaBook;
