import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";

const AddServiceDesc = ({ setPage, addService, setAddService, isEdit, setErrors, setCheckAlpha }) => {
  const classes = getSx();
  const [error, setError] = useState("");
  const handleClick = () => {
    setPage(5);
    setAddService({
      ...addService,
      title: formik.values.title,
      description: formik.values.description,
      maximumGuestCapacity: formik.values.guestCapacity,
      serviceTiming: {
        monday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        tuesday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        wednesday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        thursday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        friday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        saturday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
        sunday: {
          isAvailable: false,
          startTime: "",
          endTime: "",
        },
      },
    });
  };

  const checkIsAlphabets = (value) => {
    let lines = value.split('\n');
    let cleanedLines = lines.map(line => line.trim()).filter(line => line.length > 0);
    let cleanedText = cleanedLines.join(' ');
    if (cleanedText.trim().match(/^[A-Za-z,./$?!&:;'"[\]{}() ]+$/)) {
      setError("")
      if (isEdit)
        setCheckAlpha(false)
    }
    else {
      setError("Only Alphabets are allowed")
      if (isEdit)
        setCheckAlpha(true)
    }
  }

  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Required")
      .matches(/^[A-Za-z0-9 !@#$%^&*()_+\-=\~`\[\]{}|;:'",.<>?áéíóúñü¡¿//]+$/, "Invalid Title")
      .max(50, "Title name must be less than 50 characters"),
    guestCapacity: Yup.string("Only numbers allowed")
      .matches(/^[\d]+$/, "Invalid Guest Capacity")
      .test(
        "greaterThanZero",
        "Value must be greater than 0",
        (value) => parseInt(value) > 0
      )
      .required("Required"),
    description: Yup.string().required("Required")
  });

  const formik = useFormik({
    initialValues: {
      title: addService?.title,
      guestCapacity: addService?.maximumGuestCapacity,
      description: addService?.description,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (addService?.description) {
      formik.setFieldTouched("description", true);
      formik.setFieldTouched("guestCapacity", true);
      formik.setFieldTouched("title", true);
    }
  }, []);
  useEffect(() => {
    if (isEdit) {
      if (Object.keys(formik.errors).length > 0)
        setErrors(true)
      else
        setErrors(false)
    }
  }, [formik.errors])

  return (
    <Box sx={classes.descinfo}>
      <Box sx={isEdit ? classes.descedit : classes.desccont}>
        <Box sx={{
          minHeight: {
            xs: "auto",
            sm: 'auto',
            md: 'auto',
            lg: 'auto',
            xl: "auto"
          },
          maxHeight: {
            xs: isEdit ? "auto" : "calc(100vh - 400px)",
            sm: isEdit ? "auto" : 'calc(100vh - 400px)',
            md: isEdit ? "auto" : 'calc(100vh - 600px)',
            lg: isEdit ? "auto" : 'calc(100vh - 345px)',
            xl: isEdit ? "auto" : "calc(100vh - 450px)"
          }, overflow: "auto"
        }}>
          <Typography
            varaint="h5"
            margin="10px 0px"
            fontWeight="bold"
            fontFamily="Montserrat,sans-serif"
          >
            Service Title
          </Typography>
          <CustomTextField
            backgroundColor="rgba(246, 245, 245, 1)"
            placeholder="Enter Title Here"
            type="text"
            name="title"
            value={formik.values.title}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            errorMessage={formik.touched.title && formik.errors.title}
            onChange={(e) => {
              formik.setFieldTouched("title", true);
              formik.handleChange(e);
              if (isEdit)
                setAddService({ ...addService, title: e.target.value })
            }}
          />
          <Typography
            varaint="h5"
            margin="10px 0px"
            fontWeight="bold"
            fontFamily="Montserrat,sans-serif"
          >
            Maximum Guest Capacity
          </Typography>
          <CustomTextField
            backgroundColor="rgba(246, 245, 245, 1)"
            placeholder="Enter Guest Capacity"
            name="guestCapacity"
            value={formik.values.guestCapacity}
            onBlur={formik.handleBlur}
            onWheel={handleWheel}
            error={
              formik.touched.guestCapacity && Boolean(formik.errors.guestCapacity)
            }
            errorMessage={
              formik.touched.guestCapacity && formik.errors.guestCapacity
            }
            onChange={(e) => {
              formik.setFieldTouched("guestCapacity", true);
              formik.handleChange(e);
              if (isEdit)
                setAddService({ ...addService, maximumGuestCapacity: Number(e.target.value) })
            }}
          />
          <Typography
            varaint="h5"
            margin="10px 0px"
            fontWeight="bold"
            fontFamily="Montserrat,sans-serif"
          >
            Service Description
          </Typography>
          <TextareaAutosize
            placeholder="Enter Description "
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "rgba(246, 245, 245, 1)",
              borderRadius: "15px",
              marginBotton: "3rem",
              fontSize: "15px",
              fontFamily: "Montserrat",
              border: "none",
              outline: "none",
              maxHeight: "200px",
              fontWeight: isEdit ? "500" : "400",
              overflowY: "auto",
              color: "#000"
            }}
            className="textArea"
            name="description"
            value={formik.values.description}
            onChange={(e) => {
              formik.setFieldTouched("description", true);
              formik.handleChange(e);
              checkIsAlphabets(e.target.value)
              if (isEdit)
                setAddService({ ...addService, description: e.target.value })
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            errorMessage={formik.touched.description && formik.errors.description}
            minRows={8}
          />
          {error?.length > 0 && (<Typography color="red"> Only Alphabets are allowed</Typography>)}
        </Box>
        {!isEdit && <Box sx={{ marginTop: { lg: "0.4rem", xl: "4rem" } }}>
          <ButtonComp
            label="Next"
            width="100%"
            onClick={handleClick}
            disabled={
              ((addService?.description && formik.isValid) ||
                (formik.isValid && formik.dirty)) && (error.length == 0)
                ? false
                : true
            }
            type={
              ((addService?.description && formik.isValid) ||
                (formik.isValid && formik.dirty)) && (error.length == 0)
                ? "active"
                : "disabled"
            }
          />
        </Box>}
      </Box>
    </Box>
  );
};

export default AddServiceDesc;
