import React from 'react'

const Wifi = ({value}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 35.0013C18.8333 35.0013 17.8472 34.5985 17.0417 33.793C16.2361 32.9874 15.8333 32.0013 15.8333 30.8346C15.8333 29.668 16.2361 28.6819 17.0417 27.8763C17.8472 27.0707 18.8333 26.668 20 26.668C21.1667 26.668 22.1528 27.0707 22.9583 27.8763C23.7639 28.6819 24.1667 29.668 24.1667 30.8346C24.1667 32.0013 23.7639 32.9874 22.9583 33.793C22.1528 34.5985 21.1667 35.0013 20 35.0013ZM10.5833 25.5846L7.08333 22.0013C8.72222 20.3624 10.6461 19.0641 12.855 18.1063C15.0639 17.1485 17.4456 16.6691 20 16.668C22.5556 16.668 24.9378 17.1541 27.1467 18.1263C29.3556 19.0985 31.2789 20.418 32.9167 22.0846L29.4167 25.5846C28.1944 24.3624 26.7778 23.4041 25.1667 22.7096C23.5556 22.0152 21.8333 21.668 20 21.668C18.1667 21.668 16.4444 22.0152 14.8333 22.7096C13.2222 23.4041 11.8056 24.3624 10.5833 25.5846ZM3.5 18.5013L0 15.0013C2.55556 12.3902 5.54167 10.3485 8.95833 8.8763C12.375 7.40408 16.0556 6.66797 20 6.66797C23.9444 6.66797 27.625 7.40408 31.0417 8.8763C34.4583 10.3485 37.4444 12.3902 40 15.0013L36.5 18.5013C34.3611 16.3624 31.8817 14.6885 29.0617 13.4796C26.2417 12.2707 23.2211 11.6669 20 11.668C16.7778 11.668 13.7567 12.2724 10.9367 13.4813C8.11667 14.6902 5.63778 16.3635 3.5 18.5013Z"
        fill={value?"#17BAAE":"black"}
      />
    </svg>
  );
}

export default Wifi