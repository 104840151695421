import React,{useEffect,useState} from "react";

import { Box } from "@mui/material";
import { getSx } from "./style";
import BankDetails from "../../eventorganiser/createprofile/BankDetails";

import { getProfileApi } from "../../../api/profile";

const BankDetailsScreen = () => {
  const classes=getSx();
  const [bankDetails,setBankDetails]=useState({});
  const callApi=async()=>{
    const response=await getProfileApi();
    if(response.status===200)
      setBankDetails(response?.data?.data?.bankingDetails);
  }
  useEffect(()=>{
    callApi()
  },[])
  return (
    <Box  sx={classes.bankCont} 
    >
     {bankDetails&& <BankDetails fromSettings={true} bankDetails={bankDetails} />}
    </Box>
  );
};

export default BankDetailsScreen;
