
export const getSx = () => {
  return {
    info: {
      display: { xs: "block", md: "flex" },
      width: "100%",
      height: { xs: "80vh", md: "auto" },
      overflowY: { xs: "scroll", md: "hidden" },
    },
    left: {
      flex: 4,
      marginRight: {
        xs:0,
        sm:0,
        md:"20px",
        lg:"20px",
        xl:"20px"
      },
      paddingBottom:'10px'
    },
    revenueammount: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid rgba(41, 234, 165, 1)",
      padding: { xs: "10px", lg: "5px", xl: "10px" },
      margin: { xs: "10px 0", sm:'10px 0', md:'10px 0', lg: "5px 0", xl: "10px 0" },
      borderRadius: "12px",
    },
    right: {
      flex: 2,
      marginTop:{
        xs:'30px',
        sm:'15px',
        md:0,
        lg:0,
        xl:0
      }
    },
    subbox: {
      display: "flex",
      width: "100%",
      justifyContent:{
        xs:'flex-start',
        sm:'center',
        md:'flex-start',
        lg:'flex-start',
        xl:'flex-start',
      }
    },
    subbox1: {
      padding: "15px 15px 15px 30px",
      width: {xs:"45%",sm:"27%",md:"49%",lg:"45%"},
      height: {xs:"25vh",sm:"27%",md:"18vh",lg:"33vh",xl:'35vh'},
      backgroundColor: "#F6F5F5",
      margin: {
        xs:"3%",
        sm:"1% 3%",
        md:"3%",
        lg:"1% 3%",
        xl:"1% 3%"
      },
      borderRadius: "18px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      position: "relative",
    },
    count: {
      position: "absolute",
      right: "2rem",
      top: "2rem",
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      textAlign: "center",
      fontFamily: 'Montserrat, sans-serif',
      fontWeight:700,
      color:'#ffffff'
    },
    contentleft: {
      background: "#FFFFFF",
      border: "0.5px solid rgba(107, 107, 107, 0.2)",
      boxShadow: "0px 10px 90px rgba(0, 59, 91, 0.05)",
      borderRadius: "15px",
      padding: {xs:" 1rem 1rem 1rem 0px",sm:"1rem 1.25rem"},
      width: "calc (85vw - 480px)",
      overflowX: { xs: "scroll", md: "hidden" },
    },
    headinginfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: { xs: "auto", lg: "40px", xl: "auto" },
      padding:{
        xs:'0 15px',
        sm:0,
        md:0,
        lg:0,
        xl:0
      }
    },
    responsiveContainer: {
      width: { xs: "550px", sm: "100%", md: "100%" },
      height:{xs:"345px",xl:"350px"}
    },
    input: {
      height: "45px",
      borderRadius: "20px",
    },
    inputtag: {
      position: "absolute",
    bottom: {xs:"3rem",sm:"7rem",md:"9rem",lg:"5rem",xl:"5rem"},
    width: "98%",
    },
    sendbtnimg:{
      position: "absolute",
       right:{xs:"1rem",md:"2rem"} ,
        padding: "10px" 
    }
  };
};
