import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "profile",
  initialState: {
    profileInfo: {},
    userIds: [],
    role: 0,
  },
  reducers: {
    setProfileInfo: (state, param) => {
      const { payload } = param;
      state.profileInfo = payload;
    },
    setUserIds: (state, param) => {
      const { payload } = param;
      state.userIds = [...payload];
    },
    setSwitchRole: (state, param) => {
      const { payload } = param;
      state.role = payload
    }
  },
});

const { actions, reducer } = commonSlice;

export const { setProfileInfo, setUserIds, setSwitchRole } = actions;
export default reducer;
