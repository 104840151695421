import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import ButtonComp from "../../../../component/Button";
import { modalVariant } from "../../../../component/Modal/Modalutils";
import { Input, Grid, useFormControl, FormControl, Box } from "@mui/material";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CustomTextField from "../../../../component/Textfield";
import { addCardApi } from "../../../../api/cardInfo";
import { showToast, showModal } from "../../../../store/slices/common";

const AddCardModalContent = ({ setOpenPaymentInfoModal, setOpenCardModal, fromSettings, setOpenAddCard }) => {
  useFormControl();

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [checkfields, setCheckfields] = useState({
    cardnumber: false,
    expiry: false,
    cvv: false,
    name: false,
  });

  useEffect(() => {
    if (elements) {
      elements?.getElement(CardNumberElement).clear();
      elements?.getElement(CardExpiryElement).clear();
      elements?.getElement(CardCvcElement).clear();
    }
  }, []);

  useEffect(() => {
    if (
      checkfields.cardnumber == "true" &&
      checkfields.expiry == "true" &&
      checkfields.cvv == "true" &&
      formData.name.length > 1 &&
      formData.name.length <= 50
    ) {
      setDisabled(false);
    } else setDisabled(true);
  }, [checkfields, formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const cardElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement, {
        name: formData?.name,
      });
      if (error) {
        dispatch(showToast({ msg: error.message, color: "error" }));
      }
      const resp = await addCardApi(token.id);
      if (resp?.status === 200) {
        setOpenCardModal(false);
        dispatch(showToast({ msg: "Card Added Successfully", type: "success" }));
        if (fromSettings) setOpenAddCard(false);
        else
          dispatch(
            showModal({
              modalType: modalVariant.ADDCARD_SUCCESS,
              resolveCallback: () => {
                dispatch(showModal(null));
                setOpenPaymentInfoModal(true);
              },
              rejectCallback: () => {
                dispatch(showModal(null));
              },
            })
          );
      } else if (resp?.response?.status >= 400) {
        {
          dispatch(showToast({ msg: resp.response.data.message, color: "error" }));
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChange = async (event, cardinput) => {
    // const name = /^[A-Za-z\s]+$/;

    // if (cardinput == "name") {
    //     const value = event.target.value;
    //     if (value.length <= 50 && (name.test(value) || value === "")) {
    //         setFormData({
    //             ...formData, name: value,
    //         });
    //     }
    //     if (value.length > 1 && value.length <= 50 && name.test(value)) {
    //         setCheckfields({ ...checkfields, name: "true" });
    //     } else {
    //         setCheckfields({ ...checkfields, name: "false" });
    //     }
    // }

    const e = await event;

    if (e?.complete === true) {
      if (cardinput == "cardnumber") setCheckfields({ ...checkfields, cardnumber: "true" });
      if (cardinput == "expiry") setCheckfields({ ...checkfields, expiry: "true" });
      if (cardinput == "cvv") setCheckfields({ ...checkfields, cvv: "true" });
    } else if ((e?.empty === false && e?.error === undefined) || e?.error) {
      if (cardinput == "cardnumber") setCheckfields({ ...checkfields, cardnumber: "false" });
      if (cardinput == "expiry") setCheckfields({ ...checkfields, expiry: "false" });
      if (cardinput == "cvv") setCheckfields({ ...checkfields, cvv: "false" });
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Box
        sx={{
          height: {
            xs: "calc(97vh - 200px)",
            sm: "calc(53vh - 205px)",
            md: "calc(40vh - 200px)",
            lg: fromSettings ? "calc(72vh - 200px)" : "calc(60vh - 195px)",
            xl: fromSettings ? "calc(70vh - 200px)" : "calc(45vh - 200px)",
            xxl: fromSettings ? "calc(70vh - 200px)" : "calc(45vh - 200px)",
          },
          overflow: "auto",
        }}
        padding={{
          xs: "0 15px",
          sm: "0 15px",
          md: "0 15px",
          lg: "0 15px",
          xl: "0 15px",
        }}
      >
        <Box className="card-element-wrapper">
          <Box width="100%">
            <Box width="100%">
              <Box
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    xl: "16px",
                  },
                }}
                className="label"
              >
                Card Holder Name
              </Box>
              <FormControl sx={{ width: fromSettings ? { xs: "145%", md: "40%" } : "100%" }}>
                <CustomTextField
                  name="name"
                  // className={fromSettings ? "input-text-field input-text-fieldSettings" : "input-text-field"}
                  placeholder=" Enter Name"
                  type="text"
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  value={formData?.name}
                  pattern="[A-Za-z]"
                  maxLength={50}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#000",
                      padding: "8px",
                      paddingLeft: "1rem",
                      backgroundColor: "#fff",
                    },
                    "& input::placeholder": {
                      fontWeight: 500,
                      color: "#000",
                      opacity: 1,
                    },
                  }}
                />
              </FormControl>
            </Box>
            <Box width="100%">
              <Box
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    lg: "14px",
                    xl: "16px",
                  },
                }}
                className="label"
              >
                Credit Card Number
              </Box>
              <FormControl sx={{ width: fromSettings ? { xs: "100%", md: "40%" } : "100%" }}>
                <CardNumberElement
                  options={{
                    showIcon: false,
                    iconStyle: "solid",
                    placeholder: " Enter Card Number",
                    style: {
                      empty: {
                        "::placeholder": {
                          color: "#6B6B6B",
                        },
                      },
                      base: {
                        iconColor: "#6B6B6B",
                        color: "#111111",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "Montserrat,sans-serif",
                        fontSmoothing: "antialiased",
                        ":-webkit-autofill": {
                          color: "#6B6B6B",
                        },
                      },
                      invalid: {
                        iconColor: "red",
                        color: "red",
                        fontWeight: "500",
                        fontSize: "16px",
                        fontFamily: "Montserrat,sans-serif",
                      },
                    },
                  }}
                  className="card-number"
                  onChange={(e) => handleChange(e, "cardnumber")}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: fromSettings ? { xs: "100%", md: "40%" } : "100%" }}>
              <Grid container className="card-element d-flex justify-content-between">
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    paddingRight: "5px",
                  }}
                  className="mb-sm-3 pr-2 mb-md-0"
                >
                  <Box
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "16px",
                      },
                    }}
                    className="label"
                  >
                    Expiry Date
                  </Box>
                  <Box>
                    <CardExpiryElement
                      className="card-expiry"
                      options={{
                        placeholder: "MM/YY",
                        style: {
                          empty: {
                            "::placeholder": {
                              color: "#6B6B6B",
                            },
                          },
                          base: {
                            iconColor: "#6B6B6B",
                            color: "#111111",
                            fontWeight: "500",
                            fontSize: "16px",
                            fontFamily: "Montserrat,sans-serif",
                            fontSmoothing: "antialiased",
                            ":-webkit-autofill": {
                              color: "#6B6B6B",
                            },
                          },
                          invalid: {
                            iconColor: "red",
                            color: "red",
                            fontWeight: "500",
                            fontSize: "16px",
                            fontFamily: "Montserrat,sans-serif",
                          },
                        },
                      }}
                      onChange={(e) => handleChange(e, "expiry")}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    paddingLeft: "5px",
                  }}
                  className="pl-2 card-cvv-wrapper"
                >
                  <Box
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "16px",
                      },
                    }}
                    className="label"
                  >
                    CVV
                  </Box>
                  <Box>
                    <CardCvcElement
                      className="card-cvv"
                      type="password"
                      options={{
                        placeholder: "CVV",
                        style: {
                          empty: {
                            "::placeholder": {
                              color: "#6B6B6B",
                            },
                          },
                          base: {
                            iconColor: "#6B6B6B",
                            color: "#111111",
                            fontWeight: "500",
                            fontSize: "16px",
                            fontFamily: "Montserrat,sans-serif",
                            fontSmoothing: "antialiased",
                            ":-webkit-autofill": {
                              color: "#6B6B6B",
                            },
                          },
                          invalid: {
                            iconColor: "red",
                            color: "red",
                            fontWeight: "500",
                            fontSize: "16px",
                            fontFamily: "Montserrat,sans-serif",
                          },
                        },
                      }}
                      onChange={(e) => handleChange(e, "cvv")}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        padding={{ xs: "0 15px", md: 0 }}
        className="buttonContainer"
        textAlign={"center"}
        display={fromSettings ? "block" : "flex"}
        justifyContent="center"
        sx={{
          width: {
            xs: "100%",
            sm: "205px",
            md: "205px",
            lg: "205px",
            xl: "205px",
          },
          margin: {
            xs: "20px auto 0",
            md: "20px auto 0",
          },
        }}
      >
        <ButtonComp
          style={{
            fontFamily: "Montserrat,sans-serif",
            height: "60px",
            width: "100%",
            fontWeight: 600,
            fontSize: "20px",
          }}
          loading={loading}
          disabled={disabled}
          type={disabled ? "disabled" : "active"}
          typeButton="submit"
          className="theme-btn-contained theme-btn theme-auth-btn"
          variant="contained"
          color="primary"
          label="Add Card"
          width={fromSettings ? "100%" : "66%"}
        />
      </Box>
    </Box>
  );
};

export default AddCardModalContent;
