
export const  getSx=()=>{
  return {
    tabs: {
      marginBottom: {
        xs:'10px',
        sm:'10px',
        md:'15px',
        lg:'20px',
        xl:'30px'
      },
      borderRadius: "30px",
      boxShadow: "0 6px 20px 0 rgba(46, 46, 79, 0.12)",
      maxWidth: { xs: "100%", sm: "450px", md: "450px",lg: "450px",xl: "450px" },
      minHeight:{
        xs:'40px',
        sm:'40px',
        md:'40px',
        lg:'40px',
        xl:'44px'
      }
    },
    selectedTab: {
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
      color: "#fff !important",
      fontWeight:600,
      textTransform: "capitalize",
      borderRadius: "18px",
      fontSize: {
        xs:'10px',
        sm:'11px',
        md:'11px',
        lg:'12px',
        xl:"14px"
      },
      fontFamily:'Montserrat,sans-serif',
      minHeight:{
        xs:'40px',
        sm:'40px',
        md:'40px',
        lg:'40px',
        xl:'44px'
      },
      border:0,
      width:'25%'
    },
    unselectedTab: {
      color: "rgba(167, 167, 167, 1)",
      fontWeight: "500",
      textTransform: "capitalize",
      fontSize: {
        xs:'10px',
        sm:'11px',
        md:'11px',
        lg:'12px',
        xl:"14px"
      },
      fontFamily:'Montserrat,sans-serif',
      minHeight:{
        xs:'40px',
        sm:'40px',
        md:'40px',
        lg:'40px',
        xl:'44px'
      },
      border:0,
      width:'25%'
    },
    servicecont: {
      display: "flex",
      marginTop: {
        xs:0,
        sm:0,
        md:0,
        lg:0,
        xl:"10px"
      },
      flexDirection: "row",
      whiteSpace:"noWrap"
    },
    servicecontinfo: {
      background: "rgba(23, 186, 174, 1)",
      width: "fit-content",
      padding: "5px 15px",
      borderRadius: "10px",
      margin: "0 10px 0 0",
      color: "#fff",
      fontFamily: 'Montserrat, sans-serif',
      fontSize:'12px',
      fontWeight:500,
      lineHeight:'18px',
      textAlign:'center'
    },
    infobox: {
      height: { xs: "calc(100vh - 185px)", lg: "calc(100vh - 245px)", sm: "calc(100vh - 222px)",md:"calc(100vh - 225px)", xl: "calc(100vh - 300px)",  },
      overflowY: "auto",
      gap: "10px",
      display: {
        xs:'block',sm:"flex", md:"flex", lg:"flex", xl:"flex"
      },
      flexWrap: "wrap",
      padding: "10px",
      width: "100%",
      overflowX: "hidden",
    },
    cardInfo: {
      borderRadius: "18px",
      height: 'fit-content',
      boxShadow: "0px 4px 14.7px 0px #0000001A",
      width: {xl:"32.5", lg: "32.5%", sm: "49%", md: "49%", xs: "100%" },
      cursor:"pointer",
      position:"relative",
      padding:'4px 0 0',
      marginBottom: {
        xs:'15px',
        sm:0,
        md:0,
        lg:0,
        xl:0
      }
    },
    main: {
      height: { lg: "75vh", md: "85vh", xs: "80vh" },
      border: "1px solid rgba(217, 217, 217, 1)",
      boxSizing: "border-box",
      padding: "20px",
      borderRadius: "20px",
      width: "95vw",
      margin:{xs:"5px",md:"0px"}
    },
    eventinfo: {
      height: { xs: "70vh", lg: "60vh" },
      overflowY: "scroll",
    },
    firstinfo: {
      display: { xs: "block", md: "flex" },
      width: "100%",
    },
    eventimage: {
      width: { xs: "100%", md: "25%", lg:'27.5%',xl:'27.5%' },
      height: {
        xs:'180px',
        sm:'210px',
        md:'220px',
        lg:'230px',
        xl:"265px"
      },
    },
    eventcontent: {
      marginTop: {
        xs:"20px",
        sm:"20px",
        md:0,
        lg:0,
        xl:0
      },
      paddingLeft: {
        xs:0,
        sm:0,
        md:'15px',
        lg:"20px",
        xl:"40px"
      },
      width: {
        xs:"100%",
        sm:"100%",
        md:"66.5%",
        lg:"73%",
        xl:"73%"
      },
    },
    eventheading: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    smallheading: {
      color: "#A7A7A7",
      paddingBottom: "5px",
      fontSize: {
        xs:'12px',
        sm:'14px',
        md:'14px',
        lg:'15px',
        xl:"16px"
      },
      fontFamily:'Montserrat,sans-serif'
    },
    locationdet: {
      color: "#1A1A1A",
      fontSize: {
        xs:'14px',
        sm:'15px',
        md:'15px',
        lg:'16px',
        xl:"18px"
      },
      fontWeight:500,
      display: "flex",
      alignItems: "center",
      fontFamily:'Montserrat,sans-serif'
    },
    emailcallinfo: {
      display: { xs: "block", md: "flex", marginTop:'15px' },
    },
    emailinfo: {
      display: "block",
      padding: "0 0 10px 0px",
      width: { xs: "100%", md: "50%" },
    },
    callinfo: {
      display: "block",
      padding: "0 0 0 10px",
    },
    para: {
      color: "rgba(0, 0, 0, 0.5)",
      fontFamily:'Montserrat,sans-serif'
    },

    catmain: {
      display: "flex",
      justifyContent: "space-between",
      padding: "5px",
    },
    starinfo: {
      background: 'linear-gradient(90deg, rgba(23, 186, 174, 0.1) 0%, rgba(41, 234, 165, 0.1) 100%)',
      width:"auto",
      fontWeight:600,
      fontSize:{xs:"12px",sm:'14px', md:"15px", lg:'16px', xl:'18px'},
      fontFamily:'Montserrat,sans-serif',
      borderRadius:"20px",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      padding:"0 15px",
      height:'40px',
      color:'#1A1A1A'
    },
    catinfo1: {
      background: "rgba(23, 186, 174, 0.1)",
      height: "auto",
      borderRadius: "15px",
      padding: "20px",
      margin: "10px",
    },
    catinfo2: {
      background: "rgba(23, 186, 174, 1)",
      height: "auto",
      borderRadius: "15px",
      padding: "12px",
      textAlign: "center",
      margin: "10px",
      color: "#fff",
      fontWeight: "bold",
      fontSize: {xs:"12px",md:"17px"},
      fontFamily:'Montserrat,sans-serif'
    },
    catleft: {
      fontSize: "14px",
      fontFamily:'Montserrat,sans-serif'
    },
    catright: {
      color: "black",
      fontWeight: "600",
      fontSize: {xs:"10px",sm:"14px"},
      fontFamily:'Montserrat,sans-serif'
    },
    successButton: {
      color: "#FFFFFF",
      width: "100%",
      background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)",
      fontWeight: 600,
      padding:"10px",
      display:'flex',
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"40px",
      cursor:"pointer",
    height:'50px'
    },
    errorButton: {
      background: "transparent",
      color: "#FFFFF",
      border: "1px solid rgba(247, 17, 17, 0.40)",

      "&.Mui-disabled": {
        opacity: "0.3",
        color: "#F71111",
        background: "transparent",
        border: "1px solid rgba(247, 17, 17, 0.40)",
      },

      "&:hover": {
        color: "#FFFF",
        background: "transparent",
        border: "1px solid rgba(247, 17, 17, 0.40)",
      },
    },
    whitebtn: {
      border: "1px solid ",
      borderColor:
        "linear-gradient(to right,rgba(23, 186, 174),rgba(41, 234, 165))",
      background: "white",
      color: "rgba(23, 186, 174, 1)",
      fontSize: { xs: "12px", md: "18px" },
      fontWeight: 600,
      padding:"10px",
      display:'flex',
      justifyContent:"center",
      alignItems:"center",
      borderRadius:"40px",
      cursor:"pointer",
      fontFamily:'Montserrat,sans-serif',
      height:'50px'
    },
    unselectbox: {
      backgroundColor: "rgba(246, 245, 245, 1)",
      padding: "10px 15px",
      borderRadius: "12px",
      position: "relative",
      margin: "5px 0",
      fontSize: {xs:"11px",md:"15px"},
      color: "#1A1A1A",
      fontWeight: 600,
      display:'flex',
      alignItems:'center',
      height:{xs:"50px",sm:"50px", md:'50px', lg:'50px', xl:'60px'},
      fontFamily:'Montserrat,sans-serif'
    },
    selecteventradiobox: {
      width: "340px",
    },
    unselecteventradiobox: {
      width: "340px",
    },
    radiobox: {
      position: "absolute",
      right: '-15px',
      top: 0,
      bottom:0,
      margin:'auto',
      display:'flex',
      alignItems:'center'
    },
    selectbox: {
      background:
        "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
    },
  };

}