
export const getSx = () => {
  return {
    maininfo: {
      width: "50vw",
      height: "100vh",
    },
    content: {
      position: "absolute",
      bottom: "2rem",
      left: "2rem",
    },
    carouselContainer: {
      overflow: "hidden",
      height:"100vh",
    },
  };

  
}