
export const routePaths = {
  TERMS_AND_CONDITION: "/termsandconditions",
  PRIVACY_POLICY: "/privacypolicy",
  LOGIN: "/login",
  REGI: "/register",
  CREATE_PROFILE_EVENT_ORGANISER: "/create-profile",
  LANG_SELECT: "/lang-select",
  PROVIDER_SELECT: "/provider-select",
  SIGNUP: "/signup",
  RESET_PASSWORD: "/resetpassword",
  HOME: "/home",
  PROFILECREATEDPAGE: "/profilecreationsucessful",
  SETTINGS: "/settings",
  EVENT_DASHBOARD: "/event-dashboard",
  EVENT_INFO: "/event-info",
  NOTIFICATIONS: "/notifications",
  MESSAGES: "/messages",
  VIEW_RATING: "/viewratings",
  SERVICE_DASHBOARD: "/serviceprovider-dashboard",
  BLOG_IMAGES: "/blog-images",
  MY_SERVICES: "/myservices",
  CHANGE_PASSWORD:
    "/reset-password",
  EXPLORE_EVENT: "/",
  BANK_DETAILS:"/bank-details",
  SERVICE_INFO:"/service-info",
  BOOKING_CONFIRMATION:"/booking-confirmation",
  BOOKING_INFO:"/booking-info",
  REQUEST_INFO:"/request-info",
  RATINGS_AND_REVIEWS_SP:"/ratings-and-reviews",
  NOTIFICATIONS_NEW:"/notifications",
  SWITCH_PROFILE:"/switch-profile",


};
