import React from 'react'

const HotTub = ({value}) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2738 5.46875C13.2375 6.7475 12.76 7.84125 12.75 8.90625C12.74 9.97125 13.2137 10.7962 13.5312 11.3287C13.8488 11.86 13.99 12.1288 14 12.4213C14.01 12.715 13.9125 13.2275 13.14 14.2188L15.0938 15.7812C16.07 14.5212 16.5337 13.3888 16.5 12.3438C16.4663 11.2987 15.9875 10.5512 15.68 10.0388C15.3725 9.52625 15.245 9.2525 15.25 8.945C15.255 8.6375 15.3875 8.06625 16.2262 7.03125L14.2738 5.46875ZM20.5238 5.46875C19.4875 6.7475 19.01 7.84125 19 8.90625C18.99 9.97125 19.4637 10.7962 19.7812 11.3287C20.0987 11.86 20.24 12.1288 20.25 12.4213C20.26 12.715 20.1625 13.2275 19.39 14.2188L21.3438 15.7812C22.32 14.5212 22.7838 13.3888 22.75 12.3438C22.7162 11.2987 22.2375 10.5512 21.93 10.0388C21.6225 9.52625 21.495 9.2525 21.5 8.945C21.505 8.6375 21.6375 8.06625 22.4762 7.03125L20.5238 5.46875ZM26.7738 5.46875C25.7375 6.7475 25.26 7.84125 25.25 8.90625C25.24 9.97125 25.7138 10.7962 26.0312 11.3287C26.3487 11.86 26.49 12.1288 26.5 12.4213C26.51 12.715 26.4125 13.2275 25.64 14.2188L27.5938 15.7812C28.57 14.5212 29.0338 13.3888 29 12.3438C28.9662 11.2987 28.4875 10.5512 28.18 10.0388C27.8725 9.52625 27.745 9.2525 27.75 8.945C27.755 8.6375 27.8875 8.06625 28.7262 7.03125L26.7738 5.46875ZM4 17.5V20H5.485L7.3975 29.4925V29.5312C7.54268 30.1686 7.84866 30.7581 8.28629 31.2437C8.72393 31.7292 9.27862 32.0946 9.8975 32.305L9 35H11.5L12.32 32.5H28.18L29 35H31.5L30.6012 32.305C31.91 31.905 32.9263 30.855 33.2188 29.5312V29.4925L35.0163 20H36.5V17.5H4ZM8.02375 20H32.515L30.7575 29.0238C30.6112 29.565 30.1525 30 29.5075 30H11.11C10.44 30 9.98625 29.5562 9.86 28.9837L8.02375 20Z"
        fill={value ? "#17BAAE" : "black"}
      />
    </svg>
  );
}

export default HotTub