import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Breadcrumb from "../../../component/BreadCrumb";
import ButtonComp from "../../../component/Button";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import { getSx } from "./style";
import searchicon from "../../../assets/images/searchicon.png";
import locationicon from "../../../assets/images/svg/locationicongreen.svg";
import timinggreen from "../../../assets/images/timinggreen.png";
import NavBar from "../home";
import { convertTo12HourFormat, getCurrencyFormat } from "../../../common/constant";
import { callBookingConfirmationApi } from "../../../api/addService";
import { pushBreadCrums } from "../../../store/slices/breadcrumb";
import { Container } from "@mui/system";
const BookInfo = () => {
  const [bookingInfo, setBookingInfo] = useState({});
  const [addOnServices, setAddOnServices] = useState([]);
  const [image, setImage] = useState();
  const [title, setTitle] = useState();
  const [policy, setPolicy] = useState();
  const [loading, setLoading] = useState(false);
  const classes = getSx();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const cancelPolicy = {
    Flexible:
      "Full refund for cancellations made for at least 7 days before the event data, Refund 50% for cancellations made between 3-6 days before the event",
    Moderate:
      "Full refund for cancellations made for at least 14 days before the event data, Refund 50% for cancellations made between 7-13 days before the event. No refund for cancellations made for 6 days or less before the event",
    Strict:
      "Refund 50% for cancellations made for at least 21 days before the event data, No refund for cancellations made 20 days or less before the event.",
    "Very Strict":
      "Refund 50% for cancellations made for at least 30 days before the event data, No refund for cancellations made 29 days or less before the event",
  };

  const callApi = async () => {
    setLoading(true);
    const resp = await callBookingConfirmationApi(id);
    setBookingInfo(resp?.data?.data[0]);
    let val = resp?.data?.data[0]?.serviceDetails?.services?.slice(1)?.flatMap((item) => item.categories);
    dispatch(
      pushBreadCrums([
        { icon: searchicon, name: "Explore", path: "/" },
        { name: `${resp?.data?.data[0]?.serviceDetails?.serviceTitle}` },
      ])
    );
    setAddOnServices(val);
    setImage(resp?.data?.data[0]?.serviceDetails?.serviceImages[0].fileUrl);
    setTitle(resp?.data?.data[0]?.serviceDetails?.serviceTitle);
    setPolicy(resp?.data?.data[0]?.serviceDetails?.cancellationPolicy);
    setLoading(false);
  };
  const getValue = (catinfo) => {
    if (catinfo?.field1?.value == "Hourly" || catinfo?.field1?.value == "Fixed") {
      return catinfo?.field6?.value?.map((dateitem, index) => {
        return (
          <Box key={dateitem}>{`${dayjs(dateitem?.date).format("MMM DD")} From: ${convertTo12HourFormat(
            dateitem?.startTime?.substring(0, 5)
          )} - ${convertTo12HourFormat(dateitem?.endTime?.substring(0, 5))}`}</Box>
        );
      });
    } else {
      return catinfo?.field6?.value?.map((dateitem, index) => {
        return (
          <span style={{ marginRight: "5px" }} key={dateitem}>
            {dayjs(dateitem?.date).format("MMM DD")}
            {index != catinfo?.field6?.value?.length - 1 && ","}
          </span>
        );
      });
    }
  };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />{" "}
        </Box>
      ) : (
        <Box
          sx={{
            padding: {
              xs: "15px 0",
              sm: "15px 0",
              md: "15px 0 0",
              lg: "15px 0 0",
              xl: "30px 0 0",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "auto",
            }}
          >
            <Breadcrumb />
          </Box>
          <Box sx={classes.bookcontainer}>
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "rgba(26, 26, 26, 0.1)",
                color: "black",
                fontWeight: "600",
                right: "1rem",
                top: "1rem",
                padding: "7px 18px",
                borderRadius: "10px",
                fontSize: { xs: "10px", sm: "12px", md: "12px", lg: "14px", xl: "16px" },
                fontFamily: "Montserrat,sans-serif",
              }}
            >
              {dayjs(bookingInfo?.serviceDetails?.startDate).format("MMM DD,YYYY")}
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat,sans-serif",
                fontSize: {
                  xs: "15px",
                  sm: "16px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
              variant="h5"
              fontWeight="bold"
            >
              Booking Confirmation
            </Typography>
            <Box sx={classes.bookmain}>
              <Box sx={classes.bookleft}>
                <Grid item xs={12} md={12} sx={classes.catinfo1}>
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Booking Id</Box>
                <Box sx={classes.catright}>{bookingInfo?._id}</Box>
              </Box> */}
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Payment Type</Box>
                <Box sx={classes.catright}>Credit Card</Box>
              </Box> */}
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Service Amount</Box>
                <Box sx={classes.catright}> {getCurrencyFormat(bookingInfo?.amountData?.serviceAmount.toFixed(2))}</Box>
              </Box> */}
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Add-on Service Amount</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.adOnServiceAmount.toFixed(2))}</Box>
              </Box> */}
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}> Platform Fee</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.serviceFee.toFixed(2))}</Box>
              </Box> */}

                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Tax (Amount)</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.taxAmount.toFixed(2))}</Box>
              </Box> */}
                  {/* <hr
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  height: "1px",
                  border: "none",
                }}
              /> */}
                  <Box sx={classes.catmain}>
                    <Box sx={classes.catleft}>EventTutti Fee</Box>
                    <Box sx={classes.catright}>
                      {getCurrencyFormat(bookingInfo?.amountData?.eventTuttiFee.toFixed(2))}
                    </Box>
                  </Box>
                  {bookingInfo?.amountData?.tax > 0 && (
                    <Box sx={classes.catmain}>
                      <Box sx={classes.catleft}>Tax </Box>
                      <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.tax.toFixed(2))}</Box>
                    </Box>
                  )}
                  {bookingInfo?.amountData?.discountAmount > 0 && (
                    <>
                      <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>Discount Amount</Box>
                        <Box sx={{ ...classes.catright, color: "rgba(23, 186, 174, 1)" }}>
                          -{getCurrencyFormat(bookingInfo?.amountData?.discountAmount.toFixed(2))}
                        </Box>
                      </Box>
                      {/* <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>Total Amount</Box>
                        <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.totalPaidAmount.toFixed(2))}</Box>
                      </Box> */}
                    </>
                  )}
                  {/* <Box sx={classes.catmain}>
                <Box sx={classes.catleft}>Transaction Fee</Box>
                <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.stripeFee.toFixed(2))}</Box>
              </Box> */}
                  {/* <Box sx={{ ...classes.catmain, color: "rgba(23, 186, 174, 1)" }}>
                <Box sx={classes.catleft}>Coupon (Z678453W)</Box>
                <Box
                  sx={{
                    ...classes.catright,
                    color: "rgba(23, 186, 174, 1)",
                    fontWeight: "500",
                  }}
                >
                  -500.00
                </Box>
              </Box> */}
                  <hr
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                      height: "1px",
                      border: "none",
                    }}
                  />
                  <Box sx={classes.catmain}>
                    <Box sx={classes.catleft}>Total Amount</Box>
                    <Box sx={classes.catright}>{getCurrencyFormat(bookingInfo?.amountData?.totalPaidAmount)}</Box>
                  </Box>
                </Grid>
              </Box>

              <Box sx={classes.bookright}>
                <Box
                  sx={{
                    display: { xs: "block", sm: "block", md: "flex", xl: "flex", lg: "flex" },
                    width: "100%",
                    marginBottom: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "2rem",
                    },
                    height: { xs: "auto", lg: "51%" },
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "25px",
                      objectFit: "cover",
                      flex: "1",
                    }}
                  >
                    <img alt="" src={image} style={{ borderRadius: "25px", width: "100%", height: "100%" }} />
                  </Box>
                  <Box
                    sx={{
                      flex: "2",
                      paddingLeft: {
                        xs: 0,
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      sx={{
                        fontFamily: "Montserrat,sans-serif",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "18px",
                          lg: "20px",
                          xl: "24px",
                        },
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: "Montserrat,sans-serif" }}>
                      {bookingInfo?.serviceDetails?.serviceName}
                    </Typography>
                    {/* <Box sx={{ display: "flex", margin: "10px" }}>
                  <img src={timinggreen} style={{width:"25px",height:"25px",marginRight:"5px"}} />
                  {dayjs(bookingInfo?.startDate).format("MMM DD")}-{" "}
                  {dayjs(bookingInfo?.endDate).format("MMM DD")}
                </Box> */}
                    <Box sx={{ display: "flex", margin: "10px 0" }}>
                      <img alt="" src={timinggreen} style={{ width: "25px", height: "25px", marginRight: "5px" }} />
                      <Box sx={{ display: "block" }}>
                        {bookingInfo?.serviceDetails?.services?.map((item) => {
                          return (
                            <Box>
                              <Box>
                                {item.categories.map((catinfo) => {
                                  return (
                                    <>
                                      <Box sx={{ display: "block" }} key={catinfo}>
                                        <span style={{ fontWeight: "bold", fontFamily: "Montserrat,sans-serif" }}>
                                          {catinfo.categoryId.categoryName} :{" "}
                                        </span>
                                        <span>{getValue(catinfo)}</span>
                                      </Box>
                                    </>
                                  );
                                })}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", margin: "10px 0" }}>
                      <img alt="" src={locationicon} style={{ width: "25px", height: "25px", marginRight: "5px" }} />
                      {bookingInfo?.serviceDetails?.address} {bookingInfo?.serviceDetails?.state}{" "}
                      {bookingInfo?.serviceDetails?.zipCode}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "18px",
                        lg: "20px",
                        xl: "24px",
                      },
                    }}
                    variant="h5"
                    fontWeight="bold"
                  >
                    Cancellation Policy
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: {
                          xs: "14px",
                          sm: "15px",
                          md: "16px",
                          lg: "16px",
                          xl: "18px",
                        },
                        fontFamily: "Montserrat,sans-serif",
                      }}
                    >
                      ({policy})
                    </span>{" "}
                  </Typography>
                  <Box sx={{ color: "rgba(85, 85, 85, 0.8)", fontSize: "14px", fontFamily: "Montserrat,sans-serif" }}>
                    {cancelPolicy[policy]}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                fontFamily: "Montserrat,sans-serif",
                fontSize: {
                  xs: "16px",
                  sm: "18px",
                  md: "18px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Selected Service Categories
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {bookingInfo?.serviceDetails?.services[0]?.categories.map((item) => {
                return (
                  <Box
                    sx={{
                      border: "1px solid rgba(218, 218, 218, 1)",
                      borderRadius: "18px",
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      margin: { xs: "10px 0px", sm: "10px" },
                      maxWidth: "300px",
                    }}
                    key={item?._id}
                  >
                    <Box sx={{ width: "80px", height: "70px" }}>
                      <img
                        alt=""
                        src={item.categoryId.displayImage.fileUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          fontSize: "15px",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        {item?.categoryId?.categoryName}
                      </Box>
                      <Box
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        {getCurrencyFormat(item.field2.value)}{" "}
                        <span style={{ color: "gray", fontWeight: "500", fontFamily: "Montserrat,sans-serif" }}>
                          /{item.field1.value}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            {addOnServices?.length > 0 && (
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "18px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                Selected Add-On Service Categories
              </Typography>
            )}
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {addOnServices?.map((item) => {
                return (
                  <Box
                    sx={{
                      border: "1px solid rgba(218, 218, 218, 1)",
                      borderRadius: "18px",
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      margin: { xs: "10px 0px", sm: "10px" },
                      maxWidth: "300px",
                    }}
                    key={item.categoryId}
                  >
                    <Box sx={{ width: "80px", height: "70px" }}>
                      <img
                        alt=""
                        src={item.categoryId.displayImage.fileUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          fontSize: "15px",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        {item?.categoryId?.categoryName}
                      </Box>
                      <Box
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "Montserrat,sans-serif",
                        }}
                      >
                        {getCurrencyFormat(item.field2.value)}{" "}
                        <span style={{ color: "gray", fontWeight: "500" }}>/{item.field1.value}</span>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            className="fixed-btn-div"
            sx={{
              display: "flex",
              float: "right",
              width: {
                xs: "calc(100% - 60px)",
                sm: "20%",
                md: "20%",
                lg: "20%",
                xl: "14%",
              },
              position: "fixed",
              bottom: {
                xs: "30px",
                sm: "45px",
                md: "45px",
                lg: "45px",
                xl: "45px",
              },
              right: {
                xs: "30px",
                sm: "45px",
                md: "45px",
                lg: "45px",
                xl: "205px",
              },
              zIndex: 999,
            }}
          >
            <ButtonComp
              label="Done"
              width="100%"
              type="active"
              onClick={() => {
                navigate("/event-dashboard?id=1");
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export function BookConfirmation() {
  return (
    <NavBar>
      <Container
        sx={{
          padding: {
            xs: "0 15px",
            sm: "0 30px",
            md: "0 30px",
            lg: "0 30px",
            xl: 0,
          },
        }}
        maxWidth="xl"
      >
        <BookInfo />
      </Container>
    </NavBar>
  );
}

export default BookConfirmation;
