export const getSx=()=>{
  return {
    maincontainer: {
      width: "100%",
      height: "100vh",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      width: {
        xs:"85%",
        sm:"80%",
        md:"80%",
        lg:"80%",
        xl:"80%"
      },
      height: {
        xs: "calc(100vh - 250px)",
        sm:"calc(100vh - 400px)",
        md: "calc(100vh - 80px)",
        lg:"calc(100vh - 80px)",
        xl:"calc(100vh - 180px)",
      },
      margin:'auto',
      border: "1px solid rgba(233, 233, 233, 1)",
      padding: "10px",
      borderRadius: "15px",
      display: "flex",
      justifyContent: { xs: "center", lg: "center" },
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      overflow: "auto",
    },
 
  };
}