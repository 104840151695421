import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Modal from "../component/Modal";
import { getModalProps } from "../component/Modal/Modalutils";
import { Snackbar, Alert, Box } from "@mui/material";
import { getRole } from "../utils/auth-token-utils/cookies";
import { handleRouting } from "../utils/handleRouting";
import { showToast } from "../store/slices/common";
import useNetworkStatus from "../useNetworkStatus";
import { routePaths } from "./route-paths";
import routes from "./routers";

export const getRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element()} />
      ))}
    </Routes>
  );
};

export default function RootView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useSelector((state) => state.common.toast);
  const info = useSelector((state) => state.profile.profileInfo);
  const role = getRole();
  const modal = useSelector((state) => state.common.modal);
  const token = localStorage.getItem("token");
  const isOnline = useNetworkStatus();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let id = queryParams.get("id");

  let guestToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiR3Vlc3QiLCJpYXQiOjE3MTg2NTExNTksImV4cCI6MTc1MDE4NzE1OX0.4t4DYwmHVaTE2l9cdRA2Ylmxus4VbgXWhlM7L5wiVR4";

  let selectedServiceId = localStorage.getItem("selectedServiceId");
  
  const closeToast = () => {
    setTimeout(() => {
      dispatch(showToast(null));
    }, 500);
  };

  useEffect(() => {
    if (token && token != guestToken) {
      if (location.pathname.includes("service-info") && role == "0" && id) navigate("/service-info?id=" + id);
      else if (
        !(
          location.pathname.includes("event-dashboard") ||
          location.pathname.includes("booking-info") ||
          (location.pathname.includes("myservices") && role == "1") ||
          (location.pathname.includes("serviceprovider-dashboard") && role == "1") ||
          location.pathname.includes("ratings-and-reviews") ||
          location.pathname.includes("settings") ||
          (location.pathname.includes("service-info") && role == "1") ||
          location.pathname.includes("messages") ||
          location.pathname.includes("notifications")
        ) &&
        (role == "0" || role == "1")
      )
        handleRouting(info, navigate, routePaths, selectedServiceId);
    } else if (
      !location.pathname.includes("login") &&
      !location.pathname.includes("signup") &&
      !location.pathname.includes("reset-password") &&
      !location.pathname.includes("resetpassword") &&
      !location.pathname.includes("termsandconditions") &&
      !location.pathname.includes("privacypolicy") &&
      !location.pathname.includes("service-info") &&
      !location.pathname != "/" &&
      !(location.pathname.includes("explore_event") && role != "1") &&
      !(role == "0" || role == "1")
    ) {
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/myservices" ||
      location.pathname == "/bank-details" ||
      location.pathname.includes("profilecreationsucessful") ||
      location.pathname === "/switch-profile" ||
      location.pathname.includes("create-profile")
    ) {
      const disableBackNavigation = () => {
        history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
          history.go(1);
        };
      };

      disableBackNavigation(); // To disable back navigation
      return () => {
        window.onpopstate = null;
      };
    }
  }, [location.pathname]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(toast)}
        autoHideDuration={3000}
        onClose={closeToast}
      >
        {toast && (
          <Alert
            onClose={closeToast}
            severity={toast.color}
            onClick={() => {
              dispatch(showToast(null));
            }}
          >
            {toast.msg}
          </Alert>
        )}
      </Snackbar>
      {Boolean(modal) && <Modal open={Boolean(modal)} {...getModalProps(modal)} />}
      {!isOnline && (
        <Box
          style={{
            color: "white",
            backgroundColor: "red",
            zIndex: "1111111",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "5vh",
          }}
        >
          No Internet
        </Box>
      )}
      {getRoutes()}
    </>
  );
}
