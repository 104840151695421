import React from "react";

import { Paper } from "@mui/material";
import { getSx } from "./style";

export function Item(props) {
  const classes = getSx();
  return (
    <Paper className={classes.maininfo}>
      <img src={props.item.fileUrl} style={{ height: "100vh" }} />
    </Paper>
  );
}
