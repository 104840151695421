import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";

const NameInfo = ({ setPage, setValue, role, value, handleData, data,setSelectedDate}) => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required").matches(
      /^[A-Za-záéíóúñü¡¿ ]+$/,
      "Invalid firstName"
    ).max(50, 'First name must be less than 15 characters'),
    lastName: Yup.string().required("Required").matches(
      /^[A-Za-záéíóúñü¡¿ ]+$/,
      "Invalid lastName"
    ).max(50, 'Last name must be less than 15 characters'),
  });

  const classes = getSx();
  const formik = useFormik({
    initialValues: { firstName: data?.firstName, lastName: data?.lastName },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  

useEffect(()=>{
  if(role=="0")
  {
  if(formik?.values.firstName&& formik?.values?.lastName )
  setValue(25)
else
  setValue(6);
  }
},[formik])

  return (
    <Box sx={classes.nameinfo}>
      <Typography sx={classes.heading}>
        Enter your first and last name
      </Typography>
      <Box sx={classes.createFormMain}>
          <Box sx={{marginBottom:{
              xs:'10px',
                  sm:'10px',
                  md:'10px',
                  lg:'15px',
                  xl:'20px'}
                  }}>
            <Typography sx={classes.smallheading}>First Name</Typography>
            <CustomTextField
                type="text"
                variant="outlined"
                placeholder="Enter First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={(e) => {
                    formik.setFieldTouched("firstName", true)
                    formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                errorMessage={formik.touched.firstName && formik.errors.firstName}
                maxLength={50}
            />
        </Box>
        <Box sx={{marginBottom:{
              xs:'10px',
                  sm:'10px',
                  md:'10px',
                  lg:'15px',
                  xl:'20px'}}}>
            <Typography sx={classes.smallheading}>Last Name</Typography>
            <CustomTextField
                type="text"
                variant="outlined"
                placeholder="Enter Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={(e) => {
                    formik.setFieldTouched("lastName", true)
                    formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                errorMessage={formik.touched.lastName && formik.errors.lastName}
                maxLength={50}
            />
        </Box>
       
          <ButtonComp
              className="responsive-btn-class"
        width="100%"
        height='60px'
        disabled={!(formik.isValid&&formik.dirty  || (data?.firstName&&data?.lastName&&formik.values?.firstName&&formik?.values?.lastName) )}
        type={
          (formik.isValid&&formik.dirty  || (data?.firstName&&data?.lastName&&formik.values?.firstName&&formik?.values?.lastName ) )
            ? "active"
            : "disabled"
        }
        label="Continue"
        style={{ marginTop: "40px", width: "100%",height:'60px', fontWeight:600,fontFamily:"Montserrat, sans-serif" }}
        onClick={() => {
          setSelectedDate(null);
          handleData({
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            cNumber:"",
            phoneNumber:"",
            countryCode:""
          });
          setPage((prev)=>prev+1)

        }}
      />
      </Box>
    </Box>
  );
};

export default NameInfo;
