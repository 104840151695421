import React, {  useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import ButtonComp from "../../../../component/Button";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import crosswhiteicon from "../../../../assets/images/crosswhiteicon.svg";
import plusicongreen from "../../../../assets/images/plusicongreen.svg";
import { getSx } from "../style";

import { uploadImageApi } from "../../../../api/Signup";
import { showToast } from "../../../../store/slices/common";

const DropZone = styled("div")(({ theme }) => ({
  cursor: "pointer",
  width: "165px",
  height: "165px",
  border: "2px solid #17BAAE",
  borderStyle: "dashed",
  borderRadius: "33px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

   const ImagesAndDesc = ({
  addService,
  setAddService,
  callAddOnApi,
   loader,
  isEdit,
  callUpdateAddOnApi
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const classes = getSx();
  
  const onDrop = (acceptedFiles) => {
    if (addService?.addOnServiceImages?.length + acceptedFiles.length > 10) {
      dispatch(
        showToast({
          color: "error",
          msg: "You can upload a maximum of 10 files.",
        })
      );
      return; 
    }
    acceptedFiles.forEach((item, index) => {
      if (validateFileExtension(item?.name)) {
        handleImages(item, index, acceptedFiles.length);
      } else {
        dispatch(
          showToast({
            color: "error",
            msg: "Please Upload Valid Document in png/jpg format",
          })
        );
      }
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 15,
    accept: "image/*",
  });
 
  function validateFileExtension(fileName) {
    const allowedExtensions = ["png", "jpg", "jpeg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return allowedExtensions.indexOf(fileExtension) !== -1;
  }
  
  const handleImages = async (file, index, len) => {
    if (index == 0) setLoading(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    let formData = new FormData();
    formData.append("file", file);
    const response = await uploadImageApi(formData);
    if (response.type === "success") {
      setAddService((prev) => ({
        ...prev,
        addOnServiceImages: [
          ...prev.addOnServiceImages,
          { key: response?.data?.key, fileUrl: response?.data?.fileUrl },
        ],
      }));
    }
    if (index + 1 == len) setLoading(false);
  };

 
  const handleDeleteImage = (index) => {
    const imgs = addService?.addOnServiceImages?.filter((item, i) => index != i);
    setAddService({ ...addService, addOnServiceImages: [...imgs] });
  };

  const checkIsAlpa = (value) => {
    let lines = value.split('\n');
    let cleanedLines = lines.map(line => line.trim()).filter(line => line.length > 0);
    let cleanedText = cleanedLines.join(' ');
if(cleanedText.trim().match(/^[A-Za-z,./?$!&:;'"[\]{}() ]+$/))
{
  setError("")
}
else
{   
  setError("Only Alphabets are allowed")
}
  }
  

  return (
    <>
      <Typography
        component="div"
        variant="div"
        marginTop="20px"
        sx={classes.addressmain}
      >
        <Typography
          component="div"
          variant="body"
          fontWeight="bold"
          sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"Montserrat, sans-serif",marginBottom:'15px'}}
        >
          Service Images
        </Typography>
        <Box sx={classes.serviceimages}>
          {addService?.addOnServiceImages?.length < 10 &&
            (loading ? (
              <Box
                sx={{
                  width: "165px",
                  height: "165px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <DropZone {...getRootProps()}>
                <Box>
                  <img alt="" src={plusicongreen} />
                  <input
                    {...getInputProps()}
                    multiple
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                </Box>
              </DropZone>
            ))}

          <Box
            sx={
              addService?.addOnServiceImages?.length < 10
                ? { ...classes.cardimages }
                : { ...classes.cardimages, width: "100% !important" }
            }
          >
            {addService?.addOnServiceImages?.map((item, index) => {
              return (
                <Box sx={classes.singleimg}>
                  <img
                    src={crosswhiteicon}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                      top: "1rem",
                      width: "15px",
                      height: "15px",
                    }}
                    onClick={() => {
                      handleDeleteImage(index);
                    }}
                  />
                  <img
                    src={item?.fileUrl}
                    style={{
                      width: "165px",
                      height: "165px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ color: "rgba(23, 186, 174, 1)", margin: "0.5rem 0px" }}>
          * Please upload a minimum of two images 
        </Box>
     
          <Box>
            <Typography
              component="div"
              variant="body"
              fontWeight="bold"
              margin="10px 0px"
            >
              Service Description
            </Typography>
            <TextareaAutosize
              placeholder="Enter Description "
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "rgba(246, 245, 245, 1)",
                borderRadius: "15px",
                marginBotton: "3rem",
                fontSize: "15px",
                fontFamily: "Montserrat",
                border: "none",
                outline: "none",
                overflowY: "auto !important",
                maxHeight: "200px",
              }}
              className="textArea"
              name="description"
              value={ addService?.description}
              onChange={(e) => {
                checkIsAlpa(e.target.value);
                setAddService({ ...addService, description: e.target.value });
              }}
              minRows={8}
            />
            {error && <Typography sx={{ color: "red" }}>{error}</Typography>}
          </Box>
       
      </Typography>
  
        <ButtonComp
          label={isEdit?"Update Add-On Service":"Add-On Service"}
          type={
            addService?.addOnServiceImages?.length > 1 &&
            addService?.description?.length > 0 &&
            !loading && !error
              ? "active"
              : "disabled"
          }
          disabled={
            addService?.addOnServiceImages?.length > 1 &&
            addService?.description?.length > 0 && !error &&
            !loading
              ? false
              : true
          }
          width="100%"
          loading={loader}
          onClick={()=>{  
            if(isEdit)
            callUpdateAddOnApi();
           else         
            callAddOnApi();
          }}
        />
     
    </>
  );
};
export default ImagesAndDesc;

ImagesAndDesc.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  mapValue: PropTypes.string,
  setMapValue: PropTypes.func,
};
