//Book Service Modal  
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import AddOnServiceCategorySelection from "./AddOnServiceCategorySelection";
import AddressandDesc from "./AddressandDesc";
import rightarrow from "../../../assets/images/rightarrow.svg";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import ServiceCategorySelection from "./ServiceCategorySelection";
import { setFinalBookServiceInfo } from "../../../store/slices/bookserviceinfo";
import CustomServiceCategorySubInfo from "./CustomServiceCategorySubInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "500px",
    padding: "30px",
    borderRadius: "30px",
    height: 'auto',
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));
const BookServiceModal = ({
  openBookServiceModal,
  setOpenBookServiceModal,
  callBookSserviceAmountApi,
  showAmount,
  setFinalData,
}) => {
  const [addBookService, setAddBookService] = useState({
    description: "",
    address: "",
    state: "",
    zipCode: "",
  });
  const [page, setPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addOnSelectedCategories, setAddOnSelectedCategories] = useState([]);
  const [selectedDatesArr, setSelectedDatesArr] = useState([]);
  const [timings, setTimings] = useState([]);
  const [addOnTimings, setAddOnTimings] = useState([]);
  const [addOnSelectedDatesArr, setAddOnSelectedDatesArr] = useState([]);
  let bookServiceInfo = useSelector(
    (state) => state.bookserviceinfo.finalBookServiceInfo
  );
  let pageNum = useSelector((state) => state.bookserviceinfo.pageNum);
  let info = useSelector((state) => state.bookserviceinfo.serviceInfo);

  const addOnCatIds = useSelector(
    (state) => state.bookserviceinfo.addOnCategoryIds
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenBookServiceModal(false);
  };
  //saves the book service object and calls the api to get the amount
  const handleSave = () => {
    let serviceArr = [...selectedCategories];
    let addOnSelArr = [...addOnSelectedCategories];
    let serviceName = info.services.filter((item) => !item.isAdOnService)[0]
      .serviceId.name;

    serviceArr?.forEach((item, index1) => {
      let field6 = { value: [] };
      const addDate = (date, startTime, endTime, endDate) => {
        const entry = { date };
        if (startTime && endTime) {
          entry.startTime = convertToHourFormat(startTime);
          entry.endTime = convertToHourFormat(endTime);
          entry.endDate = dayjs(endTime, "hh:mm A").utc().isBefore(dayjs(startTime, "hh:mm A").utc()) ? dayjs(date).add(1, 'day').format('YYYY-MM-DD') : date;
        }
        field6.value.push(entry);
      };
      if (
        item.field1.value === "Hourly" ||
        item.field1.value === "Fixed Cost"
      ) {
        selectedDatesArr[index1].forEach((date, index2) => {
          const startTime = timings[index1][index2]?.startTime;
          const endTime = timings[index1][index2]?.endTime;
          //const endDate = timings[index1][index2]?.endDate;
          addDate(date, startTime, endTime, endDate);
        });
      } else {
        selectedDatesArr[index1].forEach((date) => {
          addDate(date);
        });
      }
      if (!serviceArr[index1]?.field6) {
        serviceArr[index1] = { ...serviceArr[index1], field6: { value: [] } };
      }

      serviceArr[index1].field6.value = field6.value;
      if (serviceArr[index1] && serviceArr[index1]?.field3 && serviceArr[index1]?.field3?.value) {
        serviceArr[index1] = {
          ...serviceArr[index1],
          field3: {
            ...serviceArr[index1].field3,
            value: Number(serviceArr[index1].field3.value)
          }
        };
      }
    });


    if (addOnSelectedCategories?.length > 0) {
      addOnSelArr?.forEach((item, index1) => {
        let field6 = { value: [] };

        const addDate = (date, startTime, endTime, endDate) => {
          const entry = { date };
          if (startTime && endTime) {
            entry.startTime = convertToHourFormat(startTime);
            entry.endTime = convertToHourFormat(endTime);
            entry.endDate = dayjs(endTime, "hh:mm A").utc().isBefore(dayjs(startTime, "hh:mm A").utc()) ? dayjs(date).add(1, 'day').format('YYYY-MM-DD') : date;

            //entry.endDate = endDate ? endDate : date;;
          }
          field6.value.push(entry);
        };

        if (
          item.field1.value === "Hourly" ||
          item.field1.value === "Fixed Cost"
        ) {
          addOnSelectedDatesArr[index1].forEach((date, index2) => {
            const startTime = addOnTimings[index1][index2]?.startTime;
            const endTime = addOnTimings[index1][index2]?.endTime;
            const endDate = addOnTimings[index1][index2]?.endDate;
            addDate(date, startTime, endTime, endDate);
          });
        } else {
          addOnSelectedDatesArr[index1].forEach((date) => {
            addDate(date);
          });
        }

        if (!addOnSelArr[index1].field6) {
          addOnSelArr[index1] = {
            ...addOnSelArr[index1],
            field6: { value: [] },
          };
        }

        addOnSelArr[index1].field6.value = field6.value;
        if (addOnSelArr[index1] && addOnSelArr[index1]?.field3 && addOnSelArr[index1]?.field3?.value) {
          addOnSelArr[index1] = {
            ...addOnSelArr[index1],
            field3: {
              ...addOnSelArr[index1].field3,
              value: Number(addOnSelArr[index1].field3.value)
            }
          };
        }
      });
    }
    let groupedData = {};
    addOnSelArr.forEach((item) => {
      const serviceId = item.categoryId.serviceId;
      if (!groupedData[serviceId]) {
        groupedData[serviceId] = [];
      }
      groupedData[serviceId].push(item);
    });
    let groupedData1 = Object.values(groupedData).map((service) => {
      return {
        serviceId: service[0].categoryId.serviceId,
        categories: service,
        isAdOnService: true,
      };
    });
    let finalObj = {
      ...addBookService,
      services: [
        {
          isAdOnService: false,
          categories: serviceArr,
          serviceId: serviceArr[0]?.categoryId?.serviceId,
        },
        ...groupedData1,
      ],
    };
    let completeServiceArr = finalObj.services;
    let minDate1 = "";
    let maxDate1 = "";
    completeServiceArr?.forEach((service) => {
      service?.categories?.forEach((catItem) => {
        catItem?.field6?.value?.forEach((dateitem) => {
          const val = dateitem.date;

          if (!maxDate1 || dayjs(val).isAfter(dayjs(maxDate1))) {
            maxDate1 = val;
          }

          if (!minDate1 || dayjs(val).isBefore(dayjs(minDate1))) {
            minDate1 = val;
          }
        });
      });
    });
    let finalObj1 = {
      address: info.address,
      city: info.city,
      state: info.state,
      zipCode: info.zipCode,
      location: { lat: info.location.lat, long: info.location.long },
      description: addBookService.description,
      services: [
        {
          isAdOnService: false,
          categories: serviceArr,
          serviceId: serviceArr[0]?.categoryId?.serviceId,
        },
        ...groupedData1,
      ],
    };
    setFinalData({
      startDate: dayjs(minDate1).format("YYYY-MM-DD"),
      endDate: dayjs(maxDate1).format("YYYY-MM-DD"),
    });
    if (serviceName != "Venues") {
      callBookSserviceAmountApi(finalObj);
      dispatch(setFinalBookServiceInfo(finalObj));
    }
    else {
      callBookSserviceAmountApi(finalObj1);
      dispatch(setFinalBookServiceInfo(finalObj1));
    }
    setOpenBookServiceModal(false);
  };
  //saves the service category
  const handleSaveServiceCategory = () => {
    let serviceArr = [...selectedCategories];
    serviceArr?.forEach((item, index1) => {
      let field6 = { value: [] };
      const addDate = (date, startTime, endTime) => {
        const entry = { date };
        if (startTime && endTime) {
          entry.startTime = convertToHourFormat(startTime);
          entry.endTime = convertToHourFormat(endTime);
        }
        field6.value.push(entry);
      };
      if (
        item.field1.value === "Hourly" ||
        item.field1.value === "Fixed Cost"
      ) {
        selectedDatesArr[index1].forEach((date, index2) => {
          const startTime = timings[index1][index2]?.startTime;
          const endTime = timings[index1][index2]?.endTime;
          addDate(date, startTime, endTime);
        });
      } else {
        selectedDatesArr[index1].forEach((date) => {
          addDate(date);
        });
      }
      if (!serviceArr[index1]?.field6) {
        serviceArr[index1] = { ...serviceArr[index1], field6: { value: [] } };
      }
      serviceArr[index1].field6.value = field6.value;

    });
    let service1 = { ...bookServiceInfo.services[0], isAdOnService: false };
    service1.categories = [
      ...bookServiceInfo.services[0].categories,
      ...serviceArr,
    ];
    let oddonArr = bookServiceInfo.services.slice(1);
    bookServiceInfo = { ...bookServiceInfo, services: [service1, ...oddonArr] };
    dispatch(setFinalBookServiceInfo(bookServiceInfo));
  };
  //saves the add on service category
  const handleSaveAddOnServiceCategory = () => {
    let addOnSelArr = addOnSelectedCategories;
    if (addOnSelectedCategories?.length > 0) {
      addOnSelArr?.forEach((item, index1) => {
        let field6 = { value: [] };
        const addDate = (date, startTime, endTime, endDate) => {
          const entry = { date };
          if (startTime && endTime) {
            entry.startTime = convertToHourFormat(startTime);
            entry.endTime = convertToHourFormat(endTime);
            entry.endDate = dayjs(endTime, "hh:mm A").utc().isBefore(dayjs(startTime, "hh:mm A").utc()) ? dayjs(date).add(1, 'day').format('YYYY-MM-DD') : date;

            // entry.endDate = endDate ? endDate : date;
          }
          field6.value.push(entry);
        };
        if (
          item.field1.value === "Hourly" ||
          item.field1.value === "Fixed Cost"
        ) {
          addOnSelectedDatesArr[index1].forEach((date, index2) => {
            const startTime = addOnTimings[index1][index2]?.startTime;
            const endTime = addOnTimings[index1][index2]?.endTime;
            const endDate = addOnTimings[index1][index2]?.endDate;
            addDate(date, startTime, endTime, endDate);
          });
        } else {
          addOnSelectedDatesArr[index1].forEach((date) => {
            addDate(date);
          });
        }
        if (!addOnSelArr[index1].field6) {
          addOnSelArr[index1] = {
            ...addOnSelArr[index1],
            field6: { value: [] },
          };
        }

        addOnSelArr[index1].field6.value = field6.value;
      });
    }
    let addOnCats = bookServiceInfo?.services
      ?.slice(1)
      .flatMap((service) => service.categories);
    let addOnCatsArr = [...addOnCats, ...addOnSelArr];
    let groupedData = {};
    addOnCatsArr.forEach((item) => {
      const serviceId = item.categoryId.serviceId;
      if (!groupedData[serviceId]) {
        groupedData[serviceId] = [];
      }
      groupedData[serviceId].push(item);
    });
    let groupedData1 = Object.values(groupedData).map((service) => {
      return {
        serviceId: service[0].categoryId.serviceId,
        categories: service,
        isAdOnService: true,
      };
    });
    let finalObj = {
      ...bookServiceInfo,
      services: [
        {
          ...bookServiceInfo.services[0],
        },
        ...groupedData1,
      ],
    };
    dispatch(setFinalBookServiceInfo(finalObj));
  };
  const convertToHourFormat = (time) => {
    let utcTime = dayjs.utc(time).local();
    let formattedTime = utcTime.format("HH:mm:ss");
    return formattedTime;
  }

  const callFunction = (page) => {
    switch (page) {
      case 1:
        return (
          <ServiceCategorySelection
            serviceCategories={info?.services[0]?.categories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            setPage={setPage}
            serviceTimings={info?.serviceTiming}
            availableDays={availableDays}
            setAvailableDays={setAvailableDays}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setSelectedDatesArr={setSelectedDatesArr}
            setTimings={setTimings}
          />
        );
      case 2:
        return (
          <CustomServiceCategorySubInfo
            selectedCategories={selectedCategories}
            availableDays={availableDays}
            startDate={startDate}
            endDate={endDate}
            setPage={setPage}
            setSelectedCategories={setSelectedCategories}
            selectedDatesArr={selectedDatesArr}
            setSelectedDatesArr={setSelectedDatesArr}
            timings={timings}
            setTimings={setTimings}
            isAddOn={false}
            serviceTimings={info?.serviceTiming}
            servicesLength={info?.services?.length}
            handleSaveServiceCategory={handleSaveServiceCategory}
            setOpenBookServiceModal={setOpenBookServiceModal}
            setAddOnSelectedCategories={setAddOnSelectedCategories}
          />
        );
      case 3:
        return (
          <AddOnServiceCategorySelection
            setAddOnSelectedCategories={setAddOnSelectedCategories}
            addOnSelectedCategories={addOnSelectedCategories}
            services={info?.services}
            setPage={setPage}
            setAddOnSelectedDatesArr={setAddOnSelectedDatesArr}
            setAddOnTimings={setAddOnTimings}
            showAmount={showAmount}
          />
        );
      case 4:
        return (
          <CustomServiceCategorySubInfo
            selectedCategories={addOnSelectedCategories}
            availableDays={availableDays}
            startDate={startDate}
            endDate={endDate}
            setPage={setPage}
            setSelectedCategories={setAddOnSelectedCategories}
            selectedDatesArr={addOnSelectedDatesArr}
            setSelectedDatesArr={setAddOnSelectedDatesArr}
            timings={addOnTimings}
            setTimings={setAddOnTimings}
            isAddOn={true}
            serviceTimings={info?.serviceTiming}
            setOpenBookServiceModal={setOpenBookServiceModal}
            handleSaveAddOnServiceCategory={handleSaveAddOnServiceCategory}
            setAddBookService={setAddBookService}
            showAmount={showAmount}
          />
        );
      case 5:
        return (
          <AddressandDesc
            addBookService={addBookService}
            setAddBookService={setAddBookService}
            handleSave={handleSave}
            serviceName={
              info?.services?.filter((item) => !item?.isAdOnService)[0]?.serviceId?.name
            }
          />
        );
    }
  };
  useEffect(() => {
    if (pageNum == 3) setPage(3);
  }, []);
  return (
    <CustomDialog
      open={openBookServiceModal}
      TransitionComponent={Transition}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "500px" } }}
    >
      <DialogContent sx={{
        padding: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0,
          xl: 0
        }, overflowY: "hidden"
      }}>
        <img
          src={crossicon}
          alt="crossicon"
          style={{
            position: "absolute",
            right: "1rem",
            top: "1.5rem",
            cursor: "pointer",
            zIndex: "111"
          }}
          className="bookcrossicon"
          onClick={handleClose}
        />
        {page != 1 &&
          !(
            (addOnCatIds.length > 0 && page == 3) ||
            (showAmount && page == 3)
          ) && (
            <img
              src={rightarrow}
              style={{
                position: "absolute",
                left: "1rem",
                top: "1.9rem",
                cursor: "pointer",
                zIndex: "111"
              }}
              className="bookrightcrossicon"
              onClick={() => {
                if (page > 1) {
                  if (
                    (page == 5 && info?.services?.length == 1) ||
                    (page == 5 && addOnSelectedCategories.length == 0)
                  )
                    setPage(2);
                  else setPage(page - 1);
                } else setOpenBookServiceModal(false);
              }}
            />
          )}
        <Typography
          variant="h6"
          fontWeight={700}
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          sx={{
            fontSize: {
              xs: '18px',
              sm: '20px',
              md: '20px',
              lg: '24px',
              xl: '28px'
            },
            lineHeight: '27px',
            fontFamily: "Montserrat, sans-serif"
          }}
        >
          Book a Service
        </Typography>
        {callFunction(page)}
      </DialogContent>
    </CustomDialog>
  );
};

export default BookServiceModal;
