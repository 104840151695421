import React from 'react'

import ButtonComp from '../../../../component/Button';
import { Box, Typography } from "@mui/material";
import addonserviceimg from '../../../../assets/images/addonserviceimg.svg';
import { getSx } from '../style';

const AddOnServices = ({ setPage, handleRest }) => {
  const classes = getSx();
  const handleClick = () => {
    setPage(1)
  }
  return (
    <Box sx={classes.main}  >
      <Box sx={classes.addonserviceimg}>
        <img alt="" src={addonserviceimg} />
      </Box>
      <Typography textAlign='center' variant="body" fontFamily="Montserrat,sans-serif" fontWeight="bold">
        No Service Added Yet!
      </Typography>
      <ButtonComp width="90%" label="Add-on Service" type="active" onClick={handleClick} />
      <Box sx={classes.addonbutton} >
        <ButtonComp
          label="Add Later"
          width="90%"
          type="whitebtn"
          onClick={handleRest}
        />
      </Box>
    </Box>
  );
}

export default AddOnServices;