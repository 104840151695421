import instance from "../common/config/axios"
//apis for signup and password reset and login
export const signupApi = async (val) => {
  try {
    const response = await instance.post(`user/signup`, val);
    return response;
  } catch (error) {
    return error;
  }
};

export const signinApi = async (val) => {
  try {
    const response = await instance.post(`user/login`, val);
    return response;
  } catch (error) {
    return error;
  }
};
export const resetPasswordApi = async (val) => {
  try {
    const response = await instance.post(`user/forgot-password`, val);
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyPasswordToken = async (val) => {
  try {
    const response = await instance.post(
      `user/verify-password-token`,
      val
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const resetUserPasswordApi = async (val) => {
  try {
    const response = await instance.post(`user/reset-password`, val);
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadImageApi = async (formData) => {
  try {
    const response = await instance.post(`file/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadGovtIdImage = async (formData) => {
  try {
    const response = await instance.post(`file/upload-id-card`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


