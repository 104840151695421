import React from "react";
import PrivacyPolicy from "../component/PrivacyPolicy";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "90% !important",
    padding: "17px",
    borderRadius: "20px",
    maxWidth: "90% !important",
  },
  "& .MuiPaper-root": { maxWidth: { xs: "auto", md: "97%" }, margin:'auto' },
  "& .MuiDialogActions-root": {},
}));


export default function PrivacyPolicyInfo({ policy, handlePolicy,handleClick,setTerms,setPolicy,fromMenu }) {
  return (
    <CustomDialog
      open={policy}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePolicy}
      sx={{
        "& .MuiPaper-root": { width: { xs: "auto", md: "100vw" } },
        width: { xs: "auto", md: "100%" },
      }}
    >
      <DialogContent sx={{padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        } ,width:{xs:"auto",lg:"100%"}}} >
        <PrivacyPolicy handlePolicy={handlePolicy} handleClick={handleClick} setTerms={setTerms} setPolicy={setPolicy} fromMenu={fromMenu} />
      </DialogContent>
    </CustomDialog>
  );
}
