import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import CardComp from "./CardComp";
import dashboardempty from "../../../assets/images/dashboardempty.png";
import handleScroll from "../../../common/handleScroll";
import { getSx } from "../../eventorganiser/dashboard/style";
import NavBar from "../../eventorganiser/home";

import { callMyBookingListEPApi } from "../../../api/mybookings";
import {Container} from "@mui/system";

const BookingInfo = () => {
  const bookingsArr = [
    {
      name: "OnGoing",
      value: "0",
    },
    {
      name: "UpComing",
      value: "1",
    },
    {
      name: "Requests",
      value: "2",
    },
    {
      name: "Completed",
      value: "3",
    },
  ];
  const [value, setValue] = useState(2);
  const [servicelist, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFeteched, setIsFeteched] = useState(false);
  const classes = getSx();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const listInnerRef = useRef();
  const [page, setPage] = useState(1);
  const handleChange = (evemt, newValue) => {
    setPage(1);
    setValue(newValue);
  };
  const callApi = async (value) => {
    setLoading(true);
    let val = {};
    if (value == 0) {
      val = { status: "OnGoing", date: dayjs().format("YYYY-MM-DD") };
    } else if (value == 1) {
      val = { status: "UpComing" };
    } else if (value == 2) {
      val = { status: "Pending" };
    } else if (value == 3) {
      val = { status: "Completed" };
    }
    const resp = await callMyBookingListEPApi({
      ...val,
      page: page,
      limit: 10,
    });
    if (page == 1) setServiceList(resp?.data?.data);
    else {
      let temp = [...servicelist, ...resp?.data?.data];
      setServiceList(temp);
    }
    setLoading(false);
  };
  const handleSetPage = () => {
    setPage(servicelist?.length / 10 + 1);
  };

  const getTabName = (name) => {
    if (name == "OnGoing")
      return "Ongoing"
    else if (name == "UpComing")
      return "Upcoming"
    else
      return name
  }
  const getValue = () => {
    let val = "";
    if (value == 0) {
      val = "Ongoing";
    } else if (value == 1) {
      val = "Upcoming";
    } else if (value == 2) {
      val = "Pending";
    } else if (value == 3) {
      val = "Completed";
    }
    return val;
  };

  useEffect(() => {
    if (!isFeteched && id) {
      callApi(id);
      setValue(id);
      setIsFeteched(true);
    } else callApi(value);
  }, [value, page]);

  return (
    <Container sx={{
            padding: {
                xs:'0 15px',
                sm:'0 30px'
            }
        }} maxWidth="xl">
      <Box sx={{ padding: { xs: "15px 0", sm: "15px 0", md:'15px 0', lg:'15px 0', xl:'30px 0' } }}>
      <Typography variant="h5" fontWeight="bold" sx={{
        fontWeight:700,
        fontSize:{
          xs:"20px",
          sm:"22px",
          md:"24px",
          lg:"25px",
          xl:"25px"
        }, color:'#111111',marginBottom:{
          xs:'10px',
          sm:'10px',
          md:'10px',
          lg:'15px',
          xl:'24px'
        }, fontFamily: 'Montserrat, sans-serif'}}>
        Dashboard
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={classes.tabs}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {bookingsArr?.map((item) => {
          return (
            <Tab
              key={item?.value}
              label={getTabName(item?.name)}
              sx={
                value == item?.value
                  ? classes.selectedTab
                  : classes.unselectedTab
              }
            ></Tab>
          );
        })}
      </Tabs>
      {loading && page == 1 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : servicelist?.length == 0 ? (
        <Typography
          variant="h6"
          marginTop="20px"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "50vh",
          }}
        >
          <img alt="" src={dashboardempty} style={{ width: "185px", height: "185px" }} />
          <Typography sx={{ color: "rgba(167, 167, 167, 1)", fontSize: { xs: "16px", md: "21px" },fontFamily: 'Montserrat, sans-serif' }}> No {getValue()} Service Booked</Typography>
        </Typography>
      ) : (
        <>
          <Box
            sx={
              page > 1 && loading
                ? {...classes.infobox}
                : classes.infobox
            }
            ref={listInnerRef}
            onScroll={() => {
              handleScroll(listInnerRef, servicelist?.length, handleSetPage);
            }}
          >
            {servicelist?.map((item) => {
              return <CardComp item={item} value={value} key={item?._id} />;
            })}
          </Box>
          {page > 1 && loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "Center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </Box>
    </Container>
  );
};
const DashBoard = () => {
  return (
    <NavBar>
      <BookingInfo />
    </NavBar>
  );
};

export default DashBoard;
