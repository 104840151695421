import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ButtonComp from "../../../component/Button";
import { modalVariant } from "../../../component/Modal/Modalutils";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Box,
  Typography,
  styled,
  Rating,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import crossicon from "../../../assets/images/svg/crossicon.svg";

import { addRatingApi } from "../../../api/rating";
import { showModal } from "../../../store/slices/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "800px",
    padding: "17px",
    borderRadius: "20px",
  },
  "& .MuiPaper-root": { maxWidth: "800px" },
  "& .MuiDialogActions-root": {},
}));


export default function AddReviewModal({
  reviewModal,
  setReviewModal,
  setIsReviewAdded,
  callApi,
  id
}) {
  const [value, setValue] = useState();
  const [desc, setDesc] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const callRatingApi = async () => {
    setLoading(true);
    const resp = await addRatingApi({ rating: value, text: desc, serviceId: id });
    if (resp.status === 200) {
      setIsReviewAdded(true);
      callApi();
      dispatch(
        showModal({
          modalType: modalVariant.ADD_REVIEW_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));

          },
        })
      );
    }
    setLoading(false);
    setReviewModal(false);
  }

  return (
    <CustomDialog
      open={reviewModal}
      TransitionComponent={Transition}
      keepMounted
      sx={{ "& .MuiPaper-root": { maxWidth: "380px" } }}
    >
      <DialogContent sx={{
        padding: {
          xs: "8px 0",
          sm: "8px 10px",
          md: "8px 10px",
          lg: "8px 20px",
          xl: "8px 20px"
        }
      }}>
        <img
          src={crossicon}
          style={{ position: "absolute", right: "1rem", top: "1rem" }}
          onClick={() => setReviewModal(false)}
        />
        <Typography
          variant="h5"
          fontWeight="bold"
          display="flex"
          justifyContent="center"
        >
          Add Review
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          margin="10px 0px"
        >
          Rate your experience
        </Typography>

        <Rating value={value} precision={0.5} onChange={(e) => setValue(e.target.value)} />
        <Typography
          variant="body1"
          fontWeight="bold"
          margin="10px 0px"
        >
          What did you like or dislike?
        </Typography>
        <TextareaAutosize
          placeholder="Describe your experience"
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "rgba(246, 245, 245, 1)",
            borderRadius: "15px",
            marginBotton: "3rem",
            fontSize: "15px",
            fontFamily: "Montserrat",
            border: "none",
            outline: "none",
            overflowY: "auto !important",
            maxHeight: "200px",
            fontWeight: "500",
            color: "#000"
          }}
          className="textArea"
          name="description"
          minRows={12}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />


        <Box sx={{ width: "100%", marginTop: "3rem" }}>
          <ButtonComp label="Submit Review"
            type={desc && value ? "active" : "disabled"}
            disabled={desc && value ? false : true}
            onClick={() => { callRatingApi() }}
            loading={loading}
            style={{ width: "100%", fontWeight: "bold" }} />
        </Box>
      </DialogContent>
    </CustomDialog>)

}

