import React from "react";

import { Box } from "@mui/material";
import { getSx } from "./style";
import dummyprofileicon from "../../../assets/images/dummycardimage.png";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/system";


dayjs.extend(utc);

function SideChat({ user }) {
  const classes = getSx();
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTime = (timeval) => {
    const checkDate = dayjs(timeval || new Date());
    const currentDate = dayjs(new Date());
    const isToday = currentDate.isSame(checkDate, 'day');
    const isYesterday = currentDate.subtract(1, 'day').isSame(checkDate, 'day');
    const startDate = dayjs(currentDate.subtract(7, 'day'));
    const endDate = dayjs(currentDate);
    const isBetween = checkDate >= startDate && checkDate <= endDate;
    if (isToday)
      return dayjs(checkDate).format('hh:mm a');
    else if (isYesterday)
      return smScreen ? "Yest" : "yesterday";
    else if (isBetween)
      return smScreen ? dayjs(checkDate).format("ddd") : dayjs(checkDate).format("dddd");
    else
      return smScreen ? dayjs(checkDate).format("DD/MM/YY") : dayjs(checkDate).format("DD/MM/YYYY");
  }
  return (
    <>
      <Box sx={classes.singlechatinfo}>
        <Box sx={classes.profileimg}>
          <img
            src={user?.userDetails?.profileImage?.url ? user?.userDetails?.profileImage?.url : dummyprofileicon}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}

          />
        </Box>
        <Box sx={classes.profileinfo}>
          <Box sx={classes.profilename}>{user?.userDetails?.firstName} {user?.userDetails?.lastName}</Box>
          <Box sx={classes.profilemsg}>{user?.latestMessage?.message?.substring(0, 15)}{user?.latestMessage?.message?.length > 15 && "..."}</Box>
        </Box>

        {user?.unReadCount > 0 && <Box sx={classes.readinfo}>{user?.unReadCount}</Box>}

        <Box sx={classes.profiletime}>
          <Box
            sx={classes.time}
          >
            {handleTime(user?.latestMessage?.createdAt)}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SideChat;
