import instance from "../common/config/axios";
//apis for messages
export const listchatusersApi = async () => {
    try {
      const response = await instance.post(`chat/list-chat-users`);
      return response;
    } catch (error) {
      return error;
    }
  };
  export const sendmessageApi = async (body) => {
    try {
      const response = await instance.post(`chat/send-message`,body);
      return response;
    } catch (error) {
      return error;
    }
  };

  export const listUserMessagesApi = async (id,page) => {
    try {
      const response = await instance.get(`chat/list-messages?userId=${id}&page=${page}`);
      return response;
    } catch (error) {
      return error;
    }
  };

  export const receiveMessageApi = async (id) => {
    try {
      const response = await instance.put(`chat/read-messages/${id}`);
      return response;
    } catch (error) {
      return error;
    }
  }
  
  export const unRedMessageApi=async()=>{
    try{
      const resp=await instance.get(`chat/unread-messages-count`);
      return resp;
    }
    catch(err)
    {
      return err;
    }
  }
  export const chatStatusApi=async(id,userid)=>{
    try{
      const resp=await instance.get(`chat/chat-status/${id}/${userid}`);
      return resp;
    }
    catch(err)
    {
      return err;
    }
  }
  