
export const getSx = () => {
    return {
        googleautosuggestionlist: {
            marginTop: "5px",
            width: "100%",
            height: "154px",
            display: "flex",
            flex: "1 1 0%",
            flexDirection: "column",
            marginBottom: "108px",
            position: "absolute",
            zIndex: "1123",
            background: "#fff",
            padding: "0 10px",
            overflowY: "auto",
            borderRadius:"12px"
          },
        main: {
            width: "100%",
            padding: { xs: "15px 0", sm: "15px 0", md:'15px 0', lg:'15px 0', xl:'30px 0' }
        }, menus: {
            display: "flex", width: "100%", overflow: 'auto',padding:{
                xs:'10px 15px',
                sm:'10x 0 15px',
                md:'10px 0 15px',
                lg:'10px 0 20px',
                xl:'15px 0 30px'
            },
            whiteSpace: "nowrap"
        }, singlemenu: {
            backgroundColor: "#fff",
            boxShadow:'0 10px 20px 0 #00000012',
            padding: {
                xs:"8px 16px",
                sm:"9px 18px",
                md:"10px 20px",
                lg:"10px 20px",
                xl:"13px 24px"
            },
            borderRadius: "25px",
            display: "flex",
            marginRight: "16px",
            width: 'fit-content',
            whiteSpace: 'nowrap',
            cursor: "pointer",
            fontSize:{
                xs:'12px',
                sm:'14px',
                md:'14px',
                lg:'14px',
                xl:'16px'
            }
        }, exploreleft: {
            width: "30vw", marginRight: "15px"
        }, exploreright: {
            width: "68vw",
        }, servicecont: {
            display: "flex", flexWrap: "wrap", marginTop: "10px", flexDirection: "row",
        }, servicecontinfo: {
            background: "linear-gradient(rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
            width: "fit-content",
            padding: "7px 15px",
            borderRadius: "20px",
            margin: "3px",
        },
  };
}