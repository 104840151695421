import React from 'react'

const Parking = ({value}) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1928_93376)">
        <path
          d="M20.7507 3.33203C29.9557 3.33203 37.4173 10.7937 37.4173 19.9987C37.4173 29.2037 29.9557 36.6654 20.7507 36.6654C11.5457 36.6654 4.08398 29.2037 4.08398 19.9987C4.08398 10.7937 11.5457 3.33203 20.7507 3.33203ZM23.2507 11.6654H19.084C18.1999 11.6654 17.3521 12.0166 16.727 12.6417C16.1018 13.2668 15.7507 14.1146 15.7507 14.9987V26.6654C15.7507 27.1074 15.9262 27.5313 16.2388 27.8439C16.5514 28.1564 16.9753 28.332 17.4173 28.332C17.8593 28.332 18.2833 28.1564 18.5958 27.8439C18.9084 27.5313 19.084 27.1074 19.084 26.6654V23.332H23.2507C24.7977 23.332 26.2815 22.7174 27.3754 21.6235C28.4694 20.5295 29.084 19.0458 29.084 17.4987C29.084 15.9516 28.4694 14.4679 27.3754 13.3739C26.2815 12.2799 24.7977 11.6654 23.2507 11.6654ZM23.2507 14.9987C23.9137 14.9987 24.5496 15.2621 25.0184 15.7309C25.4873 16.1998 25.7507 16.8357 25.7507 17.4987C25.7507 18.1617 25.4873 18.7976 25.0184 19.2665C24.5496 19.7353 23.9137 19.9987 23.2507 19.9987H19.084V14.9987H23.2507Z"
          fill={value ? "#17BAAE" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1928_93376">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Parking