import { createSlice } from '@reduxjs/toolkit';

const BreadCrumSlice = createSlice({
  name: 'breadCrums',
  initialState: [ { title: "", route: '' }],
  reducers: {
    pushBreadCrums:(state,action)=>{
     return action.payload;
    },
    popBreadCrum:(state)=>{
      return state.slice(0,state.length-1)
    },
    clearBreadCrum:()=>{
      return []
    }
  },
});


export const { pushBreadCrums ,popBreadCrum,clearBreadCrum} = BreadCrumSlice.actions;
export default BreadCrumSlice.reducer;
