import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Box, Typography, styled, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Fade from "@mui/material/Fade";
import { getSx } from "./style";
import crossiconblack from "../../assets/images/crossiconblack.svg";
import ButtonComp from "../Button";
import { showModal } from "../../store/slices/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "400px",
    padding: "30px",
    borderRadius: "20px",
  },
  "& .MuiPaper-root": { maxWidth: "350px" },
  "& .MuiDialogActions-root": {},
}));

export default function Modal({
  handleClose,
  subTitle,
  resolveButton,
  image,
  rejectButton,
  subText,
  successButton,
  isSingleButton,
}) {
  const classes = getSx(successButton);
  const dispatch = useDispatch();
   return (
    <CustomDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogContent sx={{ padding: {
        xs:"8px 0",
          sm:"8px 10px",
          md:"8px 10px",
          lg:"8px 20px",
          xl:"8px 20px"
        } }}>
        <Box sx={classes.subTitleContainer}>
          <img
            src={image}
            alt="logo"
            style={{ marginBottom: "10px", height: "100px", width: "100px" }}
          />
          {resolveButton.label =="Sign Up" && <img src={crossiconblack} alt="crossicon" style={{ float: "right",position:"absolute",top:"-5px",right:"0",cursor:"pointer" }} onClick={()=>{dispatch(showModal(null))}} />}
          {subTitle && (
            <Typography variant="h4" sx={classes.subTitle}>
              {subTitle}
            </Typography>
          )}
          {subText && (
            <Typography variant="h6" sx={classes.subText}>
              {subText}
            </Typography>
          )}
        
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing={false}
        sx={
          resolveButton.label === "Yes" || rejectButton.label === "Generate OTP" ||  resolveButton.label =="Sign Up"
            ? classes.buttonWrapper
            : { ...classes.buttonWrapper1, justifyContent: "center" }
        }
      >
        <ButtonComp
          type={(resolveButton.label === "Yes" || rejectButton.label=="Generate OTP" || resolveButton.label =="Sign Up" )  ?"whitebtn":"active"}
          label={resolveButton.label}
          width={isSingleButton?"60%":"50%"}
          height={'60px'}
          onClick={resolveButton.onClick}
        />
       
        {resolveButton.label === "Yes"|| rejectButton.label === "Generate OTP" || resolveButton.label === "Sign Up" ? (
          <Button
            sx={{ ...classes.button, ...classes.cancelButton }}
            {...rejectButton}
          >
            {rejectButton.label}
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </CustomDialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  resolveButton: PropTypes.object,
  rejectButton: PropTypes.object,
  image: PropTypes.image,
  subText: PropTypes.string,
  successButton: PropTypes.object,
};

Modal.defaultProps = {
  resolveButton: {},
  rejectButton: {},
};
