import React from "react";
import { useSelector,useDispatch } from "react-redux";

import ButtonComp from "../../../component/Button";
import { Typography, Box } from "@mui/material";
import { getSx } from "./style";
import tickcircleicon from "../../../assets/images/tickcircleicon.svg";
import { getCurrencyFormat } from "../../../common/constant";

import { setAddonMaxQuantityCountArr } from "../../../store/slices/bookserviceinfo";

const AddOnServiceCategorySelection = ({
  setAddOnSelectedCategories,
  addOnSelectedCategories,
  services,
  setPage,
  setAddOnSelectedDatesArr,
  setAddOnTimings,
  showAmount,
}) => {
  const classes = getSx();
  const addOnCatIds = useSelector(
    (state) => state.bookserviceinfo.addOnCategoryIds
  );
  const dispatch = useDispatch();
  const handleSetSubCategory = (val) => {
    let isInCat = addOnSelectedCategories?.filter(
      (item) => item?._id == val?._id
    );
    let filterData = addOnSelectedCategories?.filter(
      (item) => item?._id != val?._id
    );

    if (isInCat.length > 0) setAddOnSelectedCategories([...filterData]);
    else setAddOnSelectedCategories([...filterData, val]);
  };
  const checkIncludedCategory = (val) => {
    return addOnSelectedCategories.some((item) => item?._id === val?._id);
  };
  const handleSetSelectedCategory = () => {
    const allcats = services.slice(1).flatMap((service) => service.categories);
    let cats = addOnSelectedCategories.map((item, index) => {
      return allcats.find((element) => element?._id === item?._id);
    });
    let maxQuantity = cats.map((item) => item.field3.value);
    dispatch(setAddonMaxQuantityCountArr(maxQuantity));
    setAddOnSelectedCategories(cats);
  };
 

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6"
              fontWeight="bold"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontFamily:"Montserrat, sans-serif",
                fontSize:{
                  xs:'16px',
                  sm:'18px',
                  md:'20px',
                  lg:'20px',
                  xl:'20px'
                },
                 marginTop:'20px',
              }}>
          Select Add-On Services
        </Typography>
        {addOnCatIds.length == 0 && !showAmount && (
          <Box
            sx={{ color: "rgba(23, 186, 174, 1)", cursor: "pointer" }}
            onClick={() => {
              setPage(5);
              setAddOnSelectedCategories([]);
            }}
          >
            Skip
          </Box>
        )}
      </Box>
      <Box sx={{height:{
          xs:'calc(100vh - 330px)',
          sm:'calc(100vh - 700px)',
          md:'calc(80vh - 650px)',
          lg:'calc(100vh - 295px)',
          xl:'calc(75vh - 350px)',
          },
          overflow:'auto',
          marginTop:'20px',
          paddingBottom:'30px'
        }}>
        {services.slice(1).map((item, index) => {
          return (
            <>
              <Typography variant="h6"
              fontWeight="bold"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                fontFamily:"Montserrat, sans-serif",
                fontSize:{
                  xs:'14px',
                  sm:'16px',
                  md:'18px',
                  lg:'18px',
                  xl:'18px'
                },
                 marginBottom:'10px',
              }}>
                {item?.serviceId?.name}
              </Typography>

              {item.categories.map((item) => {
                return addOnCatIds.includes(item?._id) ? (
                  <Box sx={classes.serviceinfodisabled}>
                    <Box
                      sx={{ marginRight: "15px", width: "78px", height: "65px", borderRadius:'14px',overflow:'hidden' }}
                    >
                      <img alt=""
                        src={item?.categoryId?.displayImage?.fileUrl}
                        style={{ width: "100%", height: "100%",objectFit:"cover", aspectRatio:'1/1' }}
                      />
                    </Box>
                    <Box sx={{ fontWeight:700, color: "000000" }}>
                      <Box sx={{
                  fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'16px',
                    sm:'18px',
                    md:'18px',
                    lg:'18px',
                    xl:'18px',
                  }
                }}>
                        {item?.categoryId?.categoryName}
                      </Box>
                      <Box sx={{display:'flex', alignItems:'center'}}>
                        <Box sx={{fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'16px',
                    sm:'18px',
                    md:'18px',
                    lg:'18px',
                    xl:'18px',
                  },fontWeight:600, paddingRight:'5px' }}>
                          {item?.field2?.value}
                        </Box>
                          /
                        <Box sx={{fontFamily:"Montserrat, sans-serif",paddingLeft:'5px',
                  fontSize:"12px",fontWeight:500,lineHeight:'12px' }}>
                          {item?.field1?.value}
                        </Box>
                      </Box>
                    </Box>
                    {addOnSelectedCategories.includes(item) && (
                      <Box sx={{ marginLeft: "auto" }}>
                        <img alt=""
                          src={tickcircleicon}
                          style={{ width: "25px", height: "25px" }}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box style={{cursor:'pointer'}}
                    sx={
                      checkIncludedCategory(item)
                        ? classes.severicecatinfosel
                        : classes.severicecatinfo
                    }
                    onClick={() => {
                      handleSetSubCategory(item);
                    }}
                  >
                    <Box sx={{ marginRight: "15px", width: "78px", height: "65px", borderRadius:'14px',overflow:'hidden' }}>
                      <img alt=""
                        src={item?.categoryId?.displayImage?.fileUrl}
                        style={{ width: "100%", height: "100%",objectFit:"cover", aspectRatio:'1/1' }}
                      />
                    </Box>
                    <Box sx={{ fontWeight:700, color: "000000" }}>
                      <Box
                        sx={{
                  fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'16px',
                    sm:'18px',
                    md:'18px',
                    lg:'18px',
                    xl:'18px',
                  }
                }}
                      >
                        {item?.categoryId?.categoryName}
                      </Box>
                      <Box sx={{display:'flex', alignItems:'center'}}>
                        <Box sx={{fontFamily:"Montserrat, sans-serif",
                  fontSize:{
                    xs:'16px',
                    sm:'18px',
                    md:'18px',
                    lg:'18px',
                    xl:'18px',
                  },fontWeight:600, paddingRight:'5px' }}>
                          {getCurrencyFormat(item?.field2?.value)}
                        </Box>
                          /
                        <Box sx={{fontFamily:"Montserrat, sans-serif",paddingLeft:'5px',
                  fontSize:"12px",fontWeight:500,lineHeight:'12px' }}>
                          {item?.field1?.value}
                        </Box>
                      </Box>
                    </Box>
                    {checkIncludedCategory(item) && (
                      <Box sx={{ margin: "auto 0px auto auto" }}>
                        <img alt=""
                          src={tickcircleicon}
                          style={{ width: "25px", height: "25px" }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </>
          );
        })}
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        <ButtonComp
          label="Continue"
          width="100%"
          type={addOnSelectedCategories?.length > 0 ? "active" : "disabled"}
          onClick={() => {
            setPage(4);

            handleSetSelectedCategory();
            setAddOnSelectedDatesArr([]);
            setAddOnTimings([]);
          }}
        />
      </Box>
    </>
  );
};

export default AddOnServiceCategorySelection;
