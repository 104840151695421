import React from 'react'

const MessageIcon = ({val}) => {

  return !val ? (
    <svg
      className='profile-icon'
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5335 3.51855C24.1693 4.76637 24.1693 6.39986 24.1693 9.66683V11.0484C24.1693 13.0191 24.1693 14.0044 23.9336 14.8082C23.3746 16.7146 21.8837 18.2055 19.9773 18.7645C19.1735 19.0002 18.1882 19.0002 16.2175 19.0002H15.5772L15.5013 19.0003C14.311 19.0079 13.1516 19.3795 12.1786 20.0651L12.1167 20.1091L9.07083 22.2848C8.02668 23.0306 6.6546 21.9535 7.13115 20.7621C7.46873 19.9182 6.8472 19.0002 5.93825 19.0002H5.2362C2.806 19.0002 0.835938 17.0301 0.835938 14.5999V9.66683C0.835938 6.39986 0.835938 4.76637 1.47173 3.51855C2.03099 2.42094 2.92338 1.52855 4.02099 0.969291C5.26881 0.333496 6.9023 0.333496 10.1693 0.333496H14.8359C18.1029 0.333496 19.7364 0.333496 20.9842 0.969291C22.0818 1.52855 22.9742 2.42094 23.5335 3.51855ZM7.83594 6.4585C7.35269 6.4585 6.96094 6.85025 6.96094 7.3335C6.96094 7.81675 7.35269 8.2085 7.83594 8.2085L12.5026 8.2085C12.9859 8.2085 13.3776 7.81675 13.3776 7.3335C13.3776 6.85025 12.9859 6.4585 12.5026 6.4585L7.83594 6.4585ZM7.83594 11.1252C7.35269 11.1252 6.96094 11.5169 6.96094 12.0002C6.96094 12.4834 7.35269 12.8752 7.83594 12.8752H17.1693C17.6525 12.8752 18.0443 12.4834 18.0443 12.0002C18.0443 11.5169 17.6525 11.1252 17.1693 11.1252H7.83594Z"
        fill="rgba(26, 26, 26, 0.25)"
      />
    </svg>
  ) : (
    <svg
      className='profile-icon'
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5335 3.51855C24.1693 4.76637 24.1693 6.39986 24.1693 9.66683V11.0484C24.1693 13.0191 24.1693 14.0044 23.9336 14.8082C23.3746 16.7146 21.8837 18.2055 19.9773 18.7645C19.1735 19.0002 18.1882 19.0002 16.2175 19.0002H15.5772L15.5013 19.0003C14.311 19.0079 13.1516 19.3795 12.1786 20.0651L12.1167 20.1091L9.07083 22.2848C8.02668 23.0306 6.6546 21.9535 7.13115 20.7621C7.46873 19.9182 6.8472 19.0002 5.93825 19.0002H5.2362C2.806 19.0002 0.835938 17.0301 0.835938 14.5999V9.66683C0.835938 6.39986 0.835938 4.76637 1.47173 3.51855C2.03099 2.42094 2.92338 1.52855 4.02099 0.969291C5.26881 0.333496 6.9023 0.333496 10.1693 0.333496H14.8359C18.1029 0.333496 19.7364 0.333496 20.9842 0.969291C22.0818 1.52855 22.9742 2.42094 23.5335 3.51855ZM7.83594 6.4585C7.35269 6.4585 6.96094 6.85025 6.96094 7.3335C6.96094 7.81675 7.35269 8.2085 7.83594 8.2085L12.5026 8.2085C12.9859 8.2085 13.3776 7.81675 13.3776 7.3335C13.3776 6.85025 12.9859 6.4585 12.5026 6.4585L7.83594 6.4585ZM7.83594 11.1252C7.35269 11.1252 6.96094 11.5169 6.96094 12.0002C6.96094 12.4834 7.35269 12.8752 7.83594 12.8752H17.1693C17.6525 12.8752 18.0443 12.4834 18.0443 12.0002C18.0443 11.5169 17.6525 11.1252 17.1693 11.1252H7.83594Z"
        fill="url(#paint0_linear_1811_68174)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1811_68174"
          x1="0.835938"
          y1="11.4322"
          x2="24.1693"
          y2="11.4322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17BAAE" />
          <stop offset="1" stop-color="#29EAA5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MessageIcon