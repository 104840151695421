import React, { useEffect,useState } from "react";

import ButtonComp from "../../../component/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioComp from "./RadioComp";
import { getSx } from "./style";
import greencircleicon from '../../../assets/images/radioicongreen.svg'

const AboutBusiness = ({ setValue, selected, setSelected,handleData,loader,saveProfile,checkedValues,setCheckedValues }) => {
  const [decArr,setDecArr]=useState([]);
  const classes = getSx();
  const radioArr = [
    {
      name: "What types of services do you offer?",
      value: ["Music","Variety Acts","Venues","Catering","Videos & Photography","Decoration and Rentals","Special Services" ],
    },
    {
      name: "How many years of experience do you have?",
      value: ["1–2 ", "5-7","10+ years of experience" ],
    },
    {
      name: "How frequently are your services booked?",
      value: ["1-3 times per month", "5-7 times per month","10+ times per month"],
    },
    {
      name: "How many employees are on your team?",
      value: ["1-5", "5-7","10+"],
    },
    {
      name: "Are you open to providing services outside of your city?",
      value: ["Yes", "No"],
    },
    {
      name: "What motivates your decision to partner with Eventutti?",
      value: ["More bookings", "Exposure","Technology"],
    },
  ];
  const handleClick=()=>{

    const val=radioArr.map((item,index)=>{
      if(index!=0 && index!=5)
      { 
        return {
          questionId: item.name,
          questionAnswerId:selected[index]
        };
      }
      else  if(index==0)
      {
      return {
        questionId: item.name,
        questionAnswerId:checkedValues.join(',')
      };
    }
    else
    {
      return{
        questionId: item.name,
        questionAnswerId:decArr.join(',')
      }
    }
    })
    handleData({questions:val})
   saveProfile({questions:val})
  }

  const handleChange = (event, index) => {
    const val = [...selected];
    val[index] = event.target.value;
    setSelected(val);
  };
  const handleChange1=(value)=>{
   let val=selected;
   val[0]=value;
   setSelected(val);
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((val) => val !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  }

  const handleChange2=(value)=>{
    let val=selected;
    val[5]=value;
    setSelected(val);
     if (decArr.includes(value)) {
       setDecArr(decArr.filter((val) => val !== value));
     } else {
       setDecArr([...decArr, value]);
     }
  }

  useEffect(()=>{
    setValue(100)
  },[])
  return (
    <Box>
    <Typography sx={classes.heading}>About your business</Typography>
      <Box sx={classes.createFormMain}>
      <Box sx={classes.subcontainer}>
        <Typography
          variant="body1"
          sx={classes.smallheading}
        >
        1. What types of services do you offer?
        </Typography>
      {radioArr[0]?.value?.map((val,index) => {
        return (
          <>
            <Box
              key={val}
              sx={
               checkedValues.includes(val)
                  ? { ...classes.unselectbox, ...classes.selectbox }
                  : classes.unselectbox
              }
            >
              {val}
              <Box sx={classes.radiobox}>
                <FormControlLabel
                  value={val}
                  onChange={()=>{handleChange1(val)}}
                  control={
                    <Checkbox
                      sx={{ color: "rgba(23, 186, 174, 1)",marginTop:"5px" }}
                      icon={<img alt="" src={greencircleicon}/>}
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{
                            color: "rgb(22, 192, 152)",
                          }}
                        />
                      }
                    />
                  }
                />
              </Box>
            </Box>
          </>
        );
      })}
        {radioArr.slice(1,5).map((item, index) => {
          return (
            <RadioComp
              item={item}
              key={item}
              handleChange={handleChange}
              selected={selected}
              classes={classes}
              index={index+1}
            />
          );
        })}
         <Typography
          variant="body1"
          sx={classes.smallheading}
        >
        6.What motivates your decision to partner with Eventutti?
        </Typography>
         {radioArr[5]?.value?.map((val,index) => {
        return (
          <>
            <Box
              key={val}
              sx={
               decArr.includes(val)
                  ? { ...classes.unselectbox, ...classes.selectbox }
                  : classes.unselectbox
              }
            >
              {val}
              <Box sx={classes.radiobox}>
                <FormControlLabel
                  value={val}
                  onChange={()=>{handleChange2(val)}}
                  control={
                    <Checkbox
                      sx={{ color: "rgba(23, 186, 174, 1)",marginTop:"5px" }}
                      icon={<img alt="" src={greencircleicon}/>}
                      checkedIcon={
                        <CheckCircleIcon
                          sx={{
                            color: "rgb(22, 192, 152)",
                          }}
                        />
                      }
                    />
                  }
                />
              </Box>
            </Box>
          </>
        );
      })}
      </Box>
      <ButtonComp className="height-50"
        label="continue"
        loading={loader}
        disabled={selected[0]&&selected[1]&&selected[2]&&selected[3]&&selected[4]&&selected[5]&&decArr.length>0&&checkedValues.length>0 ? false : true}
       type={selected[0]&&selected[1]&&selected[2]&&selected[3]&&selected[4]&&selected[5]&&decArr.length>0&&checkedValues.length>0? "active" : "disabled"}
       style={{marginTop:'20px', width: "100%",height:'60px', fontWeight:600,fontFamily:"Montserrat, sans-serif" }}
       onClick={handleClick}
      />
      </Box>
    </Box>
  );
};

export default AboutBusiness;
