
export const getSx = () => {
  return {
    maincontainer: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: {
        xs:'flex-start',
        sm:"center",
        md:"center",
        lg:"center",
        xl:"center"
      },
      justifyContent: "center",
      padding: {
        xs:"30px 10px",
        sm:"10px",
        md:"10px",
        lg:"10px",
        xl:"10px"
      },
      overflow:"auto",
      boxSizing: "border-box",
    },
    container: {
      width: {
        xs: "100%",
        sm: "90%",
        md: "80%",
        lg:"80%",
        xl:"80%",
      },
      height: {
        xs: "calc(100vh - 100px)",
        sm:"calc(100vh - 400px)",
        md: "calc(100vh - 80px)",
        lg:"calc(100vh - 80px)",
        xl:"calc(100vh - 160px)",
      },
      padding: "10px",
      borderRadius: "30px",
      border: "1px solid rgba(233, 233, 233, 1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      overflow: "auto",
    },
    event2timgcont: {
      padding: "10px",
      width: { xs:"110px",sm: "200px", md: "200px", lg: "200px", xl: "275px" },
      marginTop: { xs: "10px", md: "0px" },
      
    },
    heading: {
      fontSize: { xs: "16px", sm:"18px", md: "18px", lg: "23px", xl: "28px" },
      color: "black",
      fontWeight: "bold",
      padding:{
        xs:"5px 0 0",
        sm:"0 10px",
        md:"0 10px",
        lg:"0 10px",
        xl:"10px"
      },
      fontFamily:"Montserrat, sans-serif"
    },
    content: {
      color: "rgba(167, 167, 167, 1)",
      fontSize: {xs:"12px",sm:'14px', md:"16px"},
      padding: {
        xs:'5px 0',
        sm:'5px 0',
        md:'5px 0',
        lg:'5px 0',
        xl:'10px 0',
      },
      width: { xs: "100%", sm:'100%', md: "50%", lg:'80%', xl:'35%' },
      textAlign: "center",
      lineHeight:{
        xs:'21px',
        sm:'24px',
        md:'27px',
      },
      fontFamily:"Montserrat, sans-serif"
    },
    englishlang: {
      width: { xs: "300px", md: "350px" },
      padding: "10px",
      cursor: "pointer",
          
    },
    englishlang1: {
      height: { xs: "180px", sm: "200px", md: "200px", lg: "215px", xl: "215px" },
      cursor: "pointer",
      overflow:'hidden',
      borderRadius:'18px',
      position:'relative'
    },
    bgOverlay:{
      position:'absolute',
      top:0,
      left:0,
      bottom:0,
      right:0,
      width:'100%',
      height:'100%',
      backgroundColor:'#29EAA573'
    },

    providerimages: {
      display: "flex",
      flexDirection: {
        md: "row",
        xs: "column",
        sm:'row',
        lg:'row',
        xl:'row'
      },
    },
    img1: {
      margin: {
        xs:"10px",
        sm:"10px 10px",
        md:"10px 10px",
        lg:"10px 10px",
        xl:"15px 10px"
      },
      position: "relative",
      height:{
        xs:"180px",
        sm:"200px",
        md:"200px",
        lg:"215px",
        xl:"215px"
      },
      width:"315px",
    },
    unselected: {
      position: "absolute",
      backgroundColor: "white",
      color: "#000000",
      bottom: '15px',
      left: 0,
      right:0,
      margin:'auto',
      borderRadius: "10px",
      width: {
        xs:"200px",
        sm:"54%",
        md:"220px",
        lg:"220px",
        xl:"220px"
      },
      height:{
        xs:'50px',
        sm:'50px',
        md:'50px',
        lg:'50px',
        xl:'50px'
      },
      fontWeight:600,
      justifyContent:"center",
      display:'flex',
      alignItems:'center',
      fontSize: {
        xs:"14px",
        sm:"15px",
        md:"16px",
        lg:"18px",
        xl:"18px"
      },
      cursor: "pointer",
      zIndex: "333",
      fontFamily:"Montserrat, sans-serif"
    },
    selected: {
      backgroundColor: "#17BAAE",
      color: "white",
    },
    selectedservice: {
      backgroundColor: "#29EAA5",
      color: "white",
    },
  };
};
