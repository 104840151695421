import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box, Typography, CircularProgress } from "@mui/material";
import ChatInfo from "./ChatInfo";
import SideChat from "./SideChat";
import { getSx } from "./style";
import useSocket from "./useSocket";
import messageempty from "../../../assets/images/messageempty.png";
import { getUtcTime } from "../../../helper/getUtcTime";
import NavBar from "../home";

import { listchatusersApi, sendmessageApi, listUserMessagesApi, receiveMessageApi } from "../../../api/messages";
import { getProfileApi } from "../../../api/profile";
import { Container } from "@mui/system";

const MessagesInfo = () => {
  const socket = useSocket();
  const [chatUsers, setChatUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newChatUser, setNewChatUser] = useState();
  const [newMessage, setNewMessage] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [isMsgRecieved, setIsMsgRecieved] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [page, setPage] = useState(1);
  const classes = getSx();
  const userId = useSelector((state) => state.profile.profileInfo.userId);
  const location = useLocation();
  const val = location?.state?.val;
  const chatUsersRef = useRef(newChatUser);

  useEffect(() => {
    socket?.on("broadcast", async (data) => {
      handleListenData(data, userId);
    });
  }, [socket]);

  useEffect(() => {
    chatUsersRef.current = newChatUser;
  }, [newChatUser]);

  const handleListenData = async (data, userId) => {
    const currentChatUsers = chatUsersRef.current;
    if (data?.from?._id == currentChatUsers?.userId && data.to?._id == userId) {
      setMessages((prevMessages) => [...prevMessages, { ...data }]);
    }
    setIsMsgRecieved((prev) => !prev);
    const messagechat = document.getElementsByClassName("messagechat");
    messagechat[0]?.scroll({ top: messagechat[0]?.scrollHeight });

    setChatUsers((prevChatUsers) => {
      const updatedChatUsers = prevChatUsers?.map((item) => {
        if (item?.userId === data?.from?._id && data?.to?._id == userId) {
          return {
            ...item,
            latestMessage: { ...item?.latestMessage, message: data?.message },
            unReadCount: item?.userId === currentChatUsers?.userId ? 0 : item?.unReadCount + 1,
          };
        } else {
          return item;
        }
      });
      return updatedChatUsers;
    });
  };

  const callChatUserApi = async () => {
    setLoading(true);
    const resp = await listchatusersApi();
    setChatUsers(resp?.data?.data);
    if (val) {
      const value = resp?.data?.data;
      const val2 = value.find((item) => item?.userId == val?._id);
      if (val2) {
        setNewChatUser(val2);
        setPage(1);
      } else {
        const value = {
          userDetails: {
            profileImage: val?.profileImage,
            firstName: val?.firstName,
            lastName: val?.lastName,
          },
          userId: val?._id,
          latestMessage: {},
          isDisabled: val?.isDisabled,
        };
        setNewChatUser(value);
        setPage(1);
        if (resp?.data?.data) setChatUsers([value, ...resp?.data?.data]);
      }
    }
    setLoading(false);
    const resp1 = await getProfileApi();
    setUserInfo(resp1?.data?.data);
  };
  const callGetMessagesApi = async (page) => {
    if (page == 1) setChatLoading(true);
    const resp = await listUserMessagesApi(newChatUser?.userId, page);
    if (page == 1) setMessages(resp?.data?.data.reverse());
    else setMessages([...resp?.data?.data.reverse(), ...messages]);
    setChatLoading(false);
  };

  const callRecieveMessage = async (id) => {
    const resp = await receiveMessageApi(id);

    if (resp?.data?.type == "success") {
      const val = chatUsers?.map((item) => {
        if (item?.userId == id) return { ...item, unReadCount: 0 };
        else return item;
      });
      setChatUsers(val);
    }
  };
  const handleSendMsg = async () => {
    let messageId = Math.random().toString(36).substr(2, 9);
    let currDate = getUtcTime();
    let value = {
      messageId: messageId,
      message: newMessage,
      status: 1,
      to: newChatUser?.userId,
      createdAt: currDate,
    };
    let socketObject = {
      messageId: messageId,
      message: newMessage,
      status: 1,
      to: { _id: newChatUser?.userId },
      from: {
        _id: userId,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        profileImage: {
          url: userInfo?.profileImage?.url,
        },
      },
      createdAt: currDate,
    };
    setMessages([...messages, socketObject]);
    setNewMessage("");
    socket?.emit("send-message", socketObject, (data) => {});

    const resp = await sendmessageApi(value);
    if (resp?.status == 200) {
      const val = chatUsers?.map((item) => {
        if (item?.userId == newChatUser?.userId) {
          return {
            ...item,
            latestMessage: {
              ...item?.latestMessage,
              message: newMessage,
              createdAt: currDate,
            },
          };
        } else return item;
      });
      const messagechat = document.getElementsByClassName("messagechat");
      messagechat[0]?.scroll({ top: messagechat[0]?.scrollHeight });
      setChatUsers([...val]);
    }
  };

  useEffect(() => {
    callChatUserApi();
  }, []);

  useEffect(() => {
    if (newChatUser) callGetMessagesApi(page);
  }, [newChatUser, page]);

  return (
    <Box sx={{ padding: { xs: "15px 0", sm: "15px 0", md: "15px 0", lg: "15px 0", xl: "20px 30px" } }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          fontWeight: 700,
          fontSize: {
            xs: "20px",
            sm: "22px",
            md: "24px",
            lg: "25px",
            xl: "25px",
          },
          color: "#111111",
          marginBottom: {
            xs: "10px",
            sm: "10px",
            md: "10px",
            lg: "15px",
            xl: "24px",
          },
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        Messages
      </Typography>
      {chatUsers?.length == 0 && !loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            flexDirection: "column",
          }}
        >
          <img alt="" src={messageempty} style={{ width: "185px", height: "185px" }} />
          <Typography sx={{ fontFamily: "Montserrat, sans-serif" }} variant="h5" fontWeight="bold" margin="10px 0px">
            You don't have any Messages yet!
          </Typography>
        </Box>
      ) : (
        <Box sx={classes.main}>
          <Box sx={classes.messagecontainer}>
            <Box sx={classes.messagecontent1}>
              {loading ? (
                <CircularProgress
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <Box sx={classes.sidechat}>
                  {chatUsers?.map((m) => {
                    return (
                      <Box
                        key={m?._id}
                        sx={
                          m?.userId == newChatUser?.userId || m?.unReadCount > 0
                            ? {
                                ...classes.sidechatuser,
                                border: "2px solid rgba(23, 186, 174, 1)",
                              }
                            : {
                                ...classes.sidechatuser,
                                border: "2px solid #ECECEE",
                              }
                        }
                        onClick={() => {
                          setPage(1);
                          setNewChatUser(m);
                          setNewMessage("");
                          if (m?.unReadCount > 0) callRecieveMessage(m?.userId);
                        }}
                      >
                        <SideChat user={m} />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            {newChatUser ? (
              chatLoading ? (
                <Box
                  sx={{ ...classes.messagecontent2, display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={classes.messagecontent2}>
                  <ChatInfo
                    newChatUser={newChatUser}
                    messages={messages}
                    senderId={userId}
                    setNewMessage={setNewMessage}
                    handleSendMsg={handleSendMsg}
                    message={newMessage}
                    page={page}
                    setPage={setPage}
                    isMsgRecieved={isMsgRecieved}
                    callRecieveMessage={callRecieveMessage}
                  />
                </Box>
              )
            ) : (
              chatUsers?.length > 0 && (
                <Box sx={classes.messagecontent2}>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                        xl: "24px",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                    variant="h5"
                    fontWeight="bold"
                    margin="15px 0px"
                  >
                    Please select a user to chat
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
const Messages = () => {
  return (
    <NavBar>
      <Container
        sx={{
          padding: {
            xs: "0 15px",
            sm: "0 30px",
            md: "0 30px",
            lg: "0 30px",
            xl: 0,
          },
        }}
        maxWidth="xl"
      >
        <MessagesInfo />
      </Container>
    </NavBar>
  );
};

export default Messages;
