import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getSx } from "./style";
import event2tlogo from "../../../assets/images/svg/event2tlogo.svg";
import { getAddressObject } from "../../../helper";
import { addBankDetailsApi } from "../../../api/createprofile";
import { showToast } from "../../../store/slices/common";
import { setProfileInfo } from "../../../store/slices/profileinfo";
import { uploadGovtIdImage } from "../../../api/Signup";
import CloudArrowUp from "../../../assets/images/CloudUpload.png";
import PaperCard from "./UploadFileSize/PaperCard";
import PhoneInput from "react-phone-input-2";
import { getProfileApi } from "../../../api/profile";

const BankDetails = ({ fromSettings, bankDetails }) => {
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const datePickerRef = useRef(null);
  const [timeData, setTimeData] = useState([]);
  const profileInfo = useSelector((state) => state.profile.profileInfo);
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState();
  const [status, setStatus] = useState();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      // componentRestrictions: { country: "ca" },
    },
  });

  const validationSchema = Yup.object({
    accountHolderName: Yup.string()
      .required("Account Holder's Name Required")
      .matches(/^[A-Za-z ]+$/, "Invalid name")
      .max(100, " name must be less than 100 characters"),
    accountNumber: Yup.string()
      .matches(/^\d+$/, "Please Enter Valid Account Number")
      .required("Account NumberRequired"),
    routingNumber: Yup.string()
      .matches(/^\d+$/, "Please Enter Valid Routing Number")
      .required("Routing Number Required"),
    bankName: Yup.string()
      .required("Bank Name Required")
      .matches(/^[A-Za-z ]+$/, "Invalid BanK Name"),
    address: Yup.string().required("Address Required"),
    city: Yup.string().required("City Required"),
    state: Yup.string().required("State Required"),
    postalCode: Yup.string()
      .required("Postal Code Required")
      .matches(/^[A-Za-z0-9 ]+$/, "Invalid Postalcode"),
    ssnNumber: Yup.string()
      .matches(/^\d+$/, "Please Enter Valid Routing Number")
      .required("EIN or SSN Number Required"),
  });

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        formik.setFieldTouched("city", true);
        formik.setFieldTouched("state", true);
        formik.setFieldTouched("postalCode", true);
        formik.setFieldTouched("address", true);
        setValue(placeDetails?.name);
        formik.setFieldValue("address", placeDetails?.name);
        formik.setFieldValue("city", address?.city);
        formik.setFieldValue("state", address?.region);
        formik.setFieldValue("postalCode", address?.postal_code);
        formik.setErrors({});
        formik.validateForm().then(() => {
          formik.setErrors({});
        });
        setShowList(false);
      }
    );
  };

  const classes = getSx();
  const formik = useFormik({
    initialValues: {
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      ssnNumber: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });
  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };

  const handleClick = async () => {
    setLoading(true);
    const resp = await addBankDetailsApi({
      ...formik.values,
      dob: dayjs(selectedDate).format("MM/DD/YYYY"),
      file: uploadFile,
      phoneNumber: phoneNumber,
      countryCode: countryCode,
    });
    if (resp?.data?.success) {
      if (!fromSettings) {
        navigate("/profilecreationsucessful?id=1");
        dispatch(setProfileInfo({ ...profileInfo, screen: 7 }));
      } else {
        dispatch(
          showToast({
            color: "success",
            msg: "Bank Details Updated Successfully",
          })
        );
      }
    } else if (resp?.response?.status >= 400) {
      dispatch(
        showToast({
          color: "error",
          msg: resp?.response?.data?.message,
        })
      );
    }
    setLoading(false);
  };
  const handleImageChange = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        const response = await uploadGovtIdImage(formData);
        if (response.type === "success") {
          setUploadFile(response.data.fileId);
        }
      }
    }
    setFileLoading(false);
  };
  const onDelete = () => {
    setUploadFile();
  };
  const callProfileApi = async () => {
    const resp = await getProfileApi();
    if (resp?.data?.data) {
      setPhoneNumber(resp?.data?.data?.phoneNumber);
      setCountryCode(resp?.data?.data?.countryCode);
    }
  };
  useEffect(() => {
    if (bankDetails) {
      formik.setValues(bankDetails);
      setValue(bankDetails?.address);
      let date = dayjs(bankDetails?.dob, "DD/MM/YYYY");
      setSelectedDate(date);
      setUploadFile(bankDetails.file);
      setStatus(bankDetails.status);
    }
    callProfileApi();
  }, [bankDetails]);

  const showFileUpload =
    !fromSettings ||
    (fromSettings && !["verified", "pending"].includes(status));

  return (
    <Box sx={fromSettings ? classes.maincontainer1 : classes.maincontainer}>
      <Box sx={fromSettings ? classes.container1 : classes.container}>
        {!fromSettings && (
          <Box sx={classes.logo}>
            <img
              alt=""
              src={event2tlogo}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        )}
        <Box
          sx={{
            ...classes.nameinfo,
            width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
          }}
          className="bankdetailsinfo"
        >
          {fromSettings ? (
            <Typography sx={classes.heading}>Enter Bank Details</Typography>
          ) : (
            <Typography style={{ textAlign: "center" }} sx={classes.heading}>
              Enter your Bank Details
            </Typography>
          )}
          <Box sx={classes.createFormMain}>
            <Box
              sx={fromSettings ? classes.bankcontainer1 : classes.bankcontainer}
              className="bankbox scrollableinfo"
            >
              {fileLoading ? (
                <Box
                  sx={{
                    height: { xs: "10vh", md: "12vh", lg: "10vh", xl: "12vh" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: `2px dashed rgba(41, 234, 165, 1)`,
                    backgroundColor: "rgba(23, 186, 174, 0.1)",
                    borderRadius: "12px",
                  }}
                >
                  <CircularProgress />{" "}
                </Box>
              ) : (
                <>
                  {showFileUpload && (
                    <Box>
                      <>
                        {!uploadFile ? (
                          <Box
                            sx={{
                              height: "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: `2px dashed rgba(41, 234, 165, 1)`,
                              backgroundColor: "rgba(23, 186, 174, 0.1)",
                              borderRadius: "12px",
                              padding: "15px",
                            }}
                          >
                            <Stack
                              direction={"column"}
                              className={"uploadlicence"}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  //cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <label htmlFor="file-upload">
                                  <img
                                    alt=""
                                    src={CloudArrowUp}
                                    style={{
                                      height: "48px",
                                      objectFit: "contain",
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="file-upload"
                                  accept="image/jpeg, image/png"
                                  style={{ display: "none" }}
                                  onChange={handleImageChange}
                                />
                                <Typography
                                  variant="h5"
                                  textAlign="center"
                                  fontSize="16px"
                                  fontWeight="500"
                                  lineHeight="130%"
                                  color="#A7A7A7"
                                  fontFamily="Montserrat, sans-serif !important"
                                >
                                  Upload Government Id:
                                </Typography>
                              </Box>
                            </Stack>
                          </Box>
                        ) : (
                          <PaperCard
                            key={1}
                            selectedFile={{ name: uploadFile }}
                            onDelete={onDelete}
                            isAPIResponseReceived={[]}
                            max={100}
                            sliderValue={100}
                            fromBankDetails={true}
                            setTimeData={setTimeData}
                            timeData={timeData}
                          />
                        )}
                        <p style={{ color: "gray", fontSize: "12px" }}>
                          {" "}
                          Note:Please upload a image associated with the
                          account's primary active bank account that displays
                          the last 4 digits of the account number, either a
                          statement or a voided check.
                        </p>
                      </>
                    </Box>
                  )}
                </>
              )}

              <form style={{ width: "100%", marginTop: "5px" }}>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>
                    Account Holder’s Name
                  </Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Account Holder's Name"
                    name="accountHolderName"
                    value={formik.values.accountHolderName}
                    onChange={(e) => {
                      formik.setFieldTouched("accountHolderName", true);
                      formik.handleChange(e);
                      formik.validateField("accountHolderName");
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountHolderName &&
                      Boolean(formik.errors.accountHolderName)
                    }
                    errorMessage={
                      formik.touched.accountHolderName &&
                      formik.errors.accountHolderName
                    }
                    maxLength={100}
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}> Bank Name</Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Bank Name"
                    name="bankName"
                    value={formik.values.bankName}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldTouched("bankName", true);
                      formik.handleChange(e);
                    }}
                    error={
                      formik.touched.bankName && Boolean(formik.errors.bankName)
                    }
                    errorMessage={
                      formik.touched.bankName && formik.errors.bankName
                    }
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>
                    Account Number
                  </Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Account  Number"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (/^\d*$/.test(newValue)) {
                        formik.setFieldTouched("accountNumber", true);
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountNumber &&
                      Boolean(formik.errors.accountNumber)
                    }
                    errorMessage={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                    }
                  />
                </Box>

                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>
                    Routing Number
                  </Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Routing  Number"
                    name="routingNumber"
                    value={formik.values.routingNumber}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (/^\d*$/.test(newValue)) {
                        formik.setFieldTouched("routingNumber", true);
                        formik.handleChange(e);
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.routingNumber &&
                      Boolean(formik.errors.routingNumber)
                    }
                    errorMessage={
                      formik.touched.routingNumber &&
                      formik.errors.routingNumber
                    }
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>EIN or SSN</Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter EIN or SSN Number"
                    name="ssnNumber"
                    value={formik.values.ssnNumber}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (/^\d*$/.test(newValue)) {
                        formik.setFieldTouched("ssnNumber", true);
                        formik.handleChange(e);
                      }
                    }}
                    onWheel={handleWheel}
                    error={
                      formik.touched.ssnNumber &&
                      Boolean(formik.errors.ssnNumber)
                    }
                    errorMessage={
                      formik.touched.ssnNumber && formik.errors.ssnNumber
                    }
                  />
                </Box>

                <Box sx={{ position: "relative", marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>
                    Street Address
                  </Typography>
                  <CustomTextField
                    size="large"
                    value={value}
                    autoFocus={value?.length > 0 ? true : false}
                    onChange={(evt) => {
                      setValue(evt.target.value);
                      formik.setFieldValue("address", evt.target.value);
                      getPlacePredictions({ input: evt.target.value });
                      if (!isPlacePredictionsLoading) setShowList(true);
                    }}
                    onKeyDown={(event) => {
                      if (event?.keyCode === 8 || event?.keyCode === 46) {
                        formik.setFieldValue("city", "");
                        formik.setFieldValue("province", "");
                        formik.setFieldValue("postalCode", "");
                      }
                    }}
                    placeholder="Street Address"
                  />
                  {value !== "" && showList && placePredictions.length > 0 && (
                    <Box sx={classes.googleautosuggestionlist}>
                      <List>
                        {placePredictions.map((item) => (
                          <ListItem
                            key={item.place_id}
                            onClick={() => getDetails(item?.place_id)}
                            style={{ cursor: "pointer" }}
                          >
                            <ListItemText primary={item.description} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}> City</Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter City"
                    name="city"
                    value={formik.values.city}
                    onChange={(e) => {
                      formik.setFieldTouched("city", true);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    errorMessage={formik.touched.city && formik.errors.city}
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>State</Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter State"
                    name="state"
                    value={formik.values.state}
                    onChange={(e) => {
                      formik.setFieldTouched("state", true);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    errorMessage={formik.touched.state && formik.errors.state}
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>Postal Code</Typography>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    placeholder="Enter Postal Code"
                    name="postalCode"
                    value={formik.values.postalCode}
                    maxLength={8}
                    onChange={(e) => {
                      formik.setFieldTouched("postalCode", true);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.postalCode &&
                      Boolean(formik.errors.postalCode)
                    }
                    errorMessage={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography sx={classes.smallheading}>
                    Date of Birth
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      maxDate={dayjs().subtract(18, "year")}
                      value={selectedDate ? dayjs(selectedDate) : null}
                      onChange={handleDateChange}
                      ref={datePickerRef}
                      onChangeRaw={(e) => {
                        e.preventDefault();
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                      format="MM/DD/YYYY"
                      sx={{
                        width: "100%",
                        borderRadius: "18px",
                        height: "60px",
                        "& fieldset": {
                          border: "1px solid #EBEBEB",
                          height: "60px",
                          borderRadius: "18px",
                        },
                        "& fieldset:hover": {
                          border: "1px solid #EBEBEB",
                          height: "60px",
                          borderRadius: "18px",
                        },
                        "& fieldset:focus": {
                          border: "1px solid #EBEBEB",
                          height: "60px",
                          borderRadius: "18px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EBEBEB !important",
                        },
                        "& input": {
                          fontWeight: 600,
                        },
                      }}
                      slotProps={{
                        textField: {
                          onClick: () => setOpen(true),
                          readOnly: true,
                        },
                      }}
                      renderInput={(props) => (
                        <TextField
                          inputProps={{ readOnly: true }}
                          onClick={() => setOpen(true)}
                          onKeyDown={(e) => e.preventDefault()}
                          {...props}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                {!fromSettings && (
                  <Box width={"100%"} position="relative">
                    <Typography sx={{ ...classes.smallheading }}>
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      sx={classes.phoneinput}
                      placeholder="Enter Phone Number"
                      //value={phoneNumber}
                      onlyCountries={["us"]}
                      value={`${countryCode + phoneNumber}`}
                      onChange={(value, country) => {
                        setPhoneNumber(value.slice(country?.dialCode?.length));
                        setCountryCode(country?.dialCode);

                        //  handleData({ countryCode: country?.dialCode, phoneNumber: value.slice(country?.dialCode?.length) })
                      }}
                      inputStyle={{
                        ...classes.inputStyle,
                        height: "60px",
                      }}
                      containerStyle={classes.containerStyle}
                    />
                  </Box>
                )}
              </form>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", sm: "205px", lg: "205px", xl: "205px" },
                margin: "auto",
              }}
            >
              <ButtonComp
                disabled={
                  !(
                    formik.isValid &&
                    formik.dirty &&
                    formik.values.ssnNumber &&
                    (phoneNumber.length == 10 || fromSettings) &&
                    selectedDate != null &&
                    (uploadFile ||
                      ((status == "verified" || status == "pending") &&
                        fromSettings))
                  )
                }
                type={
                  formik.isValid &&
                  formik.dirty &&
                  formik.values.ssnNumber &&
                  (phoneNumber.length == 10 || fromSettings) &&
                  selectedDate != null &&
                  (uploadFile ||
                    ((status == "verified" || status == "pending") &&
                      fromSettings))
                    ? "active"
                    : "disabled"
                }
                onClick={handleClick}
                label={fromSettings ? "Save" : "Continue"}
                loading={loading}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "60px",
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BankDetails;
