import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ButtonComp from "../component/Button";
import CustomTextField from "../component/Textfield";
import { Box, Grid, Typography } from "@mui/material";
import { getSx } from "./style";
import emailIcon from "../assets/images/svg/emailicongreen.svg";
import event2tlogo from "../assets/images/svg/event2tlogo.svg";
import passwordIconInput from "../assets/images/svg/passwordiconsvg.svg";
import { getFCMToken } from "../firebase";
import {
  getRole,
  getLanguage,
  setToken,
} from "../utils/auth-token-utils/cookies";
import { handleRouting } from "../utils/handleRouting";
import { signinApi } from "../api/Signup";
import { showToast, setTermsAccepted } from "../store/slices/common";
import { setProfileInfo, setUserIds } from "../store/slices/profileinfo";
import { routePaths } from "../Routes/route-paths";
import FaceBookLogin from "../component/FaceBookLogin";
import { getProfileApi } from "../api/profile";
import GoogleLoginInfo from "../component/GoogleLogin";
import { handleProfileInfo } from "../utils/handleProfileInfo";


const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const role = getRole();
  const dispatch = useDispatch();
  const langCode = getLanguage();
  const validationSchema = Yup.object({
    password: Yup.string().required("Enter Password"),
    email: Yup.string()
      .required("Enter Email Address")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid Email address"
      ),
  });
  const selectedServiceId = localStorage.getItem("selectedServiceId");
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => { },
  });
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (formik.isValid && formik.dirty)
        handleSignIn();

    }
  };
  const handleSignIn = async () => {
    setLoading(true);
    const resp = await signinApi({
      userType: "0",
      defaultLanguageCode: langCode,
      email: formik.values.email,
      password: formik.values.password,
    });
    if (resp?.status == 200) {
      localStorage.setItem("role", 0)
      setToken(resp?.data?.data?.token);
      dispatch(setTermsAccepted(resp?.data?.data?.isTermsAccepted));
      getFCMToken();
      const profileResp = await getProfileApi();
      handleProfileInfo(profileResp?.data?.data, dispatch, setProfileInfo, 0);
      dispatch(setUserIds(resp?.data?.data?.userIds));
      if (
        selectedServiceId &&
        resp?.data?.data?.isProfileComplete &&
        role == "0"
      ) {
        navigate(`/service-info?id=${selectedServiceId}`);
      } else {
        handleRouting(
          profileResp?.data?.data,
          navigate,
          routePaths,
          selectedServiceId
        );
      }
      localStorage.setItem("isLogin", true);
      dispatch(
        showToast({
          color: "success",
          msg: "Sign-in successful",
        })
      );
    } else if (resp?.response?.status >= 400) {
      dispatch(
        showToast({
          color: "error",
          msg: resp?.response?.data?.message,
        })
      );
    }
    setLoading(false);
  };

  const classes = getSx();
  return (
    <Grid item xs={12} md={10} lg={8} margin="auto">
      <Box sx={classes.container}>
        <Box sx={classes.maincontainer}>
          <Box sx={classes.logo}>
            <img alt="" src={event2tlogo} style={{ width: "100%", height: "100%" }} />
          </Box>
          <Box
            sx={{ ...classes.heading, fontSize: { xs: "20px", md: "24px", lg: '26px', xl: '34px' }, fontFamily: "Montserrat, sans-serif" }}
          >
            Welcome!
          </Box>
          <Box
            sx={{ ...classes.content, fontSize: { xs: "16px", md: "17px", lg: '18px', xl: '20px' }, marginBottom: "10px", fontFamily: "Montserrat, sans-serif" }}
          >
            Sign In to your account
          </Box>

          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "100%", marginTop: "5px" }}
          >
            <Box sx={classes.inputinfo}>
              <Typography
                fontWeight={600}
                color={"#1A1A1A"}
                marginBottom={"12px"}
                fontFamily={"Montserrat, sans-serif"}
                sx={{
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '15px',
                    lg: '15px',
                    xl: '18px'
                  },
                  lineHeight: {
                    xs: '19px',
                    sm: '19px',
                    md: '20px',
                    lg: '20px',
                    xl: '23px'
                  }
                }}
              >
                Email Address
              </Typography>
              <Typography
                className="input-with-icon-div margintopp"
                component="div"
                position={"relative"}
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  marginBottom: {
                    xs: '10px',
                    sm: '10px',
                    md: '15px',
                    lg: '15px',
                    xl: '20px'
                  }
                }}
              >
                <CustomTextField
                  type="email"
                  variant="outlined"
                  placeholder="Enter Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  errorMessage={formik.touched.email && formik.errors.email}
                  className={"email-with-icon"}
                  onKeyDown={handleKeyPress}
                />
                <Typography
                  sx={{ fontFamily: "Montserrat, sans-serif", }}
                  className="input-icon"
                  component="div"
                  position="absolute"
                >
                  <img src={emailIcon} style={{ opacity: "1" }} alt="Image" />
                </Typography>
              </Typography>
            </Box>
            <Box sx={classes.inputinfo}>
              <Typography
                fontWeight={600}
                color={"#1A1A1A"}
                marginBottom={"12px"}
                fontFamily={"Montserrat, sans-serif"}
                sx={{
                  fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '15px',
                    lg: '15px',
                    xl: '18px'
                  },
                  lineHeight: {
                    xs: '19px',
                    sm: '19px',
                    md: '20px',
                    lg: '20px',
                    xl: '23px'
                  },
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Password
              </Typography>
              <Typography
                className="input-with-icon-div margintopp"
                component="div"
                position={"relative"}
                sx={{
                  marginBottom: {
                    xs: '10px',
                    sm: '10px',
                    md: '15px',
                    lg: '15px',
                    xl: '20px'
                  },
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <CustomTextField
                  type="password"
                  variant="outlined"
                  placeholder="Enter Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  errorMessage={
                    formik.touched.password && formik.errors.password
                  }
                  className={"email-with-icon"}
                  onKeyDown={handleKeyPress}
                />
                <Typography
                  sx={{ fontFamily: "Montserrat, sans-serif", }}
                  className="input-icon"
                  component="div"
                  position="absolute"
                >
                  <img
                    src={passwordIconInput}
                    style={{ opacity: "1" }}
                    alt="Image"
                  />
                </Typography>
              </Typography>
            </Box>
            <Box sx={classes.forgotpassword}>
              <span
                onClick={() => {
                  navigate(routePaths.RESET_PASSWORD);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                Forgot Password?
              </span>
            </Box>
            <ButtonComp
              width="100%"
              height={'60px'}
              loading={loading}
              disabled={!(formik.isValid && formik.dirty)}
              type={formik.isValid && formik.dirty ? "active" : "disabled"}
              label="Sign In"
              onClick={handleSignIn}
            />
          </form>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: { xs: "0", md: "2rem 0rem", lg: "0.5rem 0", xl: "0.8rem 0rem" } }}>
            <hr class="styled-line" style={{ width: "80px", margin: "0px 15px" }} />
            or
            <hr class="styled-line" style={{ width: "100px", margin: "0px 15px" }} />
          </Box>
          <Box sx={classes.logins}>
            <GoogleLoginInfo setLoading={setLoading} />
            <FaceBookLogin setLoading={setLoading} />
          </Box>

          <Box sx={classes.donthaveacc}>
            Don&apos;t have an account ? {` `}
            <span
              style={{
                color: "rgba(22, 192, 152, 1)",
                fontWeight: "600",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(routePaths.SIGNUP);
              }}
            >
              Sign Up
            </span>{" "}
          </Box>

        </Box>
      </Box>
    </Grid>
  );
};

export default SignIn;
