import React from "react";
import { Carousel } from "react-responsive-carousel";
import Fade from "@mui/material/Fade";

import { styled ,Box} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import crossicon from "../../../assets/images/svg/crossicon.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "680px",
    borderRadius: "20px",
  },
  "& .MuiPaper-root": { maxWidth: "800px" },
  "& .MuiDialogActions-root": {},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const ImageModal = ({ imageModal, setImageModal, serviceImages }) => {
  return (
    <CustomDialog
        classes="images-slider-modal"
      open={imageModal}
      TransitionComponent={Transition}
      onClose={()=>{setImageModal(false)}}
      keepMounted
      sx={{
        "& .MuiPaper-root": {
          maxWidth: {
              xs:'100%',
              sm:"80%",
              md:"85%",
              lg:"70%",
              xl:"70%"
          },
          maxHeight:{
              xs:"calc(75vh - 65px)",
              sm:"calc(80vh - 65px)",
              md:"calc(80vh - 65px)",
              lg:"calc(100vh - 65px)",
              xl:"calc(100vh - 65px)"
          },
            width:{
              xs:'100%',
              sm:"80%",
              md:"85%",
              lg:"70%",
              xl:"70%"
            },
          padding:{lg:0,xl:0,xs:'0 !important',sm:0,md:0}
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      }}
    >
      <DialogContent
        sx={{  overflow: "hidden",width:"100%",height:"100%",padding:{
            xs:0,
                sm:0,
                md:0,
                lg:0,
                xl:0
            } }}
      >
        <img alt=""
          src={crossicon}
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "0.5rem",
            cursor: "pointer",
            zIndex:"11111"
          }}
          onClick={() => {
            setImageModal(false);
          }}
        />
        <Box sx={{width:"100%",height:"100%"}} className="imagescarousel">
        <Carousel
          infiniteLoop={true}
          interval={2000}
          autoPlay={true}
          showStatus={false}
          style={{ marginTop: "10px",width:"100%",height:"300px" }}
        >
          {serviceImages.map((item) => {
            return (
              <img className="image-modal-slider-img" alt=""
                src={item.fileUrl}
                style={{ width: "100%",objectFit:"cover",aspectRatio:'1/1', borderRadius:'20px' }}
              />
            );
          })}
        </Carousel>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default ImageModal;
