import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../component/Button";
import { Box } from "@mui/system";
import eventorganiserimag from "../../assets/images/eventorganiserimg.png";
import serviceproviderimag from "../../assets/images/serviceproviderimg.jpg";
import event2tlogo from "../../assets/images/svg/event2tlogo.svg";
import { getSx } from "../languageselect/style";
import { storeRole } from "../../utils/auth-token-utils/cookies";
import { routePaths } from "../../Routes/route-paths";

const ProviderSelector = () => {
  const classes = getSx();
  const [provider, setProvider] = useState("");
  const navigate = useNavigate();
  return (
    <Box sx={classes.maincontainer}>
      <Box sx={classes.container}>
        <Box sx={classes.event2timgcont}>
          <img alt="" src={event2tlogo} style={{ width: "100%", height: "100%" }} />
        </Box>
        <Box sx={classes.heading}>Select Your Role</Box>
        <Box sx={classes.content}>
        Creating unforgettable moments is our passion, and we're excited to have you join us.
        </Box>
        <Box sx={classes.providerimages}>
          <Box sx={classes.img1}>
            <Box
              sx={
                provider == 1
                  ? { ...classes.unselected, ...classes.selected }
                  : { ...classes.unselected }
              }
              onClick={() => {
                setProvider(1);
                storeRole("0");

              }}
            >
              Event Creator
            </Box>
            <Box sx={classes.englishlang1}
             onClick={() => {
              setProvider(1);
              storeRole("0");
            }}
            >
              <img alt={""}
                src={eventorganiserimag}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
               
              />
              <Box sx={classes.bgOverlay}></Box>
            </Box>
          </Box>
          <Box sx={classes.img1}>
            <Box
              sx={
                provider == 2
                  ? { ...classes.unselected, ...classes.selectedservice }
                  : classes.unselected
              }
              onClick={() => {
                setProvider(2);
                storeRole("1");}}
             
            >
              Service Provider
            </Box>
            <Box sx={classes.englishlang1}
             onClick={() => {
              setProvider(2);
              storeRole("1");
            }}
            >
              <img alt=""
                src={serviceproviderimag}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => {
                  setProvider(2);
                  storeRole("1");
                }}
              />
              <Box sx={classes.bgOverlay}></Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "200px", md: "365px" },
            marginTop: { xs: "0", sm: "30px", md: "30px", lg: "30px", xl: "30px" },
          }}
        >
          <ButtonComp
            label="Next"
            width="100%"
            height="45px"
            disabled={provider ? false : true}
            type={provider == 2 || provider == 1 ? "active" : "disabled"}
            onClick={() => {
                navigate(routePaths.LOGIN);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProviderSelector;
