import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../component/BreadCrumb";
import ButtonComp from "../../../component/Button";
import { modalVariant } from "../../../component/Modal/Modalutils";
import { getSx } from "../../../pages/eventorganiser/dashboard/style";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import ShareOtpPopUp from "./ShareOtpPopUp";
import bookingicon from "../../../assets/images/bookingicon.png";
import calendargreenicon from "../../../assets/images/calendargreenicon.png";
import canceliconbooking from "../../../assets/images/canceliconbooking.png";
import dashboardbreadcrum from "../../../assets/images/dashboardbreadcrum.png";
import dummyprofilepic from "../../../assets/images/dummyprofilepic.svg";
import callgreen from "../../../assets/images/svg/callgreen.svg";
import emailicongreen from "../../../assets/images/svg/emailicongreen.svg";
import locationicongreen from "../../../assets/images/svg/locationicongreen.svg";
import Star from "../../../assets/images/svg/Star.svg";
import timinggreen from "../../../assets/images/svg/timinggreen.svg";
import AddCardModal from "../../eventorganiser/BookServiceModal/PaymentModal.js/AddCardModal";
import PaymentInfoModal from "../../eventorganiser/BookServiceModal/PaymentModal.js/PaymentInfoModal";
import AddReviewModal from "../../eventorganiser/dashboard/AddReviewModal";
import CancelPolicyModal from "../../eventorganiser/dashboard/CancelPolicyModal";
import ViewRating from "../../eventorganiser/dashboard/ViewRating";
import NavBar from "../../eventorganiser/home";
import { convertTo12HourFormat, cancelPolicy, getCurrencyFormat } from "../../../common/constant";
import { callBookingConfirmationApi } from "../../../api/addService";
import {
  callAcceptOrRejectApi,
  generateOtpEoApi,
  callCancelApiForEO,
  callCancelApiForSP,
  verifyOtpSpApi, reportApi
} from "../../../api/mybookings";
import { pushBreadCrums } from "../../../store/slices/breadcrumb";
import { showModal, showToast } from "../../../store/slices/common";
import { Container } from "@mui/system";
import { chatStatusApi } from "../../../api/messages";

const RequestDetails = () => {
  const location = useLocation();
  const role = localStorage.getItem("role");
  const queryParams = new URLSearchParams(location.search);
  const [serviceInfo, setServiceInfo] = useState();
  const [addOnCategories, setAddOnCategories] = useState([]);
  const [serviceCategory, setServiceCategory] = useState();
  const [openPaymentInfoModal, setOpenPaymentInfoModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [otp, setOtp] = useState("");
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [isReviewAdded, setIsReviewAdded] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [showCancelInfo, setShowCancelInfo] = useState(
    localStorage.getItem("role") == "0" ? true : false
  );
  const id = queryParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = getSx();

  const getTotalAmount = (type, peramount, count, daysArr, noofDays) => {
    if (type == "Per Day") {
      let val = (peramount * noofDays).toFixed(2)
      return getCurrencyFormat(val);
    } else if (type == "Per Person") {
      let val = (peramount * count * daysArr?.length).toFixed(2)
      return getCurrencyFormat(val);
    } else if (type == "Hourly") {
      let hours = 0;
      daysArr?.map((item) => {
        let startTime = convertToHours(item.startTime);
        let endTime = convertToHours(item.endTime);

        // If end time is less than start time, it means the end time is on the next day
        if (endTime < startTime) {
          endTime.setDate(endTime.getDate() + 1); // Add one day to end time
        }

        const diffInMilliseconds = endTime - startTime;
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

        hours += diffInHours;
      });

      let val = (hours * peramount).toFixed(2);
      return getCurrencyFormat(val);
    } else {
      let val = peramount.toFixed(2)
      return getCurrencyFormat(val);
    }
  };

  const handleAcceptorReject = async (state) => {
    const resp = await callAcceptOrRejectApi(serviceInfo?._id, {
      status: state,
    });
    if (resp?.status == 200) {
      if (state == "Accepted") {
        dispatch(
          showModal({
            modalType: modalVariant.ACCEPT_SERVICE_SUCCESS,
            resolveCallback: () => {
              dispatch(showModal(null));
              if (role == "0") navigate("/event-dashboard");
              else navigate("/booking-info");
            },
          })
        );
      } else {
        if (role == "0") navigate("/event-dashboard");
        else navigate("/booking-info");
      }
    }
  };

  const callCancelBookingApi = async () => {
    setLoading(true);
    const resp = await callCancelApiForEO(id);
    if (resp?.status == 200) {
      dispatch(
        showModal({
          modalType: modalVariant.REQUEST_CANCEL_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            navigate("/event-dashboard");
          },
        })
      );
    } else if (resp?.response?.status >= 400) {
      {
        dispatch(
          showToast({ msg: resp.response.data.message, color: "error" })
        );
      }
    }
    setLoading(false);
  };

  const cancelBookingApi = async (status) => {
    if (
      role == "0" &&
      (status == "Pending" || status == "Accepted" || status == "Rejected")
    ) {
      dispatch(
        showModal({
          modalType: modalVariant.CANCEL_BOOKING,
          resolveCallback: () => {
            dispatch(showModal(null));
            callCancelBookingApi();
          },
          rejectCallback: () => {
            dispatch(showModal(null));
          },
        })
      );
    } else callCancelApiForSP(id);
  };

  const convertToHours = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }

    return new Date(1970, 0, 1, hours, minutes);
  };

  const getNumberOfHours = (daysArr) => {
    let hours = 0;
    daysArr?.map((item) => {
      let startTime = convertToHours(item.startTime);
      let endTime = convertToHours(item.endTime);

      // If end time is less than start time, it means the end time is on the next day
      if (endTime < startTime) {
        endTime.setDate(endTime.getDate() + 1); // Add one day to end time
      }

      const diffInMilliseconds = endTime - startTime;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      hours += diffInHours;
    });
    return hours;
  };

  const handleCancelApi = async (selected, desc) => {
    setLoading(true);
    let resp = null;
    if (role == "0")
      resp = await callCancelApiForEO(id, {
        description: desc,
        reason: selected,
      });
    else
      resp = await callCancelApiForSP(id, {
        description: desc,
        reason: selected,
      });
    setLoading(false);
    if (resp?.status == 200) {
      setCancelPopUp(false);
      dispatch(
        showModal({
          modalType: modalVariant.REQUEST_CANCEL_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            handleNavigation();
          },
        })
      );
    } else if (resp?.response?.status >= 400) {
      {
        dispatch(
          showToast({ msg: resp.response.data.message, color: "error" })
        );
      }
    }
  };

  const handleReportApi = async (selected, desc) => {
    setBtnLoading(true);
    let reportedUserId = "";
    if (role == "0")
      reportedUserId = serviceInfo?.requestedTo[0]?._id;
    else
      reportedUserId = serviceInfo?.requestedBy[0]?._id;
    let resp = await reportApi({
      serviceId: id,
      description: desc,
      reason: selected,
      reportedUser: reportedUserId,
    });
    if (resp?.status == 200) {
      setReportModal(false);
      dispatch(
        showModal({
          modalType: modalVariant.REPORT_SUCCESSFULL,
          resolveCallback: () => {
            dispatch(showModal(null));
          },
        })
      );

    }
    setBtnLoading(false);
  }

  const handleNavigation = () => {
    if (localStorage.getItem("role") == "0") navigate("/event-dashboard?id=1");
    else navigate("/booking-info?id=1");
  };

  const handleOtpInfo = async () => {
    setLoading(true);

    const resp = await verifyOtpSpApi(id, { otp, serviceId: id });
    if (resp?.status == 200) {
      setOpenOtpModal(false);
      setOtp("");
      dispatch(
        showModal({
          modalType: modalVariant.OTP_COMPLETE_SUCCESS,
          resolveCallback: () => {
            dispatch(showModal(null));
            navigate("/booking-info?id=3");
          },
        })
      );
    } else if (resp?.response?.status >= 400) {
      {
        setOtp("");
        dispatch(
          showToast({ msg: resp.response.data.message, color: "error" })
        );
      }
    }
    setLoading(false);
  };

  const handleCompleteApi = async () => {
    dispatch(
      showModal({
        modalType: modalVariant.GENERATE_OTP,
        resolveCallback: () => {
          dispatch(showModal(null));
        },
        rejectCallback: async () => {
          setLoading(true);
          dispatch(showModal(null));
          const resp = await generateOtpEoApi(id);
          setLoading(false);
          if (resp?.status == 200) {
            dispatch(
              showModal({
                modalType: modalVariant.OTP_SHARE_SUCCESS,
                resolveCallback: () => {
                  dispatch(showModal(null));
                  navigate("/event-dashboard?id=0");
                },
              })
            );
          }
        },
      })
    );
  };

  const handleClickMessage = async () => {
    let id = serviceInfo?.serviceId;
    const userId = role == "0" ? serviceInfo?.requestedTo[0]?._id : serviceInfo?.requestedBy[0]?._id;

    let val = await chatStatusApi(id, userId);
    const isDisabled = val?.data?.data?.isDisabled;
    if (!isDisabled || (serviceInfo?.status == "Completed")) {
      let value = role == "0" ?
        {
          firstName: serviceInfo?.requestedTo[0]?.firstName,
          lastName: serviceInfo?.requestedTo[0]?.lastName,
          profileImage: serviceInfo?.requestedTo[0]?.profileImage,
          _id: serviceInfo?.requestedTo[0]?._id,
          isDisabled: isDisabled
        } : {
          firstName: serviceInfo?.requestedBy[0]?.firstName,
          lastName: serviceInfo?.requestedBy[0]?.lastName,
          profileImage: serviceInfo?.requestedBy[0]?.profileImage,
          _id: serviceInfo?.requestedBy[0]?._id,
          isDisabled: isDisabled
        }
      navigate('/messages', {
        state: {
          val: value,
        },
      });
    }
    else {
      if (serviceInfo?.status == "Pending")
        dispatch(
          showToast({ msg: "Message service is not available since service request is yet to be accepted by Eventutti partner.", color: "error" })
        );
      else if (serviceInfo?.status == "Accepted" || serviceInfo?.status == "PaymentPending") {

        dispatch(
          showToast({ msg: "Message service is not available since payment is pending for booking confirmation.", color: "error" })
        );
      }

    }
  };

  const getBackgroundColor = (status) => {
    if (status == "Pending")
      return "rgba(213, 171, 25, 0.2)"
    else if (status == "OnGoing" || status == "Completed")
      return "rgba(23, 186, 174, 0.1)"
    else if (status == "UpComing")
      return "rgba(25, 134, 213, 0.1)"
    else
      return "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))"
  }

  const getColor = (status) => {
    if (status == "Pending")
      return "rgba(213, 171, 25, 1)"
    else if (status == "OnGoing" || status == "Completed")
      return "rgba(23, 186, 174, 1)"
    else if (status == "UpComing")
      return "rgba(25, 134, 213, 1)"
    else
      return "#fff"
  }

  const getIsFielldValid = (field) => {
    if (field?.value == 0)
      return false
    else if (field?.value && field?.label && field?.value != 0 && field?.label != "")
      return true;
    else
      return false
  }

  const callApi = async () => {
    setLoading(true);
    const resp = await callBookingConfirmationApi(id);

    if (resp?.data?.data?.length == 0 || resp?.data?.length == 0) {
      dispatch(showToast({ msg: "Service Expired", color: "error" }));
      navigate("/notifications")
    } else if (resp?.data?.data[0]?.status === "Cancelled" && location.state?.from === "notification" && role == "0") {
      navigate("/")
    }
    else {
      setServiceInfo({ ...resp?.data?.data[0] });
      setPhoneNumber(resp?.data?.data[0]?.requestedBy[0]?.phoneNumber.replace(/\s/g, ''))
      let navId = 2;
      if (resp?.data?.data[0]?.status == "UpComing") navId = 1;
      else if (resp?.data?.data[0]?.status == "OnGoing") navId = 0;
      else if (resp?.data?.data[0]?.status == "Completed") navId = 3;
      let val = resp?.data?.data[0]?.serviceDetails?.services?.filter((item) => { if (item?.isAdOnService) return item })
      let finalVal = val?.flatMap((item) => item?.categories);
      setAddOnCategories(finalVal);
      let serviceCat = resp?.data?.data[0]?.serviceDetails?.services?.filter((item) => { if (!item?.isAdOnService) return item });
      let serviceCat1 = serviceCat?.flatMap((item) => item?.categories);
      setServiceCategory(serviceCat1);
      dispatch(
        pushBreadCrums([
          {
            icon: role == "0" ? dashboardbreadcrum : bookingicon,
            name: role == "0" ? "Dashboard" : "My Bookings",
            path: role == "0" ? `/event-dashboard?id=2` : `/booking-info?id=2`,
          },
          {
            name:
              resp?.data?.data[0]?.status == "Pending" ||
                resp?.data?.data[0]?.status == "Accepted"
                ? "Request"
                : getTabName(resp?.data?.data[0]?.status),
            path:
              role == "0"
                ? `/event-dashboard?id=${navId}`
                : `/booking-info?id=${navId}`,
          },
          {
            name:
              role == "0"
                ? resp?.data?.data[0]?.serviceDetails?.serviceTitle
                : `${resp?.data?.data[0]?.requestedBy[0]?.firstName} ${resp?.data?.data[0]?.requestedBy[0]?.lastName}`,
          },
        ])
      );
    }
    setLoading(false);
  };

  const handleRejected = () => {
    dispatch(
      showModal({
        modalType: modalVariant.REJECT_BOOKING,
        resolveCallback: () => {
          dispatch(showModal(null));
          handleAcceptorReject("Rejected");
        },
        rejectCallback: () => {
          dispatch(showModal(null));
        },
      })
    );
  };

  const getTabName = (name) => {
    if (name == "OnGoing")
      return "Ongoing"
    else if (name == "UpComing")
      return "Upcoming"
    else
      return name
  }

  useEffect(() => {
    callApi();
  }, []);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        backgroundColor: "#ffff"
      }}
    >
      <CircularProgress />
    </Box>
  ) : !showRating ? (
    <Box sx={{
      padding: {
        xs: "15px 0",
        sm: "15px 0",
        md: "15px 0",
        lg: "15px 0",
        xl: "30px 0"
      }
    }}>
      <Breadcrumb />
      {serviceInfo &&
        <Box
          sx={{
            border: "1px solid #D9D9D9",
            borderRadius: "24px",
            height: { xs: "calc(100vh - 170px)", sm: "calc(100vh - 200px)", lg: "calc(100vh - 210px)", xl: "calc(100vh - 230px)" },
            padding: {
              xs: "15px",
              sm: "30px",
              md: "30px",
              lg: "30px",
              xl: "30px"
            },
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "calc(100vh - 270px)",
                sm: "calc(100vh - 315px)",
                md: "calc(100vh - 315px)",
                lg: "calc(100vh - 320px)",
                xl: "calc(100vh - 340px)",
              },
              overflowY: "auto",
            }}
          >
            {role == "1" ? (
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box sx={{ marginRight: "5px" }}>
                      <img alt=""
                        src={serviceInfo?.status == "Accepted" || serviceInfo?.status == "Pending" ? dummyprofilepic : serviceInfo?.requestedBy[0]?.profileImage?.url}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "block", sm: "flex" },
                        flexDirection: "column",
                        alignItems: "flexStart",
                      }}
                    >
                      <Box
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: { xs: "14px", sm: "18px" },
                          fontFamily: 'Montserrat,sans-serif'
                        }}
                      >
                        {" "}
                        {serviceInfo?.requestedBy[0]?.firstName
                          ? serviceInfo?.requestedBy[0]?.firstName
                          : ""}{" "}
                        {serviceInfo?.status != "Accepted" && serviceInfo?.status != "Pending" && serviceInfo?.requestedBy[0]?.lastName}

                      </Box>
                      <Box sx={{ color: "rgba(167, 167, 167, 1)" }}>
                        {serviceInfo?.serviceDetails?.serviceTitle}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: getColor(serviceInfo?.status),
                      background: getBackgroundColor(serviceInfo?.status),
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 20px",
                      height: "40px",
                      borderRadius: "29px",

                      fontWeight:
                        serviceInfo?.status == "OnGoing" || serviceInfo?.status == "UpComing" ? "bold" : "normal",
                    }}
                  >
                    {getTabName(serviceInfo?.status)}
                  </Box>
                </Box>
                <Box sx={{ display: { xs: "block", sm: "flex" } }}>
                  {serviceInfo?.status != "UpComing" && serviceInfo?.status != "Completed" && <Box
                    sx={{
                      color: "rgba(26, 26, 26, 1)",
                      marginRight: "2rem",
                      display: "flex",
                    }}
                  >
                    <img alt=""
                      src={calendargreenicon}
                      style={{
                        marginRight: "5px",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    {dayjs(serviceInfo?.startDate).format("MMMM DD YYYY ")} {serviceInfo?.startDate != serviceInfo?.endDate && <> {" "}-{" "} {dayjs(serviceInfo?.endDate).format("MMMM DD  YYYY")}</>}
                  </Box>}
                  {serviceInfo?.status != "Pending" && serviceInfo?.status != "UpComing" && serviceInfo?.status != "Completed" &&
                    serviceInfo?.status != "Accepted" &&
                    serviceInfo?.status != "Rejected" && <>
                      <span className="spanseperator" style={{ color: "rgba(167, 167, 167, 1)" }}>|</span>
                      <Box
                        sx={{
                          margin: { xs: "0px", sm: "0px 2rem" },
                          display: "flex",
                        }}
                      >
                        <img alt=""
                          src={emailicongreen}
                          style={{
                            marginRight: "5px",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {serviceInfo?.requestedBy[0]?.email
                          ? serviceInfo?.requestedBy[0]?.email
                          : ""}
                      </Box>
                      <span className="spanseperator" style={{ color: "rgba(167, 167, 167, 1)" }}>|</span>
                      <Box
                        sx={{
                          margin: { xs: "0px", md: "0px 2rem" },
                          display: "flex",
                        }}
                      >
                        <img alt=""
                          src={callgreen}
                          style={{
                            marginRight: "5px",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {serviceInfo?.requestedBy[0]?.countryCode
                          ? serviceInfo?.requestedBy[0]?.countryCode
                          : ""}
                        {serviceInfo?.requestedBy[0]?.phoneNumber
                          ? ` (${phoneNumber?.substring(0, 3)})-${phoneNumber?.substring(3, 6)}-${phoneNumber?.substring(6, 10)}`
                          : ""}

                      </Box>
                    </>}
                  {
                    (serviceInfo?.status == "UpComing" || serviceInfo?.status == "Completed") &&
                    <Box sx={{ display: "block" }} className="completedlineh">
                      <Box sx={{ display: "block" }}>
                        <Box sx={{
                          display: "flex", flexDirection: { xs: "column", sm: "row" }, gapX: {
                            xs: '10px',
                            sm: 0,
                            md: 0,
                            lg: 0,
                            xl: 0
                          }
                        }}>
                          <Box
                            sx={{
                              color: "rgba(26, 26, 26, 1)",
                              marginRight: "2rem",
                              display: "flex",
                              flexDirection: { xs: "row", sm: "row" }
                            }}
                          >
                            <img alt=""
                              src={calendargreenicon}
                              style={{
                                marginRight: "5px",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                            {dayjs(serviceInfo?.startDate).format("MMMM DD YYYY")}
                            {dayjs(serviceInfo?.startDate).format("MMMM DD YYYY") != dayjs(serviceInfo?.endDate).format("MMMM DD YYYY") && <>
                              {" "} - {" "}
                              {dayjs(serviceInfo?.endDate).format("MMMM DD YYYY")}</>}
                          </Box>{" "}
                          {/* <span className="spanseperator" style={{ color: "rgba(167, 167, 167, 1)", marginRight: "1rem" }}>|</span>
                          <Box sx={classes.locationdet}>
                            <img alt=""
                              src={locationicongreen}
                              style={{ marginRight: "10px" }}
                            />
                            {serviceInfo?.serviceDetails?.address},{" "}
                            {serviceInfo?.serviceDetails?.state},{" "}
                            {serviceInfo?.serviceDetails?.zipCode}
                          </Box> */}
                        </Box>
                      </Box>

                      <Box sx={{ display: "block", marginTop: "10px" }}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
                          <Box
                            sx={{
                              margin: { xs: "0px", md: "0px 2rem 0px 0px" },
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <img alt=""
                              src={emailicongreen}
                              style={{
                                marginRight: "5px",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                            {serviceInfo?.requestedBy[0]?.email
                              ? serviceInfo?.requestedBy[0]?.email
                              : ""}
                          </Box>
                          <span className="spanseperator" style={{ color: "rgba(167, 167, 167, 1)" }}>|</span>
                          <Box
                            sx={{
                              margin: { xs: "0px", md: "0px 2rem" },
                              display: "flex",
                            }}
                          >
                            <img alt=""
                              src={callgreen}
                              style={{
                                marginRight: "5px",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                            {serviceInfo?.requestedBy[0]?.countryCode
                              ? serviceInfo?.requestedBy[0]?.countryCode
                              : ""}
                            {serviceInfo?.requestedBy[0]?.phoneNumber
                              ? ` (${phoneNumber?.substring(0, 3)})-${phoneNumber?.substring(3, 6)}-${phoneNumber?.substring(6, 10)}`
                              : ""}

                          </Box>
                        </Box>
                      </Box>
                    </Box>

                  }
                </Box>
                {serviceInfo?.status != "Pending" && serviceInfo?.status != "Cancelled" && serviceInfo?.status != "Rejected" && <Box sx={{
                  display: "flex",
                  margin: "10px 0px"
                }} ><img src={locationicongreen}
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }} />{serviceInfo?.serviceDetails?.address},{serviceInfo?.serviceDetails?.city},{serviceInfo?.serviceDetails?.state},{serviceInfo?.serviceDetails?.zipCode}</Box>}
                <hr
                  style={{
                    margin: "20px 0px",
                    color: "rgba(217, 217, 217, 0.1)",
                  }}
                />
                <Box
                  sx={{
                    display: { xs: "block", sm: 'block', md: 'block', lg: "flex", xl: 'flex' },
                    justifyContent: "space-between",
                    height: { xs: "auto", sm: "auto", md: 'auto', lg: '53px', xl: '53px' },
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(23, 186, 174, 0.1)",
                      borderRadius: "23px",
                      display: { xs: "block", sm: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: {
                        xs: "15px 2rem",
                        sm: "15px 2rem",
                        md: "15px 2rem",
                        lg: "0 2rem",
                        xl: "0 2rem"
                      },
                      margin: {
                        xs: '5px 0',
                        sm: '5px 0',
                        md: '5px 0',
                        lg: 0,
                        xl: 0
                      },
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: 'fit-content',
                        xl: 'fit-content'
                      }
                    }}
                  >
                    <Box sx={{ color: "black", marginRight: "10px" }}>
                      Booking Id
                    </Box>

                    <Box sx={{ color: "black", fontWeight: "bold" }}>
                      {serviceInfo?._id}
                    </Box>
                  </Box>
                  {(serviceInfo?.status == "OnGoing" || serviceInfo?.status == "UpComing" || serviceInfo?.status == "Completed") && <Box
                    sx={{
                      backgroundColor: "rgba(23, 186, 174, 0.1)",
                      borderRadius: "23px",
                      display: { xs: "block", sm: "flex" },
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: {
                        xs: "15px 2rem",
                        sm: "15px 2rem",
                        md: "15px 2rem",
                        lg: "0 2rem",
                        xl: "0 2rem"
                      },
                      margin: {
                        xs: '5px 0',
                        sm: '5px 0',
                        md: '5px 0',
                        lg: 0,
                        xl: 0
                      },
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '30%',
                        xl: '30%'
                      }
                    }}
                  >
                    <Box sx={{ color: "black", marginRight: "10px" }}>
                      Payment type
                    </Box>

                    <Box sx={{ color: "black", fontWeight: "bold" }}>
                      Credit Card
                    </Box>
                  </Box>}
                  <Box
                    sx={{
                      backgroundColor: "rgba(23, 186, 174, 1)",
                      borderRadius: "23px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: {
                        xs: "15px 2rem",
                        sm: "15px 2rem",
                        md: "15px 2rem",
                        lg: "0 2rem",
                        xl: "0 2rem"
                      },
                      margin: {
                        xs: '5px 0',
                        sm: '5px 0',
                        md: '5px 0',
                        lg: 0,
                        xl: 0
                      },
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '30%',
                        xl: '30%'
                      }
                    }}
                  >
                    <Box sx={{ color: "#fff" }}>Total Amount</Box>
                    <Box sx={{ color: "#fff", fontWeight: "bold" }}>
                      {getCurrencyFormat(serviceInfo?.amountData?.serviceAmount + serviceInfo?.amountData?.adOnServiceAmount)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box sx={classes.firstinfo}>
                  <Box sx={classes.eventimage}>
                    <img alt=""
                      src={serviceInfo?.serviceDetails?.serviceImages[0]?.fileUrl}
                      style={{
                        borderRadius: "20px",
                        height: "100%",
                        width: "100%",
                        objectFit: 'cover'
                      }}
                    />
                  </Box>

                  <Box sx={classes.eventcontent}>
                    <Box
                      sx={{
                        ...classes.eventheading,
                        display: { xs: "block", sm: "flex" },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          marginBottom: { xs: "10px", sm: '10px', lg: "10px", xl: '20px', md: '10px' },
                          fontSize: { xs: "16px", sm: "18px", md: '18px', lg: '24px', xl: '40px' },
                          lineHeight: { xs: "18px", sm: "22px", md: '26px', lg: '30px', xl: '38px' },
                          fontFamily: 'Montserrat,sans-serif', fontWeight: 700,

                        }}
                      >
                        {" "}
                        {serviceInfo?.serviceDetails?.serviceTitle}
                      </Typography>
                      <Box
                        sx={classes.starinfo}
                        onClick={() => {
                          setShowRating((prev) => !prev);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <img alt="" src={Star} style={{ marginRight: "10px" }} />
                        {serviceInfo?.averageRating?.toFixed(1)}{" "}
                        ({serviceInfo?.totalRating ? serviceInfo?.totalRating : 0}{" "}
                        reviews)
                      </Box>
                    </Box>
                    {!((serviceInfo?.status == "Request" || serviceInfo?.status == "Pending" || serviceInfo?.status == "Accepted" || serviceInfo?.status == "Rejected") && serviceInfo.serviceDetails.serviceName == "Venues") &&
                      <>
                        <Box sx={classes.smallheading}>Location</Box>
                        <Box sx={classes.locationdet}>
                          <img alt=""
                            src={locationicongreen}
                            style={{ marginRight: "10px" }}
                          />
                          {serviceInfo?.serviceDetails?.address},{" "}
                          {serviceInfo?.serviceDetails?.state},{" "}
                          {serviceInfo?.serviceDetails?.zipCode}
                        </Box>
                      </>}

                    <Box sx={classes.emailcallinfo}>
                      <Box sx={classes.emailinfo}>
                        <Box sx={classes.smallheading}>Event Date(s)</Box>
                        <Box sx={classes.locationdet}>
                          <img alt=""
                            src={timinggreen}
                            style={{ marginRight: "10px" }}
                          />
                          {dayjs(serviceInfo?.startDate).format("MMMM DD YYYY")}

                          {dayjs(serviceInfo?.startDate).format("MMMM DD YYYY") != dayjs(serviceInfo?.endDate).format("MMMM DD YYYY") && <>
                            {" "}  - {" "}
                            {dayjs(serviceInfo?.endDate).format("MMMM DD YYYY")}
                          </>}
                        </Box>
                      </Box>
                      {serviceInfo?.status != "Pending" &&
                        serviceInfo?.status != "Accepted" &&
                        serviceInfo?.status != "Rejected" && (
                          <Box sx={classes.callinfo}>
                            <Box sx={classes.smallheading}>Email Id</Box>
                            <Box sx={classes.locationdet}>
                              <img alt=""
                                src={emailicongreen}
                                style={{ marginRight: "10px" }}
                              />
                              {serviceInfo?.requestedTo[0]?.email
                                ? serviceInfo?.requestedTo[0]?.email
                                : ""}
                            </Box>
                          </Box>
                        )}
                    </Box>
                    <Box sx={classes.emailcallinfo}>
                      {serviceInfo?.status != "Pending" &&
                        serviceInfo?.status != "Accepted" &&
                        serviceInfo?.status != "Rejected" && (
                          <Box sx={classes.emailinfo}>
                            <Box sx={classes.smallheading}>Phone Number</Box>
                            <Box sx={classes.locationdet}>
                              <img alt=""
                                src={callgreen}
                                style={{ marginRight: "10px" }}
                              />{" "}
                              {serviceInfo?.requestedTo[0]?.countryCode
                                ? serviceInfo?.requestedTo[0]?.countryCode
                                : ""}
                              {serviceInfo?.requestedTo[0]?.phoneNumber
                                ? ` (${serviceInfo?.requestedTo[0]?.phoneNumber?.substring(0, 3)})-${serviceInfo?.requestedTo[0]?.phoneNumber?.substring(3, 6)}-${serviceInfo?.requestedTo[0]?.phoneNumber?.substring(6, 10)}`
                                : ""}
                            </Box>
                          </Box>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  fontWeight="700"
                  margin="20px 0 10px"
                  sx={{
                    fontFamily: 'Montserrat,sans-serif', fontSize: {
                      xs: '16px', sm: '18px', md: '18px', lg: '20px', xl: '20px', color: '#000000', lineHeight: '26px'
                    }
                  }}
                >
                  Event Description
                </Typography>
                <Box sx={{ ...classes.para, fontSize: "14px" }}>
                  {serviceInfo?.serviceDetails?.description.split("\n").map((item) => { return <p key={item}>{item}</p> })}
                </Box>
              </Box>
            )}

            <Typography
              variant="h6"
              fontWeight="700"
              margin="20px 0 10px"
              sx={{
                fontFamily: 'Montserrat,sans-serif', fontSize: {
                  xs: '16px', sm: '18px', md: '18px', lg: '20px', xl: '20px', color: '#000000', lineHeight: '26px'
                }
              }}
            >
              Service Category
            </Typography>

            <Grid
              container
              style={{ width: "100%", display: "flex" }}
              sx={classes.catinfo}
            >
              {serviceCategory?.map(
                (item) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} lg={5.6} xl={5.6} sx={classes.catinfo1} key={item?.categoryId?._id}>
                      <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>Service Category</Box>
                        <Box sx={classes.catright}>
                          {item?.categoryId?.categoryName}
                        </Box>
                      </Box>
                      {(item?.field1.value == "Per Day" ||
                        item?.field1.value == "Per Person") && (
                          <Box sx={classes.catmain}>
                            <Box sx={classes.catleft}>Selected Dates</Box>
                            <Box sx={classes.catright}>
                              <Box sx={{ display: "flex" }}>
                                {item?.field6?.value.map((dateitem, index) => {
                                  return (
                                    <Box style={{ marginLeft: "5px" }} key={dateitem}>
                                      {dayjs(dateitem?.date)?.format("MMM DD")}
                                      {item?.field6?.value?.length - 1 != index &&
                                        ","}
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      {(item?.field1.value == "Hourly" ||
                        item?.field1.value == "Fixed" ||
                        item?.field1.value == "Fixed Cost") && (
                          <Box sx={classes.catmain}>
                            <Box sx={classes.catleft}>Selected Dates</Box>
                            <Box sx={classes.catright}>
                              <Box sx={{ display: "block" }}>
                                {item?.field6?.value?.map((dateitem, index) => {
                                  return (
                                    <Box key={dateitem}>{`${dayjs(dateitem?.date).format("MMM DD")} From: ${convertTo12HourFormat(dateitem?.startTime?.substring(0, 5))} - ${convertTo12HourFormat(dateitem?.endTime?.substring(0, 5))}`}</Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      {item?.field1.value == "Hourly" && (
                        <Box sx={classes.catmain}>
                          <Box sx={classes.catleft}>Total Number of Hours</Box>
                          <Box sx={classes.catright}>
                            {getNumberOfHours(item?.field6?.value)}
                          </Box>
                        </Box>
                      )}
                      {item?.field1.value == "Per Day" && (
                        <Box sx={classes.catmain}>
                          <Box sx={classes.catleft}>{item?.field7?.label}</Box>
                          <Box sx={classes.catright}>{item.field7?.value}</Box>
                        </Box>
                      )}
                      {item?.field3?.label && item?.field3?.value && item?.field3?.value != 0 && (
                        <Box sx={classes.catmain}>
                          <Box sx={classes.catleft}>{item?.field3?.label}</Box>
                          <Box sx={classes.catright}>{item?.field3?.value}</Box>
                        </Box>
                      )}
                      <hr
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                          height: "1px",
                          border: "none",
                        }}
                      />
                      <Box sx={classes.catmain}>
                        <Box
                          sx={classes.catleft}
                        >{`Service fee (${item?.field1?.value})`}</Box>
                        <Box sx={classes.catright}>{getCurrencyFormat(item?.field2?.value)}</Box>
                      </Box>
                      <hr
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                          height: "1px",
                          border: "none",
                        }}
                      />
                      <Box sx={classes.catmain}>
                        <Typography
                          variant="body"
                          fontWeight="bold"
                          fontFamily="Montserrat"
                        >
                          Total Amount
                        </Typography>
                        <Typography
                          variant="body"
                          fontWeight="bold"
                          fontFamily="Montserrat"
                        >


                          {getTotalAmount(
                            item?.field1?.value,
                            item?.field2?.value,
                            item?.field3?.value,
                            item?.field6?.value,
                            item?.field7?.value
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
            {addOnCategories?.length > 0 && (
              <Typography variant="h5" fontWeight="700"
                margin="20px 0 10px"
                sx={{
                  fontFamily: 'Montserrat,sans-serif', fontSize: {
                    xs: '16px', sm: '18px', md: '18px', lg: '20px', xl: '20px', color: '#000000', lineHeight: '26px'
                  }
                }}>
                Add-On Services
              </Typography>
            )}
            <Grid
              container
              style={{ width: "100%", display: "flex" }}
              sx={classes.catinfo}
            >
              {addOnCategories?.map((item) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={5.6} xl={5.6} sx={classes.catinfo1} key={item}>
                    <Box sx={classes.catmain}>
                      <Box sx={classes.catleft}>Service Category</Box>
                      <Box sx={classes.catright}>
                        {item?.categoryId?.categoryName}
                      </Box>
                    </Box>
                    {(item?.field1?.value == "Per Person" ||
                      item?.field1?.value == "Per Day") && (
                        <Box sx={classes.catmain}>
                          <Box sx={classes.catleft}>Selected Dates</Box>
                          <Box sx={classes.catright}>
                            <Box sx={{ display: "flex" }}>
                              {item?.field6?.value.map((dateitem, index) => {
                                return (
                                  <Box style={{ marginLeft: "5px" }} key={dateitem}>
                                    {dayjs(dateitem?.date)?.format("MMM DD")}
                                    {item?.field6?.value?.length - 1 != index &&
                                      ","}
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    {(item?.field1.value == "Hourly" ||
                      item?.field1.value == "Fixed" ||
                      item?.field1.value == "Fixed Cost") && (
                        <Box sx={classes.catmain}>
                          <Box sx={classes.catleft}>Selected Dates</Box>
                          <Box sx={classes.catright}>
                            <Box sx={{ display: "block" }}>
                              {item?.field6?.value?.map((dateitem, index) => {
                                return (
                                  <Box key={dateitem}>{`${dayjs(dateitem?.date).format("MMM DD")} From: ${convertTo12HourFormat(dateitem?.startTime?.substring(0, 5))} - ${convertTo12HourFormat(dateitem?.endTime?.substring(0, 5))}`}</Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    {item?.field1.value == "Hourly" && (
                      <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>Total Number of Hours</Box>
                        <Box sx={classes.catright}>
                          {getNumberOfHours(item?.field6?.value)}
                        </Box>
                      </Box>
                    )}
                    {getIsFielldValid(item?.field3) && (
                      <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>{item?.field3?.label}</Box>
                        <Box sx={classes.catright}>{item.field3?.value}</Box>
                      </Box>
                    )}
                    {item?.field1.value == "Per Day" && (
                      <Box sx={classes.catmain}>
                        <Box sx={classes.catleft}>{item?.field7?.label}</Box>
                        <Box sx={classes.catright}>{item.field7?.value}</Box>
                      </Box>
                    )}
                    <hr
                      style={{
                        backgroundColor: "rgba(217, 217, 217, 1)",
                        height: "1px",
                        border: "none",
                      }}
                    />
                    <Box sx={classes.catmain}>
                      <Box
                        sx={classes.catleft}
                      >{`Service fee (${item.field1?.value})`}</Box>
                      <Box sx={classes.catright}>{getCurrencyFormat(item?.field2?.value)}</Box>
                    </Box>
                    <hr
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        height: "1px",
                        border: "none",
                      }}
                    />
                    <Box sx={classes.catmain}>
                      <Typography
                        variant="body"
                        fontWeight="bold"
                        fontFamily="Montserrat"
                      >
                        Total Amount
                      </Typography>
                      <Typography
                        variant="body"
                        fontFamily="Montserrat"
                        fontWeight="bold"
                      >

                        {getTotalAmount(
                          item?.field1?.value,
                          item?.field2?.value,
                          item?.field3?.value,
                          item?.field6?.value,
                          item?.field7?.value
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {role == "0" && (
              <Box
                sx={{
                  backgroundColor: "rgba(23, 186, 174, 1)",
                  borderRadius: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: { xs: "calc(100% - 20px)", sm: "calc(100% - 20px)", md: 'calc(100% - 20px)', lg: '46%' },
                  alignItems: "center",
                  height: "45px",
                  padding: "0px 1rem",
                  margin: "10px",
                }}
              >
                <Box sx={{ color: "#fff", fontFamily: 'Montserrat,sans-serif' }}>Total Amount</Box>
                <Box sx={{ color: "#fff", fontWeight: "bold", fontFamily: 'Montserrat,sans-serif' }}>
                  {getCurrencyFormat(serviceInfo?.amountData?.serviceAmount + serviceInfo?.amountData?.adOnServiceAmount)}  
                </Box>
              </Box>
            )}
            <Box sx={{ fontSize: "16px", color: "rgba(0,0,0,0.6)", margin: "10px 0", fontFamily: 'Montserrat,sans-serif' }}>
              {serviceInfo?.status == "Cancelled" ? cancelPolicy[serviceInfo?.serviceDetails?.cancellationPolicy] :
                "By clicking the button below, I agree to Eventutti's Terms & Conditions,Privacy Policy, and Eventutti Partner Refund Policy. I also authorize Eventutti to charge my payment method."}
            </Box>
            <Box
              sx={{
                fontSize: "18px",
                color: "rgba(0,0,0,1)",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                fontFamily: 'Montserrat,sans-serif'
              }}

            >
              <span> Having a Problem? <span style={{ borderBottom: "1px solid black", cursor: "pointer" }} onClick={() => { setReportModal(true) }}>Raise a ticket</span></span>
            </Box>
          </Box>
          {role == "1" ? (
            <>
              {serviceInfo?.status == "Pending" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                    fontFamily: 'Montserrat,sans-serif'
                  }}
                >
                  <Box sx={{ marginRight: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <ButtonComp
                      label="Reject"
                      type="whitebtn"
                      width="100%"
                      onClick={() => {
                        handleRejected();
                      }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <ButtonComp
                      label="Accept"
                      type="active"
                      width="100%"
                      onClick={() => {
                        handleAcceptorReject("Accepted");
                      }}
                    />
                  </Box>
                </Box>
              )}
              {serviceInfo?.status == "UpComing" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                  }}
                >
                  <Box sx={{ marginRight: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <Box
                      sx={classes.whitebtn}
                      onClick={() => {
                        setCancelPopUp(true);
                      }}
                    >
                      Cancel
                      <img alt=""
                        style={{
                          marginLeft: "4px",
                          width: "20px",
                          height: "20px",
                          objectFit: "contain",
                        }}
                        src={canceliconbooking}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ marginLeft: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <Box sx={classes.successButton} onClick={() => { handleClickMessage(); }}>Message</Box>
                  </Box>
                </Box>
              )}
              {serviceInfo?.status == "Accepted" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                  }}
                >
                  <Box sx={{ marginRight: "2%", width: { xs: "47%", sm: "200px" }, }}></Box>
                  <Box sx={{ marginRight: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <ButtonComp label="Message" type="active" width="100%" onClick={() => { handleClickMessage() }} />
                  </Box>
                </Box>
              )}
              {serviceInfo?.status == "OnGoing" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "2%",
                      width: { xs: "47%", sm: "200px" },
                    }}
                  >
                    <ButtonComp type="whitebtn" label="Message" width="100%" onClick={() => { handleClickMessage() }} />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "2",
                      width: { xs: "47%", sm: "200px" },
                    }}
                  >
                    <ButtonComp
                      type="active"
                      label="Complete"
                      width="100%"
                      onClick={() => {
                        setOpenOtpModal(true);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              {(serviceInfo?.status == "Pending" ||
                serviceInfo?.status == "UpComing" ||
                serviceInfo?.status == "Accepted") && (
                  <Box
                    sx={{
                      display: "flex",
                      float: { xs: "auto", sm: "right" },
                      paddingTop: "10px",
                      flexWrap: { xs: "wrap", sm: "nowrap" },
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: "2%",
                        width: { xs: "47%", sm: "200px" },
                      }}
                    >
                      <Box
                        sx={classes.whitebtn}
                        onClick={() => {
                          serviceInfo?.status == "UpComing"
                            ? setCancelPopUp(true)
                            : cancelBookingApi(serviceInfo?.status);
                        }}
                      >
                        Cancel
                        {serviceInfo?.status == "UpComing" && (
                          <img alt=""
                            style={{
                              marginLeft: "4px",
                              width: "20px",
                              height: "20px",
                              objectFit: "contain",
                            }}
                            src={canceliconbooking}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2%",
                        width: { xs: "47%", sm: "200px" },
                      }}
                    >
                      <Box
                        sx={
                          serviceInfo?.status == "Accepted"
                            ? classes.whitebtn
                            : classes.successButton
                        }
                        onClick={() => { handleClickMessage(); }}
                      >
                        Message
                      </Box>
                    </Box>
                    {serviceInfo?.status == "Accepted" && (
                      <Box
                        sx={{
                          marginRight: "2%",
                          width: { xs: "47%", sm: "200px" },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenPaymentInfoModal(true);
                        }}
                      >
                        <Box
                          sx={{
                            marginLeft: "2%",
                            width: { xs: "47%", sm: "200px" },
                          }}
                        >
                          <Box sx={classes.successButton}>Complete Payment</Box>
                          <></>{" "}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              {serviceInfo?.status == "OnGoing" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "2%",
                      width: { xs: "47%", sm: "200px" },
                    }}
                  >
                    <ButtonComp type="whitebtn" label="Message" width="100%" onClick={() => { handleClickMessage() }} />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "2%",
                      width: { xs: "47%", sm: "200px" },
                    }}
                  >
                    <ButtonComp
                      type="active"
                      label="Complete"
                      width="100%"
                      onClick={() => {
                        handleCompleteApi();
                      }}
                    />
                  </Box>
                </Box>
              )}
              {serviceInfo?.status == "Completed" && (
                <Box
                  sx={{
                    display: "flex",
                    float: { xs: "auto", sm: "right" },
                    paddingTop: "10px",
                  }}
                >
                  <Box sx={{ marginRight: "2%", width: { xs: "47%", sm: "200px" }, }}>
                    <ButtonComp label="Message" type="whitebtn" width="100%" onClick={() => { handleClickMessage() }} />
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "2%",
                      width: { xs: "47%", sm: "200px" },
                    }}
                  >
                    <ButtonComp
                      type={
                        isReviewAdded || serviceInfo?.isReviewAdded
                          ? "disabled"
                          : "active"
                      }
                      disabled={
                        isReviewAdded || serviceInfo?.isReviewAdded ? true : false
                      }
                      label="Review"
                      width="100%"
                      onClick={() => {
                        setReviewModal(true);
                      }}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>}

      {openPaymentInfoModal && (
        <PaymentInfoModal
          openPaymentInfoModal={openPaymentInfoModal}
          setOpenPaymentInfoModal={setOpenPaymentInfoModal}
          finalData={serviceInfo?.amountData}
          bookingId={serviceInfo?._id}
          fromRequest={true}
          setOpenCardModal={setOpenCardModal}
        />
      )}
      {openCardModal && (
        <AddCardModal
          openCardModal={openCardModal}
          setOpenPaymentInfoModal={setOpenPaymentInfoModal}
          setOpenCardModal={setOpenCardModal}
        />
      )}
      {cancelPopUp && (
        <CancelPolicyModal
          btnloading={btnloading}
          cancelPopUp={cancelPopUp}
          setCancelPopUp={setCancelPopUp}
          showCancelInfo={showCancelInfo}
          setShowCancelInfo={setShowCancelInfo}
          handleCancelApi={handleCancelApi}
          cancellationPolicy={serviceInfo?.serviceDetails?.cancellationPolicy}
        />
      )}
      {reportModal && (
        <CancelPolicyModal
          btnloading={btnloading}
          cancelPopUp={reportModal}
          setCancelPopUp={setReportModal}
          showCancelInfo={false}
          setShowCancelInfo={setShowCancelInfo}
          handleReportApi={handleReportApi}
          isReport={true}
        />
      )}
      {openOtpModal && (
        <ShareOtpPopUp
          openOtpModal={openOtpModal}
          setOpenOtpModal={setOpenOtpModal}
          setOtp={setOtp}
          otp={otp}
          handleOtpInfo={handleOtpInfo}
        />
      )}
      {reviewModal && (
        <AddReviewModal
          reviewModal={reviewModal}
          setReviewModal={setReviewModal}
          id={id}
          setIsReviewAdded={setIsReviewAdded}
          callApi={callApi}
        />
      )}
    </Box>
  ) : (
    <ViewRating
      userId={serviceInfo?.requestedTo[0]._id}
      setShowRating={setShowRating}
    />
  );
};
const RequestInfo = () => {
  return (
    <NavBar>
      <Container sx={{
        padding: {
          xs: '0 15px',
          sm: '0 30px',
          md: '0 30px',
          lg: '0 30px',
          xl: 0
        }
      }} maxWidth="xl">
        <RequestDetails />
      </Container>
    </NavBar>
  );
};

export default RequestInfo;
