import { createSlice } from "@reduxjs/toolkit";

const bookserviceinfoSlice= createSlice({
    name: "bookserviceinfo",
    initialState: {
        startDate:null,
        endDate:null,
        finalBookServiceInfo:null,
        pageNum:1,
        addOnCategoryIds:[],
        maxQuantityCountArr:[],
        addonMaxQuantityCountArr:[],
        serviceCategoryIds:[],
        serviceInfo:null
    },
    reducers: {
        setBookServiceDates: (state, param) => {
          const { payload } = param;
          state.startDate = payload.startDate;
          state.endDate=payload.endDate;
        },
        setFinalBookServiceInfo:(state,param)=>{
            const {payload}=param;
            state.finalBookServiceInfo=payload
        },
        setPageNum:(state,param)=>{
            const {payload}=param;
            state.pageNum=payload
        },
        setAddOnCategoryIds:(state,param)=>{
            const {payload}=param;
            state.addOnCategoryIds=payload
        },
        setMaxQuantityCountArr:(state,param)=>{
            const {payload}=param;
            state.maxQuantityCountArr=payload
        },
        setAddonMaxQuantityCountArr:(state,param)=>{
            const {payload}=param;
            state.addonMaxQuantityCountArr=payload
        },
        setSelectedCategoryIds:(state,param)=>{
            const {payload}=param;
            state.serviceCategoryIds=[...payload]
            },
            setServiceInfo:(state,param)=>{
                const {payload}=param;
                state.serviceInfo={...payload}
                }
    }

})
const { actions, reducer } = bookserviceinfoSlice;
export const {
    setBookServiceDates,setFinalBookServiceInfo,
    setPageNum,setAddOnCategoryIds,setMaxQuantityCountArr,setAddonMaxQuantityCountArr,setSelectedCategoryIds,setServiceInfo
}=actions
export default reducer
