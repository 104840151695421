
export const cancelPolicy = {
  Flexible:
    "Full refund for cancellations made for at least 7 days before the event date, Refund 50% for cancellations made between 3-6 days before the event",
  Moderate:
    "Full refund for cancellations made for at least 14 days before the event date, Refund 50% for cancellations made between 7-13 days before the event. No refund for cancellations made for 6 days or less before the event",
  "Strict":
    "Refund 50% for cancellations made for at least 21 days before the event date, No refund for cancellations made 20 days or less before the event.",
  "Very Strict":
    "Refund 50% for cancellations made for at least 30 days before the event date, No refund for cancellations made 29 days or less before the event",
};


export const mapContainerStyle = {
  width: "95%",
  height: " 26vh",
  border: "1px solid rgba(0, 0, 0, 0.10)",
  borderRadius: "1.25rem",
  background: "#fff",
  margin: "auto",
};
export const mapOptions = {
  maxZoom: 15,
  minZoom: 1,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  keyboardShortcuts: false,
  disableDefaultUI: true,
  zoomControlOptions: { position: 9 },
  styles: [
    {
      elementType: "geometry",
      stylers: [{ color: "#FFFFFF", border: "none" }],
    },
    { elementType: "labels.text.stroke", stylers: [{ color: "black" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "black" }] },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#F4F4F4" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#F4F4F4" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [{ color: "#F4F4F4" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "black" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#F4D2C5" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry.stroke",
      stylers: [{ color: "#F4F4F4" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#F4D2C5" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "black" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#EAF6F8" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "black" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#EAF6F8" }],
    },
    {
      featureType: "poi.park",
      stylers: [{ color: "#E5F3D6" }],
    },
  ],
};

export function convertTo12HourFormat(time24) {
  let [hours, minutes] = time24.split(':').map(Number);
  let suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${suffix}`;
}



export function handleReload() {
  window.location.reload();
}

export function getCurrencyFormat(value) {
  if (typeof value === 'number' || typeof value === 'string') {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return 'Invalid number';
    }
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  } else {
    return 'Invalid input';
  }

}