import React, { useState, useEffect } from "react";

import ButtonComp from "../../../../component/Button";
import CustomTextField from "../../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import radioicongreen from "../../../../assets/images/radioicongreen.png";
import tickcircleicon from "../../../../assets/images/tickcircleicon.png";
import blackcrossicon from ".././../../../assets/images/blackcrossicon.png";
import { getSx } from "../style";

const AddAddOnSubInfo = ({
  setPage,
  addonsubCategory,
  setaddonSubCategory,
  isAddOn,
  handleEditSubInfo,
  isEdit
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(addonsubCategory?.length).fill(false)
  );
  const [isEnableBtn, setIsEnableBtn] = useState(false);
  const classes = getSx();
  const isAllTrue = (array) => {
    return array.every((element) => {
      return !!element;
    });
  };
  const handleEnableFunc = () => {
    let isEnable = Array(addonsubCategory?.length).fill(false);
    if (addonsubCategory?.length < 1) setIsEnableBtn(false);
    else {
      for (let i = 0; i < addonsubCategory?.length; i++) {
        if (addonsubCategory[i]?.field3?.label || addonsubCategory[i]?.isAdditionalInfoRequired) {
          if (
            addonsubCategory[i]?.field1?.label &&
            addonsubCategory[i]?.field1?.value &&
            addonsubCategory[i]?.field2?.label &&
            addonsubCategory[i]?.field2?.value &&
            addonsubCategory[i]?.field3?.label &&
            addonsubCategory[i]?.field3?.value
          )
            isEnable[i] = true;
        } else if (
            addonsubCategory[i]?.field1?.label &&
            addonsubCategory[i]?.field1?.value &&
            addonsubCategory[i]?.field2?.label &&
            addonsubCategory[i]?.field2?.value
        )
        {
          isEnable[i] = true;
        }
      }
      let val = isAllTrue(isEnable);
      setIsEnableBtn(val);
    }
  };
  const handleDelSubCategory = (val, index) => {
    let filteredServiceCat = addonsubCategory.filter(
      (item, i) =>i!=index
    );
    setaddonSubCategory(filteredServiceCat)
  };
  const feeType = ["Per Day", "Hourly", "Per Person"];
  const handleClick = () => {
    if(isAddOn)
   setPage((prev)=>prev+1)
  else
  {
  setPage(1)
  handleEditSubInfo(1);
  }
  };
  const handleDropDown = (index) => {
    const updatedDropdownOpen = [...dropdownOpen];
    updatedDropdownOpen[index] = !updatedDropdownOpen[index];
    setDropdownOpen(updatedDropdownOpen);
  };
  const handleWheel = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };
  const handleChangeInfo = (index, field, val, label) => {
    const categoriesCopy = [...addonsubCategory]; // Copy addonSubCategory array
    let categoryToUpdate = categoriesCopy[index]; // Get the category object at the specified index

    if (!categoryToUpdate || !categoryToUpdate?.field1) {
      categoryToUpdate = {...addonsubCategory[index],categoryId:addonsubCategory[index]}
      categoriesCopy[index] = categoryToUpdate; // Update the category object at the specified index in the copy
    }
    // Update the fields based on the 'field' parameter
    if (field === "field1") {
      
      categoryToUpdate.field1 = { label: label, value: val };
    } else if (field === "field2") {
      categoryToUpdate.field2 = { label: label, value: val};
    } else if (field === "field3") {
      categoryToUpdate.field3 = { label: label, value: val };
    }
    categoriesCopy[index] = categoryToUpdate;
    setaddonSubCategory(categoriesCopy);
    handleEnableFunc();
  };
  useEffect(() => {
    handleEnableFunc();
  }, [addonsubCategory]);
  return (
    <Box>
      <Box
        sx={{ height: {
              xs: "calc(100vh - 300px)",
              sm:'calc(100vh - 300px)',
              md:'calc(100vh - 250px)',
              lg:'calc(100vh - 350px)',
              xl: "calc(100vh - 400px)"
            }, overflow: "auto" }}
      >
        {addonsubCategory?.map((item, index) => {
          return (
            <Box sx={classes.subcatinfo} key={index}>
              <Box sx={classes.singlebox}>
                <Box sx={classes.headinfo}>
                  <Box className="flexbox">
                    <Typography variant="h6" fontWeight="bold" sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"Montserrat, sans-serif",display:'inline-block',
              whiteSpace:'nowrap',
              textOverflow:'ellipsis',
                                            width:'100%',
                                            overflow:'auto'}}>
                      {item?.categoryName }
                    </Typography>
                    <img alt=""
                      src={blackcrossicon}
                      style={{width:"30px",height:"30px"}}
                      onClick={() => {
                        handleDelSubCategory(item?._id, index);
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily:"Montserrat, sans-serif",
                    margin:'12px 0',
                    fontSize:'16px',
                  }}
                >
                  Service Fee Type
                </Typography>
                <Box
                  onClick={() => {
                    handleDropDown(index);
                  }}
                >
                  <CustomTextField
                    variant="outlined"
                    placeholder="Select Service Fee Type"
                    type="dropdown"
                    backgroundColor="rgba(246, 245, 245, 1);"
                    readOnly={true}
                    value={addonsubCategory[index]?.field1?.value}
                    className="dropdownselection"
                  />
                </Box>
                
                {dropdownOpen[index] && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      height: "165px",
                      width: "93%",
                      zIndex: "23",
                      padding: "15px",
                      border: "1px solid rgba(63, 73, 88, 0.1)",
                      borderRadius: "15px",
                      marginTop: "5px",
                    }}
                  >
                    {feeType.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "5px",
                            fontWeight: "500",
                          }}
                          key={index}
                          onClick={() => {
                            handleChangeInfo(
                              index,
                              "field1",
                              item,
                              "serviceFeeType"
                            );
                            handleDropDown(index);
                          }}
                        >
                          <Box>{item}</Box>
                          <Box>
                            <img alt=""
                              src={
                                addonsubCategory[index]?.field1?.value ==
                                item
                                  ? tickcircleicon
                                  : radioicongreen
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
                <Typography variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily:"Montserrat, sans-serif",
                    margin:'12px 0',
                    fontSize:'16px',
                  }}>
                  Service Fee
                </Typography>
                <CustomTextField
                  variant="outlined"
                  placeholder="Enter Service Fee"
                  backgroundColor="rgba(246, 245, 245, 1);"
                  type="number"
                  dolarFor="number"
                  isNum={true}
                  maxLength={15}
                  onChange={(e) => {
                    let val=e.target.value;
                    if(val.match(/^[\d.].*$/) && val.length>0 && !val.match(/^00/))
                    handleChangeInfo(
                      index,
                      "field2",
                      e.target.value,
                      "serviceFee"
                    );
                    else
                    handleChangeInfo(
                      index,
                      "field2",
                     "",
                      "serviceFee"
                    );

                  }}
                  name="servicefee"
                  value={addonsubCategory[index]?.field2?.value}
                  onWheel={handleWheel}
                  onKeyPress={(e)=>{
                    let value=e.target.value;
                    if (value.includes('.')) {
                      const parts = value.split('.');
                      if (parts[1].length > 1) {
                        e.preventDefault()
                      }
                    }
                  }
                }
                  
                />
                {addonsubCategory[index]?.field3?.label && !addonsubCategory[index]?.additionalFieldLabel   && (
                  <>
                    {" "}
                    <Typography variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily:"Montserrat, sans-serif",
                    margin:'12px 0',
                    fontSize:'16px',
                  }} >
                      {addonsubCategory[index]?.field3?.label}
                    </Typography>
                    <CustomTextField
                      variant="outlined"
                      placeholder={`${addonsubCategory[index]?.field3?.label}`}
                      backgroundColor="rgba(246, 245, 245, 1);"
                      type="number"
                      allowdot={true}
                      maxLength={15}
                      name="fee"
                      isNum={true}
                      onChange={(e) => {
                        let val=e.target.value;
                        if(val.match(/^[\d.].*$/) && val.length>0 && !val.match(/^00/))
                        handleChangeInfo(
                          index,
                          "field3",
                          e.target.value,
                          addonsubCategory[index]?.field3?.label
                        );
                        else
                        handleChangeInfo(
                          index,
                          "field3",
                          "",
                          addonsubCategory[index]?.field3?.label
                        );
                      }}
                      value={addonsubCategory[index]?.field3?.value}
                      onWheel={handleWheel}
                    />
                  </>
                )}
                {
                   addonsubCategory[index]?.isAdditionalInfoRequired  &&(
                    <>
                    {" "}
                    <Typography variant="body1"
                  fontWeight="bold"
                  sx={{
                    fontFamily:"Montserrat, sans-serif",
                    margin:'12px 0',
                    fontSize:'16px',
                  }}>
                      {addonsubCategory[index]?.additionalFieldLabel}
                    </Typography>
                    <CustomTextField
                      variant="outlined"
                      placeholder={`${addonsubCategory[index]?.additionalFieldLabel}`}
                      backgroundColor="rgba(246, 245, 245, 1);"
                      type="number"
                      allowdot={true}
                      isNum={true}
                      maxLength={15}
                      name="fee"
                      onChange={(e) => {
                        let val=e.target.value;
                        if(val.match(/^[\d.].*$/) && val.length>0 && !val.match(/^00/))
                        handleChangeInfo(
                          index,
                          "field3",
                          e.target.value,
                          addonsubCategory[index]?.additionalFieldLabel
                        );
                        else
                        handleChangeInfo(
                          index,
                          "field3",
                          "",
                          addonsubCategory[index]?.additionalFieldLabel
                        );
                      }}
                      value={addonsubCategory[index]?.field3?.value}
                      onWheel={handleWheel}
                    />
                  </>

                   )
                }
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ marginTop: "15px" }}>
        <ButtonComp
          label={isAddOn?"Next":"Update"}
          type={isEnableBtn ? "active" : "disabled"}
          disabled={!isEnableBtn}
          onClick={handleClick}
          style={{height:'60px', fontFamily:"Montserrat, sans-serif", fontSize:'16px',width:'100%',fontWeight:600}}
        />
      </Box>
    </Box>
  );
};

export default AddAddOnSubInfo;
