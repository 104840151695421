import axios from "axios";

import { getHeaders } from "../get-headers";

import { store } from "../../store/index";
import { showToast } from "../../store/slices/common";

const dispatch = store.dispatch;

let instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([409].includes(error.response?.status)) {
      dispatch(
        showToast({
          msg: error.response?.data?.message || "An error occurred",
          color: "error",
        })
      );
      localStorage.clear();
    }
    else{
    dispatch(
      showToast({
        msg: error.response?.data?.message || "Something went wrong!",
        color: "error",
      })
    );
  }
    return Promise.reject(error);
  }
);

export default instance;
