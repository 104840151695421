import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modalVariant } from "../../../component/Modal/Modalutils";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { Box, Rating } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { getSx } from "./style";
import { addFavApi, removeFavApi } from "../../../api/profile";
import {
    setAddOnCategoryIds,
    setAddonMaxQuantityCountArr,
    setBookServiceDates,
    setFinalBookServiceInfo,
    setMaxQuantityCountArr,
    setPageNum,
    setSelectedCategoryIds,
} from "../../../store/slices/bookserviceinfo";
import { setMileAway, showModal, showToast } from "../../../store/slices/common";
import verifiedroundedtick from '../../../assets/images/verifiedroundedtick.svg'


const Favourites = ({ item, isExplore, inMap, fromSettings, callFavApi }) => {
    const [isFav, setIsFav] = useState(item?.isFav ? true : false);
    const navigate = useNavigate();
    const classes = getSx();
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    const favref = useRef();
    const dispatch = useDispatch();
    const callRemoveFavApi = async (id) => {
        const handleDispatch = async () => {
            const resp = await removeFavApi(id);
            if (resp?.status == 200) {
                setIsFav(false);
                if (fromSettings) callFavApi();
            }
        };
        dispatch(showModal({
            modalType: modalVariant.FAVOURITES_POPUP, resolveCallback: () => {
                handleDispatch();
                dispatch(showModal(null));
            }, rejectCallback: () => {
                dispatch(showModal(null));
            },
        }));
    };
    const callAddFavApi = async (id) => {
        if (token) {
            const resp = await addFavApi(id);
            if (resp?.status == 200) {
                setIsFav(true);
            }
        } else {
            if (role)
                dispatch(showModal({
                    modalType: modalVariant.EO_SIGN, resolveCallback: () => {
                        navigate("/signup");
                        dispatch(showModal(null));
                    }, rejectCallback: () => {
                        navigate("/login");
                        dispatch(showModal(null));
                    }
                }));
            else
                navigate("/login");

        }
    };
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            dispatch(showToast({ msg: "Link copied sucessfully!", color: "success" }))
        } catch (err) {
            dispatch(showToast({ msg: "Try Again!", color: "error" }))
        }
    };

    const handleCopyClick = () => {
        let url = `${process.env.REACT_APP_WEB_URL}service-info?id=${item?._id}`;
        copyToClipboard(url);
    };


    return inMap ?
        (
            <Box sx={{
                paddingLeft: {
                    xs: '12px',
                    sm: '12px',
                    md: '12px',
                    lg: '12px',
                    xl: '12px'
                },
                paddingRight: {
                    xs: '12px',
                    sm: '12px',
                    md: '12px',
                    lg: '12px',
                    xl: '12px'
                }, flex: '0 0 auto', width: {
                    xs: "100%", sm: inMap ? "100%" : "50%", md: "100%", lg: inMap ? "100%" : "25%",
                },
            }}>
                <Card
                    sx={{
                        width: '100%',
                        borderRadius: "30px",
                        position: "relative",
                        border: 0,
                        cursor: "pointer",
                        margin: "12px 0",
                        boxShadow: '0 4px 14.7px 0px #0000001A',
                        minHeight: {
                            xs: inMap ? "initial" : 'initial',
                            sm: inMap ? "initial" : '475px',
                            md: inMap ? "initial" : '460px',
                            lg: inMap ? "initial" : '425px',
                            xl: inMap ? "initial" : '470px'
                        },
                        maxHeight: {
                            xs: inMap ? "initial" : 'initial',
                            sm: inMap ? "initial" : '475px',
                            md: inMap ? "initial" : '440px',
                            lg: inMap ? "initial" : '425px',
                            xl: inMap ? "initial" : '470px'
                        },
                    }}
                    onClick={(event) => {
                        if (item.isAccepted) {
                            let address = `${item.address},${item.city},${item.state},${item.zipCode}`;
                            dispatch(setMileAway(address));
                        }
                        else {
                            let distance = `${item?.calcDistance?.toFixed(1)} miles away`;
                            dispatch(setMileAway(distance));
                        }
                        if (favref.current && !favref.current?.contains(event.target)) navigate(`/service-info?id=${item?._id}`);
                    }}
                >
                    {isExplore && item?.isInstaBook && (<Box
                        sx={{
                            position: "absolute",
                            top: "1rem",
                            left: "1rem",
                            background: "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
                            padding: "5px 12px",
                            borderRadius: "15px",
                            color: "white",
                            fontSize: { xs: "8px", md: "12px" },
                            fontFamily: "Montserrat, sans-serif"
                        }}
                    >
                        {item?.isInstaBook ? "Party Now" : ""}
                    </Box>)}
                    {role == "1" && (<Box
                        sx={{
                            position: "absolute",
                            top: "1rem",
                            left: "1rem",
                            background: "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
                            padding: "5px 5px",
                            borderRadius: "15px",
                            color: "white",
                            fontSize: "15px",
                            fontFamily: "Montserrat, sans-serif"
                        }}
                    >
                        {item?.services[0]?.service[0]?.name}
                    </Box>)}

                    <Box sx={{ position: "absolute", right: "1rem", top: "1.3rem" }} ref={favref}>
                        <span>
                            {" "}
                            {isFav ? (<FavoriteOutlinedIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                    callRemoveFavApi(item?._id);
                                }}
                            />) : (<FavoriteBorderIcon
                                variant="contained"
                                sx={{ color: "black", cursor: "pointer" }}
                                onClick={() => {
                                    callAddFavApi(item?._id);
                                }}
                            />)}
                            <ShareIcon style={{ color: "rgba(0, 0, 0, 1)" }} onClick={() => {
                                handleCopyClick()
                            }} />

                        </span>
                    </Box>
                    <CardContent
                        sx={{ padding: "0 !important", height: "auto", width: '100%' }}
                    >
                        <Box sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '100%',
                                lg: '160px',
                                xl: '160px'
                            },
                            float: 'left',
                            height: '220px'
                        }}>
                            <img alt=""
                                src={item?.serviceImages[0]?.fileUrl}
                                style={{ width: "100%", height: "100%", objectFit: 'cover', pointerEvents: "none !important" }}
                            />
                        </Box>
                        <Box sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '100%',
                                lg: 'calc(100% - 160px)',
                                xl: 'calc(100% - 160px)'
                            },
                            float: 'left', padding: "10px 0 10px 15px"
                        }}>
                            <Typography
                                gutterBottom
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: {
                                        xs: "14px",
                                        sm: "16px",
                                        md: "18px",
                                        lg: "20px",
                                        xl: "24px",
                                    },
                                    fontFamily: "Montserrat, sans-serif",
                                    display: 'inline-block',
                                    width: '72%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    marginBottom: 0
                                }}
                            >
                                {item?.title}
                            </Typography>
                            <Typography
                                color="rgba(113, 113, 113, 1);"
                                fontWeight="bold"
                                fontSize={item?.isAccepted ? "13px" : "14px"}

                                width="95%"
                                sx={{
                                    fontFamily: 'Montserrat,sans-serif', lineHeight: '15px', wordWrap: 'break-word'
                                }}
                            >
                                {item?.isAccepted ? `${item.address},${item.city},${item.state},${item.zipCode}` : `${item?.calcDistance?.toFixed(1)} miles away`}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex", justifyContent: "space-between", margin: "5px 0",
                                }}
                            >
                                <Box sx={{
                                    display: "flex",
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '24px'
                                }}>
                                    {" "}
                                    {item?.averageRating.toFixed(1)}{" "}
                                    <Rating
                                        value={item?.averageRating}
                                        precision={0.5}
                                        readOnly={true}
                                    />
                                </Box>
                                <AvatarGroup
                                    max={4}
                                    sx={{
                                        marginLeft: "0.5rem", "& .MuiAvatar-root": {
                                            width: 24, height: 24, fontSize: 16,
                                        },
                                    }}
                                >
                                    {item?.ratings?.map((item) => {
                                        return (<Avatar
                                            alt="Remy Sharp"
                                            size={16}
                                            src={item?.userDetails?.profileImage?.url}
                                            key={item}
                                        />);
                                    })}

                                </AvatarGroup>
                            </Box>
                            <Typography
                                variant="body1"
                                color="black"
                                fontWeight="600"
                                sx={{
                                    fontFamily: 'Montserrat,sans-serif',
                                    fontSize: '12px',
                                    lineHeight: '11px',
                                    marginBottom: '8px'
                                }}
                            >
                                Service Categories
                            </Typography>
                            <Box sx={classes.servicecont}>
                                {item?.services[0]?.categories?.map((val) => {
                                    return (<Box key={val?.categoryName} sx={classes.servicecontinfo}>
                                        {val.categoryName}
                                    </Box>);
                                })}
                            </Box>
                            {item?.services.length > 1 && (<>
                                <Typography
                                    variant="body1"
                                    color="black"
                                    fontWeight="600"
                                    sx={{
                                        fontFamily: 'Montserrat,sans-serif',
                                        fontSize: '12px',
                                        lineHeight: '11px',
                                        marginBottom: '8px'
                                    }}
                                >
                                    Add-on Services
                                </Typography>
                                <Box sx={{ ...classes.servicecont, marginBottom: 0 }}>
                                    {item?.services?.slice(1).map((item1) => {
                                        return (<Box
                                            key={item1?.service[0]?.name}
                                            sx={classes.servicecontinfo}
                                        >
                                            {item1?.service[0]?.name}
                                        </Box>);
                                    })}
                                </Box>
                            </>)}
                        </Box>
                    </CardContent>
                </Card>
            </Box>) :
        (<Box sx={{
            paddingLeft: {
                xs: '12px',
                sm: '12px',
                md: '12px',
                lg: '12px',
                xl: '12px'
            },
            paddingRight: {
                xs: '12px',
                sm: '12px',
                md: '12px',
                lg: '12px',
                xl: '12px'
            }, flex: '0 0 auto', width: {
                xs: "100%", sm: "50%", lg: inMap ? "100%" : fromSettings ? "33.33%" : "25%",
            },
        }}>
            <Card
                sx={{
                    width: '100%',
                    float: 'left',
                    borderRadius: "30px",
                    position: "relative",
                    border: 0,
                    cursor: "pointer",
                    margin: "0 0 24px",
                    boxShadow: '0 4px 14.7px 0px #0000001A',
                    minHeight: {
                        xs: inMap ? "initial" : 'initial',
                        sm: inMap ? "initial" : '475px',
                        md: inMap ? "initial" : '460px',
                        lg: inMap ? "initial" : '425px',
                        xl: inMap ? "initial" : '470px'
                    },
                    maxHeight: {
                        xs: inMap ? "initial" : 'initial',
                        sm: inMap ? "initial" : '475px',
                        md: inMap ? "initial" : '440px',
                        lg: inMap ? "initial" : '425px',
                        xl: inMap ? "initial" : '470px'
                    },
                }}
                onClick={(event) => {
                    if (item.isAccepted) {
                        let address = `${item.address},${item.city},${item.state},${item.zipCode}`;
                        dispatch(setMileAway(address));
                    }
                    else {
                        let distance = `${item?.calcDistance?.toFixed(1)} miles away`;
                        dispatch(setMileAway(distance));
                    }
                    dispatch(setFinalBookServiceInfo(null));
                    dispatch(setBookServiceDates({ startDate: null, endDate: null }));
                    dispatch(setPageNum(1));
                    dispatch(setAddOnCategoryIds([]));
                    dispatch(setMaxQuantityCountArr([]));
                    dispatch(setAddonMaxQuantityCountArr([]));
                    dispatch(setSelectedCategoryIds([]));
                    if (favref.current && !favref.current?.contains(event.target)) {
                        if (role == "1")
                            navigate(`/service-info?id=${item?._id}&provider="sp"`);
                        else
                            navigate(`/service-info?id=${item?._id}&provider="eo"`);
                    }

                }}
            >
                {isExplore && item?.isInstaBook && (<Box
                    sx={{
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                        background: "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
                        padding: isExplore ? "5px 11px" : "5px 5px",
                        borderRadius: "15px",
                        fontSize: isExplore ? "12px" : "16px",
                        color: "white",
                    }}
                >
                    {item?.isInstaBook ? "Party Now" : ""}
                </Box>)}
                {role == "1" && (<Box
                    sx={{
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                        background: "linear-gradient(to right,rgba(23, 186, 174, 1),rgba(41, 234, 165, 1))",
                        padding: "5px 5px",
                        borderRadius: "15px",
                        color: "white",
                    }}
                >
                    {item?.services[0]?.service[0]?.name}
                </Box>)}
                <Box
                    sx={{
                        position: "absolute", right: isExplore ? "1rem" : "2rem", top: "1rem",
                    }}
                    ref={favref}
                >
                    <span>
                        {isExplore && (fromSettings || isFav ? (<FavoriteOutlinedIcon
                            sx={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                                callRemoveFavApi(item?._id);
                            }}
                        />) : (<FavoriteBorderIcon
                            variant="contained"
                            sx={{ color: "#fff", fill: "#fff", cursor: "pointer" }}
                            onClick={() => {
                                callAddFavApi(item?._id);
                            }}
                        />))}
                        <ShareIcon style={{ color: "#fff" }} onClick={() => {
                            handleCopyClick()
                        }} />
                    </span>

                </Box>
                <CardMedia
                    sx={{
                        height: { xs: "200px", md: '200px', lg: "160px", xl: "205px" },
                        borderRadius: "30px 30px 0 0",
                        objectFit: 'cover',
                        aspectRatio: "2/1",
                        width: '100%',
                        pointerEvents: "none !important"
                    }}
                    image={item?.serviceImages[0]?.fileUrl}
                    title="green iguana"
                />
                <CardContent sx={{ paddingBottom: "10px" }}>
                    <Typography
                        gutterBottom
                        variant="body1"
                        sx={{
                            fontWeight: 700,
                            fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "16px",
                                lg: "20px",
                                xl: "24px",
                            },
                            fontFamily: "Montserrat, sans-serif",
                            padding: { xs: "5px 0", md: 0 }
                        }}
                    >
                        {item?.title} <span><img alt="" src={verifiedroundedtick} style={{ marginLeft: "5px", width: "24px", height: "24px", objectFit: 'cover', position: 'relative', top: '8px' }} /></span>

                    </Typography>
                    {isExplore ? (<Typography
                        variant="body2"
                        color="rgba(113, 113, 113, 1);"
                        fontWeight="bold"
                        fontSize={item?.isAccepted ? "13px" : "14px"}
                        sx={{
                            fontFamily: 'Montserrat,sans-serif', lineHeight: '15px'
                        }}
                    >
                        {item?.isAccepted ? `${item.address},${item.city},${item.state},${item.zipCode}` : `${item?.calcDistance?.toFixed(1)} miles away`}
                    </Typography>) : (<Typography
                        variant="body2"
                        color="rgba(113, 113, 113, 1);"
                        fontWeight={500}
                        fontSize={item?.isAccepted ? "13px" : "14px"}
                        sx={{
                            fontFamily: 'Montserrat,sans-serif', lineHeight: '13px'
                        }}
                    >
                        {item?.address}, {item?.state}, {item?.zipCode}
                    </Typography>)}
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: '12px 0' }}>
                        <Box sx={{
                            display: "flex",
                            fontFamily: 'Montserrat,sans-serif',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px'
                        }}>
                            {" "}
                            {item?.averageRating?.toFixed(1)}{" "}
                            <Rating
                                value={item?.averageRating}
                                precision={0.5}
                                readOnly={true}
                            />
                        </Box>
                        <AvatarGroup
                            max={4}
                            sx={{
                                marginLeft: "0.5rem", "& .MuiAvatar-root": {
                                    width: 24, height: 24, fontSize: 16,
                                },
                            }}
                        >
                            {item?.ratings?.map((item) => {
                                return (<Avatar
                                    alt="Remy Sharp"
                                    size={16}
                                    src={item?.userDetails?.profileImage?.url}
                                    key={item}
                                />);
                            })}
                        </AvatarGroup>
                    </Box>
                    <Typography sx={{
                        fontFamily: 'Montserrat,sans-serif',
                        fontSize: '12px',
                        lineHeight: '11px',
                        marginBottom: '8px'
                    }} variant="body1" color="black" fontWeight="600">
                        Service Categories
                    </Typography>
                    <Box sx={classes.servicecont}>
                        {item?.services[0]?.categories?.map((val) => {
                            return (<Box key={val?.categoryName} sx={classes.servicecontinfo}>
                                {val.categoryName}
                            </Box>);
                        })}
                    </Box>
                    {item?.services.length > 1 && (<>
                        <Typography sx={{
                            fontFamily: 'Montserrat,sans-serif',
                            fontSize: '12px',
                            lineHeight: '11px',
                            marginBottom: '8px'
                        }} variant="body1" color="black" fontWeight="600">
                            Add-on Services
                        </Typography>
                        <Box sx={classes.servicecont}>
                            {item?.services?.slice(1).map((item1) => {
                                return (<Box
                                    key={item1?.service[0]?.name}
                                    sx={classes.servicecontinfo}
                                >
                                    {item1?.service[0]?.name}
                                </Box>);
                            })}
                        </Box>
                    </>)}
                </CardContent>
            </Card>
        </Box>);
};

export default Favourites;
