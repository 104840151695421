import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    modal: null,
    toast: false,
    provider: 3,
    isTermsAccepted: false,
    milesAway: 0,
    profileInfo: {},
    serviceSelected: null,
    iconSelected:'',
    filteredInfo:{guestCount:"",city:"",category:"",budget:[0,10000],isApiCalled:false},
    days:[],
    deleteCardId:"",
    location:{lat:"",long:""},
    startTime:null,
    endTime:null

  },
  reducers: {
    showModal: (state, param) => {
      const { payload } = param;
      state.modal = payload;
    },
    showToast: (state, param) => {
      const { payload } = param;
      state.toast = payload;
    },
    setProvider: (state, param) => {
      const { payload } = param;
      state.provider = payload;
    },
    setTermsAccepted: (state, param) => {
      const { payload } = param;
      state.isTermsAccepted = payload;
    },
    setMileAway: (state, param) => {
      const { payload } = param;
      state.milesAway = payload;
    },
    setSelectedService: (state, param) => {
      const { payload } = param;
      state.serviceSelected = payload;
    },
    setIconSelected: (state, param) => {
      const { payload } = param;
      state.iconSelected = payload;
    },
    setFilteredInfo:(state,param)=>{
      const {payload}=param;
      state.filteredInfo={...state.filteredInfo,...payload};
    },
    setDays:(state,param)=>{
      const {payload}=param;
      state.days=payload;
    },
    setDelCardId:(state,param)=>{
      const {payload}=param;
      state.deleteCardId=payload;
    },
    setLocationData:(state,param)=>{
      const {payload}=param;
      state.location=payload
    },
    setStartTime:(state,param)=>{
      const {payload}=param;
      state.startTime=payload
    },
    setEndTime:(state,param)=>{
      const {payload}=param;
      state.endTime=payload
    }
  },
});

const { actions, reducer } = commonSlice;

export const {
  showModal,
  showToast,
  setProvider,
  setTermsAccepted,
  setSelectedService,
  setMileAway,
  setIconSelected,
  setFilteredInfo,
  setDays,
  setDelCardId,
  setLocationData,
  setStartTime,
  setEndTime

} = actions;
export default reducer;
