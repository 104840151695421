//signup page
import React from 'react'
import CustomCarousel from '../component/Carousel'
import {Box, Container} from '@mui/system'
import Register from './Register'
import { getSx } from './style'
import sliderimg from "../assets/images/CarouselImage.png";
import sliderimg1 from "../assets/images/sliderimg1.png";
import sliderimg2 from "../assets/images/sliderimg2.png";

const Signup = () => {
  const classes=getSx();
  let items = [
    {
      key: "1",
      fileUrl: sliderimg ,
      heading:"Discover",
      content: "Uncover a world of services and venues to bring your event to life",    },
    {
      key: "2",
      fileUrl: sliderimg2 ,
      heading:"Create",
      content:"Create your dream event with tools that turn your vision into reality."
    },
    {
      key: "3",
      fileUrl: sliderimg1 ,
      heading:"Experience",
      content:"Be in the moment and enjoy your event, free from stress and worry."
    },
  ];
  return (
    <Container sx={{
                padding: {
                xs:0,
                sm:0,
                md:0,
                lg:0,
                xl:0
            }
            }} maxWidth="xl">
      <Box sx={classes.maincontainer}>
      <Box sx={classes.leftBox}>
        <Register/>
      </Box>
      <Box sx={classes.rightBox}>
        <CustomCarousel items={items} className="carouselsliders"/>
      </Box>
    </Box>
    </Container>
  );
}
export default Signup;