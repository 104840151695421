export const storeAccessToken = (accessToken) =>
  localStorage.setItem("accessToken", accessToken);
export const IsPending = (Pending) =>
  localStorage.setItem("isPending", Pending);
export const storeIsLogin = (value) => localStorage.setItem("isLogin", value);
export const storeIsSignUp = (value) => localStorage.setItem("isSignUp", value);
export const userDetails = (value) => localStorage.setItem("User", value);

export const storeUserDetails = (userDetails) =>
  localStorage.setItem("userDetails", JSON.stringify(userDetails));

export const getAccessToken = () => localStorage.getItem("accessToken");

export const getIsSignUp = () => localStorage.getItem("isSignUp");
export const getisLogin = () => localStorage.getItem("isLogin");

export const getuserDetails = () =>
  JSON.parse(localStorage.getItem("userDetails"));

export const removeIsSignup = () => localStorage.removeItem("isSignUp");

export const removeAccessToken = () => localStorage.removeItem("accessToken");

export const storeLanguage = (val) => localStorage.setItem("language",val);
export const storeRole=(val)=> localStorage.setItem("role",val)
export const getLanguage = () => localStorage.getItem("language");
export const getRole=()=>localStorage.getItem("role");
export const getToken = () => localStorage.getItem("token");
export const setToken = (val) => localStorage.setItem("token",val);


