import instance from "../common/config/axios";
//apis for create profile
export const createProfileApi = async (val) => {
  try {
    const response = await instance.put(`user/update`, val);
    return response;
  } catch (error) {
    return error;
  }
};
export const addBankDetailsApi = async (val) => {
  try {
    const response = await instance.put(
      `user/update-banking-details`,
      val
    );
    return response;
  } catch (error) {
    return error;
  }
};
