import React, { useEffect, useState } from "react";

import ButtonComp from "../../../../component/Button";
import CustomTextField from "../../../../component/Textfield";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { getSx } from "../style";

import { getServiceListApi } from "../../../../api/addService";

const AddOnAddService = ({
  addOnCat,
  setPage,
  handleAddOnCategory,
  serviceSelected,
  isEdit,
  isAddOn,
  setServiceSelected
}) => {
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState();
  const [services, setServices] = useState([]);
  const classes = getSx();
  const handleClick = () => {
    setPage((prev) => prev + 1)
  };
  const handleFilter = (val) => {
    const resp = services?.filter((item) => {
      if (item?.name?.toLowerCase().includes(val?.toLowerCase())) return item;
    });
    setFilteredList(resp);
  }; const handleServiceChange = (newId, oldId) => {
    let removeId = serviceSelected.filter((val) => val != oldId);
    removeId = [...removeId, newId];
    setServiceSelected(removeId);
  }

  const callApi = async () => {
    setLoading(true);
    const resp = await getServiceListApi();
    setServices(resp?.data?.data);
    setFilteredList(resp?.data?.data);
    setLoading(false);
  };
  useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ ...classes.main, height: "60vh" }}>
          <CircularProgress color="#fff" />
        </Box>
      ) : (
        <Box sx={classes.main}>
          <Box sx={classes.search}>
            <CustomTextField
              backgroundColor="rgba(246, 245, 245, 1)"
              placeholder={isAddOn ? "Search Add-On Service" : "Search Service"}
              type="text"
              name="category"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
          </Box>
          <Typography variant="body" fontWeight="bold">
            {isAddOn ? "Select Add-On Service" : "Select Service"}
          </Typography>
          <Box sx={{
            height: {
              xs: "calc(100vh - 400px)",
              sm: 'calc(100vh - 400px)',
              md: 'calc(100vh - 400px)',
              lg: 'calc(100vh - 400px)',
              xl: "calc(100vh - 400px)"
            },
            overflow: "auto"
          }}>
            <Box sx={classes.catbox}>
              {filteredList?.map((item) => {
                return (
                  serviceSelected?.includes(item?._id) && item?._id != addOnCat?._id ? <Box
                    sx={{ ...classes.singleItem, background: "#fff", color: "rgba(218, 218, 218, 1)" }}
                  >
                    {item.name}
                  </Box> :
                    <Box
                      sx={
                        addOnCat?.name == item?.name
                          ? { ...classes.singleItem, ...classes.singleItemsel }
                          : classes.singleItem
                      }
                      key={item?._id}
                      onClick={() => {
                        if (!isEdit)
                          handleServiceChange(item?._id, addOnCat?._id);
                        handleAddOnCategory(item);

                      }}
                    >
                      {item.name}
                    </Box>
                );
              })}
            </Box>
          </Box>
          <ButtonComp
            label="Next"
            width="100%"
            onClick={handleClick}
            disabled={addOnCat ? false : true}
            type={addOnCat ? "active" : "disabled"}
          />
        </Box>
      )}
    </>
  );
};

export default AddOnAddService;
