import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ButtonComp from "../../../component/Button";
import { modalVariant } from "../../../component/Modal/Modalutils";
import { Box, Typography, CircularProgress } from "@mui/material";
import AddCard from "./AddCard";
import amex from "../../../assets/images/cardicons/amex.png";
import diners from "../../../assets/images/cardicons/diners.png";
import discovers from "../../../assets/images/cardicons/discover.png";
import jcb from "../../../assets/images/cardicons/jcb.png";
import mastercard from "../../../assets/images/cardicons/mastercard.png";
import unioncard from "../../../assets/images/cardicons/unioncard.png";
import visaicon from "../../../assets/images/cardicons/visaicon.svg";
import deleteicon from "../../../assets/images/deletewhite.svg";

import { getCardListApi, deleteCardApi } from "../../../api/profile";
import { popBreadCrum, pushBreadCrums } from "../../../store/slices/breadcrumb";
import { showModal } from "../../../store/slices/common";

const PaymentMethod = () => {
    const dispatch = useDispatch();
    const [cardList, setCardList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openAddCard, setOpenAddCard] = useState(false);
    const callApi = async () => {
        setLoading(true);
        const resp = await getCardListApi();
        setCardList(resp?.data?.data);
        setLoading(false);
    };
    const cardImage = {
        Discover: discovers,
        "American Express": amex,
        MasterCard: mastercard,
        UnionCard: unioncard,
        Visa: visaicon,
        Diners: diners,
        Jcb: jcb,
    };
    const handleDeleteCard = async (id) => {
        const handleDispatch = async () => {
            dispatch(showModal(null));
            setLoading(true);
            const resp = await deleteCardApi(id);
            if (resp.status === 200) {
                callApi();
            }
            setLoading(false);
        };
        dispatch(showModal({
            modalType: modalVariant.DELETE_CARD_POPUP, resolveCallback: (id) => {
                handleDispatch();
            }, rejectCallback: () => {
                dispatch(showModal(null));
            },
        }));
    };
    const handleBreadCrum = () => {
        dispatch(popBreadCrum());
        setOpenAddCard(false);
    };

    useEffect(() => {
        if (!openAddCard) {
            callApi();
        } else {
            dispatch(pushBreadCrums([{ name: "Payment Method", breadFunc: handleBreadCrum }, { name: "Add New Card" },]));
        }
    }, [openAddCard]);
    return loading ? (<Box
        sx={{
            display: "flex", justifyContent: "center", alignItems: "center", height: "30vh",
        }}
    >
        <CircularProgress />
    </Box>) : openAddCard ? (<>
        <Typography variant="h5" sx={{
            fontSize: {
                xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '28px'
            }, lineHeight: {
                xs: '26px', sm: '28px', md: '32px', lg: '36px', xl: '40px'
            }, fontWeight: 700, marginBottom: '10px', fontFamily: "Montserrat, sans-serif"
        }}>
            Add New Card
        </Typography>
        <AddCard setOpenAddCard={setOpenAddCard} />
    </>) : (<>
        <Typography variant="h5" sx={{
            fontSize: {
                xs: '16px', sm: '18px', md: '20px', lg: '24px', xl: '28px'
            }, lineHeight: {
                xs: '26px', sm: '28px', md: '32px', lg: '36px', xl: '40px'
            }, fontWeight: 700, marginBottom: '30px', fontFamily: "Montserrat, sans-serif"
        }}>
            Payment Method
        </Typography>
        <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {cardList?.map((item, index) => {
                return (<Box
                    sx={{
                        width: "300px",
                        height: "216px",
                        borderRadius: "10%",
                        padding: "12px",
                        background: "linear-gradient(31.58deg, #93278F -2.49%, #29ABE2 67.92%)",
                        margin: '0 5px 30px'
                    }}
                    key={`section${item?.id}`}
                >
                    <Box style={{ position: "relative", padding: "5px" }}>
                        <img alt=""
                            src={deleteicon}
                            style={{ position: "absolute", right: "1rem", color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                                handleDeleteCard(item.id);
                            }}
                        />
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            color="#fff"
                            width="240px"
                            marginBottom="10px"
                            fontSize="22px"
                            sx={{ fontFamily: "Montserrat, sans-serif" }}
                        >
                            {item.name.substring(0, 20) + (item.name.length >= 20 ? "..." : "")}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: "17px", md: "25px" }, fontFamily: "Montserrat, sans-serif" }}
                            fontWeight="500"
                            color="#fff"
                        >
                            **** **** **** {item.last4}
                        </Typography>
                        <Box
                            style={{
                                display: "flex",
                                color: "#fff",
                                marginTop: "1rem",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box sx={{ display: "flex" }}>
                                <Box>
                                    <Box style={{ fontSize: "15px", fontFamily: "Montserrat, sans-serif" }}>Expiry Date</Box>
                                    <Box style={{ fontSize: "13px", fontFamily: "Montserrat, sans-serif" }}>
                                        {item.exp_month}/{item.exp_year}
                                    </Box>
                                </Box>
                                <Box style={{ marginLeft: "20px" }}>
                                    <Box style={{ fontSize: "17px", fontFamily: "Montserrat, sans-serif" }}>cvv</Box>
                                    <Box style={{ fontSize: "17px", fontFamily: "Montserrat, sans-serif" }}>***</Box>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    display: "flex", alignItems: "center", color: "#fff",
                                }}
                            >
                                <img alt="" src={cardImage[item?.brand]} />
                            </Box>
                        </Box>
                    </Box>

                    <Box></Box>
                </Box>);
            })}
        </Box>
        {cardList?.length < 5 && <Box sx={{ width: { xs: '100%', sm: '50%', md: '50%', lg: "205px" } }}>
            <ButtonComp
                label="Add New Card"
                type="active"
                onClick={() => setOpenAddCard(true)}
                style={{
                    fontFamily: 'Montserrat,sans-serif', height: '60px', width: '100%', fontWeight: 600, fontSize: '20px'
                }}
            />
        </Box>}
    </>);
};

export default PaymentMethod;
