import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import ButtonComp from "../../../component/Button";
import CustomSwitch from "../../../component/Switch";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import CustomTextField from "../../../component/Textfield";
import CustomTimePicker from "../../../component/CustomTimePicker";
import { Checkbox } from "@mui/material";



export default function CustomEditServiceTiming({
  setPage,
  addService,
  setAddService,
  setIsDayAvail,
  isDayAvail,
  handleEditSubInfo,
}) {
  const [isenabled, setIsenabled] = useState(false);
  const [errors, setErrors] = useState(Array(7).fill(false));
  const [startDateOpen, setStartDateOpen] = useState([]);
  const [disableTime, setDisableTime] = useState([]);
  const [endDateOpen, setEndDateOpen] = useState([]);
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const classes = getSx();

  const handleChange = (index) => {
    let val = isDayAvail;
    val[index] = !val[index];
    setIsDayAvail([...val]);
    let val2 = addService.serviceTiming;
    let day = weekDays[index].toLowerCase();
    if (!val2[day].isAvailable) val2[day].isAvailable = true;
    else if (val2[day].isAvailable) {
      val2[day] = { startTime: null, endTime: null, isAvailable: false };
    }
    setAddService({ ...addService, serviceTiming: val2 });
    handleEnableFunc();
    setErrors(Array(7).fill(false));
  };

  const handleClick = () => {
    const modifiedServiceTiming = {};
    Object.keys(addService.serviceTiming).forEach((day) => {
      const item = addService.serviceTiming[day];
      modifiedServiceTiming[day] = {
        isAvailable: item.isAvailable,
        startTime: convertToHourFormat(item.startTime),
        endTime: convertToHourFormat(item.endTime),
        isOverNight: item.isOverNight,
      };
    });
    setAddService({ ...addService, serviceTiming: modifiedServiceTiming });
    setPage(1);
    handleEditSubInfo(2, modifiedServiceTiming);
  };
  function checkStartDateOpen(startDateOpen, index) {
    if (!Array.isArray(startDateOpen)) {
      return false;
    }
    return startDateOpen[index];
  }
  const convertToHourFormat = (time) => {
    if (time && !(typeof time == "string" && time.includes("Invalid Date"))) {
      let formattedTime = "";
      if (typeof time == "string")
        formattedTime = time;
      else {
        let utcTime = dayjs.utc(time).local();
        formattedTime = utcTime.format("hh:mm A");
      }
      if (formattedTime.startsWith("0"))
        formattedTime = formattedTime.substring(1,)
      return formattedTime;
    }
  }
  const handleStartTime = (timeval, index) => {
    let time = dayjs(timeval, "hh:mm A").utc();
    let val = disableTime;
    val[index] = dayjs(time);
    setDisableTime(val);
    let serviceTiming = addService.serviceTiming;
    let day1 = weekDays[index];
    day1 = day1?.toLowerCase();
    if (serviceTiming[day1]) {
      serviceTiming[day1].startTime = time;
    } else {
      serviceTiming[day1] = { startTime: time };
    }
    setAddService({ ...addService, serviceTiming: serviceTiming });
    if (addService?.serviceTiming[day1]?.endTime) {
      let endTime = dayjs.utc(addService?.serviceTiming[day1]?.endTime).local();
      endTime = dayjs(
        endTime,
        "h:mm A"
      ).format("HH:mm");
      let startTime = dayjs(
        timeval,
        "h:mm A"
      ).format("HH:mm");
      const isStartTimeAfterEndTime =
        dayjs(startTime, "HH:mm").isAfter(dayjs(endTime, "HH:mm")) ||
        dayjs(startTime, "HH:mm").isSame(dayjs(endTime, "HH:mm"));
      let errorArr = errors;
      if (isStartTimeAfterEndTime && !addService?.serviceTiming[day1]?.isOverNight) {
        errorArr[index] = true;
      } else {
        errorArr[index] = false;
      }
      setErrors(errorArr);
    }
    if (addService?.serviceTiming[day1]?.isOverNight) {
      if (dayjs(serviceTiming[day1]?.startTime, "hh:mm A").utc().isSame(dayjs(serviceTiming[day1]?.endTime, "hh:mm A").utc())) {
        let errorArr = errors;
        errorArr[index] = true;
        setErrors(errorArr);
      }
      else {
        let errorArr = errors;
        errorArr[index] = false;
        setErrors(errorArr);
      }
    }
    handleEnableFunc();
  };

  const handleEndTime = (timeval, index) => {

    let val = isDayAvail;
    val[index] = !val[index];
    setIsDayAvail([...val]);
    let val2 = addService.serviceTiming;
    let day = weekDays[index].toLowerCase();
    let serviceTiming = addService.serviceTiming;
    if (val2[day].isOverNight) {
      let time = dayjs(timeval, "hh:mm A").utc();
      let day1 = weekDays[index];
      day1 = day1.toLowerCase();
      if (serviceTiming[day1]) {
        serviceTiming[day1].endTime = time;
      }
      else {
        serviceTiming[day1] = { endTime: time };
      }
      if (dayjs(serviceTiming[day1].startTime, "hh:mm A").utc().isSame(dayjs(serviceTiming[day1].endTime, "hh:mm A").utc())) {
        let errorArr = errors;
        errorArr[index] = true;
        setErrors(errorArr);
      }
      else {
        let errorArr = errors;
        errorArr[index] = false;
        setErrors(errorArr);
      }
      handleEnableFunc();
    }
    else {
      let time = dayjs(timeval, "hh:mm A").utc();
      let errorArr = errors;
      if (time?.isBefore(disableTime[index]) || time?.isSame(disableTime[index]))
        errorArr[index] = true;
      else errorArr[index] = false;
      setErrors(errorArr);
      let day1 = weekDays[index];
      day1 = day1.toLowerCase();
      if (serviceTiming[day1]) {
        serviceTiming[day1].endTime = time;
      } else {
        serviceTiming[day1] = { endTime: time };
      }
    }
    setAddService({ ...addService, serviceTiming: serviceTiming });
    handleEnableFunc();
  };

  const handleKeyPress = (e, index) => {
    let val = isDayAvail;
    val[index] = !val[index];
    setIsDayAvail([...val]);
    let val2 = addService.serviceTiming;
    let day = weekDays[index].toLowerCase();
    val2[day].isOverNight = e.target.checked;
    setAddService({ ...addService, serviceTiming: val2 });
    if (!e.target.checked) {
      let serviceTiming = addService.serviceTiming;
      let day1 = weekDays[index];
      day1 = day1?.toLowerCase();
      if (dayjs(serviceTiming[day1].endTime, "hh:mm A").utc().isBefore(dayjs(serviceTiming[day1].startTime, "hh:mm A").utc())) {
        let errorArr = errors;
        errorArr[index] = true;
        setErrors(errorArr);
      }
    }
    if (e.target.checked) {
      let serviceTiming = addService.serviceTiming;
      let day1 = weekDays[index];
      day1 = day1?.toLowerCase();
      if (dayjs(serviceTiming[day1].endTime, "hh:mm A").utc().isBefore(dayjs(serviceTiming[day1].startTime, "hh:mm A").utc())) {
        let errorArr = errors;
        errorArr[index] = false;
        setErrors(errorArr);
      }
    }
    handleEnableFunc();

  }

  const handleEnableFunc = () => {
    let allDaysValid = true;
    let isDaySel = false;
    Object.keys(addService?.serviceTiming).forEach((day, index) => {
      const { isAvailable, startTime, endTime } =
        addService?.serviceTiming[day];
      if (isAvailable) {
        isDaySel = true;
        if (!startTime || !endTime || errors[index]) {
          allDaysValid = false;
        }
      }
    });
    if (!isDaySel) setIsenabled(false);
    else setIsenabled(allDaysValid);
  };

  const handleDropDownStart = (index, closeAll) => {
    if (!closeAll) {
      let startDropArray = [...startDateOpen];
      startDropArray[index] = true;
      setStartDateOpen([...startDropArray]);
    }
    else {
      setStartDateOpen([]);
    }
  };

  const handleDropDownEnd = (index, closeAll) => {
    if (!closeAll) {
      let startDropArray = [];
      if (!startDropArray) {
        startDropArray = [];
      }
      startDropArray[index] = true;
      setEndDateOpen([...startDropArray]);
    }
    else {
      setEndDateOpen([]);
    }
  };

  const getStart = (index) => {
    const timeString = convertToHourFormat(addService?.serviceTiming[days[index]]?.startTime);
    return timeString;
  };
  const getEnd = (index) => {
    const timeString = convertToHourFormat(addService?.serviceTiming[days[index]]?.endTime);
    return timeString;
  };

  useEffect(() => {
    handleEnableFunc();
  }, []);

  return (
    <Box sx={classes.boxtiming}>
      <Typography variant="body1" fontWeight="bold" margin="10px 0px">
        Edit Service Timings
      </Typography>
      <Box
        sx={{
          height: { xs: "45vh", sm: "50vh", lg: "50vh", xl: "55vh" },
          overflow: "scroll",
        }}
      >
        {weekDays.map((item, index) => {
          return (
            <>
              <Box
                sx={
                  !addService?.serviceTiming[days[index]]?.isAvailable
                    ? classes.singleBoxInfo
                    : {
                      ...classes.singleBoxInfo,
                      backgroundColor: "rgba(246, 245, 245, 1)",
                      border: "none",
                    }
                }
              >
                <Box sx={classes.headinginfo}>
                  <Typography variant="body1" fontWeight="bold">
                    {item}
                  </Typography>
                  <CustomSwitch
                    checked={
                      addService?.serviceTiming[days[index]]?.isAvailable
                    }
                    onChange={() => {
                      handleChange(index);
                    }}
                  />
                </Box>
                {addService?.serviceTiming[days[index]]?.isAvailable && (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", margin: "5px" }}>
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={addService?.serviceTiming[days[index]]?.isOverNight}
                        onChange={(e) => { handleKeyPress(e, index) }}
                        //onKeyDown={(e) => { handleKeyPress(e, index) }}
                        sx={{
                          padding: "0",
                          "& .MuiSvgIcon-root": {
                            borderRadius: "50%",
                            padding: "0px",
                          },
                          '&.Mui-checked': {
                            color: 'rgb(22, 192, 152)',
                          },
                        }}

                      />
                      <Typography variant="body1" fontWeight="bold" sx={{
                        fontFamily: "Montserrat, sans-serif",
                        fontSize: '14px',
                        textAlign: 'center',
                        color: "black"
                      }}>
                        Over Time Service Available
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: "10px", flexDirection: { xs: "column", md: "row" } }}>
                      <Box sx={{ width: { xs: "90%", md: "48%" }, position: "relative" }}>
                        <CustomTextField placeholder="Start Time"
                          value={getStart(index)}
                          textAlign="center"
                          className="timepickerinput"
                          readOnly={true}
                          onClick={() =>
                            handleDropDownStart(index, false)
                          } />
                        {checkStartDateOpen(startDateOpen, index) && <Box sx={{ position: "absolute", marginTop: "4px", zIndex: "3", width: "100%" }} className={index > 1 ? "customtimepickertop" : "custom"} >
                          <CustomTimePicker handleTimeChange={handleStartTime} index={index} isStartTime={true} handleDropDownStart={handleDropDownStart} timings={addService?.serviceTiming} fromComp="Edit Service" />
                        </Box>}
                      </Box>


                      <Box sx={{ width: { xs: "90%", md: "48%" }, position: "relative", marginBottom: { xs: "10px", md: "0px" } }}

                      >
                        <CustomTextField placeholder="End Time"
                          value={getEnd(index)}
                          textAlign="center"
                          className="timepickerinput"

                          readOnly={true}
                          onClick={() => {
                            handleDropDownEnd(index)
                          }}
                        />
                        {checkStartDateOpen(endDateOpen, index) && <Box sx={{ position: "absolute", marginTop: "4px", zIndex: "3", width: "100%" }} className={index > 1 ? "customtimepickertop" : "custom"}>
                          <CustomTimePicker handleTimeChange={handleEndTime} index={index} fromComp="Edit Service" isStartTime={false} handleDropDownStart={handleDropDownEnd} timings={addService?.serviceTiming} />
                        </Box>}
                      </Box>
                    </Box>
                    {errors[index] && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        End time should be greater than start time
                      </span>
                    )}
                  </>
                )}
              </Box>
            </>
          );
        })}
      </Box>

      <ButtonComp
        label="Update"
        width="100%"
        type={isenabled ? "active" : "disabled"}
        disabled={!isenabled}
        onClick={() => {
          handleClick();
        }}
      />
    </Box>
  );
}
