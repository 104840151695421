import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector, useDispatch } from "react-redux";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";
import blackcrossicon from "../../../assets/images/blackcrossicon.png";
import CustomTimePicker from "../../../component/CustomTimePicker";
import { setMaxQuantityCountArr, setAddonMaxQuantityCountArr } from "../../../store/slices/bookserviceinfo";
import { getCurrencyFormat } from "../../../common/constant";
import DaysMapping from "./DaysMapping";
dayjs.extend(utc);

const CustomServiceCategorySubInfo = ({
  setPage,
  selectedCategories,
  availableDays,
  setSelectedCategories,
  selectedDatesArr,
  setSelectedDatesArr,
  timings,
  setTimings,
  serviceTimings,
  isAddOn,
  handleSaveServiceCategory,
  setOpenBookServiceModal,
  handleSaveAddOnServiceCategory,
  servicesLength,
  setAddOnSelectedCategories,
  setAddBookService,
  showAmount,
}) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [timingerrors, setTimingErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [allDays, setAllDays] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(new Array(selectedCategories.length).fill(false));
  const [startDateOpen, setStartDateOpen] = useState([[]]);
  const [endDateOpen, setEndDateOpen] = useState([[]]);
  const startDate = useSelector((state) => state.bookserviceinfo.startDate);
  const endDate = useSelector((state) => state.bookserviceinfo.endDate);
  const classes = getSx();
  const catIds = useSelector((state) => state.bookserviceinfo.serviceCategoryIds);
  const addOnCatIds = useSelector((state) => state.bookserviceinfo.addOnCategoryIds);
  const maxQuantityCountArr = useSelector((state) =>
    isAddOn ? state.bookserviceinfo.addonMaxQuantityCountArr : state.bookserviceinfo.maxQuantityCountArr
  );
  const dispatch = useDispatch();

  const getDatsofAvailableDays = (startDate, endDate, availableDays) => {
    const dates = [];
    const alldays = [];
    let currentDate = new Date(startDate);
    let end = new Date(endDate);
    let adays = availableDays.map((item) => {
      if (item == "sunday") return 0;
      else if (item == "monday") return 1;
      else if (item == "tuesday") return 2;
      else if (item == "wednesday") return 3;
      else if (item == "thursday") return 4;
      else if (item == "friday") return 5;
      else if (item == "saturday") return 6;
    });
    while (dayjs(currentDate).isBefore(dayjs(end).add(1, "day"), "day")) {
      const dayOfWeek = currentDate.getDay();
      let date = dayjs(currentDate).format("YYYY-MM-DD");
      if (adays.includes(dayOfWeek)) {
        dates.push(date);
        alldays.push(date);
      } else {
        alldays.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setAllDays(alldays);
    return dates;
  };
  const handleSelectedCatDates = (item, index) => {
    let isInCat = selectedDatesArr[index]?.filter(
      (val) => dayjs(val).format("MMM DD") === dayjs(item).format("MMM DD")
    );
    let filterData =
      selectedDatesArr[index]?.filter((val) => dayjs(val).format("MMM DD") !== dayjs(item).format("MMM DD")) || [];

    let updatedDates = [...selectedDatesArr]; // Make a copy of the selected dates array
    // Step 6: If the date exists, remove it; otherwise, add it back
    //timingErrorsArr[index1][index2].errorInfo =//

    if (isInCat?.length > 0) {
      updatedDates[index] = filterData; // Remove the date
    } else {
      updatedDates[index] = [...filterData, item]; // Add the date
      // if (filterData) updatedDates[index] = [...filterData, item]; // Add the date
      // else updatedDates[index] = [item];
    }

    // Step 7: Update the selected dates state
    setSelectedDatesArr([...updatedDates]);

    let timingsArr = [...timings];
    let indexVal = selectedDatesArr[index]?.findIndex(
      (val) => dayjs(val).format("MMM DD") === dayjs(item).format("MMM DD")
    );

    if (indexVal !== -1) {
      if (timingsArr[index] && timingsArr[index][indexVal]) {
        timingsArr[index][indexVal] = null;
      }
      setTimings([...timingsArr]);
      let timingErrorsArr = timingerrors;
      if (timingErrorsArr[index] && timingErrorsArr[index].length > 0) {
        timingErrorsArr[index] = timingErrorsArr[index].filter((val, selindex) => indexVal !== selindex);
      }
      setTimingErrors([...timingErrorsArr]);
    }

    // Step 8: Handle change info if the selected category is "Per Day"
    if (selectedCategories[index].field1.value === "Per Day") {
      handleChangeInfo(updatedDates[index]?.length, index, "Total Number of Days", "field7");
    }
  };

  const handleOverNightService = (dayName, dayTimings, startTime, index1, index2, currentDate, timingsArr) => {
    const startRange = dayjs(dayTimings.startTime, "hh:mm A").utc();
    let endRange = dayjs(dayTimings.endTime, "hh:mm A").utc();
    if (dayjs(dayTimings.endTime, "hh:mm A").isBefore(dayjs(dayTimings.startTime, "hh:mm A"))) {
      endRange = dayjs(dayTimings.endTime, "hh:mm A").add(1, "day").utc();
    }

    let selectedStartTime = timingsArr[index1][index2].startTime;
    let selectedEndTime = timingsArr[index1][index2].endTime;
    if (
      dayjs(selectedEndTime, "hh:mm A").isBefore(dayjs(selectedStartTime, "hh:mm A")) ||
      dayjs(selectedEndTime, "hh:mm A").isSame(dayjs(selectedStartTime, "hh:mm A"))
    ) {
      selectedEndTime = dayjs(selectedEndTime, "hh:mm A").add(1, "day").utc();
    }

    if (selectedStartTime && selectedEndTime) {
      const isStartTimeValid =
        (selectedStartTime.isAfter(startRange) || selectedStartTime.isSame(startRange)) &&
        selectedStartTime.isBefore(endRange);
      const isEndTimeValid =
        (selectedEndTime.isBefore(endRange) || selectedEndTime.isSame(endRange)) && selectedEndTime.isAfter(startRange);
      if (!isStartTimeValid || !isEndTimeValid) {
        let timingErrorsArr = timingerrors;
        if (!timingErrorsArr[index1]) {
          timingErrorsArr[index1] = [];
        }
        if (!timingErrorsArr[index1][index2]) {
          timingErrorsArr[index1][index2] = {};
        }

        timingErrorsArr[index1][index2].startTimeError = !isStartTimeValid;
        timingErrorsArr[index1][index2].endTimeError = !isEndTimeValid;

        timingErrorsArr[index1][
          index2
        ].errorInfo = `Please select times between ${dayTimings.startTime} and ${dayTimings.endTime}. End time should also be after start time.`;
        setTimingErrors([...timingErrorsArr]);
      } else {
        let timingErrorsArr = timingerrors;
        if (timingErrorsArr[index1] && timingErrorsArr[index1][index2]) {
          timingErrorsArr[index1][index2] = {};
        }
      }
    }
  };
  const handleTimeChange = (time, index1, index2, currentDate, isStartTime) => {
    let startTime = dayjs(time, "hh:mm A").utc();
    const dateString = currentDate;
    const date = new Date(dateString);

    const currentTime = dayjs().utc();
    const isToday = dayjs(currentDate).isSame(dayjs(), "day");

    if (isToday && startTime.isBefore(currentTime)) {
      let timingErrorsArr = timingerrors;
      if (!timingErrorsArr[index1]) {
        timingErrorsArr[index1] = [];
      }
      if (!timingErrorsArr[index1][index2]) {
        timingErrorsArr[index1][index2] = {};
      }

      timingErrorsArr[index1][index2].startTimeError = true;
      timingErrorsArr[index1][index2].errorInfo = `${
        isStartTime ? "Start" : "End"
      } time cannot be less than the current time.`;
      setTimingErrors([...timingErrorsArr]);
      return;
    }

    let timingsArr = timings;
    if (!timingsArr[index1]) {
      timingsArr[index1] = [];
    }
    if (!timingsArr[index1][index2]) {
      timingsArr[index1][index2] = {};
    }
    if (isStartTime) {
      timingsArr[index1][index2].startTime = startTime;
    } else {
      timingsArr[index1][index2].endTime = startTime;
    }

    let dayOfWeek = "";
    if (!isNaN(date.getTime())) {
      dayOfWeek = date.getUTCDay();
    }
    const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayName = weekdays[dayOfWeek];
    const dayTimings = serviceTimings[dayName];
    if (isStartTime) {
      const startTimeUTC = dayjs(dayTimings.startTime, "hh:mm A").utc();
      const endTimeUTC = dayjs(dayTimings.endTime, "hh:mm A").utc();
      const givenTimeUTC = dayjs(startTime).utc();
      if (!dayTimings.isOverNight) {
        const isWithinTimeRange =
          (givenTimeUTC.isAfter(startTimeUTC) || givenTimeUTC.isSame(startTimeUTC)) &&
          givenTimeUTC.isBefore(endTimeUTC) &&
          timingsArr[index1] &&
          timingsArr[index1][index2] &&
          timingsArr[index1][index2].endTime &&
          timingsArr[index1][index2].startTime &&
          givenTimeUTC.isBefore(dayjs(timingsArr[index1][index2].endTime).utc());

        if (!isWithinTimeRange) {
          let timingErrorsArr = timingerrors;
          if (!timingErrorsArr[index1]) {
            timingErrorsArr[index1] = [];
          }
          if (!timingErrorsArr[index1][index2]) {
            timingErrorsArr[index1][index2] = {};
          }

          timingErrorsArr[index1][index2].startTimeError = true;
          timingErrorsArr[index1][index2].endTimeError = timingerrors[index1][index2].endTimeError;

          timingErrorsArr[index1][
            index2
          ].errorInfo = `Please Select the time between ${dayTimings.startTime} and ${dayTimings.endTime} , end time should be after start time.`;
          setTimingErrors([...timingErrorsArr]);
        } else {
          let timingErrorsArr = timingerrors;

          if (timingErrorsArr[index1] && timingErrorsArr[index1][index2]) {
            timingErrorsArr[index1][index2] = {};
          }
          setTimingErrors([...timingErrorsArr]);
        }
      } else {
        handleOverNightService(dayName, dayTimings, startTime, index1, index2, currentDate, timingsArr);
      }
    } else {
      const dayTimings = serviceTimings[dayName];
      const startTimeUTC = dayjs(dayTimings.startTime, "hh:mm A").utc();
      const endTimeUTC = dayjs(dayTimings.endTime, "hh:mm A").utc();
      const givenTimeUTC = dayjs(startTime).utc();
      if (!dayTimings.isOverNight) {
        const isWithinTimeRange =
          givenTimeUTC.isAfter(startTimeUTC) &&
          (givenTimeUTC.isBefore(endTimeUTC) || givenTimeUTC.isSame(endTimeUTC)) &&
          timingsArr[index1] &&
          timingsArr[index1][index2] &&
          timingsArr[index1][index2].startTime &&
          timingsArr[index1][index2].endTime &&
          givenTimeUTC.isAfter(dayjs(timingsArr[index1][index2].startTime).utc()) &&
          (timingsArr[index1][index2].startTime.isAfter(startTimeUTC) ||
            timingsArr[index1][index2].startTime.isSame(startTimeUTC)) &&
          timingsArr[index1][index2].startTime.isBefore(endTimeUTC);
        if (!isWithinTimeRange) {
          let timingErrorsArr = timingerrors;
          if (!timingErrorsArr[index1]) {
            timingErrorsArr[index1] = [];
          }
          if (!timingErrorsArr[index1][index2]) {
            timingErrorsArr[index1][index2] = {};
          }
          timingErrorsArr[index1][index2].endTimeError = true;
          timingErrorsArr[index1][index2].startTimeError = timingerrors[index1][index2].startTimeError;
          timingErrorsArr[index1][
            index2
          ].errorInfo = `Service is available between ${dayTimings.startTime} and ${dayTimings.endTime} , end time should be after start time.`;
          setTimingErrors([...timingErrorsArr]);
        } else {
          let timingErrorsArr = timingerrors;
          if (timingErrorsArr[index1] && timingErrorsArr[index1][index2]) {
            timingErrorsArr[index1][index2] = {};
          }
          setTimingErrors([...timingErrorsArr]);
        }
      } else {
        handleOverNightService(dayName, dayTimings, startTime, index1, index2, currentDate, timingsArr);
      }
    }
    setTimings([...timingsArr]);
  };

  const handleEnableFunction = () => {
    let errors = Array(selectedCategories?.length).fill(true);
    selectedCategories?.map((item, index) => {
      if (item?.field1?.value == "Hourly" || item?.field1?.value == "Fixed" || item?.field1?.value == "Fixed Cost") {
        if (!selectedDatesArr[index]) errors[index] = true;
        else {
          let errorArr = Array(selectedDatesArr[index]?.length).fill(true);
          if (selectedDatesArr[index]?.length == 0) errorArr[0] = true;
          selectedDatesArr[index]?.map((date, selindex) => {
            if (
              (timingerrors[index] && timingerrors[index][selindex] && timingerrors[index][selindex]?.startTimeError) ||
              (timingerrors[index] && timingerrors[index][selindex] && timingerrors[index][selindex]?.endTimeError) ||
              !timings[index] ||
              !timings[index][selindex] ||
              !timings[index][selindex]?.startTime ||
              !timings[index][selindex]?.endTime
            ) {
              errorArr[selindex] = true;
            } else {
              if (!maxQuantityCountArr[index]) errorArr[selindex] = false;
              else {
                if (
                  (maxQuantityCountArr[index] && maxQuantityCountArr[index] < Number(item?.field3?.value)) ||
                  !item?.field3?.value
                )
                  errorArr[selindex] = true;
                else errorArr[selindex] = false;
              }
            }
          });
          errors[index] = errorArr.some((error) => error);
        }
      } else {
        if (item?.field1?.value == "Per Day") {
          if (maxQuantityCountArr[index]) {
            if (
              item &&
              item?.field7 &&
              item?.field7?.value &&
              item?.field7?.label &&
              item?.field3 &&
              item?.field3?.label &&
              item?.field3?.value &&
              item?.field3?.value > 0 &&
              selectedDatesArr[index]?.length > 0 &&
              maxQuantityCountArr[index] >= Number(item?.field3?.value)
            ) {
              errors[index] = false;
            } else {
              errors[index] = true;
            }
          } else {
            if (item && item?.field7 && item?.field7?.value && item?.field7?.label) errors[index] = false;
            else errors[index] = true;
          }
        } else {
          if (
            item &&
            item?.field3 &&
            item?.field3?.label &&
            item?.field3?.value &&
            item?.field3?.value > 0 &&
            selectedDatesArr[index]?.length > 0
          ) {
            if (!maxQuantityCountArr[index]) errors[index] = false;
            else {
              if (maxQuantityCountArr[index] < Number(item?.field3?.value)) errors[index] = true;
              else errors[index] = false;
            }
          } else {
            errors[index] = true;
          }
        }
      }
      setDisabled(errors.some((error) => error));
    });
  };
  const handleChangeInfo = (val, index, heading, field) => {
    const selectedCat = [...selectedCategories];
    if (field == "field7") {
      if (!selectedCat[index]?.field7)
        selectedCat[index] = {
          ...selectedCat[index],
          field7: {
            label: "",
            value: "",
          },
        };
      selectedCat[index].field7 = {
        label: heading !== undefined ? heading : selectedCat[index]?.field7?.label,
        value: val !== undefined ? val : selectedCat[index]?.field7?.value,
      };
    } else {
      selectedCat[index] = {
        ...selectedCat[index],
        field3: {
          label: heading,
          value: val,
        },
      };
    }
    setSelectedCategories([...selectedCat]);
  };

  const handleDelSubCategory = (val, index) => {
    let datesArr = selectedDatesArr;
    datesArr.splice(index, 1);
    let timeArr = timings;
    timeArr.splice(index, 1);
    setTimings([...timeArr]);
    setSelectedDatesArr([...datesArr]);
    // datesArr[index] = [];

    setSelectedDatesArr([...datesArr]);
    let filteredSubCat = selectedCategories.filter((item) => item?._id != val);
    let maxQuantity = filteredSubCat.map((item) => item?.field3?.value);
    if (isAddOn) dispatch(setAddonMaxQuantityCountArr(maxQuantity));
    else dispatch(setMaxQuantityCountArr(maxQuantity));
    setSelectedCategories(filteredSubCat);
  };
  const convertToHourFormat = (time) => {
    let utcTime = dayjs.utc(time).local();
    let formattedTime = utcTime.format("hh:mm A");
    if (formattedTime.startsWith("0")) formattedTime = formattedTime.substring(1);
    return formattedTime;
  };

  function checkStartDateOpen(startDateOpen, index, selindex) {
    // Check if startDateOpen is an array and not null or undefined
    if (!Array.isArray(startDateOpen)) {
      return false;
    }

    // Check if startDateOpen[index] is an array
    if (!Array.isArray(startDateOpen[index])) {
      return false;
    }

    // Check if startDateOpen[index][selindex] exists
    if (typeof startDateOpen[index][selindex] === "undefined") {
      return false;
    }

    // If all checks pass, return the value
    return startDateOpen[index][selindex];
  }

  const getStart = (index1, index2) => {
    if (timings[index1] && timings[index1][index2] && timings[index1][index2].startTime)
      return convertToHourFormat(timings[index1][index2].startTime);
    else return "Start Time";
  };
  const getEnd = (index1, index2) => {
    if (timings[index1] && timings[index1][index2] && timings[index1][index2].endTime) {
      return convertToHourFormat(timings[index1][index2].endTime);
    } else return "End Time";
  };

  useEffect(() => {
    const availdates = getDatsofAvailableDays(startDate, endDate, availableDays);
    setAvailableDates(availdates);
  }, []);

  const handleDropDownStart = (index, selindex, closeAll) => {
    if (!closeAll) {
      let startDropArray = [[]]; // Close all dropdowns
      if (!startDropArray[index]) {
        startDropArray[index] = [];
      }
      startDropArray[index][selindex] = true; // Open the selected dropdown
      setStartDateOpen([...startDropArray]);
    } else {
      setStartDateOpen([[]]);
    }
  };
  const handleDropDownEnd = (index, selindex, closeAll) => {
    if (!closeAll) {
      let startDropArray = [[]]; // Close all dropdowns
      if (!startDropArray[index]) {
        startDropArray[index] = [];
      }
      startDropArray[index][selindex] = true; // Open the selected dropdown
      setEndDateOpen([...startDropArray]);
    } else {
      setEndDateOpen([[]]);
    }
  };

  useEffect(() => {
    handleEnableFunction();
  }, [selectedCategories, selectedDatesArr, timings, timingerrors]);

  return (
    <>
      <Box
        sx={{
          minHeight: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
          height: {
            xs: "calc(100vh - 300px)",
            sm: "calc(100vh - 600px)",
            md: "calc(100vh - 700px)",
            lg: "calc(100vh - 220px)",
            xl: "calc(100vh - 300px)",
          },
          overflow: "auto",
          marginTop: {
            xs: 0,
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "30px",
          },

          paddingBottom: {
            xs: "10px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "30px",
          },
        }}
      >
        {selectedCategories?.map((item, index) => {
          return (
            <Box sx={classes.subcatinfo} key={item}>
              <Box sx={classes.singlebox}>
                <Box sx={classes.headinfo}>
                  <Box className="flexbox" sx={{ alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        fontFamily: "Montserrat, sans-serif",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "100%",
                        overflow: "auto",
                      }}
                      fontSize={{ xs: "11px", sm: "12px", md: "13px", lg: "14px", xl: "15px" }}
                    >
                      {item?.categoryId?.categoryName}
                      <span>({getCurrencyFormat(item?.field2.value)}/</span>
                      <span style={{ fontWieght: "400", color: "black" }}>{item?.field1?.value}</span>)
                    </Typography>
                    <img
                      alt=""
                      src={blackcrossicon}
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        handleDelSubCategory(item?._id, index);
                      }}
                    />
                  </Box>
                </Box>
                <DaysMapping
                  allDays={allDays}
                  availableDates={availableDates}
                  selectedDatesArr={selectedDatesArr}
                  handleSelectedCatDates={handleSelectedCatDates}
                  setIsButtonClicked={setIsButtonClicked}
                  index={index}
                  isButtonClicked={isButtonClicked}
                />

                {isButtonClicked[index] && (
                  <Box sx={{ color: "red", fontFamily: "Montserrat, sans-serif" }}>Service is not available </Box>
                )}
                {(item?.field1?.value == "Hourly" ||
                  item?.field1?.value == "Fixed" ||
                  item?.field1?.value == "Fixed Cost") &&
                  selectedDatesArr[index]?.map((item, selindex) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100px",
                              fontWeight: "600",
                              textAlign: "center",
                              marginRight: "10px",
                              marginTop: { xs: "0px", md: "-7px" },
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            {dayjs(item).format("MMM DD")}
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              flexDirection: { xs: "row", md: "row" },
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: "48%", md: "48%" },
                                marginBottom: 0,
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid rgb(217, 217, 217)",
                                  width: "100%",
                                  height: "45px",
                                  alignItems: "center",
                                  color: getStart(index, selindex) != null ? "black" : "rgb(217, 217, 217)",
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  handleDropDownStart(index, selindex, false);
                                }}
                              >
                                {getStart(index, selindex)}
                              </Box>
                              {checkStartDateOpen(startDateOpen, index, selindex) && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    marginTop: "4px",
                                    zIndex: "3",
                                  }}
                                  className={
                                    index > 0 || (index == 0 && selindex > 1) ? "customtimepickertop" : "custom"
                                  }
                                >
                                  <CustomTimePicker
                                    handleTimeChange={handleTimeChange}
                                    index={index}
                                    timings={timings}
                                    selindex={selindex}
                                    currentDate={item}
                                    isStartTime={true}
                                    handleDropDownStart={handleDropDownStart}
                                  />
                                </Box>
                              )}
                            </Box>

                            <Box
                              sx={{
                                width: { xs: "48%", md: "48%" },
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  border: "1px solid rgb(217, 217, 217)",
                                  width: "100%",
                                  height: "45px",
                                  color: getStart(index, selindex) != null ? "black" : "rgb(217, 217, 217)",
                                  fontSize: "18px",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  handleDropDownEnd(index, selindex);
                                }}
                              >
                                {getEnd(index, selindex)}
                              </Box>

                              {checkStartDateOpen(endDateOpen, index, selindex) && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    marginTop: "4px",
                                    zIndex: "3",
                                  }}
                                  className={
                                    index > 0 || (index == 0 && selindex > 1) ? "customtimepickertop" : "custom"
                                  }
                                >
                                  <CustomTimePicker
                                    handleTimeChange={handleTimeChange}
                                    index={index}
                                    selindex={selindex}
                                    currentDate={item}
                                    isStartTime={false}
                                    handleDropDownStart={handleDropDownEnd}
                                    timings={timings}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {((timingerrors[index] &&
                          timingerrors[index][selindex] &&
                          timingerrors[index][selindex].startTimeError) ||
                          (timingerrors[index] &&
                            timingerrors[index][selindex] &&
                            timingerrors[index][selindex].endTimeError)) && (
                          <Box
                            sx={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "10px",
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            {timingerrors[index][selindex]?.errorInfo}
                          </Box>
                        )}
                      </>
                    );
                  })}
                {item.field3.label && maxQuantityCountArr[index] && maxQuantityCountArr[index] != 0 && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "13px", md: "18px" },
                        margin: "0.5rem 0px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {item.field3.label}
                    </Typography>

                    <CustomTextField
                      backgroundColor="rgba(246, 245, 245, 1)"
                      placeholder={item.field3.label}
                      value={item?.field3?.value}
                      name="guestCapacity"
                      allowdot={true}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      onChange={(e) => {
                        handleChangeInfo(e.target.value, index, item.field3.label, "field3");
                      }}
                    />
                    {maxQuantityCountArr[index] < Number(item?.field3?.value) && (
                      <Box
                        sx={{ color: "red", fontFamily: "Montserrat, sans-serif" }}
                      >{`maximum available quanitity is ${maxQuantityCountArr[index]}`}</Box>
                    )}
                  </>
                )}
                {(!maxQuantityCountArr[index] || maxQuantityCountArr[index] == 0) &&
                  item?.field1?.value == "Per Person" && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: "13px", md: "18px" },
                          margin: "0.5rem 0px",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Total Number of Guest Count
                      </Typography>

                      <CustomTextField
                        backgroundColor="rgba(246, 245, 245, 1)"
                        placeholder="Total Number of Guest Count"
                        value={item?.field3?.value}
                        name="guestCapacity"
                        allowdot={true}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          handleChangeInfo(e.target.value, index, "Total Number of Guest Count", "field3");
                        }}
                      />
                    </>
                  )}

                {item?.field1?.value == "Per Day" && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "13px", md: "18px" },
                        margin: "0.5rem 0px",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      Total Number of Days
                    </Typography>

                    <CustomTextField
                      backgroundColor="rgba(246, 245, 245, 1)"
                      placeholder="Enter number of days"
                      value={item?.field7?.value}
                      name="guestCapacity"
                    />
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box>
        <ButtonComp
          label="Continue"
          width="100%"
          type={disabled || selectedCategories.length == 0 ? "disabled" : "active"}
          disabled={disabled || selectedCategories.length == 0 ? true : false}
          onClick={() => {
            if (isAddOn) {
              if (addOnCatIds?.length > 0 || showAmount) {
                setOpenBookServiceModal(false);
                handleSaveAddOnServiceCategory();
                setAddBookService({
                  description: "",
                  address: "",
                  state: "",
                  location: {},
                  zipCode: "",
                });
              } else setPage(5);
            } else {
              setAddOnSelectedCategories([]);
              if (catIds.length > 0 || showAmount) {
                setOpenBookServiceModal(false);
                handleSaveServiceCategory();
              } else {
                if (servicesLength > 1) setPage(3);
                else setPage(5);
              }
            }
          }}
        />
      </Box>
    </>
  );
};

export default CustomServiceCategorySubInfo;
