import React from "react";

import { Box, Typography } from "@mui/material";

const ImagesView = ({ info, setImageModal }) => {
  return (
    <>
      {info?.length >= 2 && info?.length <= 4 && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            margin: "15px 0",
            position: "relative",
            justifyContent:'space-between'
          }}
        >
          <Box sx={{width: "49%" }}>
            <img alt=""
              src={info[0]?.fileUrl}
              style={{
                width: "100%",
                borderRadius: "12px",
                aspectRatio:'2/1',
                objectFit:'cover'
              }}
            />
          </Box>
          <Box sx={{ width: "49%", position: "relative" }}>
            <img alt=""
              src={info[1]?.fileUrl}
              style={{
                width: "100%",
                aspectRatio:'2/1',
                objectFit:'cover',
                borderRadius: "12px",
              }}
            />
          </Box>
          {info?.length > 2 && (
            <Typography
              color="black"
              sx={{
                position: "absolute",
                bottom: "2rem",
                right: "2rem",
                backgroundColor: "#fff",
                borderRadius: "10px",
                fontFamily:"Montserrat, sans-serif",
                padding: "10px",
                cursor: "pointer",
                fontSize:{xs:"0.6rem",sm:"1rem"},
              }}
              onClick={() => {
                setImageModal(true);
              }}
            >
              +{info?.length - 2} more {info?.length == 3 ? "Photo" : "Photos"}
            </Typography>
          )}
        </Box>
      )}

      {info?.length > 4 && (
        <Box
          sx={{
            width: "100%",
            height:'auto',
            display: "flex",
            margin: '15px 0',
            position: "relative",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <img alt=""
              src={info[0]?.fileUrl}
              style={{
                width: "98%",
                height: "98%",
                borderRadius: "12px",
                aspectRatio:'2/1',
                objectFit:'cover'
              }}
            />
          </Box>
          <Box sx={{ width: "25%", display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
            <Box sx={{ width: "100%", height: "49%" }}>
              <img alt=""
                src={info[1]?.fileUrl}
                style={{ width: "97%", height: "96%",
                  aspectRatio:'2/1',
                objectFit:'cover',borderRadius:'12px' }}
              />
            </Box>
            <Box sx={{ width: "100%", height: "49%" }}>
              <img alt=""
                src={info[2]?.fileUrl}
                style={{ width: "97%", height: "96%",
                  aspectRatio:'2/1',
                objectFit:'cover',borderRadius:'12px' }}
              />
            </Box>
          </Box>
          <Box sx={{ width: "25%", display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
            <Box sx={{ width: "100%", height: "49%" }}>
              <img alt=""
                src={info[3]?.fileUrl}
                style={{
                  width: "97%",
                  height: "96%",
                  borderRadius: "12px",
                  aspectRatio:'2/1',
                objectFit:'cover'
                }}
              />
            </Box>
            <Box sx={{ width: "100%", height: "49%", position: "relative" }}>
              <img alt=""
                src={info[4]?.fileUrl}
                style={{
                  width: "97%",
                  height: "96%",
                  borderRadius: "12px",
                  aspectRatio:'2/1',
                objectFit:'cover'
                }}
              />
            </Box>
            {info?.length > 5 && (
              <Typography
                color="black"
                sx={{
                  position: "absolute",
                  bottom: "2rem",
                  right: "2rem",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  fontFamily:"Montserrat, sans-serif",
                  padding: "10px",
                  cursor: "pointer",
                  width: "150px",
                }}
                onClick={() => {
                  setImageModal(true);
                }}
              >
                +{info?.length - 5} more{" "}
                {info?.length == 6 ? "Photo" : "Photos"}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ImagesView;
