export const getSx = () => {
    return {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: { padding: { xs: "15px 0", sm: "15px 0", md: '15px 0', lg: '15px 0', xl: '30px 0' } },
        }, addserviceheading: {
            display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
        }, addserheding: {
            fontSize: {
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "25px",
                xl: "25px"
            }, color: '#111111', marginBottom: {
                xs: '10px',
                sm: '10px',
                md: '10px',
                lg: '15px',
                xl: '24px'
            }, fontWeight: "600", fontFamily: 'Montserrat, sans-serif'
        }, main: {
            width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
        }, search: {
            width: "100%",
            height: "50px",
            display: "flex",
            justifyContent: "flex-start",
            margin: "20px 0",
            borderRadius: "10px",
            alignItems: "center",
        }, catbox: {
            display: "flex", padding: "10px", flexWrap: "wrap", width: "100%", overflow: "auto",
        }, singleItem: {
            margin: "5px",
            fontSize: "18px",
            border: "1px solid rgba(218, 218, 218, 1)",
            padding: "5px 10px 5px 10px",
            borderRadius: "2rem",
            fontWeight: "500",
            color: "rgba(0, 0, 0, 1)",
            cursor: "pointer",
        }, singleItemsel: {
            background: "linear-gradient(to right,rgba(23, 186, 174, 0.1),rgba(41, 234, 165, 0.1))",
            color: " rgba(23, 186, 174, 1)",
            border: "1px solid rgba(23, 186, 174, 0.2)",
        }, singlebox: {
            width: "100%",
            borderRadius: "15px",
            padding: "25px 25px 25px 10px",
            border: "1px solid rgba(218, 218, 218, 1)",
            position: "relative",
            paddingTop: "1.5rem",
        }, headinfo: {
            position: "absolute",
            top: "-1.5rem",
            backgroundColor: "#E9FCF6",
            width: "90%",
            margin: 'auto',
            borderRadius: "14px",
            minHeight: "40px",
            padding: "10px",
            display: 'flex',
            alignItems: 'center'
        }, subcatinfo: {
            marginTop: "2rem",
        }, descinfo: {
            width: "100%",
        }, desccont: {
            width: { xs: "100%", md: "90%" }, margin: "auto",
        }, descedit: {
            width: "100%"
        }, boxtiming: {
            width: "100%",
        }, singleBoxInfo: {
            width: "100%",
            border: "1px solid gray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "between",
            padding: "15px",
            borderRadius: "15px",
            marginBottom: "15px",
        }, headinginfo: {
            display: "flex", justifyContent: "space-between", alignItems: "center",
        }, addserviceimg: {
            border: "2px solid rgba(23, 186, 174, 1)",
            borderStyle: "dashed",
            width: "150px",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
        }, timinginfo: {
            fontSize: "14px", color: "rgba(0, 0, 0, 0.5)", margin: "1rem 0px",
        }, addressmain: {
            width: "100%", minHeight: {
                xs: "auto",
                sm: 'auto',
                md: 'auto',
                lg: 'auto',
                xl: "auto"
            },
            maxHeight: {
                xs: "calc(100vh - 400px)",
                sm: 'calc(100vh - 400px)',
                md: 'calc(100vh - 600px)',
                lg: 'calc(100vh - 345px)',
                xl: "calc(100vh - 450px)"
            }, overflow: "auto"
        }, addressmainedit: {
            width: "100%",
        }, aminitiesbox: {
            width: { xs: "100%", md: "80%" },
            margin: "10px auto",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
        }, aminities: {
            padding: "10px",
            border: "1px solid rgba(205, 205, 205, 1)",
            borderRadius: "10px",
            width: "107px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            margin: "10px",
            alignItems: "center",
            cursor: "pointer"
        }, aminitiname: {
            color: "black", fontWeight: "bold", fontSize: "13px",
        }, instainfo: {
            background: "rgba(23, 186, 174, 0.1)",
            color: "rgba(23, 186, 174, 1)",
            borderRadius: "10px",
            margin: "10px 0px",
            padding: "10px",
            fontSize: "14px",
            fontFamily: 'Montserrat, sans-serif'
        }, instabookcont: {
            border: "1px solid rgba(218, 218, 218, 1)",
            borderRadius: "22px",
            height: "65px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
        }, leftinfoinsta: {
            display: "flex", justifyContent: "center", alignItems: "center",
        }, buttoncomp: {
            display: "flex", width: "100%",
        }, addonbutton: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: { xs: "1rem", lg: "1rem", xl: "1rem" },
        }, addimgcont: {
            width: "180px",
            height: "180px",
            border: "2px solid #17BAAE",
            borderStyle: "dashed",
            borderRadius: "33px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }, cardimages: {
            marginLeft: "10px",
            flexDirection: "row",
            display: "flex",
            width: { xs: "calc(100% - 160px)", md: "calc(100% - 200px)" },
            overflowX: "scroll",
            overflowY: "hidden",

        }, serviceimages: {
            display: "flex", flexDirection: "row", height: "170px",
        }, singleimg: {
            width: "165px", height: "165px", marginRight: "10px", position: "relative",
        }, mainwithspace: {
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid rgba(218, 218, 218, 1)",
            padding: "15px",
            borderRadius: "10px",
            width: "100%",
        }, leftinfo: {
            display: "flex", flexDirection: "row", width: "100%",
        }, leftimg: {
            width: "30%", height: "90px", marginRight: "20px",
        }, listcat: {
            width: "calc(100% - 130px)", display: "flex", flexDirection: "column",
        }, catlist: {
            display: "flex", flexWrap: "wrap", width: "100%", overflowY: "auto", maxHeight: "8vh"
        }, buttoncomplist: {
            display: "flex", marginTop: "1rem", justifyContent: "space-between",
        }, info: {
            width: { xs: "80%", md: "30%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }, googleautosuggestionlist: {
            marginTop: "0px",
            width: "100%",
            height: "154px",
            display: "flex",
            flex: "1 1 0%",
            flexDirection: "column",
            marginBottom: "108px",
            position: "absolute",
            zIndex: "1123",
            background: "#fff",
            padding: "0 10px",
            overflowY: "auto",
        }, addonserviceimg: {
            marginTop: "8vh",
            marginBottom: '30px'
        }, editheading: {
            display: "flex", justifyContent: "space-between", margin: "10px 0px"
        }, editheadinginfo: {
            fontWeight: "bold", fontSize: {
                xs: '14px',
                sm: '15px',
                md: '15px',
                lg: '16px',
                xl: '18px'
            }, color: "black", fontFamily: "Montserrat, sans-serif"

        }, editfeeinfo: {
            color: "black",
            backgroundColor: "rgba(246, 245, 245, 1)",
            padding: "15px",
            borderRadius: "13px",
            margin: "10px 0px",
            fontFamily: "Montserrat, sans-serif "

        }, editday: {
            fontWeight: "600"
        }, edittime: {
            color: "rgba(23, 186, 174, 1)"
        },

        rightinfo: {},
    };
};
