import React, { useState, useEffect } from "react";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { getSx } from "./style";

import { getCategoryListApi } from "../../../api/addService";

const AddServiceCategory = ({
  subCategory,
  setSubCategory,
  setPage,
  category,
  setSubCategories,
  subCategories,
  setAddService,
  addService,
  isAddOn,
  isEdit
}) => {
  const classes = getSx();
  const [loading, setLoading] = useState(false);
  const [filteredList,setFilteredList]=useState();
  const [value,setValue]=useState();

  const handleClick = () => {
    if(!isEdit)
    {
    setPage(3);
    setAddService({ ...addService, categories: [] });
    }
    else
    {
      setPage((page)=>page+1)
    }
  };
  const callApi = async () => {
    setLoading(true);
    const resp = await getCategoryListApi(category?._id);
    setSubCategories(resp?.data?.data);
    setFilteredList(resp?.data?.data);
    setLoading(false);
  };
  const handleFilter=(val)=>{
   const resp=subCategories.filter((item)=>{
  if(item.categoryName.toLowerCase().includes(val.toLowerCase()))
  return item;
   })
   setFilteredList(resp)
  }
  const handleSetSubCategory = (val) => {
    let isInCat = subCategory?.filter((item) => item?._id == val?._id);
    let filterData = subCategory?.filter((item) => item?._id != val?._id);

    if(isInCat.length>0)
    setSubCategory([...filterData]) ;
  else
  setSubCategory([...filterData,val]) ;

      };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {loading ? (
        <Box sx={{ ...classes.main, height: "60vh" }}>
          <CircularProgress color="#fff" />
        </Box>
      ) : (
        <Box sx={classes.main}>
          <Box sx={classes.search}>
            <CustomTextField
              backgroundColor="rgba(246, 245, 245, 1)"
              placeholder={  isAddOn?"Search Add-On Service Category":"Search Service Category"}
              type="text"
              name="category"
              value={value}
              onChange={(e) => {
                handleFilter(e.target.value);
                setValue(e.target.value)
              }}
            />
          </Box>
          <Typography variant="body" fontWeight="bold">
          {  isAddOn?"Select Add-On Service Category":"Select Service Category"}
          </Typography>
          <Box sx={{ minHeight: {
              xs: "auto",
              sm:'auto',
              md:'auto',
              lg:'auto',
              xl: "auto"
            },
            maxHeight: {
              xs: "calc(100vh - 400px)",
              sm:'calc(100vh - 400px)',
              md:'calc(100vh - 600px)',
              lg:'calc(100vh - 345px)',
              xl: "calc(100vh - 450px)"
            }, overflow: "auto" }}>
            <Box sx={classes.catbox}>
              {filteredList?.map((item) => {
                return (
                  <Box style={{cursor:'pointer'}}
                    sx={
                      subCategory?.some(subItem => subItem._id === item._id)
                    
                        ? { ...classes.singleItem, ...classes.singleItemsel }
                        : classes.singleItem
                    }
                    key={item}
                    onClick={() => {
                      handleSetSubCategory(item);
                    }}
                  >
                    {item.categoryName}
                  </Box>
                );
              })}
            </Box>
          </Box>
          <ButtonComp
            label="Next"
            onClick={handleClick}
            disabled={subCategory.length ? false : true}
            type={subCategory.length ? "active" : "disabled"}
            style={{height:'60px', fontFamily:"Montserrat, sans-serif", fontSize:'16px',width:'100%',fontWeight:600}}
          />
        </Box>
      )}
    </>
  );
};

export default AddServiceCategory;
