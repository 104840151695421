export const getSx = () => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      height: "100vh",
      width: {
        xs:'90%',
        sm:'450px',
        md:'450px',
        lg:'450px',
        xl:'450px'
      },
    },
    maincontainer: {
      width: '100%',
      height: 'auto',
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding:{
        xs:'60px 0',
        sm:'100px 0',
        md:'100px 0',
        lg:'30px 0',
        xl:'50px 0'
      }
    },
    inputinfo: {
      width: "100%",
    },
    heading: {
      fontSize: { xs: "20px", md: "24px", lg:'36px',xl:'34px' },
      fontWeight: 700,
      marginBottom:"10px",
      marginTop:{
        xs:'15px',
        sm:'15px',
        md:'15px',
        lg:'15px',
        xl:'20px'
      },
      lineHeight:'33px',
      fontFamily:"Montserrat, sans-serif"
    },
    content: {
      color: "#0000008C",
      fontSize:'18px',
      lineHeight:'30px',
      marginBottom:'25px',
      fontFamily:"Montserrat, sans-serif"
    },
    forgotpassword: {
      display: "flex",
      justifyContent: "flex-end",
      width: "95%",
      padding: "10px",
      color: "rgba(23, 186, 174, 1)",
      fontWeight: "600",
      fontSize: "14px",
      marginBottom:"1rem",
      marginTop:"0.3rem",
      fontFamily:"Montserrat, sans-serif"
    },
    donthaveacc: {
      color: "#6B6B6B",
      fontWeight: "500",
      marginTop:{
        xs:'20px',
        sm:'20px',
        md:'20px',
        lg:'20px',
        xl:'25px'
      },
      fontSize:'16px',
      lineHeight:'20px',
      fontFamily:"Montserrat, sans-serif"
    },
    checkboxinfo: {
      margin: "10px",
    },
    resetcontainer: {
      width: "100vw",
      height: "100vh",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    resetinsidecontainer: {
      width: {
        xs: "100%",
        sm: "90%",
        md: "80%",
        lg:"80%",
        xl:"80%",
      },
      height: {
        xs: "calc(100vh - 100px)",
        sm:"calc(100vh - 400px)",
        md: "calc(100vh - 80px)",
        lg:"calc(100vh - 80px)",
        xl:"calc(100vh - 160px)",
      },
      border: "1px solid rgba(233, 233, 233, 1)",
      padding: "10px",
      borderRadius: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      overflow:'auto'
    },
    backsigin: {
      marginTop: {
        xs:'15px',
        sm:'20px',
        md:'20px',
        lg:'30px',
        xl:"50px"
      },
      color:'#000000',
      fontWeight:500,
      fontSize:{
        xs:'13px',
        sm:'14px',
        md:'14px',
        lg:'15px',
        xl:'16px'
      },
      lineHeight:'20px',
      fontFamily:"Montserrat, sans-serif"
    },
    resetmaincontainer: {
      width: { xs: "90%",sm: "65%", md: "55%", lg: "45%", xl: "45%" },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    inputinforeset: {
      width: "100%",
    },
    resetheading: {
      fontSize: { xs: "18px", md: "20px", lg:'24px', xl:'28px'},
      fontWeight: 700,
      padding: {
        xs:"15px 0 5px",
        sm:"20px 0 10px",
        md:"20px 0 10px",
        lg:"20px 0 10px",
        xl:"40px 0 10px"
      },
      lineHeight:{ xs: "28px", md: "30px", lg:'34px', xl:'38px'},
      textAlign: "center",
      display:'block',
      fontFamily:"Montserrat, sans-serif"
    },
    resetcontent: {
      color: "rgba(167, 167, 167, 1)",
      textAlign: "center",
      fontSize:{
        xs:'13px',
        sm:'13px',
        md:'14px',
        lg:'15px',
        xl:'16px'
      },
      lineHeight:{
        xs:'21px',
        sm:'21px',
        md:'24px',
        lg:'25px',
        xl:'27px'
      },
      fontWeight:500,
      marginBottom:{
        xs:0,
        sm:'10px',
        md:'10px',
        lg:'10px',
        xl:'30px'
      },
      fontFamily:"Montserrat, sans-serif"
    },
    logo: {
      width: { xs: "100px", md: "150px",lg:"200px",xl:"250px" },

    },
    rightarrowicon: {
      position: "absolute",
      left: { xs: "1rem", md: "2rem" },
      top: "2rem",
    },
  };
};
